import React from "react";
import { useLanguage } from "../../../../language/LanguageController";
import PropTypes from "prop-types";

const ShiftSection = ({ shift, disable_morning, disable_evening, handleInput }) => {

    const { t } = useLanguage();

    return (
        <div className="form-group clearfix">
            <div className="btn-group d-flex" role="group" aria-label={t("text_shift")}>
                <label
                    className={`btn mb-0 ${shift === "morning" ? "bg-light-blue text-white active" : "btn-light"} ${(disable_morning) && "disabled"}`}
                    htmlFor="day_time_morning"
                    role="button"
                >
                    <input
                        type="radio"
                        id="day_time_morning"
                        name="type" 
                        value="morning"
                        required
                        className="sr-only"
                        disabled={disable_morning}
                        checked={shift === "morning"}
                        onChange={handleInput}/>
                    <i className="fa fa-sun"></i>
                    &nbsp;{t("text_morning")}
                </label>
                <label
                    className={`btn mb-0 ${shift === "evening" ? "bg-light-blue text-white active" : "btn-light"} ${(disable_evening) && "disabled"}`}
                    htmlFor="day_time_evening"
                    role="button"
                >
                    <input
                        type="radio"
                        id="day_time_evening"
                        name="type"
                        value="evening"
                        required
                        className="sr-only"
                        disabled={disable_evening}
                        checked={shift === "evening"}
                        onChange={handleInput}/>
                    <i className="fa fa-moon"></i>
                    &nbsp;{t("text_evening")}
                </label>
            </div>
        </div>
    );
};

ShiftSection.propTypes = {
    shift: PropTypes.string.isRequired,
    disable_evening: PropTypes.bool.isRequired,
    handleInput: PropTypes.func.isRequired
};

export default ShiftSection;