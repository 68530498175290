// @ts-nocheck
import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from '@material-ui/core';
import "../subscription.css";
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CouponComponenet from "../CouponComponent";
import CheckoutAddress from './CheckoutAddress';
import { connect } from 'react-redux';
import { calculateTotal, getCheckoutAccountBalance, getExtraDays, getPriceByDuration, getRemainingBalance, getSubscriptionRemainingDays, getSubTotal } from "../../../library/pricing";
import { currencyFormat, loader, notification, replaceAll } from '../../../library/util';
import request from '../../../library/request';
import paymentService, { getDurations } from '../paymentService';
import { errorHandler } from '../../../library/response';
import { fetchSubscriptionSuccess } from "../../../../actions/subscriptions";
import { toggleSubscriptionUpsalePopup } from "../../../../actions";
import { styles, StyledTableCell } from "./style";
import withLanguage, { getLang } from "../../../language/LanguageController";
import SubscriptionUpsaleModal from '../../../modalPopups/subscriptionUpsaleModal';
import analytics from '../../../library/firebase/analytics';
import { withRouter } from 'react-router';
import { DefaultLayout } from "../../common/Layout";
import Eventer from '../../../library/Eventer';
import RedeemComponent from '../RedeemComponent'
import { getPoints, calculateCoupon, calculateRewardBalance} from "../../../library/reward";
import Addons from '../Addons';


class Checkout extends Component {
    spacing = 2;

    constructor (props) {
        super(props);
        this.changePage = false;
        this.isFirstBack = true;
        this.isFirstPay = true;
        const { plan, duration = 12, defaultCouponDuration = 12, durations = getDurations(plan), defaultCoupon, renew = false } = this.props.history?.location?.state || {};
        this.state = {
            plan: plan,
            duration: defaultCoupon ? defaultCouponDuration : duration,
            durations: durations,
            isUpsaleOpen: false,
            couponCode: defaultCoupon,
            rewardPoints:null,
            redeem:false,
            selectedAddons: this.props?.history?.location?.state?.selectedAddons || [],
            renew
        }
        this.applyCoupon = this.applyCoupon.bind(this);
        this.applyDefaultCoupon = this.applyDefaultCoupon.bind(this);
    }

    componentDidMount () {
        analytics.setScreenName("Checkout");
        const { plan } = this.state;
        if(this.props.history?.location?.state?.defaultCoupon) {
            this.isFirstBack = false;
            this.isFirstPay = false;
            this.applyCoupon()
        } else if(plan) {
            this.applyDefaultCoupon();
        }
        Eventer.on("planDurationChanged", this.selectDuration);
        Eventer.on("backbutton", this.onDeviceBackButton);
    }

    componentWillUnmount () {
        Eventer.off("planDurationChanged", this.selectDuration);
        Eventer.off("backbutton", this.onDeviceBackButton);
    }

    componentDidUpdate () {
        if(this.changePage) {
            this.changePage = false;
            this.props.history.replace({
                pathname: "/subscription",
                state: {
                    paymentSuccess: true,
                    subscription: this.props.subscription
                }
            });
        }
    }

    selectPlan = (event) => {
        const selectedPlanId = event.target.value;
        this.setState({
            plan: this.props.plans.find(plan => plan.plan_id == selectedPlanId)
        }, this.clearCoupon);
    }

    selectDuration = ({ detail }) => {
        this.setState({
            duration: detail.duration
        }, this.clearCoupon);
    }

    async applyDefaultCoupon () {
        try {
            const { plan, duration } = this.state;
            if(plan?.plan_id) {
                loader.show(this.props.t("text_loading"));
                const formData = new FormData();
                formData.append("plan_id", plan.plan_id);
                formData.append("plan_duration", duration);
                const { status, data } = await request.post("/subscription/coupon/apply/default", {
                    withAuth: true,
                    body: formData
                });
                if(status) {
                    const { coupon, ...restData } = data;
                    this.setState({
                        couponCode: coupon,
                        coupon: {
                            coupon,
                            ...restData
                        }
                    });
                }
            }
        } catch (err) {
            errorHandler(err);
        } finally {
            loader.hide();
        }
    }

    async applyCoupon () {
        analytics.logEvent("applyCoupon");
        const coupon = document.getElementById("input-checkout-coupon").value;
        if(coupon.length) {
            try {
                const { plan, duration, selectedOffer, selectedAddons } = this.state;
                loader.show(this.props.t("text_applying_coupon"));
                const formData = new FormData();
                formData.append("plan_id", plan.plan_id);
                formData.append("plan_duration", duration);
                formData.append("coupon", coupon);
                formData.append("addons[]", selectedAddons);
                if(selectedOffer?.offer_id) {
                    formData.append("offer_id", selectedOffer.offer_id);
                }
                const { status, data, message } = await request.post("/subscription/coupon/apply", {
                    withAuth: true,
                    body: formData
                });
                if(status) {
                    this.setState({
                        coupon: {
                            coupon,
                            ...data
                        }
                    });
                } else {
                    throw message;
                }
            } catch (err) {
                errorHandler(err);
            } finally {
                loader.hide();
            }
        } else {
            document.getElementById("input-checkout-coupon").focus();
        }
    }

    clearCoupon = () => {
        analytics.logEvent("clearCoupon");
        document.getElementById("input-checkout-coupon").value = "";
        this.setState({
            couponCode: "",
            coupon: null
        });
    }

    async doCheckout () {
        analytics.logEvent("doCheckout");
        const { upsale_popup, dairy } = this.props;
        const { plan, duration, redeem, selectedAddons, renew } = this.state;
        if(upsale_popup.show_popup 
            && duration <= 3
            && this.isFirstBack
            && (!this.props.subscription.subscription_id || this.props.subscription.is_trial == 1)) {
            this.setState({
                isUpsaleOpen: true
            });
            this.isFirstBack = false;
            return false;
        }
        const { t, user, user: { address } } = this.props;
        const coupon = document.getElementById("input-checkout-coupon").value;
        if(!address.address_1 || !address.city || !address.postcode || !address.state) {
            notification("Error", t("error_add_your_address"));
            return false;
        }
        paymentService.doCheckout({
            plan,
            duration,
            renew,
            coupon,
            user,
            redeemBalance: redeem,
            addons: selectedAddons,
            dairyId: dairy.id
        }, subscription => {
            this.changePage = true;
            this.props.fetchSubscriptionSuccess(subscription);
        });
    }

    onDeviceBackButton = e => {
        const { upsale_popup } = this.props;
        if(upsale_popup.show_popup) {
            if(this.isFirstBack && (!this.props.subscription.subscription_id || this.props.subscription.is_trial == 1)) {
                this.setState({
                    isUpsaleOpen: true
                });
                this.isFirstBack = false;
                return true;
            }
        }
        this.props.history.goBack();
    }

    onCouponCodeChange = (e) => {
        this.setState({
            couponCode: e.target.value
        });
    }

    useCoupon = (coupon) => {
        analytics.logEvent("SubscriptionUpsaleModalUseCoupon");
        this.setState({
            couponCode: coupon,
            duration: 12,
            isUpsaleOpen: false
        }, () => {
            this.applyCoupon()
        });
    }

    hideUpsaleModal = () => {
        analytics.logEvent("SubscriptionUpsaleModalClose");
        this.setState({
            isUpsaleOpen: false
        });
    }

    renderFixed = () => <SubscriptionUpsaleModal
        isOpen={this.state.isUpsaleOpen}
        navigator={this.props.navigator}
        onClose={this.hideUpsaleModal}
        onUseCoupon={this.useCoupon} />

    onRedeemChange = (e,reward) => {
        this.setState({
            rewardPoints:(e.target.checked)?reward:0,
            redeem:e.target.checked
        });
    }

    onAddonSelected = (addons) => {
        this.setState({
            selectedAddons: addons
        }, () => {
            if(document.getElementById("input-checkout-coupon").value) {
                this.applyCoupon();
            }
        });
    }

    listSelectedAddons = () => {
        const { selectedAddons } = this.state;
        const { addons } = this.props;
        return addons.filter((addon) => {
            return selectedAddons.includes(addon.add_on_id)
        });
    }

    render() {
        const { t, classes, subscription = {}, user, header } = this.props;
        const { duration, plan, coupon, couponCode, durations = [], rewardPoints, redeem, selectedAddons: selectedAddonIds, renew } = this.state;
        const lang = getLang();
        const selectedDuration = durations.find(dur => dur.value == duration);
        const selectedAddons = this.listSelectedAddons();
        const subTotal = getSubTotal({
            plan,
            duration,
            subscription,
            renew,
            addOns: selectedAddons
        });
        const checkoutAccountBalance = getCheckoutAccountBalance(subscription, plan, renew);
        const remainingBalance = getRemainingBalance(subTotal, checkoutAccountBalance);
        const payableTotal = calculateTotal({
            remainingBalance,
            coupon,
            redeemBalance: rewardPoints
        });
        const planPrice = getPriceByDuration(plan, duration);
        const rewardBalance = calculateRewardBalance(rewardPoints, remainingBalance, coupon);
        const points= getPoints(rewardPoints, rewardBalance);
        const couponDiscount = calculateCoupon(remainingBalance, coupon);
        const remainingDays = getSubscriptionRemainingDays(subscription, plan);
     
        return (
            <DefaultLayout title={t("text_checkout")} header={header}>
                <div className="alert alert-danger text-center" role="danger">
                    {t("refund_policy")}
                </div>
                <CheckoutAddress
                    classes={classes}
                    user={user}
                    onEditAddress={this.onEditAddress}/>
                <CouponComponenet
                    applyCoupon={this.applyCoupon.bind(this)}
                    clearCoupon={this.clearCoupon}
                    onCouponCodeChange={this.onCouponCodeChange}
                    couponCode={couponCode}
                    coupon={coupon}
                />
                <RedeemComponent
                    onRedeemChange={this.onRedeemChange}
                    redeem={this.redeem}
                />
                
                <Grid container justifyContent="center" className="mb-3">
                    <Grid item xs={12} >
                        <Box>
                            <TableContainer component={Paper}>
                                <Table size="small" className={classes.table} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>{t("text_plan")}/{t("text_addon")}</StyledTableCell>
                                            <StyledTableCell align="center">{t("text_duration")}</StyledTableCell>
                                            <StyledTableCell align="right">{t("text_price")}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {plan?.plan_id && <TableRow>
                                            <TableCell className="pr-2" component="th" scope="row">
                                                {plan.name_local ? plan.name_local[lang] : plan.name}
                                            </TableCell>
                                            <TableCell className="pr-0" align="center">
                                                {selectedDuration.name ? selectedDuration.name[lang] : plan.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(planPrice)}
                                            </TableCell>
                                        </TableRow>}
                                        {selectedAddons.map((addon) => {
                                            return (
                                                <TableRow key={addon.add_on_id}>
                                                    <TableCell className="pr-2" component="th" scope="row">
                                                        {addon.name_local ? addon.name_local[lang] : addon.name}<br/>
                                                        {(remainingDays > 0 && selectedAddons.length > 0) && !renew &&
                                                            <sub> (Add-ons added for {remainingDays} remaining days and total will be calculated based on that only.)</sub>}
                                                    </TableCell>
                                                    <TableCell className="pr-0" align="center">
                                                        {selectedDuration.name ? selectedDuration.name[lang] : plan.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {currencyFormat(getPriceByDuration(addon, duration))}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell size="small" align="right" colSpan={2}>
                                                {t("text_subtotal")}
                                            </TableCell>
                                            <TableCell size="small" align="right">{currencyFormat(subTotal)}</TableCell>
                                        </TableRow>
                                        {!renew && checkoutAccountBalance > 0 ? (
                                            <TableRow>
                                                <TableCell size="small" align="right" colSpan={2}>
                                                    {t("text_account_balance")}
                                                </TableCell>
                                                <TableCell className="nowrap" size="small" align="right">
                                                    <Typography color="error">-{currencyFormat(checkoutAccountBalance)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                        {remainingBalance < 0 && <TableRow>
                                            <TableCell size="small" align="right" colSpan={2}>
                                                {t("text_remaining_balance")}
                                                {<sub> ({replaceAll(t("text_extra_subscription"), '{n}', getExtraDays(plan, duration, subscription, selectedAddons, remainingBalance))})</sub>}
                                            </TableCell>
                                            <TableCell size="small" align="right">{currencyFormat(Math.abs(remainingBalance))}</TableCell>
                                        </TableRow>}
                                        {couponDiscount > 0 && <TableRow>
                                            <TableCell size="small" colSpan={2} align="right">
                                                {t("text_discount")}
                                                <Typography variant="caption" display="block">
                                                    ({coupon.coupon})
                                                </Typography>
                                            </TableCell>
                                            <TableCell size="small" align="right">
                                                <Typography color="error">-{currencyFormat(couponDiscount)}</Typography>
                                            </TableCell>
                                        </TableRow>}
                                        {rewardBalance > 0 && <TableRow>
                                            <TableCell size="small" align="right" colSpan={2}>
                                                {t("text_reward_point_discount")} ({points})
                                                <Typography variant="caption" display="block">
                                                    
                                                </Typography>
                                            </TableCell>
                                            <TableCell size="small" align="right">
                                                <Typography color="error">-{currencyFormat(rewardBalance)}</Typography>
                                            </TableCell>
                                        </TableRow>}
                                        <TableRow>
                                            <TableCell size="small" align="right" colSpan={2}>
                                                {t("text_payable_amount")}
                                            </TableCell>
                                            <TableCell size="small" align="right">
                                                {currencyFormat(payableTotal)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>

                {Number(plan?.plan_id) === 4 && <Addons onAddonSelected={this.onAddonSelected} selectedAddons={selectedAddonIds}/>}

                <div className="checkout-bottom-button-box">
                    <Grid container>
                        <Grid item xs={12} >
                            <Box textAlign="center">
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    onClick={this.doCheckout.bind(this)}>
                                    {payableTotal ? t("text_make_payment") : t("text_proceed")}
                                </PrimaryButton>
                            </Box>
                        </Grid>
                    </Grid>
                    {!header && <Grid container>
                        <Grid item xs={12} >
                            <Box mt={1} ml={2} mr={2} textAlign="center">
                                <Typography variant="caption" display="block" className={classes.policyText}>
                                    {t("text_subscription_duration_policy")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>}
                </div>
                
                {this.renderFixed()}
            </DefaultLayout>
        );
    }
}

Checkout.displayName = "Checkout";

Checkout.defaultProps = {
    header: true,
    renew: false
};

const mapStateToProps = state => ({
    user: state.userReducer.user,
    upsale_popup: state.defaultReducer.upsale_popup,
    plans: state.subscription.plans,
    subscription: state.subscription.subscription,
    addons: state?.addonsReducer?.addons ?? [],
    dairy: state.dairy?.dairy
});

const mapDispatchToProps = {
    fetchSubscriptionSuccess,
    toggleSubscriptionUpsalePopup
};

const CheckoutComp = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Checkout));

export default withRouter(withLanguage(CheckoutComp));