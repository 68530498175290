// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Page } from "react-onsenui";
import { useDispatch } from "react-redux";
import withLanguage from "../../language/LanguageController";
import analytics from "../../library/firebase/analytics";
import InnerHeaderSmall from "../common/InnerHeaderSmall";
import request from "../../library/request";
import { loader } from "../../library/util";
import { errorHandler } from "../../library/response";
import DairyPage from "../dairy/Dairy.jsx";
import { fetchSubscription } from "../../../actions/subscriptions";
import subscriptionService from "../../services/subscription";

const AccountActivation = ({ t, navigator }) => {

    const [activationKey, setActivationKey] = useState("");
    const [isLoading, toggleIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        analytics.setScreenName("Account Activation");
    });

    const activateAccount = async event => {
        event.preventDefault();
        try {
            loader.show(t("text_loading"));
            toggleIsLoading(true);
            const { status, message } = await subscriptionService.activateAccount(activationKey);
            if (status) {
                await dispatch(fetchSubscription());
                await redirectToDairyPage();
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            toggleIsLoading(false);
        }
    };

    const redirectToDairyPage = async () => {
        await navigator.resetPage({
            component: DairyPage,
            props: {
              key: DairyPage.name,
            }
        });
    }

    const continueWithTrial = async () => {
        await redirectToDairyPage();
    }

    return (
        <Page className="page">
            <InnerHeaderSmall
                className="text-center"
                title={t("text_account_activation")}
                toolbar={false}
                back={false}
                right={false} />
            <div className="d-flex flex-column justify-content-center vh-100">
                <div className="bg-white px-3 py-2 rounded shadow-sm w-75 mx-auto">
                    <form action="#" method="post" onSubmit={activateAccount}>
                        <div className="form-group text-center">
                            <label htmlFor="activation_key">{t("text_activation_key")}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="activation_key"
                                name="activation_key"
                                value={activationKey}
                                onChange={e => setActivationKey(e.target.value)}
                                placeholder={t("text_activation_key_placeholder")}/>
                        </div>
                        <div className="form-group mb-1 text-center">
                            <button className="btn btn-success" disabled={isLoading}>
                                {isLoading ? t("text_loading") : t("text_activate")}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="text-center my-2">
                    {t("text_or")}
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-success"
                        disabled={isLoading}
                        onClick={continueWithTrial}>
                        {t("text_continue_with_trial")}
                    </button>
                </div>
            </div>
        </Page>
    );
};

export default withLanguage(AccountActivation);
