
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Checkbox } from "react-onsenui";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { currencyFormat } from "../../../../library/util";

const DairyPayableGridView = ({
    t,
    monthDiff,
    filter,
    records,
    payableSelected,
    paymentBtn,
    allChecked,
    onCheck,
    onAllCheck,
    onPayClick,
    onAllPayClick,
    onCustomerClick
}) => {
    return (
        <>
            {records.map((row, index) => {
                if (filter && filter.length) {
                    filter = filter.toLowerCase();
                    const name = (row.customer_name || "").toLowerCase();
                    const code = (row.code || "").toLowerCase();
                    if (!name.includes(filter) && !code.includes(filter)) {
                        return null;
                    }
                }
                return (
                    <div className="t-card shadow-sm mb-2" key={row.dairy_customer_id}>
                        {index === 0 ? (
                            <Fragment>
                                <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
                                    <div className="d-account-h1 float-left mt-1">
                                        <Checkbox
                                            onChange={onAllCheck}
                                            checked={allChecked}
                                            disabled={!monthDiff}
                                        /> {t("text_payable")}
                                    </div>
                                    <div className="float-right">
                                        <PrimaryButton
                                            size="small"
                                            variant="contained"
                                            disabled={!payableSelected || paymentBtn || !monthDiff}
                                            onClick={onAllPayClick}
                                        >
                                            {t("text_pay")}
                                        </PrimaryButton>
                                    </div>
                                </div>
                                <hr />
                            </Fragment>
                        ) : null}
                        <div onClick={e => onCustomerClick(e, row.dairy_customer_id)} className={index === 0 ? "content card__content card--material__content" : ""}>
                            <div className="clearfix">
                                <span className="capitalize">
                                    {row.payable_remaining != "0.00" ? (
                                        <span>
                                            <Checkbox
                                                className="mrt-2"
                                                disabled={!monthDiff}
                                                checked={row.is_checked}
                                                onChange={e => onCheck(e, index)}
                                            />&nbsp;
                                        </span>
                                    ) : null}
                                    <b>
                                        {`${row.code}-${row.customer_name}`}
                                    </b>
                                </span>
                                {row.payable_remaining != "0.00" ? (
                                    <div className="float-right">
                                        <PrimaryButton
                                            variant="contained"
                                            size="small"
                                            disabled={!monthDiff}
                                            onClick={e => onPayClick(e, row, index)}
                                        >
                                            {t("text_pay")}
                                        </PrimaryButton>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                <b>
                                    <span className="lheight-28">{t("text_net_payable")}: &nbsp;&nbsp;{currencyFormat(row.payable_remaining)}</span>
                                </b>
                            </div>
                            <div className="table-fix-header mt-1">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="size0_85rm">{t("text_last_due")}</th>
                                            <th className="size0_85rm min-width65">{t("payable")}</th>
                                            <th className="size0_85rm">{t("text_cash_recovered")}</th>
                                            <th className="size0_85rm v-align-middle">{t("text_paid")}</th>
                                            <th className="size0_85rm min-width65">{t("text_to_loan")}</th>
                                            <th className="size0_85rm">{t("text_remaining")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-left size0_85rm text-nowrap">
                                                <span className="display-block">
                                                    {currencyFormat(row.last_due)}
                                                </span>
                                            </td>
                                            <td className="text-center size0_85rm text-nowrap">
                                                <span className="text-red display-block">
                                                    {currencyFormat(row.month_payable)}
                                                </span>
                                            </td>
                                            <td className="text-center size0_85rm text-nowrap">
                                                <span className="text-green display-block">
                                                    {currencyFormat(row.cash_recovered)}
                                                </span>
                                            </td>
                                            <td className="text-center size0_85rm text-nowrap">
                                                <span className="text-green display-block">
                                                    {currencyFormat(row.paid)}
                                                </span>
                                            </td>
                                            <td className="text-center size0_85rm text-nowrap">
                                                <span className="text-green display-block">
                                                    {currencyFormat(row.transferd_to_loan)}
                                                </span>
                                            </td>
                                            <td className="text-right size0_85rm text-nowrap">
                                                <span className="text-red display-block">
                                                    {currencyFormat(row.payable_remaining)}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

DairyPayableGridView.propTypes = {
    t: PropTypes.func,
    monthDiff: PropTypes.bool,
    filter: PropTypes.string,
    records: PropTypes.array,
    payableSelected: PropTypes.bool,
    paymentBtn: PropTypes.bool,
    allChecked: PropTypes.bool,
    onCheck: PropTypes.func,
    onAllCheck: PropTypes.func,
    onPayClick: PropTypes.func,
    onAllPayClick: PropTypes.func,
    onCustomerClick: PropTypes.func
};

export default DairyPayableGridView;