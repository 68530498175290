/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { updateAuth } from "../../actions/user";
import request from "./request";
import { getToken } from "./util";

export const checkLogin = async () => {
    let isValid = verifyToken();
    if(isValid === false) {
        return await refreshToken();
    }
    return (isValid === true) ? isValid : false;
};

export const verifyToken = () => {
    const authToken = getToken();
    if(!authToken) {
        return -1;
    }
    const { exp: jwtExpire } = parseJwt(authToken);
    const now = (new Date().getTime() + 1) / 1000;
    return jwtExpire <= now ? false : true;
};

export const refreshToken = async () => {
    try {
        const { status, data } = await request.post('/auth/refresh', {
            withAuth: true
        });
        if(status) {
            updateAuth(data);
            return true;
        }        
    } catch (error) {
        Sentry.captureException(error);
    }
    return false
}

export const parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};