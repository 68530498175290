import useStyles from "./DesktopSideNavigation.style";
import { Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { useLanguage } from "../../language/LanguageController";

const DesktopSideNavigationSubMenu = ({ anchorEl, open, handleClose, menus }) => {
    const classes = useStyles();
    const { t } = useLanguage();
    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => {
                console.log("onClose");
                handleClose()
            }}
            elevation={2}
            classes={{
                paper: classes.subMenu,
                list: classes.subMenuMenuList
            }}
        >
            {menus.map(subMenu => 
                subMenu.hasAccess && <MenuItem
                    key={subMenu.key}
                    component={Link}
                    className={classes.subMenuMenuItem}
                    to={subMenu.path}
                >
                    {t(subMenu.text)}
                </MenuItem>
            )}
        </Menu>
    );
};

export default DesktopSideNavigationSubMenu;