// @ts-nocheck
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, {  } from "react";
import ModalDialog from "src/components/common/ModalDialog/ModalDialog";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { useLanguage } from "src/components/language/LanguageController";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const HelpDialog = ({ isOpen, onClose }) => {
    const { t } = useLanguage();

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={'md'}
            title={t("autocollection.helpdialog.title")}
            dividers={false}
            content={
                <div className="automatic-milk-collection-help-container">
                    <List>
                        <ListItem button>
                            <ListItemIcon>
                                <ArrowForwardIosIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("autocollection.helpdialog.helptext1")}/>
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <ArrowForwardIosIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("autocollection.helpdialog.helptext2")} />
                        </ListItem>
                    </List>
                </div>
            }
            dialogActions={
                <>
                    <PrimaryButton
                        variant="contained"
                        size="small"
                        onClick={onClose}
                    >
                        {t("text_close")}
                    </PrimaryButton>
                </>
            }
        />
    );
};

export default HelpDialog;