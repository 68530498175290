/* eslint-disable no-console */
// @ts-nocheck
import Model from "./Model";

const tableName = "user";
class User extends Model {

    constructor(){
        super(tableName);
    }

    static get tableName() {
        return tableName; // define child's tableName here
    }

    logout() {
        const show_intro_slider = window.localStorage.getItem("show_intro_slider");
        const deviceId = window.localStorage.getItem("device_id");
        let language = window.localStorage.getItem("language");
        language = language ? language : "hi";
        window.localStorage.clear();
        window.localStorage.setItem("show_intro_slider", show_intro_slider);
        window.localStorage.setItem("language", language);
        window.localStorage.setItem("device_id", deviceId);
    }

    checkLogin(){
        let authToken = this.get();
        return (authToken) ? authToken["auth_token"] : false;
    }

    static update (data) {
        let user = this.get();

        let newUser = {...user, ...data};
        try {
            window.localStorage.setItem(this.tableName, JSON.stringify(newUser));
        } catch (error) {
            console.error(error);
        }
        return newUser;
    }
}

export default User;