import * as Sentry from "@sentry/react";

export const trackUser = (user) => {
    Sentry.setUser({
        id: user.customer_id,
        username: `${user.firstname} ${user.lastname}`,
        email: user.email,
        user_group: user.customer_group_id
    });
};

export const captureException = (err) => {
    Sentry.captureException(err);
};