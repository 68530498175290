import { useSelector } from "react-redux";
import { useLanguage } from "src/components/language/LanguageController";
import { selectCollectionQueueCount, selectCollectionQueueTotal } from "src/store/offline-store/collection-queue-total/selectors";
import { currencyFormat } from "src/components/library/util";

export const CollectionTotal = () => {
    const { t } = useLanguage();
    const entryCount = useSelector(selectCollectionQueueCount);
    const collectionTotal = useSelector(selectCollectionQueueTotal);
    return (
        <div className={"content card__content card--material__content mb-2"}>
            <div className="table-fix-header mt-1 shadow-sm">
                <table className="table mb-0 table-light">
                    <thead>
                        <tr>
                            <th>{t("text_entries")}</th>
                            <th>{t("text_liter")}</th>
                            <th>{t("text_avg_fat")}</th>
                            <th>{t("text_avg_clr")}</th>
                            <th>{t("text_avg_snf")}</th>
                            <th>{t("text_rate")}</th>
                            <th>{t("text_amount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{entryCount}</td>
                            <td>
                                {currencyFormat(collectionTotal.total_milk, false) || '0'}
                            </td>
                            <td>
                                {collectionTotal.avg_fat || '0'}
                            </td>
                            <td>
                                {collectionTotal.avg_clr || '0'}
                            </td>
                            <td>
                                {collectionTotal.avg_snf || '0'}
                            </td>
                            <td>
                                {currencyFormat(collectionTotal.avg_rate) || '0'}
                            </td>
                            <td>
                                {currencyFormat(collectionTotal.total_amount) || '0'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CollectionTotal;