import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "../../../common/ErrorBoundary";
import { useLanguage } from "src/components/language/LanguageController";

function SNFFormula({ snfFormula }) {
    const { t } = useLanguage();
    return (
        <ErrorBoundary>
            <div className="form-group clearfix">
                <label>{t("text_snf_formula")}</label>
                <div className="input-group">
                    <span className="input-group-prepend">
                        <span className="input-group-text text-sm px-1">
                            SNF =
                        </span>
                    </span>
                    <span className="input-group-prepend input-group-append">
                        <span className="input-group-text text-sm px-1">
                            ( CLR / 4 ) + (
                        </span>
                    </span>
                    <input 
                        type="number"
                        step={0.01}
                        defaultValue={snfFormula?.["factor"] || 0.25}
                        className="form-control"
                        name="snf_formula[factor]"/>
                    <span className="input-group-prepend input-group-append">
                        <span className="input-group-text text-sm px-1">
                            * FAT ) +
                        </span>
                    </span>
                    <input 
                        type="number"
                        step={0.01}
                        defaultValue={snfFormula?.["constant"] || 0.7}
                        className="form-control"
                        name="snf_formula[constant]"/>
                </div>
            </div>
        </ErrorBoundary>
    );
}

SNFFormula.propTypes = {
    snfFormula: PropTypes.object.isRequired
};

export default SNFFormula;