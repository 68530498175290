import { useDispatch } from "react-redux";
import LockIcon from "@material-ui/icons/Lock"
import { togglePremiumPopup } from "../../../actions";

const SubscriptionLock = () => {
    const dispatch = useDispatch();

    return (
        <div className="subscription-lock">
            <button type="button" className="btn" onClick={e => dispatch(togglePremiumPopup())}>
                <span className="sr-only">{"Unlock"}</span>
                <LockIcon/>
            </button>
        </div>
    )
};

export default SubscriptionLock;