// @ts-nocheck
import React, {  } from "react";
import ModalDialog from "src/components/common/ModalDialog/ModalDialog";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { useLanguage } from "src/components/language/LanguageController";
import MachinesStatus from "../../../automaticCollection/MachinesStatus"

const OfflineHistoryDialog = ({ isOpen, onClose }) => {
    const { t } = useLanguage();

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={'lg'}
            title={t("text_automatic_milk_collection_machine_status")}
            dividers={false}
            content={
                <div className="automatic-milk-collection-container">
                    <MachinesStatus />
                </div>
            }
            dialogActions={
                <>
                    <PrimaryButton
                        variant="contained"
                        size="small"
                        onClick={onClose}
                    >
                        {t("text_close")}
                    </PrimaryButton>
                </>
            }
        />
    );
};

export default OfflineHistoryDialog;