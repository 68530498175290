// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useLanguage } from "../../../language/LanguageController";
import { useSelector } from 'react-redux';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@material-ui/core';
import { RadioPrimary } from "../../../core/Radio";
import { MILK_ENTRY_BY_BOTH, MILK_ENTRY_BY_CODE, MILK_ENTRY_BY_NAME } from 'src/_constants';
import { makeStyles } from '@material-ui/core/styles';
import { getAddMilkHowToSelectCustomer } from 'src/reducers/dairySettings';

const useStyle = makeStyles(() => ({
    root: {
        fontWeight: "bold",
        color: "black",
        lineHeight: 1.5
    }
}))

const SelectCustomerOnMilkEntrySetting = () => {
    const { t } = useLanguage();
    const classes = useStyle();

    const [howToSelectCustomer, setHowToSelectCustomer] = useState(MILK_ENTRY_BY_BOTH);
    const dairyPreferenceHowToSelectCustomer = useSelector(getAddMilkHowToSelectCustomer);

    useEffect(() => {
        setHowToSelectCustomer(dairyPreferenceHowToSelectCustomer);
    }, [dairyPreferenceHowToSelectCustomer]);

    const handleChange = (event) => {
        setHowToSelectCustomer(event.target.value);
    }

    return (
        <div className='form-group'>
            <FormControl component="fieldset">
                <FormLabel className={classes.root} component="label">{t("text_how_to_choose_customer_on_milk_entry")}</FormLabel>
                <RadioGroup row aria-label="position" name="position" value={howToSelectCustomer} onChange={handleChange}>
                    <FormControlLabel
                        value={MILK_ENTRY_BY_CODE}
                        control={<RadioPrimary color="primary" />}
                        label={t("text_by_code")}
                        name="add_milk_how_to_select_customer"
                    />
                    <FormControlLabel
                        value={MILK_ENTRY_BY_NAME}
                        control={<RadioPrimary color="primary" />}
                        label={t("text_by_name")}
                        name="add_milk_how_to_select_customer"
                    />
                    <FormControlLabel
                        value={MILK_ENTRY_BY_BOTH}
                        control={<RadioPrimary color="primary" />}
                        label={t("text_both")}
                        name="add_milk_how_to_select_customer"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default SelectCustomerOnMilkEntrySetting
