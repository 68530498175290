export const MILK_LOWER_LIMIT = 0.10;
export const MILK_UPPER_LIMIT = 9999999.99;
export const MILK_INPUT_STEP = 0.01;

export const DEFAULT_STD_CLR = 30;
export const DEFAULT_STD_SNF = 8.3;

export const SNF_LOWER_LIMIT = 6.0;
export const SNF_UPPER_LIMIT = 15.0;
export const SNF_INPUT_STEP = 0.1;

export const FAT_LOWER_LIMIT = 2.5;
export const FAT_UPPER_LIMIT = 15.0;
export const FAT_INPUT_STEP = 0.1;

export const CLR_LOWER_LIMIT = 18;
export const CLR_UPPER_LIMIT = 40;
export const CLR_INPUT_STEP = 0.1;

export const DEFAULT_SNF_FACTOR = 0.2;
export const DEFAULT_SNF_CONSTANT = 0.7;

export const RATE_CHART_SETUP_TYPES = [
    {
        value: "general",
        label: "text_general"
    },
    {
        value: "by_cattle",
        label: "text_by_cattle"
    }
]