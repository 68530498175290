import { Component, createRef } from 'react';
import moment from "moment";
import _ from 'lodash';
import { loader } from "../../../library/util";
import { errorHandler } from '../../../library/response';
import { getBillingStartEndDate } from '../../../library/dateUtils';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../_constants';
import Eventer from 'src/components/library/Eventer';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';

class CustomerLoanHistoryController extends Component {

    constructor(props) {
        super(props);
        this.getCustomers = this.getCustomers.bind(this);
        this.getLoanHistory = this.getLoanHistory.bind(this);
        this.getSanctionedLoans = this.getSanctionedLoans.bind(this);
        this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
        this.customerCodeRef = createRef();
        this.state = {
            selectedCustomer: {},
            customers: [],
            loan_total: 0,
            loan_history: [],
            loans: [],
            isBrowser: false,
            isFeatureSubscribed: checkFeatureInSubscription("loan", false),
            ...getBillingStartEndDate("monthly", moment())
        }
    }

    componentDidMount() {
        analytics.setScreenName("LoanHistory");
        this.getCustomers();
        Eventer.on("loanSanctioned", this.fetchLoan);
        Eventer.on("loanRecovered", this.fetchLoan);
    }

    componentWillUnmount() {
        Eventer.off("loanSanctioned", this.fetchLoan);
        Eventer.off("loanRecovered", this.fetchLoan);
    }

    fetchLoan = (event) => {
        const { dairyCustomerId = null } = event.detail;
        const customer = this.state.customers.find(customer => customer.dairy_customer_id == dairyCustomerId);
        if(this.state.selectedCustomer.dairy_customer_id != dairyCustomerId && customer){
            this.customerChangeHandler(customer);
        } else if(dairyCustomerId){
            this.getCustomers(dairyCustomerId);
        } else {
            this.getLoanHistory();
            this.getSanctionedLoans();
        }
    }

    customerChangeHandler = customer => {
        this.setState({
            selectedCustomer: customer
        }, () => {
            this.customerCodeRef.current.value = customer.code;
            this.getLoanHistory();
            this.getSanctionedLoans();
        });
    }

    handleCodeChange(event) {
        const code = event.target.value;
        const customer = this.state.customers.find(customer => customer.code == code);
        if(customer) {
          this.customerChangeHandler(customer);
        } else {
          this.setState({
            selectedCustomer: {
              label: this.props.t("text_no_customer_for_code"),
              value: null
            }
          });
        }
    }

    async getCustomers(dairyCustomerId = null) {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/accounts/loan/customers`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                const { dairy_customer_id = dairyCustomerId } = this.props?.match?.params;
                const customers = data;
                let customer = {};
                if (dairy_customer_id) {
                    customer = customers.find(customer => customer.dairy_customer_id === dairy_customer_id) || {};
                } else {
                    customer = customers[0];
                }
                this.setState({
                    customers: customers,
                    selectedCustomer: customer
                }, () => {
                    if (customer && customer.dairy_customer_id) {
                        this.customerCodeRef.current.value = customer.code;
                        this.getLoanHistory();
                        this.getSanctionedLoans();
                    }
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    async getLoanHistory() {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            const { selectedCustomer, startDate } = this.state;
            
            if(selectedCustomer?.dairy_customer_id) {
                loader.show(this.props.t("text_loading"));
                let filterStr = encodeURI(`date=${startDate.format('MMMM YYYY')}`);

                const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/accounts/loan/history/${selectedCustomer?.dairy_customer_id}?${filterStr}`, {
                    withAuth: true,
                    version: "v2"
                });
                if (status) {
                    let { loan_history } = data;
                    this.setState({
                        loan_history: loan_history
                    });
                } else if (error_code != 501) {
                    throw message;
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    async getSanctionedLoans() {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            const { selectedCustomer: { dairy_customer_id } } = this.state;
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/accounts/loan/sanctioned/${dairy_customer_id}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                const { loans, loan_total } = data;
                this.setState({
                    loans: loans,
                    loan_total: loan_total
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        if(!checkFeatureInSubscription("loan")) {
            return false;
        }
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getLoanHistory();
        });
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }
}

export default CustomerLoanHistoryController;