import React, { useEffect, useMemo, useState } from "react";
import "./DownScrollArrow.scss";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconButton } from "@material-ui/core";

const DownScrollArrow = ({ scrollTarget = null }) => {

    const isTargetVisible = useOnScreen(scrollTarget);
    const handleClick = () => {
        scrollTarget.scrollIntoView();
    };

    useEffect(() => {
        console.log("isTargetVisible", isTargetVisible);
    }, [isTargetVisible]);

    if(isTargetVisible) {
        return null;
    }

    return (
        <div className="down-arrow-container">
            <IconButton onClick={handleClick}>
                <KeyboardArrowDownIcon fontSize="large" />
            </IconButton>
        </div>
    );
};

export default DownScrollArrow;

const useOnScreen = (target) => {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [target])
  
  
    useEffect(() => {
        target && observer.observe(target)
        return () => observer.disconnect()
    }, [observer, target])
  
    return isIntersecting
}