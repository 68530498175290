// @ts-nocheck

import Eventer from "src/components/library/Eventer";
import parseMilkData from "../parsers/milk-data-parser/milk-data-parser";
import parseWeightData from "../parsers/milk-weight-parser/milk-weight-parser";

const samplePorts = [
    {
        "path": "COM1",
        "friendlyName": "USB-SERIAL CH340 (COM1)",
    },
    {
        "path": "COM2",
        "friendlyName": "USB-SERIAL CH340 (COM2)",
    },
    {
        "path": "COM3",
        "friendlyName": "USB-SERIAL CH340 (COM3)",
    }
];
let milkWeight = 0;

export const getSerialDevices = async () => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.getSerialDevices();
    } else if(window.UsbSerial) {
        const ports = await window.UsbSerial?.getSerialDevices();
        return ports?.map(port => ({
            path: port.device.deviceId,
            friendlyName: `${port.device.productName} - ${port.device.deviceId}`
        }));
    } else {
        return samplePorts;
    }
}

export const connectDevices = async () => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.connectDevices();
    } else {
        return await window.UsbSerial?.connectDevices();
    }
}

export const disconnectDevices = async () => {
    if(typeof cordova === "undefined") {
        await window?.literAPI?.autoCollectionAPI.disconnectDevices();
    } else {
        window.UsbSerial?.disconnectDevices();
    }
}

export const reconnectDevice = async (settings) => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.reconnectDevice(settings.deviceType);
    } else {
        console.log("settings", settings);
        const permission = await window.UsbSerial.requestPermission(settings.path);
        console.log("permission ", permission);
        return await window.UsbSerial?.connectDevice({
            ...settings,
            portNum: 0,
            deviceId: settings.path
        });
    }
}

export const disconnectDevice = async (deviceType) => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.disconnectDevice(deviceType);
    } else {
        return await window.UsbSerial?.disconnectDevice(deviceType);
    }
}

export const getDevicesStatus = async () => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.getDevicesStatus();
    } else {
        return await window.UsbSerial?.getDevicesStatus();
    }
}

export const updateMachineConfiguration = async () => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.updateMachineConfiguration();
    } else {
        return await window.UsbSerial?.updateMachineConfiguration();
    }
}

export const getMilkWeightSync = async () => {
    if(typeof cordova === "undefined") {
        return await window?.literAPI?.autoCollectionAPI.getMilkWeightSync();
    } else {
        console.log("milkWeight", milkWeight);
        return milkWeight;
    }
}

Eventer.on("weightData", ({ detail }) => {
    console.log("weightData detail", detail);
    milkWeight = detail;
})

export const registerReadCallback = async (deviceType) => {
    if(typeof cordova === "undefined") {
        // return await window?.literAPI?.autoCollectionAPI.registerReadCallback();
    } else {
        console.log('registerReadCallback', deviceType);
        return await window.UsbSerial?.registerReadCallback(deviceType, (event) => {
            console.log("readCallback", event);
            if(event.deviceType === "milkAnalyser") {
                const parsedData = parseMilkData(event.data);
                console.log("milkAnalyser parsedData", parsedData);
                Eventer.emit("milkAnalysis", JSON.stringify({
                    fat: parsedData.fat,
                    clr: parsedData.clr,
                    snf: parsedData.snf,
                }));
            } else if(event.deviceType === "weightAnalyser"){
                const parsedData = parseWeightData(event.data);
                console.log("weightAnalyser parsedData", parsedData);
                milkWeight = parsedData;
                Eventer.emit("weightData", parsedData);
            }
        });
    }
}