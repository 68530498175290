export const emit = (eventType, detail = {}) => {
    const customEvent = new CustomEvent(eventType, {
        detail
    })
    document.dispatchEvent(customEvent);
}

export const on = (eventType, callback) =>
    document.addEventListener(eventType, callback);

export const off = (eventType, callback) => 
    document.removeEventListener(eventType, callback);