import request from '../components/library/request';
import { errorHandler } from "../components/library/response";
import { ADD_GESTATIONS_SUCCESS, CONFIRM_GESTATIONS_FAILURE, CONFIRM_GESTATIONS_REQUEST, CONFIRM_GESTATIONS_SUCCESS, DELETE_GESTATIONS_FAILURE, DELETE_GESTATIONS_REQUEST, DELETE_GESTATIONS_SUCCESS, FETCH_GESTATIONS_FAILURE, FETCH_GESTATIONS_REQUEST, FETCH_GESTATIONS_SUCCESS, FETCH_OFFERS_FAILURE, FETCH_OFFERS_REQUEST, FETCH_OFFERS_SUCCESS, POST_GESTATIONS_FAILURE, POST_GESTATIONS_REQUEST, UPDATE_GESTATIONS_SUCCESS } from "../_constants/action-types";

// action creators
export const fetchGestationsRequest = () => ({
    type: FETCH_GESTATIONS_REQUEST
});

export const fetchGestationsSuccess = payload => ({
    type: FETCH_GESTATIONS_SUCCESS,
    payload
});

export const fetchGestationsFailure = () => ({
    type: FETCH_GESTATIONS_FAILURE
});

export const fetchGestations = async (dispatch, customerId) => {
    try {
        dispatch(fetchGestationsRequest());
        const { data = [] } = await request.get("/gestations", {
            params: {
                customer_id: customerId
            },
            withAuth: true
        });
        dispatch(fetchGestationsSuccess(data));
    } catch (error) {
        dispatch(fetchGestationsFailure());
        errorHandler(error);
    }
}

export const postGestationsRequest = () => ({
    type: POST_GESTATIONS_REQUEST
});

export const addGestationsSuccess = payload => ({
    type: ADD_GESTATIONS_SUCCESS,
    payload
});

export const updateGestationsSuccess = payload => ({
    type: UPDATE_GESTATIONS_SUCCESS,
    payload
});

export const postGestationsFailure = () => ({
    type: POST_GESTATIONS_FAILURE
});

export const postGestationData = async (dispatch, formData, gestationId = null) => {
    try {
        dispatch(postGestationsRequest());
        const { status, data = [], message } = await request.post(`/gestations${gestationId ? "/" + gestationId : ""}`, {
            body: formData,
            withAuth: true,
        });
        if(status) {
            if(gestationId)
                dispatch(updateGestationsSuccess(data));
            else
                dispatch(addGestationsSuccess(data));
            return message;
        } else {
            throw message;
        }
    } catch (error) {
        throw error;
    } finally {
        dispatch(postGestationsFailure());
    }
}

export const deleteGestationsRequest = () => ({
    type: DELETE_GESTATIONS_REQUEST
});
export const deleteGestationsFailure = () => ({
    type: DELETE_GESTATIONS_FAILURE
});
export const deleteGestationsSuccess = payload => ({
    type: DELETE_GESTATIONS_SUCCESS,
    payload
});
export const deleteGestation = async (dispatch, gestationId) => {
    try {
        dispatch(deleteGestationsRequest());
        const { status, message } = await request.delete(`/gestations/${gestationId}`, {
            withAuth: true,
        });
        if(status) {
            dispatch(deleteGestationsSuccess(gestationId));
            return message;
        } else {
            throw message;
        }
    } catch (error) {
        dispatch(deleteGestationsFailure());
        errorHandler(error);
    }
}

export const confirmGestationsRequest = () => ({
    type: CONFIRM_GESTATIONS_REQUEST
});
export const confirmGestationsFailure = () => ({
    type: CONFIRM_GESTATIONS_FAILURE
});
export const confirmGestationsSuccess = payload => ({
    type: CONFIRM_GESTATIONS_SUCCESS,
    payload
});
export const confirmGestation = async (dispatch, gestationId) => {
    try {
        dispatch(confirmGestationsRequest());
        const { status, data, message } = await request.post(`/gestations/${gestationId}`, {
            withAuth: true,
            body: JSON.stringify({
              is_confirm: 1
            })
        });
        if(status) {
            dispatch(confirmGestationsSuccess({
                gestationId,
                data
            }));
            return message;
        } else {
            throw message;
        }
    } catch (error) {
        dispatch(confirmGestationsFailure());
        errorHandler(error);
    }
}