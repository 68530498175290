// @ts-nocheck
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect } from "react";
import config from "../../../config";
import analytics from "../../library/firebase/analytics";
import { currencyFormat, ucFirst } from "../../library/util";

const OrderDetailsPopup = ({ t, language, order, plan = {}, addOns = [], plans = [], orderId, paymentDetail }) => {
    const { activation_key, referral } = order;

    useEffect(() => {
        analytics.logEvent("OrderDetailsPopup");
    }, []);

    const getDurationType = useCallback(() => {
        return order.duration > 1 ? (
            order.integration_type == "referral" ? t("text_days") : t("text_months")
        ) : (
            order.integration_type == "referral" ? t("text_day") : t("text_month")
        )
    }, [order.duration, order.integration_type, t])

    return (
        <TableContainer>
            <Table aria-label="Payment detail table" className="mb-3">
                <TableBody>
                    <TableRow>
                        <TableCell size="small">
                            <Typography variant="caption" display="block">
                                {t("text_date")}
                            </Typography>
                        </TableCell>
                        <TableCell size="small" align="right">
                            <Typography variant="caption" display="block">{moment(order.date_added).format(config.display_datetime_format)}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell size="small">
                            <Typography variant="caption" display="block">
                                {t("text_order_id")}
                            </Typography>
                        </TableCell>
                        <TableCell size="small" align="right">
                            <Typography variant="caption" display="block">#{orderId}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell size="small">
                            <Typography variant="caption" display="block">
                                {t("text_duration")}
                            </Typography>
                        </TableCell>
                        <TableCell size="small" align="right">
                            <Typography variant="caption" display="block">
                                {order.duration} {getDurationType()}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {paymentDetail.method ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">
                                    {t("text_payment_type")}
                                </Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">
                                    {paymentDetail.method === "wallet" ? ucFirst(paymentDetail.wallet) : null} {ucFirst(paymentDetail.method)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {activation_key ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">
                                    {t("text_activation_key")}
                                </Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">
                                    {activation_key.activation_code}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {referral ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">
                                    {t("text_referral_code")}
                                </Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">
                                    {referral.code}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {paymentDetail.method === "netbanking" ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">{t("text_bank")}</Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">{paymentDetail.bank}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {paymentDetail.method === "card" ? (
                        <Fragment>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="caption" display="block">{t("text_card")}</Typography>
                                </TableCell>
                                <TableCell size="small" align="right">
                                    <Typography variant="caption" display="block">{paymentDetail.card.network} ending {paymentDetail.card.last4}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">
                                    <Typography variant="caption" display="block">{t("text_name_on_card")}</Typography>
                                </TableCell>
                                <TableCell size="small" align="right">
                                    <Typography variant="caption" display="block">{paymentDetail.card.name}</Typography>
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    ) : null}
                    {paymentDetail.contact ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">{t("text_mobile")}</Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">{paymentDetail.contact}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {paymentDetail.email ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">{t("text_email")}</Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">
                                    {paymentDetail.email}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {paymentDetail.method === "netbanking" ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="caption" display="block">{t("text_transaction_id")}</Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="caption" display="block">{paymentDetail.acquirer_data.bank_transaction_id}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {order.previous_balance > 0 ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="subtitle2" display="block">{t("text_previous_account_balance")}</Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="subtitle2" display="block">{currencyFormat(order.previous_balance)}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {paymentDetail.amount ? (
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="subtitle2" display="block">{t("text_amount_paid")}</Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <Typography variant="subtitle2" display="block">{currencyFormat(paymentDetail.amount / 100)}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableBody>
            </Table>
            <Typography component="h2" variant="h6" className="mb-2 text-left">{t("text_plan_addon_detail")}</Typography>
            <Table aria-label="Plan/Add-on detail table">
                <TableRow>
                    <TableCell size="small">{t("text_plan")}/{t("text_addon")}</TableCell>
                    <TableCell size="small" align="right">{t("text_price")}</TableCell>
                </TableRow>
                <TableBody>
                    {plans.map(plan => <TableRow>
                        <TableCell size="small">
                            {plan.name_local?.[language] || plan.name}
                        </TableCell>
                        <TableCell size="small" align="right">
                            {currencyFormat(plan?.pivot?.price)}
                        </TableCell>
                    </TableRow>)}
                    {addOns.map(addOn => <TableRow>
                        <TableCell size="small">
                            {addOn.name_local?.[language] || addOn.name}
                        </TableCell>
                        <TableCell size="small" align="right">
                            {currencyFormat(addOn?.pivot?.price)}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

OrderDetailsPopup.displayName = "OrderDetailsPopup";

OrderDetailsPopup.propTypes = {
    t: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    plan: PropTypes.object,
    orderId: PropTypes.number.isRequired,
    paymentDetail: PropTypes.object.isRequired,
    addOns: PropTypes.array
};

export default OrderDetailsPopup;
