import React, { Fragment, useState } from "react";
import { Switch, Radio } from "react-onsenui";
import { useLanguage } from "src/components/language/LanguageController";

const FixFATSNFRate = ({ fixSNFCLR, rateBy }) => {
    const { t } = useLanguage();
    const [isFixCLRSNF, setIsFixCLRSNF] = useState(rateBy === "fat" ? true : (fixSNFCLR.enabled == "1" || false));
    const [fixValueType, setFixValueType] = useState(fixSNFCLR.type || "snf");
    const [fixValue, setFixValue] = useState(fixSNFCLR.value || (fixSNFCLR.type === "clr" ? 30 : 8.5));

    return (
        <Fragment>
            <div className={`form-group ${isFixCLRSNF ? "mb-2" : ""}`}>
                <div className="form-row clearfix">
                    <div className="col-10">
                        <label className="lheight-24 mb-0">{t("text_fix_rate_by_snf_clr")} </label>
                    </div>
                    <div className="col-2 text-right pl-0">
                        <Switch
                            modifier="material"
                            checked={isFixCLRSNF}
                            onChange={e => {
                                setIsFixCLRSNF(e.target.checked)
                            }} />
                        <input type="hidden" name="fix_snf_clr[enabled]" value={isFixCLRSNF ? 1 : 0} readOnly/>
                    </div>
                </div>
            </div>
            {isFixCLRSNF ? (
            <div className="form-group">
                <div className="form-row clearfix">
                    <div className="col-5">
                        <div className="form-check form-check-inline mb-1">
                            <Radio
                                inputId="fix_snf_clr_type_snf"
                                name="fix_snf_clr[type]"
                                value="snf"
                                modifier='material form-check-input'
                                checked={fixValueType === "snf"}
                                onChange={e => setFixValueType("snf")} />
                            <label htmlFor="fix_snf_clr_type_snf" className="form-check-label">
                                {t("text_snf")}
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Radio
                                inputId="fix_snf_clr_type_clr"
                                name="fix_snf_clr[type]"
                                value="clr"
                                modifier='material form-check-input'
                                checked={fixValueType === "clr"}
                                onChange={e => setFixValueType("clr")} />
                            <label htmlFor="fix_snf_clr_type_clr" className="form-check-label">
                                {t("text_clr")}
                            </label>
                        </div>
                    </div>
                    <div className="col-7 pl-0">
                        <input
                            type="number"
                            name="fix_snf_clr[value]"
                            className="form-control"
                            placeholder={fixValueType === "snf" ? t("text_snf") : t("text_clr")}
                            defaultValue={fixValue}/>
                        {/* {validator.message('fix_value', setFixValue, `required|numeric|min:${SNF_LOWER_LIMIT},num|max:${SNF_UPPER_LIMIT},num`)} */}
                    </div>
                </div>
            </div>
            ) : null}
        </Fragment>
    );
}

export default FixFATSNFRate;