import { copyObject } from "src/components/library/util";
import {
  DAIRY_RATE_LISTS_FETCH_ERROR,
  DAIRY_RATE_LISTS_FETCH_REQUEST,
  DAIRY_RATE_LISTS_FETCH_SUCCESS,
  DAIRY_RATE_LISTS_RESET,
  DAIRY_RATE_LIST_ADD,
  DAIRY_RATE_LIST_DELETE_RESET,
  DAIRY_RATE_LIST_ADDED_RESET,
  DAIRY_RATE_LIST_ADD_ERROR,
  DAIRY_RATE_LIST_ADD_SUCCESS,
  DAIRY_RATE_LIST_DELETE,
  DAIRY_RATE_LIST_DELETE_ERROR,
  DAIRY_RATE_LIST_DELETE_SUCCESS,
  DAIRY_RATE_LIST_UPDATE,
  DAIRY_RATE_LIST_UPDATE_ERROR,
  DAIRY_RATE_LIST_UPDATE_SUCCESS
} from "../_constants/action-types";

const initialState = {
  rateLists: [],
  isAdding: false,
  isUpdating: false,
  isDeleting: false,
  isFetching: false,
  isFetched: false,
  isListAdded: false,
  isListDeleted: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DAIRY_RATE_LISTS_FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true
      }
    }
    case DAIRY_RATE_LISTS_FETCH_SUCCESS: {
      return {
        ...state,
        rateLists: action.payload,
        isFetching: false,
        isFetched: true
      };
    }
    case DAIRY_RATE_LISTS_FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: true
      }
    }
    case DAIRY_RATE_LIST_ADD: {
      return {
        ...state,
        isAdding: true
      }
    }
    case DAIRY_RATE_LIST_ADD_ERROR: {
      return {
        ...state,
        isAdding: false
      }
    }
    case DAIRY_RATE_LIST_ADD_SUCCESS: {
      return {
        ...state,
        rateLists: [
          ...state.rateLists,
          action.payload
        ],
        isAdding: false,
        isListAdded: true
      };
    }
    case DAIRY_RATE_LIST_ADDED_RESET: {
      return {
        ...state,
        isListAdded: false
      };
    }
    case DAIRY_RATE_LIST_DELETE_RESET: {
      return {
        ...state,
        isListDeleted: false
      }
    }
    case DAIRY_RATE_LIST_UPDATE: {
      return {
        ...state,
        isUpdating: true
      }
    }
    case DAIRY_RATE_LIST_UPDATE_ERROR: {
      return {
        ...state,
        isUpdating: false
      }
    }
    case DAIRY_RATE_LIST_UPDATE_SUCCESS: {
      const rateListIndex = state.rateLists.findIndex(rateList => rateList.rate_list_id === action.payload.rateListId);
      const rateLists = [...state.rateLists];
      rateLists[rateListIndex] = action.payload.data;
      return {
        ...state,
        rateLists: [...rateLists],
        isUpdating: false
      };
    }
    case DAIRY_RATE_LIST_DELETE: {
      return {
        ...state,
        isDeleting: true
      }
    }
    case DAIRY_RATE_LIST_DELETE_ERROR: {
      return {
        ...state,
        isDeleting: false
      }
    }
    case DAIRY_RATE_LIST_DELETE_SUCCESS: {
      const rateListIndex = state.rateLists.findIndex(rateList => rateList.rate_list_id === action.payload);
      return {
        ...state,
        rateLists: [
          ...state.rateLists.slice(0, rateListIndex),
          ...state.rateLists.slice(rateListIndex + 1)
        ],
        isDeleting: false,
        isListDeleted: true
      };
    }
    case DAIRY_RATE_LISTS_RESET: {
      return {
        ...copyObject(state),
        rateLists: [],
        isFetched: false,
      }
    }
    default:
      return state;
  }
};

export const selectRateLists = (state) => state.dairyRateList?.rateLists || [];

export default reducer;