// @ts-nocheck
import { fetchCollectionTotal } from "src/offline-storage/dairy/milk-collection";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCollectionQueueTotal = createAsyncThunk(
    'collectionQueueTotal/fetchCollectionQueueTotal',
    async ({ dairyId, shift, date }, { getState }) => {
        const data = await fetchCollectionTotal({
            date,
            shift,
            dairy_id: dairyId
        });
        return data;
    },
  );
  