// @ts-nocheck
/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { toast, loader } from '../components/library/util';
import { t } from '../components/language/LanguageController';
import { errorHandler } from '../components/library/response';
import request from "../components/library/request";
import { checkFeatureInSubscription } from "../components/common/FeatureFlag/FeatureFlag";

/*
 * action types
 */

export const ADD_DC_PRODUCT = 'ADD_DC_PRODUCT';
export const UPDATE_DC_PRODUCT = 'UPDATE_DC_PRODUCT';
export const DELETE_DC_PRODUCT = 'DELETE_DC_PRODUCT';
export const FETCH_DC_PRODUCTS_BEGIN = 'FETCH_DC_PRODUCTS_BEGIN';
export const FETCH_DC_PRODUCTS_SUCCESS = 'FETCH_DC_PRODUCTS_SUCCESS';
export const FETCH_DC_PRODUCTS_FAILURE = 'FETCH_DC_PRODUCTS_FAILURE';
export const RESET_DC_PRODUCTS = 'RESET_DC_PRODUCTS';


/*
 * action creators
 */

export const addProduct = data => ({
    type: ADD_DC_PRODUCT, 
    data: data 
});

export const updateProduct = (index, data) => ({ 
    type: UPDATE_DC_PRODUCT, 
    data: data,
    index: index 
});

export const deleteProductSuccess = index => ({
    type: DELETE_DC_PRODUCT,
    index: index
});

export const fetchProductsBegin = () => ({
    type: FETCH_DC_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = data => ({
    type: FETCH_DC_PRODUCTS_SUCCESS,
    data: data
});

export const fetchProductsFailure = () => ({
    type: FETCH_DC_PRODUCTS_FAILURE,
    data: {}
});

export const resetDairyCustomerProducts = () => ({
    type: RESET_DC_PRODUCTS
});

export function postProducts(dairyId, cb){
    return async () => {
        try {
            loader.show(t("text_saving"));
            let formData = new FormData(document.getElementById("productForm"));
            const { status, message, error_code } = await request.post("/dairies/" + dairyId + "/customer/products", {
                withAuth: true,
                body: formData,
                version: "v2"
            });
            if(status === true){
                cb(true);
                toast(message);
            } else {
                cb(false);
                if(error_code != 501)
                    throw message
            }
        } catch (error) {
            Sentry.captureException(error);
            cb(false);
            errorHandler(error);
        } finally {
            loader.hide();
        }
    };
}

export function fetchProducts(dairyId, filterData){
    return async dispatch => {
        try {
            if(checkFeatureInSubscription("product_management", false)){
                loader.show(t("text_loading"));
                dispatch(fetchProductsBegin());
                let filterStr = "?dairy_customer_id=" + filterData.dairy_customer_id;
                filterStr += "&date=" + filterData.start_date + ":" + filterData.end_date;
                filterStr += "&product_id=" + filterData.product_id;
                filterStr += "&view=" + filterData.view;
                const { status, data } = await request.get("/dairies/" + dairyId + "/customer/products" + filterStr, {
                    withAuth: true,
                    version: "v2"
                });
                if(status) {
                    dispatch(fetchProductsSuccess(data));
                } else {
                    dispatch(fetchProductsFailure());    
                }
            } else {
                dispatch(fetchProductsSuccess({
                    result: [],
                    total: {}
                }));
            }
        } catch (error) {
            errorHandler(error);
            dispatch(fetchProductsFailure());
        } finally {
            loader.hide();
        }
    };
}

export function deleteProduct(dairyId, productId, filterData){
    return async dispatch => {
        try {
            loader.show(t("text_deleting"));
            const { status, message } = await request.delete("/dairies/" + dairyId + "/customer/products/" + productId, {
                withAuth: true,
                version: "v2"
            });
            if(status === true){
                toast(message);
                dispatch(fetchProducts(dairyId, filterData));
            } else {
                throw message;
            }
        } catch (error) {
            Sentry.captureException(error);
            errorHandler(error);
        } finally {
            loader.hide();
        }
    };
}