import React from "react";
import moment from "moment";
import CustomerLoanHistoryController from "./CustomerLoanHistoryController";
import { connect } from "react-redux";
import InnerNav from "../../InnerNav";
import withLanguage from "../../../../language/LanguageController";
import BillDatePicker from "../../account/BillDatePicker";
import { currencyFormat } from "../../../../library/util";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { Typography } from "@material-ui/core";

class CustomerLoanHistory extends CustomerLoanHistoryController {

    render() {
        const {
            loan_history,
            loans,
            loan_total
        } = this.state;
        const {
            t,
            dairyCustomerId,
            customerId,
            dairyId,
            header
        } = this.props;
        
        return (
            <DefaultLayout
                back
                header={header}
                title={t("text_your_loan_statement")}
                toolbar={false}>
                <div className="t-card shadow-sm mb-2">
                    <div className="title right card__title card--material__title mt-0 mb-2 clearfix">
                        <h4 className="d-none d-lg-block">{t("text_your_loan_statement")}</h4>
                        <BillDatePicker
                            billingCycle={"monthly"}
                            onChange={this.onDateChange}
                        />
                    </div>
                    <div className="content card__content card--material__content">
                        <div className="row mb-2">
                            <div className="col-6">
                                <Typography variant="h6" component="span">
                                    {t("text_balance")}
                                </Typography>
                            </div>
                            <div className="col-6 text-right">
                                <Typography variant="h6" component="span">
                                    <span className="text-red">
                                        {currencyFormat(loan_history.length ? loan_history[0].balance : 0)}
                                    </span>
                                </Typography>
                            </div>
                        </div>
                        <div className="table-fix-header">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>{t("text_date")}</th>
                                        <th className="text-center">
                                            <span className="text-green">{t("text_cr")}</span> / <span className="text-red">{t("text_de")}</span>
                                        </th>
                                        <th className="text-right">{t("text_balance")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loan_history.map(row => {
                                            return (
                                                <tr key={row.id}>
                                                    <td>
                                                        <div className="width100p weight-500 size-15">
                                                            {moment(row.date).format("MMM DD")}
                                                        </div>
                                                        <div className="width100p size-12 text-grey">
                                                            {row.payment_type === "milk_bill" ? t("text_from_milk_bill") : t("text_by_cash")}
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <span className={`size-15 ${row.transaction_type === "credit" ? "text-green" : "text-red"}`}>
                                                            {currencyFormat(row.amount)}
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="size-15">
                                                            {currencyFormat(row.balance)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    { loan_history.length <= 0 
                                        ? (
                                            <tr>
                                                <td colSpan={3} className="text-center" style={{background: "#eee"}}>
                                                    <div className="">{t("text_no_data_available")}</div>
                                                </td>
                                            </tr>
                                        ) 
                                        : null }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="t-card shadow-sm">
                    <div className="title right card__title card--material__title mt-0">
                        <div className="row mb-2">
                            <div className="col-6">
                                <Typography variant="h6" component="h2">
                                    {t("text_loan_sanctioned")}
                                </Typography>
                            </div>
                            <div className="col-6 text-right">
                                <Typography variant="h6" component="span">
                                    <span className="text-red">
                                        {currencyFormat(loan_total)}
                                    </span>
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="content card__content card--material__content">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>{t("text_date")}</th>
                                    <th className="text-right">{t("text_amount")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loans.map(loan => <tr key={loan.id}>
                                    <td>
                                        <div className="width100p weight-500 size-15">
                                            {moment(loan.date).format("MMM DD")}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-red size-15">
                                            {currencyFormat(loan.amount)}
                                        </span>
                                    </td>
                                </tr>)}
                                { loans.length <= 0 
                                    ? <tr>
                                        <td colSpan={2} className="text-center" style={{background: "#eee"}}>
                                            <div className="">{t("text_no_data_available")}</div>
                                        </td>
                                    </tr> : null }
                            </tbody>
                        </table>
                    </div>
                </div>
                <InnerNav
                    menu="kisan_customer"
                    page="customerLoan"
                    navigator={this.props.navigator}
                    dairyCustomerId={dairyCustomerId}
                    dairyId={dairyId}
                    customerId={customerId} />
            </DefaultLayout>
        );
    }
}

CustomerLoanHistory.displayName = "CustomerLoanHistory";

CustomerLoanHistory.defaultProps = {
    header: true
};

const mapStateToProps = state => ({
    dairy: state.dairy.dairy,
    dairyName: state.dairy.dairy.name,
    dairyId: state.dairy.dairy.dairy_id,
    dairyCustomerId: state.dairy.dairy.dairy_customer_id,
    dairyType: state.dairy.dairy.type
});

export default connect(mapStateToProps)(withLanguage(CustomerLoanHistory));