import { Button } from "@material-ui/core";
import { useSelector } from "react-redux"
import { getDairyName } from 'src/reducers/dairy';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "#fff"
    }
}));

const DairyNameWithSelection = () => {
    const classes = useStyles();

    const dairyName = useSelector(getDairyName);
    const history = useHistory();
    const handleClick = useCallback(() => {
        history.replace("/dairy/selection");
    }, [history]);

    return (
        <Button className={classes.root} endIcon={<ArrowDropDownIcon />} onClick={handleClick}>
            {dairyName}
        </Button>
    );
}

export default DairyNameWithSelection;