// @ts-nocheck
/* eslint-disable react/prop-types */

import React from "react";
import { InputText } from "../../../../core/input";
import { Icon} from "react-onsenui";
import Tooltip from "react-power-tooltip";
import { useLanguage } from "../../../../language/LanguageController";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import { SNF_LOWER_LIMIT, SNF_UPPER_LIMIT } from "../../../../../_constants/rateList";

const AddMilkCLRSNF = ({
    clr,
    clrHelp,
    rateListTypeId,
    handleInput,
    inputReadOnly,
    rate_by,
    showHelp,
    snf,
    snfHelp,
    validator,
    validateField,
    rateSettings = {}
}) => {
  const { t } = useLanguage();
    const { rate_settings } = rateSettings
    let defaultCLRSNFType = rate_settings?.[`default_${rate_by}_type`] == "fixed";
    let defaultCLRSNFValue = rate_settings?.[`default_${rate_by}_value`];
    if(!checkFeatureInSubscription("default_clr_snf", false)) {
        defaultCLRSNFType = false;
    }
    if(defaultCLRSNFType) {
      if(rate_by === "snf") {
        snf = defaultCLRSNFValue;
      } else {
        clr = defaultCLRSNFValue;
      }
    }
    if(rate_by === "snf")  {
        return <div className="col">
          <InputText 
            label={
              <span>  
                {t("text_snf")}  &nbsp;
                <Icon icon="md-help" onClick={e => showHelp(e, "snf")}/>
                <Tooltip 
                  show={snfHelp}
                  position="left center">
                  <span>{t("text_clr_snf")}</span>
                </Tooltip>
              </span>
            }
            type="number"
            className="form-control input-bottom-border"
            name="snf"
            id="input_snf"
            step="0.1"
            value={snf || ""}
            readOnly={inputReadOnly || defaultCLRSNFType ? true : false}
            errorMessage={
              validator.message("snf", snf, `required|numeric|min:${SNF_LOWER_LIMIT},num|max:${SNF_UPPER_LIMIT},num`)
            }
            autoComplete="new-password"
            onChange={handleInput}
            placeholder="ex: 8.5"/>
        </div>; 
    } else if( rateListTypeId !== 5)  {
        return <div className="col">
            <InputText
              label={
                <span>  
                  {t("text_clr")}  &nbsp;
                  <Icon icon="md-help" onClick={e => showHelp(e, "clr")}/>
                  <Tooltip 
                    show={clrHelp}
                    position="left center">
                    <span>{t("text_clr_help")}</span>
                  </Tooltip>
                </span>
              }
              type="number" 
              className="form-control input-bottom-border"
              name="clr"
              id="input_clr"
              value={clr || ""}
              min="18"
              max="40"
              step="0.1"
              errorMessage={
                validator.message("clr", clr, "required|numeric|min:18,num|max:40,num")
              }
              readOnly={inputReadOnly || defaultCLRSNFType ? true : false}
              autoComplete="new-password"
              onBlur={validateField}
              onChange={handleInput}
              placeholder="ex: 27.5"/>
          </div>;
    }

    return null;
};

export default AddMilkCLRSNF;