// @ts-nocheck
/* eslint-disable no-console */
import React, { Component } from "react";
import withLanguage from "../../language/LanguageController";
import ModalImage from "../../../assets/img/trial_expired.png";
import { connect } from "react-redux";
import { checkSubscriptionIsActive } from "../../common/FeatureFlag/FeatureFlag";
import analytics from "../../library/firebase/analytics";
import ModalDialog from "../../common/ModalDialog";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { withRouter } from "react-router";

let popupTimeout;
class TrialExpiredModal extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidMount () {
        popupTimeout = setTimeout(() => {
            this.showPopup();
        }, 1000*5);
    }

    componentWillUnmount () {
        clearTimeout(popupTimeout);
    }

    showPopup = () => {
        const { subscription, location } = this.props;
        if(subscription.is_trial == 1) {
            if(!checkSubscriptionIsActive() && !location.pathname.includes("subscription")) {
                analytics.logEvent("TrialExpiredModal - Displayed");
                this.setState({
                    isOpen: true
                });
            }
        }
    }

    hidePopup = () => {
        analytics.logEvent("TrialExpiredModal - Close");
        this.setState({
            isOpen: false
        });
    }

    handleSubscribe = () => {
        this.hidePopup();
        analytics.logEvent("TrialExpiredModal - GotoPlans");
        this.props.history.push("/subscription/plans")
    }

    render () {
        const { isOpen } = this.state;
        const { t } = this.props;
        return (
            <ModalDialog
                title={t("text_title_trial_expired")}
                isOpen={isOpen}
                onClose={this.hidePopup}
                content={
                    <div className="text-center subscription-purchase-modal">
                        <div className="mb-3">
                            <img src={ModalImage} style={{maxWidth: "110px"}} alt=""/>
                        </div>
                        {t("text_trial_membership_expired")}
                    </div>
                }
                dialogActions={
                    <PrimaryButton onClick={this.handleSubscribe}>
                        {t("text_subscribe")}
                    </PrimaryButton>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    subscription: state.subscription.subscription
});

export default connect(mapStateToProps)(
    withRouter(withLanguage(TrialExpiredModal))
);