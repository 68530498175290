import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import AppUpdateModal from "../appUpdate/AppUpdateModal";
import SubscriptionPurchaseModal from "../modalPopups/subscriptionPurchaseModal";
import SalePremiumModal from "../modalPopups/salePremiumModal";
import TrialExpiredModal from "../modalPopups/trialExpiredModal";
import SubscriptionExpiredModal from "../modalPopups/subscriptionExpiredModal";
import { useSelector } from "react-redux";
import SubscriptionUpsaleModal from "../modalPopups/subscriptionUpsaleModal";
import ReferralDetailModal from "../modalPopups/ReferralDetailModal";
import LanguageSelectorPopup from './LanguageSelectorPopup';
import ReferModal from '../modalPopups/referModal';
import DesktopUpdateModal from "../modalPopups/desktopUpdateModal";

const Portals = (props) => {
    const user = useSelector(state => state.userReducer.user);
    const upsalePopupAnywhere = useSelector(state => state.defaultReducer.upsale_popup_anywhere);
    const { referral } = useSelector(state => state);
    return (
        <Fragment>
            {ReactDOM.createPortal(
                <AppUpdateModal/>,
                document.getElementById("appUpdatePortal")
            )}
            {ReactDOM.createPortal(
                <DesktopUpdateModal/>,
                document.getElementById("appUpdatePortal")
            )}
            {referral.isOpen
                && ReactDOM.createPortal(
                <ReferralDetailModal />,
                document.getElementById("portal")
            )}
            {props.isLogin 
                && user.customer_group_id == 2
                && ReactDOM.createPortal(
                <SubscriptionPurchaseModal {...props} />,
                document.getElementById("premiumModalPortal")
            )}
            {props.isLogin 
                && user.customer_group_id == 2
                && ReactDOM.createPortal(
                <SalePremiumModal {...props} />,
                document.getElementById("salePremiumModalPortal")
            )}
            {props.isLogin
                && user.customer_group_id == 2
                && ReactDOM.createPortal(
                <TrialExpiredModal {...props} />,
                document.getElementById("trialExpiredModalPortal")
            )}
            {props.isLogin
                && user.customer_group_id == 2
                && ReactDOM.createPortal(
                <SubscriptionExpiredModal {...props} />,
                document.getElementById("subscriptionExpiredModalPortal")
            )}
            {props.isLogin
                && user.customer_group_id == 2
                && upsalePopupAnywhere 
                && upsalePopupAnywhere.show_popup
                && ReactDOM.createPortal(
                <SubscriptionUpsaleModal {...props} isCheckout={false} />,
                document.getElementById("subscriptionUpsaleModalPortal")
            )}
            {ReactDOM.createPortal(
                <LanguageSelectorPopup {...props} />,
                document.getElementById("languageSelectorPopup")
            )}

            {props.isLogin && ReactDOM.createPortal(
                <ReferModal {...props} />,
                document.getElementById("referPopup")
            )}
        </Fragment>
    );
};

Portals.propTypes = {
    isLogin: PropTypes.bool,
    changePage: PropTypes.func,
    getNavigator: PropTypes.func
};

export default Portals;