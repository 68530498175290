import React, { useEffect, Fragment } from 'react'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Box, Card, Grid, Typography, Checkbox, FormGroup, FormControlLabel, InputBase, Paper, Button } from "@material-ui/core";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useLanguage } from "./../../language/LanguageController";
import ListItemText from "@material-ui/core/ListItemText";
import { fetchcustomerRewardHistory } from '../../../actions/reward';
import { useSelector, useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const RewardHistory = () => {

  const classes = useStyles();
  const { t } = useLanguage();
  const rewardData = useSelector(state => state.reward.rewardHistory);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchcustomerRewardHistory(dispatch);
  }, []);
  return (
    <List className={classes.root}>
      {rewardData?.map((reward, index) => (
        <Fragment key={reward.id}>
          <ListItem >
            <ListItemAvatar>
              <Fragment>
                <Typography variant="h5" component="h6" display="block" align="center">
                  {moment(reward.created_at).format("DD")}
                </Typography>
                <Typography variant="subtitle1" display="block" align="center">
                  {moment(reward.created_at).format("MMM")}
                </Typography>
                <Typography variant="body2" display="block" align="center">
                  {moment(reward.created_at).format("YYYY")}
                </Typography>
              </Fragment>
            </ListItemAvatar>
            <ListItemText

              secondary={
                <Fragment>
                  <Typography component="span" display="block" variant="body2">

                    <Grid container>
                      <Grid item>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textPrimary"
                        >
                          {t("text_points")}:
                        </Typography>{" "}
                        <Typography component="span" variant="subtitle2" className={`font-weight-bold ${reward.transation_type === 'text_credit' ? 'text-green' : 'text-red'}`}>
                           {reward.points}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Grid container direction="row-reverse">
                          <Grid item>
                            <Typography
                              component="span"
                              variant="subtitle2"
                              color="textPrimary"
                            >
                              {t("text_balance")}:
                            </Typography>{" "}
                            <Typography component="span" variant="subtitle2" className={`font-weight-bold ${reward.current_balance >0 ? 'text-green' : 'text-red'}`}>
                              {reward.current_balance>0? reward.current_balance:'0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Typography>

                  <Typography component="span" display="block" variant="body2">
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="textPrimary"
                    >
                      {t("text_transaction")}:
                    </Typography>{" "}
                    <Typography component="span" variant="subtitle2" className={`font-weight-bold ${reward.transation_type === 'text_credit' ? 'text-green' : 'text-red'}`}>
                      {t(reward.transation_type)}
                    </Typography>
                  </Typography>
                  <Typography component="span" display="block" variant="body2">
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="textPrimary"
                    >
                      {t("text_remark")}:
                    </Typography>{" "}
                    {t(reward.remark)}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>

          {+index + 1 < rewardData.length ? (
            <Divider variant="inset" component="li" />
          ) : null}

        </Fragment>
      ))}
    </List>
  )
}

export default RewardHistory
