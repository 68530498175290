// @ts-nocheck
import { FormControlLabel, IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PrimarySwitch from "src/components/core/Switch/PrimarySwitch";
import { t } from "src/components/language/LanguageController";
import { getShift } from "src/components/library/milkUtils";
import config from "src/config";
import MachineStatusDialog from "../MachineStatusDialog";
import OfflineHistoryDialog from "../OfflineHistoryDialog";
import HelpDialog from "../HelpDialog";
import useDeviceStatus from "src/hooks/hardwareIntegration/useDeviceStatus/useDeviceStatus";
import WarningIcon from '@material-ui/icons/Warning';
import { WarningIconButton } from "../../../../../common/buttons";
import LoaderIcon from "src/components/common/icons/LoaderIcon";
import Eventer from "src/components/library/Eventer";
import { HtmlTooltip } from "../../../../../common/Tooltip";
import DropdownMenu from "../../../../../common/DropdownMenu";
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import { checkFeatureInSubscription } from "src/components/common/FeatureFlag/FeatureFlag";

export const AutoCollectionHeader = ({ autoCollectionSwitch, onAutoCollectionSwitch }) => {

    const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
    const [isStatusModalOpen, setStatusModalOpen] = useState(false);
    const [isHelpModalOpen, setHelpModalOpen] = useState(false);
    const [syncInProgress, setSyncInProgress] = useState(false);
    const { devicesStatus } = useDeviceStatus();
    const isAllDevicesConnected = devicesStatus?.weightAnalyser?.isOpen && devicesStatus?.milkAnalyser?.isOpen;
    
    const handleDataSync = () => {
        window?.literAPI?.backgroundSync.sync();
        setSyncInProgress(true);
    }

    const onSyncStatus = ({ detail }) => {
        console.log("onSyncStatus::detail", detail);
        setSyncInProgress(false);
    }

    useEffect(() => {
        Eventer.on("syncStatus", onSyncStatus);
        return () => {
            Eventer.off("syncStatus", onSyncStatus);
        }
    }, []);

    const isAutoCollectionFeature = checkFeatureInSubscription("automatic_collection", false)

    return (
        <div className="t-card mb-2 shadow-sm">
            <div className="d-flex justify-content-between mb-2">
                <div className="text-left d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                    <label className="mb-0 mr-2">
                        <b>{t("text_date")}:</b> {moment().format(config.display_date_format)}

                    </label>
                    <label className="mb-0">
                        <b>{t("text_shift")}</b>: {t(`text_${getShift()}`)}
                    </label>
                </div>
                <div className="text-right">
                    {isAutoCollectionFeature && <FormControlLabel
                        value="start"
                        control={<PrimarySwitch size="medium" checked={autoCollectionSwitch} onChange={onAutoCollectionSwitch}  />}
                        label={t("text_auto_collection")}
                        labelPlacement="start"
                        className="mr-2 ml-0"
                    />}
                    {isAutoCollectionFeature && autoCollectionSwitch && !isAllDevicesConnected && <HtmlTooltip
                        title={
                            <Typography color="inherit">{t("error_device_connection_issue_text")}</Typography>
                        }
                        arrow
                    >
                        <WarningIconButton size="medium" className="mr-2">
                            <WarningIcon/>
                        </WarningIconButton>
                    </HtmlTooltip>}
                    <IconButton color="primary" onClick={() => setHelpModalOpen(true)}>
                        <HelpIcon />
                    </IconButton>
                    <DropdownMenu
                        label={"Options"}
                        buttonType="icon"
                        renderIcon={() => <SettingsIcon />}
                    >
                        <MenuItem onClick={handleDataSync} disabled={syncInProgress}>
                            {syncInProgress && <LoaderIcon spin={syncInProgress} />} {t("text_sync")}
                        </MenuItem>
                        {isAutoCollectionFeature && <MenuItem onClick={() => setStatusModalOpen(true)} disabled={!autoCollectionSwitch}>
                            {t("text_machine_status")}
                        </MenuItem>}
                        <MenuItem onClick={() => setHistoryModalOpen(true)}>{t("text_view_history")}</MenuItem>
                        <MenuItem onClick={() => setHelpModalOpen(true)}>{t("text_help")}</MenuItem>
                    </DropdownMenu>
                    {isHistoryModalOpen && <OfflineHistoryDialog
                        isOpen={isHistoryModalOpen}
                        onClose={() => setHistoryModalOpen(false)}
                    />}
                    {isStatusModalOpen && <MachineStatusDialog
                        isOpen={isStatusModalOpen}
                        onClose={() => setStatusModalOpen(false)}
                    />}
                    {isHelpModalOpen && <HelpDialog
                        isOpen={isHelpModalOpen}
                        onClose={() => setHelpModalOpen(false)}
                    />}
                </div>
            </div>                    
        </div>
    );
}

export default AutoCollectionHeader;