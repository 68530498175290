// @ts-nocheck
import React, { useCallback, useEffect } from  "react";
import analytics from "../../library/firebase/analytics";
import { useDispatch, useSelector } from "react-redux";
import { toggleReferralModal } from "../../../actions/referral";
import { getLang, useLanguage } from "../../language/LanguageController";
import { socialSharing } from "../../library/util";
import ModalDialog from "src/components/common/ModalDialog";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { Button } from "@material-ui/core";

const ReferralDetailModal = () => {
    const dispatch = useDispatch();
    const langCode = getLang();
    const{ t } = useLanguage();
    const { isOpen } = useSelector(state => state.referral);
    const { plans } = useSelector(state => state.subscription);
    const { user: { referral = {} } = {} } = useSelector(state => state.userReducer || {});
    const { referral_subscription_days = {} } = useSelector(state => state.defaultReducer || {});
    const { merchant: referralSubscriptionDetails = {} } = referral_subscription_days;
    const planIds = Object.keys(referralSubscriptionDetails);

    useEffect(() => {
        analytics.logEvent("ReferralDetailModal");
    }, []);

    const referNow = useCallback(() => {
        const shareText = t("text_referral_share_code").replace("{referral_code}", referral.code);
        socialSharing(shareText, "", "share_app");
    }, [socialSharing, referral]);

    return (
        <ModalDialog
            title={t("text_referral_details")}
            isOpen={isOpen}
            onClose={() => dispatch(toggleReferralModal())}
            content={
                <>
                    <div className="table_container">
                        <table className="table table-striped text-sm shadow-sm">
                            <thead>
                                <tr>
                                    <th className="px-2 py-2">
                                        {t("text_months")}
                                    </th>
                                    {planIds.map(planId => {
                                        const plan = plans.find(plan => plan.plan_id == planId);
                                        return (
                                            <th key={planId} className="px-2 py-2">
                                                {plan ? (plan.name_local[langCode] || plan.name) : null}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {[1,3,6,12].map(duration => 
                                    <tr key={duration}>
                                        <td className="px-2 py-2">
                                            <b>{duration}</b>
                                        </td>
                                        {planIds.map(planId => {
                                            const durationDay = referralSubscriptionDetails[planId][duration];
                                            return (
                                                <td key={planId} className="px-2 py-2">
                                                    {durationDay} {t("text_days")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {referralSubscriptionDetails
                            && referralSubscriptionDetails[4]
                            && referralSubscriptionDetails[4][12] ? (
                            <p className="text-center text-sm">
                                {t("text_referral_plan_details_table_text").replace("{n}", referralSubscriptionDetails[4][12])}
                            </p>
                        ) : null}

                        <p className="text-center font-weight-bold">
                            {t("text_referral_code")}: {referral?.code}
                        </p>
                    </div>
                </>
            }
            dialogActions={
                <>
                    <Button 
                        onClick={() => dispatch(toggleReferralModal())}>
                        {t("text_close")}
                    </Button>
                    <PrimaryButton
                        variant="contained"
                        onClick={referNow}>
                        {t("text_refer_now")}
                    </PrimaryButton>
                </>
            }
        />
    );
};

export default ReferralDetailModal;