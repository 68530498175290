import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CustomersSelect from "../../../common/CustomersSelect";
import withLanguage from "../../../../language/LanguageController";
import MuiDatepicker from "../../../../common/MuiDatepicker";
import moment from "moment";
import { useMobile } from "src/components/library/detect-device";

const Filter = ({ 
    t,
    startDate,
    endDate,
    customers,
    customer,
    customerChangeHandler,
    dateChangeHandler,
    handleCodeChange,
    code
}) => {
    const isMobile = useMobile();
    return (
        <div className="form-row">
            <div className="col-12">
                {isMobile ? <div className="d-flex mb-2">
                    <div className="form-row flex-fill">
                        <div className="col-3">
                            <div className="form-group mb-0">
                                <label htmlFor="customer_code" className="sr-only">{t("text_code")}</label>
                                <input
                                    className="form-control shadow-sm"
                                    type="number"
                                    id="customer_code"
                                    placeholder={t("text_code")}
                                    defaultValue={code}
                                    onChange={e => {
                                        e.persist();
                                        handleCodeChange(e);
                                    }} />
                            </div>  
                        </div>
                        <div className="col">
                            <CustomersSelect
                                customers={customers}
                                onChange={customerChangeHandler}
                                value={customer}/>
                        </div>
                    </div>
                </div> : null}
                <div className="d-flex mb-2 justify-content-between">
                    <div className="input-group mr-1">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm">{t("text_from")}</span>
                        </div>
                        <MuiDatepicker
                            value={startDate}
                            maxDate={endDate}
                            onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                            name="start_date"
                            inputId="start_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm">{t("text_to")}</span>
                        </div>
                        <MuiDatepicker
                            value={endDate}
                            minDate={startDate}
                            maxDate={moment().endOf("month")}
                            onChange={selectedDate => dateChangeHandler("end_date", selectedDate)}
                            name="end_date"
                            inputId="end_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Filter.propTypes = {
    t: PropTypes.func.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    customers: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    customerChangeHandler: PropTypes.func.isRequired,
    dateChangeHandler: PropTypes.func.isRequired,
    handleCodeChange: PropTypes.func.isRequired,
    code: PropTypes.string
};

export default withLanguage(Filter);