import React, { useEffect, useState } from "react";
import withLanguage from "../../language/LanguageController";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import analytics from "../../library/firebase/analytics";
import ModalDialog from "../../common/ModalDialog";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";

const NoCustomerModal = ({ t }) => {
    const [isOpen, setIsOpen] = useState(false);
    const customers = useSelector(selectDairyCustomers);
    const history = useHistory();
    useEffect(() => {
        if(!customers.length) {
            analytics.logEvent("NoCustomerModal");
            setIsOpen(true);
        }
    }, [customers]);

    const addCustomer = useCallback(() => {
        setIsOpen(false);
        history.push("/customers/add");
    }, [history]);

    return (
        <ModalDialog
            isOpen={isOpen}
            title={t("text_no_customers_title")}
            dividers={false}
            content={
                <div className="text-center">
                   {t("text_no_customer_body")}
                </div>
            }
            dialogActions={
                <PrimaryButton onClick={addCustomer}>
                    {t("text_add_customer")}
                </PrimaryButton>
            }
        />
    );
};

NoCustomerModal.propTypes = {
    t: PropTypes.func.isRequired,
    navigator: PropTypes.object
};

export default withLanguage(NoCustomerModal);