import { advanceTemplate } from "../../../library/smsTemplates";
import { currencyFormat } from "../../../library/util";
import moment from "moment";
import React from "react";
import { Icon } from "react-onsenui";
import withLanguage from "../../../language/LanguageController";
import { socialSharing } from "../../../library/util";
import analytics from "../../../library/firebase/analytics";
import { useAndroid } from "src/components/library/detect-device";
import Spinner from "src/components/core/Spinner";

const CustomerAdvancePayment = ({ t, advance = [], advanceTotal = 0, selectedCustomer = {}, isLoading }) => {
    const shareAdvance = e => {
        e.preventDefault();
        analytics.logEvent("shareAdvance");
        const { name } = selectedCustomer;
        let message = advanceTemplate(name, advance);
        socialSharing(message);
    };

    const isAndroid = useAndroid();

    return (
        <div className="t-card mb-2 mx-0 shadow-sm">
            <div className="title right card__title card--material__title mt-0">
                {t("text_advance_payment")}
                {(advance.length > 0) && (
                    <div className="float-right">
                        {currencyFormat(advanceTotal)}{" "}
                        {isAndroid && <button className="btn"
                            type="button"
                            title={t("text_share")}
                            onClick={e => shareAdvance(e)}>
                            <Icon icon="md-share" size={18} />
                        </button>}
                    </div>
                )}
            </div>
            <div className="content card__content card--material__content">
                {!isLoading ? <table className="table mb-2 size-12 table-pad-10-15">
                    <thead>
                        <tr>
                            <th>{t("text_date")}</th>
                            <th>{t("text_amount")}</th>
                            <th className="text-right">{t("text_remark")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            advance.map(row => {
                                return (
                                    <tr key={row.id}>
                                        <td>{moment(row.date).format("Do MMM, YY")}</td>
                                        <td>{row.amount}</td>
                                        <td className="text-right">{row.remark}</td>
                                    </tr>
                                );
                            })
                        }
                        {
                            !advance.length ? (
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        {t("text_no_data_available")}
                                    </td>
                                </tr>
                            ) : null
                        }
                    </tbody>
                </table> : <Spinner />}
            </div>
        </div>
    );
};

export default withLanguage(CustomerAdvancePayment);