import { TOGGLE_SIDE_BAR, TOGGLE_SIDE_BAR_CLOSE } from "src/_constants/action-types";
import { getLang } from "../components/language/LanguageController";
import { copyObject } from "../components/library/util";
import {
    SET_LANGUAGE,
    TOGGLE_PREMIUM_POPUP,
    FETCH_APP_CONFIGURATION_SUCCESS,
    TOGGLE_SUBSCRIPTION_UPSALE_POPUP,
    FETCH_APP_CONFIGURATION_REQUEST,
    FETCH_APP_CONFIGURATION_FAILURE,
    TOGGLE_LANGUAGE_POPUP,
    FETCH_CUSTOMER_PREFERENCE
} from "../_constants";

const initialState = {
    isLanguagePopup: false,
    language: getLang(),
    isPremiumModelOpen: false,
    isSubscriptionUpsaleModalOpen: false,
    isSidebarOpen: false,
    premium_model: {},
    upsale_popup: {},
    upsale_popup_anywhere: {},
    support_number: 9589436928,
    whatsapp_link: "https://wa.me/message/AZ4HKSUYQS2JM1",
    youtube_ratelist_link: "https://www.youtube.com/channel/UCgcORyAy7z4QssLqMm0Ju2g",
    youtube_channel: "https://www.youtube.com/channel/UCgcORyAy7z4QssLqMm0Ju2g",
    appConfigFetched: false,
    appConfigFecthing: true,
    customerPreference:[],
    rate_list_setup_payment_button: {
        enabled: false,
        button_script: "",
        button_id: "",
        help_text: ""
    }
};

function defaultReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_LANGUAGE_POPUP: {
            return {
                ...copyObject(state),
                isLanguagePopup: action.data
            };
        }
        case SET_LANGUAGE:
            window.localStorage.setItem("language", action.data);
            return {
                ...copyObject(state),
                language: action.data
            };
        case TOGGLE_PREMIUM_POPUP:
            return {
                ...copyObject(state),
                isPremiumModelOpen: !state.isPremiumModelOpen,
                premium_model: action.data
            };
        case TOGGLE_SIDE_BAR: 
            return {
                ...copyObject(state),
                isSidebarOpen: !state.isSidebarOpen
            };
        case TOGGLE_SIDE_BAR_CLOSE: 
            return {
                ...copyObject(state),
                isSidebarOpen: false
            };
        case TOGGLE_SUBSCRIPTION_UPSALE_POPUP:
            return {
                ...copyObject(state),
                isSubscriptionUpsaleModalOpen: !state.isSubscriptionUpsaleModalOpen
            };
        case FETCH_APP_CONFIGURATION_REQUEST: {
            return {
                ...copyObject(state),
                appConfigFecthing: true
            }
        }
        case FETCH_APP_CONFIGURATION_SUCCESS: {
            return {
                ...copyObject(state),
                ...action.data,            
                appConfigFecthing: false,
                appConfigFetched: true
            };
        }
        case FETCH_APP_CONFIGURATION_FAILURE: {
            return {
                ...copyObject(state),
                appConfigFecthing: false,
                appConfigFetched: true
            }
        }
        case FETCH_CUSTOMER_PREFERENCE: {
            return {
                ...copyObject(state),
                customerPreference:action.data
            }
        }
        default:
            return state;
    }
}

export const getRateListSetupPaymentButton = (state) => state?.defaultReducer?.rate_list_setup_payment_button;

export const selectSupportNumber = (state) => state.defaultReducer?.support_number ?? "";
export const selectWhatsappLink = (state) => state.defaultReducer?.whatsapp_link ?? "";
export const selectYoutubeRatelistLink = (state) => state.defaultReducer?.youtube_ratelist_link ?? "";

export default defaultReducer;
