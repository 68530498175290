import { t } from "src/components/language/LanguageController";
import request from "../components/library/request";
import { errorHandler } from "../components/library/response";
import { toast } from "../components/library/util";
import {
    ADD_DAIRY_USERS_FAILURE,
    ADD_DAIRY_USERS_REQUEST,
    ADD_DAIRY_USERS_SUCCESS,
    DELETE_DAIRY_USERS_SUCCESS,
    FETCH_DAIRY_USERS_FAILURE,
    FETCH_DAIRY_USERS_REQUEST,
    FETCH_DAIRY_USERS_SUCCESS,
    UPDATE_DAIRY_USERS_FAILURE,
    UPDATE_DAIRY_USERS_REQUEST,
    UPDATE_DAIRY_USERS_SUCCESS,
    RESET_DAIRY_USERS
} from "../_constants/dairyUsers";

export const fetchDairyUsersRequest = () => ({
    type: FETCH_DAIRY_USERS_REQUEST
});

export const fetchDairyUsersSuccess = (payload) => ({
    type: FETCH_DAIRY_USERS_SUCCESS,
    payload
});

export const fetchDairyUsersFailure = () => ({
    type: FETCH_DAIRY_USERS_FAILURE
});

export const addDairyUsersRequest = () => ({
    type: ADD_DAIRY_USERS_REQUEST
});

export const addDairyUsersSuccess = (payload) => ({
    type: ADD_DAIRY_USERS_SUCCESS,
    payload
});

export const addDairyUsersFailure = () => ({
    type: ADD_DAIRY_USERS_FAILURE
});

export const updateDairyUsersRequest = () => ({
    type: UPDATE_DAIRY_USERS_REQUEST
});

export const updateDairyUsersSuccess = (payload) => ({
    type: UPDATE_DAIRY_USERS_SUCCESS,
    payload
});

export const updateDairyUsersFailure = () => ({
    type: UPDATE_DAIRY_USERS_FAILURE
});

export const deleteDairyUsersSuccess = (payload) => ({
    type: DELETE_DAIRY_USERS_SUCCESS,
    payload
});

export const resetDairyUsers = () => ({
    type: RESET_DAIRY_USERS,
});

export const fetchDairyUsers = async (dispatch, dairyId) => {
    try {
        dispatch(fetchDairyUsersRequest());
        const { data } = await request.get(`/dairies/${dairyId}/users`, {
            withAuth: true
        });
        dispatch(fetchDairyUsersSuccess(data));
    } catch (error) {
        errorHandler(error);
        dispatch(fetchDairyUsersFailure(error));
    }
}

export const addDairyUser = async (dispatch, dairyId, formData) => {
    try {
        dispatch(addDairyUsersRequest());
        const { status, message, data } = await request.post(`/dairies/${dairyId}/users`, {
            body: formData,
            withAuth: true
        });
        if(status) {
            dispatch(addDairyUsersSuccess(data));
            toast(t("text_dairy_user_add_success"));
            return true;
        } else {
            throw message;
        }
    } catch(error) {
        errorHandler(error);
        dispatch(addDairyUsersFailure());
    }
}

export const updateDairyUser = async (dispatch, dairyId, formData, dairyUserId) => {
    try {
        dispatch(updateDairyUsersRequest());
        const { status, message, data } = await request.post(`/dairies/${dairyId}/users/${dairyUserId}`, {
            body: formData,
            withAuth: true
        });
        if(status) {
            dispatch(updateDairyUsersSuccess({
                dairyUserId,
                data
            }));
            toast(t("text_dairy_user_update_success"));
            return true;
        } else {
            throw message;
        }
    } catch(error) {
        errorHandler(error);
        dispatch(updateDairyUsersFailure());
    }
}

export const deleteDairyUser = async (dispatch, dairyId, dairyUserId) => {
    try {
        const { status, message } = await request.delete(`/dairies/${dairyId}/users/${dairyUserId}`, {
            withAuth: true
        });
        if(status) {
            dispatch(deleteDairyUsersSuccess({
                dairyUserId,
            }));
            toast(t("text_dairy_user_add_success"));
        } else {
            throw message;
        }
    } catch(error) {
        errorHandler(error);
    }
}