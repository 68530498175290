import React, {Component} from 'react';
class CommonHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {    
    return (<div>
      <div className={`comman_header clearfix ${this.props.classname ? '' : this.props.className}`}>
        <h1 className="text-center">{this.props.pagetitle}</h1>
      </div>
    </div>);
  }
}
export default CommonHeader;
