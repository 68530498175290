import { copyObject } from "../library/util";
import React, { Component } from "react";
import { Icon } from "react-onsenui";
import PropTypes from "prop-types";

class InputTextMobileCode extends Component {

    render() {
        const { label, errorMessage, addressBook = false, onAddressBook, id, ...rest } = this.props;
        const errorClass = "";
        const restProps = copyObject(rest);
        delete restProps.errorMessage;
        return (
            <div className={"form-group " + errorClass}>
                <label htmlFor={id}>{label}</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">+91</span>
                    </div>
                    <input id={id} {...rest}/>
                    {addressBook ? (
                        <div className="input-group-append">
                            <button
                                className="btn btn-outline-secondary"
                                type="button" onClick={onAddressBook}
                            >
                                <span className="sr-only">Address Book</span>
                                <Icon icon="fa-address-book" />
                            </button>
                        </div>
                    ) : null}
                </div>
                {errorMessage}
            </div>
        );
    }
}

InputTextMobileCode.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    errorMessage: PropTypes.string,
    addressBook: PropTypes.bool,
    onAddressBook: PropTypes.func
};

export default InputTextMobileCode;