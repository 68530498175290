// @ts-nocheck
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { captureException } from "../../errorTracking";

let remoteConfig;

let configPromiseResolve, configPromiseReject;
let configPromise = new Promise(function(resolve, reject){
    configPromiseResolve = resolve;
    configPromiseReject = reject;
});

export const initRemoteConfig = async (firebaseApp) => {
    // Initialize Remote Config and get a reference to the service
    remoteConfig = getRemoteConfig(firebaseApp);

    remoteConfig.settings.minimumFetchIntervalMillis = 5000;
    
    try {
        await fetchAndActivate(remoteConfig);
        configPromiseResolve();
    } catch (err) {
        captureException(err);
        configPromiseReject(err)
    }
}

export const getAsJSON = async (key) => {
    await configPromise;
    const remoteConfigValue = getValue(remoteConfig, key);
    return JSON.parse(remoteConfigValue.asString());
}