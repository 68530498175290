// @ts-nocheck
import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import { Icon } from "react-onsenui";
import { getCroppedImg } from "../../library/canvasUtils";
import "./style.css";

const ImageCropper = ({
  aspect = 1,
  cropShape = "rect",
  cropSize = { width: 300, height: 300 },
  imgSrc,
  onCroppDone,
  onCancel
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const rotateImage = () => {
      const newRotation = rotation + 90;
      setRotation(newRotation);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const cropImage = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(
          imgSrc,
          croppedAreaPixels,
          rotation
        );
        onCroppDone(croppedImage);
      } catch (e) {
        console.error(e)
      }
    }, [croppedAreaPixels, rotation]);

    return ReactDOM.createPortal(
      <div className="crop-container">
        <Cropper
          image={imgSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspect}
          cropShape={cropShape}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropSize={cropSize}
        />
        <div className="crop-controls">
          <button className="crop-btn" onClick={onCancel}>Cancel</button>
          <button className="crop-btn" onClick={rotateImage}><Icon icon="md-rotate-ccw"/></button>
          <button className="crop-btn" onClick={cropImage}>Done</button>
        </div>
      </div>,
      document.getElementById("imageCropperPortal")
    );
};

export default ImageCropper;