import Model from "./Model";

class Subscription extends Model{
    constructor(){
        super("subscription");
    }

    static get tableName() {
        return "subscription"; // define child's tableName here
    }
}

export default Subscription;