import React, { Component } from 'react';
import InnerHeader from '../common/InnerHeader';
import UserDetail from './UserDetail';
import UserAddress from './UserAddress';
import { connect } from 'react-redux';
import { updateProfile, updateAddress, fetchCustomer } from "../../../actions/user";
import withLanguage from "../../language/LanguageController";
import analytics from '../../library/firebase/analytics';
import ProfilePicture from "./ProfilePicture";
import { Helmet } from 'react-helmet';

class Profile extends Component {

    constructor(props) {
        super(props);
        let profilePic = './assets/img/customerimage.jpeg';
        if(props.user.profile_pic) {
            profilePic = props.user.profile_pic;
        }
        this.state = {
            profile_pic: profilePic
        };
    }

    componentDidMount () {
        analytics.setScreenName("Profile");
    }

    handleInput = (e) => {
        let target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let disabledvalue = target.type === 'checkbox' ? target.checked : '';    
        let statedata = {
            [name]: value
        }
        if(name === "register_tc"){
            statedata["disabled"] = (disabledvalue) ? true : false
        }
        this.setState(statedata);        
    }

    render() {
        const { user, t } = this.props;
        return (
            <div className="container-fluid">
                <Helmet>
                    <title>{t("text_user_profile")} - Liter</title>
                </Helmet>
                <InnerHeader
                    className="height-200-p">
                    <div className="center mt-3 text-white">
                        <div className="sidemenu-user clearfix">
                            <ProfilePicture />
                            <div className="user-detail text-center">
                                <div className="user-name">
                                    {user.firstname} {user.lastname}
                                </div>
                                <div className="user-mobile">
                                    {user.telephone} ({user.customer_group_id === "2" ? t("text_dairy_owner") : t("text_kisan")})
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="form-row mt-220">
                    <div className="col-12 col-sm-6 mx-auto mb-2">
                        <UserDetail 
                            t={t}
                            user={user}
                            updateProfile={this.props.updateProfile}
                            showReferralToDealerCustomer={this.props.showReferralToDealerCustomer}
                        />
                    </div>
                    <div className="col-12 col-sm-6 mx-auto mb-2">
                        <UserAddress 
                            address={user.address}
                            updateAddress={this.props.updateAddress}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { userReducer: { user = {} }, defaultReducer } = state;
    return {
      user: user ? user : {},
      showReferralToDealerCustomer: defaultReducer.show_referral_to_dealer_customer
    }   
}

const mapDispatchToProps = ({
    updateProfile,
    updateAddress,
    fetchCustomer
});

const ProfileComp = connect(mapStateToProps, mapDispatchToProps)(withLanguage(Profile));

const ProfilePage = props => {
    return <ProfileComp {...props} />
}

ProfilePage.key = "ProfilePage1";

export default ProfilePage;
