import React, { Fragment } from 'react';
import { Icon, Button } from 'react-onsenui';
import SimpleValidator from '../../common/SimpleValidator';
import { copyToClipboard, loader, socialSharing, toast, ucFirst } from '../../library/util';
import { errorHandler } from '../../library/response';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';

class UserDetail extends React.Component {

    constructor (props) {
        super(props);
        this.saveProfile = this.saveProfile.bind(this);
        const { user } = props;
        this.state = {
            isEdit: false,
            name: user.firstname + " " + user.lastname,
            mobile: user.telephone,
            gender: user. gender,
            dob: user.dob,
            email: user.email,
            isSaving: false
        };

        this.validator = new SimpleValidator();
    }

    handleInputChange = e => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
    
        this.setState(() => {
            return {
                [name]: value
            };
        });
    }

    toggleEdit = isEdit => {
        this.setState({
            isEdit: isEdit
        });
    }

    async saveProfile () {
        try {
            // validate form before submitting to server 
            if (!this.validator.allValid()) {
                console.log("Error in update profile");
                this.validator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("saveProfile");
            loader.show(this.props.t("text_saving"));
            const { status, data, message } = await request.patch("/customer/profile/" + this.props.user.customer_id, {
                withAuth: true,
                body: JSON.stringify({
                    email: this.state.email,
                    gender: this.state.gender,
                    dob: this.state.dob,
                    name: this.state.name
                })
            });            
            if (status) {
                toast(message);
                this.props.updateProfile(data);
                this.setState({
                    isEdit: false
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    copyReferralCode = (referralCode) => {
        copyToClipboard(referralCode);
        toast(this.props.t("text_copied"));
    }

    shareReferralCode = (referralCode) => {
        const shareText = this.props.t("text_referral_share_code").replace("{referral_code}", referralCode);
        console.log("shareText", shareText);
        socialSharing(shareText, "", "share_app");
    }

    render () {
        const { isEdit, isSaving, name, mobile, gender, dob, email} = this.state;
        const { user, t, showReferralToDealerCustomer } = this.props;
        return (
            <div className="t-card shadow-sm">
                {!isEdit ? (
                <div className="card-toolbar-dropdown dropdown gesation-cicular-dropdown dropdown_zindex">
                    <button className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        title="Edit Profile"
                        onClick={_ => this.toggleEdit(true)}>
                        <Icon style={{"color": "#5cb848","fontSize": "20px"}} icon="md-edit" />
                    </button>
                </div>
                ) : null}
                <div className="row profile-detail-row">
                    <div className="col-4">
                        {t("text_id")}: 
                    </div>
                    <div className="col-8">
                        LTR{user.customer_id}
                    </div>
                </div>
                {((user.referral_code && showReferralToDealerCustomer) || (!user.referral_code)) && user.referral ? (
                    <div className="row profile-detail-row">
                        <div className="col-4">
                            {t("text_referral_code")}: 
                        </div>
                        <div className="col-8 pr-2">
                            <span className="align-middle">{user.referral.code}</span>
                            <button
                                className="btn btn-small float-right btn-outline-success"
                                onClick={e => this.shareReferralCode(user.referral.code)}>
                                <span className="sr-only">{t("text_share")}</span>
                                <i className="ons-icon zmdi zmdi-share" />
                            </button>
                            <button
                                className="btn btn-small float-right btn-outline-success mr-1"
                                onClick={e => this.copyReferralCode(user.referral.code)}>
                                <span className="sr-only">{t("text_copy")}</span>
                                <i className="fa fa-copy" />
                            </button>
                        </div>
                    </div>
                ) : null}
                <div className="row profile-detail-row">
                    <div className="col-4">
                        {t("text_name")}: 
                    </div>
                    <div className="col-8">
                        { isEdit ? (
                            <Fragment>
                                <input 
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.handleInputChange}
                                    />
                                {this.validator.message("name", name, "required")}
                            </Fragment>
                        ) : name }
                    </div>
                </div>
                <div className="row profile-detail-row">
                    <div className="col-4">
                        {t("text_mobile")}: 
                    </div>
                    <div className="col-8">
                        {user.telephone}
                    </div>
                </div>
                <div className="row profile-detail-row">
                    <div className="col-4">
                        {t("text_gender")}: 
                    </div>
                    <div className="col-8">
                        { isEdit ? (
                            <Fragment>
                                <select
                                    className="form-control"
                                    name="gender"
                                    value={gender}
                                    onChange={this.handleInputChange}>
                                    <option value="">{t("text_select")}</option>
                                    <option value="male">{t("text_male")}</option>
                                    <option value="female">{t("text_female")}</option>
                                    <option value="transgender">{t("text_transgender")}</option>
                                </select>
                                {this.validator.message("gender", gender, "required")}
                            </Fragment>
                        ) : (user.gender ? ucFirst(t("text_" + user.gender)) : null) }
                    </div>
                </div>
                <div className="row profile-detail-row">
                    <div className="col-4">
                        {t("text_dob")}: 
                    </div>
                    <div className="col-8">
                        { isEdit ? (
                            <Fragment>
                                <input 
                                    className="form-control"
                                    type="date"
                                    name="dob"
                                    value={dob}
                                    onChange={this.handleInputChange}
                                    defaultValue={user.dob}/>
                                {this.validator.message("dob", dob, "required")}
                            </Fragment>
                        ) : user.dob }
                    </div>
                </div>
                <div className="row profile-detail-row">
                    <div className="col-4">
                        {t("text_email")}: 
                    </div>
                    <div className="col-8">
                        { isEdit ? (
                            <Fragment>
                                <input 
                                    className="form-control"
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={this.handleInputChange}
                                    defaultValue={user.email}/>
                                {this.validator.message("email", email, "email")}
                            </Fragment>
                        ) : user.email }
                    </div>
                </div>
                {isEdit ? (
                <div className="row profile-detail-row text-right">
                    <div className="col-12">
                        <Button 
                            className="mr-3"
                            onClick={e => this.toggleEdit(false)}>{t("text_cancel")}</Button>
                        <Button
                            disabled={isSaving}
                            onClick={this.saveProfile}>
                            {isSaving ? t("text_saving") : t("text_save")}
                        </Button>
                    </div>
                </div>
                ) : null}
            </div>
        );
    }
};

export default UserDetail;