/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, ADD_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER, FETCH_CUSTOMERS_ERROR, UPDATE_CUSTOMERS_RATELIST, RESET_CUSTOMERS } from "../_constants";
import { toast, loader } from "../components/library/util";
import { t } from "../components/language/LanguageController";
import { errorHandler } from "../components/library/response";
import request from "../components/library/request";
/*
 * action creators
 */

export const addCustomerSuccess = data => ({
    type: ADD_CUSTOMER, 
    data: data 
});

export const updateCustomerSuccess = (index, data) => ({ 
    type: UPDATE_CUSTOMER, 
    data: data,
    index: index 
});

export const deleteCustomerSuccess = index => ({
    type: DELETE_CUSTOMER,
    index: index
});

export const fetchCustomersRequest = () => ({
    type: FETCH_CUSTOMERS_REQUEST
});

export const fetchCustomersSuccess = data => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    data: data
});

export const fetchCustomersError = () => ({
    type: FETCH_CUSTOMERS_ERROR
});

export const updateCustomersRatelist = data => ({
    type: UPDATE_CUSTOMERS_RATELIST,
    data: data
});

export const resetCustomers = () => ({
    type: RESET_CUSTOMERS
});

export const fetchCustomers = (dairyId) => {
    return async dispatch => {
        try {
            dispatch(fetchCustomersRequest());
            const { status, data } = await request.get("/dairies/" + dairyId + "/customers", {
                withAuth: true,
                version: "v2"
            });
            if(status){
                dispatch(fetchCustomersSuccess(data));
            }
        } catch (error) {
            errorHandler(error);
            dispatch(fetchCustomersError());
        }
    }
}

export const updateCustomer = (dairyId, dairyCustomerId, customerIndex) => {
    return async dispatch => {
        try {
            loader.show(t("text_saving"));
            let formData = new FormData(document.getElementById("editCustomerForm"));
            if(!formData.has("rate[cow]") && !formData.has("rate[buffalo]")) {
                formData.append("rate", 0);
            }
            const { status, data, message, error_code = 0 } = await request.post("/dairies/" + dairyId + "/customers/" + dairyCustomerId, {
                withAuth: true,
                body: formData,
                version: "v2"
            });
            if(status){
                toast(message);
                dispatch(updateCustomerSuccess(customerIndex, data));
            } else if(error_code != 501) {
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }
}

export const changeStatus = (dairyId, dairyCustomerId, index, newStatus) => {
    return async dispatch => {
        try {
            loader.show(t("text_saving"));
            const { status, data, message, error_code } = await request.post("/dairies/" + dairyId + "/customers/status/" + dairyCustomerId, {
                withAuth: true,
                body: JSON.stringify({
                    status: newStatus
                }),
                version: "v2"
            });
            if(status){
                toast(message);
                dispatch(updateCustomerSuccess(index, data));
            } else if(error_code != 501) {
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }
}

export const deleteCustomer = (dairyId, dairyCustomerId, index) => {
    return async dispatch => {
        try {
            loader.show(t("text_deleting"));
            const { status, message } = await request.delete("/dairies/" + dairyId + "/customers/" + dairyCustomerId, {
                withAuth: true,
                version: "v2"
            });
            if(status){
                toast(message);
                dispatch(deleteCustomerSuccess(index));
                dispatch(fetchCustomers(dairyId));
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }
}