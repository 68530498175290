import React from 'react';
import withLanguage from '../../language/LanguageController';

const NoData = ({t}) => {
    return (
        <div className="no_data">
            <span>{t("text_no_data_available")}</span>
        </div>
    );
};

export default withLanguage(NoData);

