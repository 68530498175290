// @ts-nocheck
import { getSettingMobile, saveSettingMobile } from "src/mobile/offline-store/app-setting/app-setting";

const { appSetting } = window?.literAPI?.offlineDataStore || { appSetting: () => {} };

export const saveSetting = async (data) => {
    if(typeof cordova === "undefined") {
        return await appSetting("saveSetting", data);
    } else {
        return await saveSettingMobile(data);
    }
};

export const getSetting = async (key) => {
    if(typeof cordova === "undefined") {
        return await appSetting("getSetting", key);
    } else {
        return await getSettingMobile(key);
    }
}