import {
    FETCH_CUSTOMER_REWARD,
    FETCH_CUSTOMER_REWARD_HISTORY,
    TOGGLE_REFER_MODAL
} from "../_constants/reward";
import { errorHandler } from "../components/library/response";
import request from "../components/library/request";


export const fetchCustomerRewardSuccess = data => ({
    type: FETCH_CUSTOMER_REWARD,
    data: data
});

export const fetchCustomerReward = async (dispatch) => {
    try {
        const { data, status, message } = await request.get("/customer/reward");
        if (status) {
            dispatch(fetchCustomerRewardSuccess(data));
        } else {
            throw message;
        }
    } catch (error) {
        errorHandler(error);
    }
}


export const fetchCustomerRewardHistorySuccess = data => ({
    type: FETCH_CUSTOMER_REWARD_HISTORY,
    data: data
});

export const fetchcustomerRewardHistory = async (dispatch) => {
    try {
        const { data, status, message } = await request.get("/customer/reward/history");
        if (status) {
            dispatch(fetchCustomerRewardHistorySuccess(data));
        } else {
            throw message;
        }
    } catch (error) {
        errorHandler(error);
    }
}

export const toggleReferralModal = (isOpen = false, manual = false) => ({
    type: TOGGLE_REFER_MODAL,
    data: {
        isOpen: isOpen,
        manual: manual
    }
});




