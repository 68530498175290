import React from "react";
import moment from "moment";
import config from "src/config";
import { currencyFormat } from "../../../../library/util";
import { useLanguage } from "src/components/language/LanguageController";
import { HasPermission } from "../../../../common/AccessPermission";
import { ABILITY_ADVANCE_PAYMENT, PERMISSION_DELETE } from "../../../../../_constants/permissions";

const SingleCustomerAdvancePayment = ({ customerPayment, deletePayment }) => {
    const { t } = useLanguage();
    return (
        <div className="t-card shadow-sm mb-2" key={customerPayment.dairy_customer_id}>
            <div className="card--material__title mb-2 mt-0">
                <div className="d-flex justify-content-between flex-column flex-md-row">
                    <h5 className="m-0 mb-2 mb-md-0">
                        {(customerPayment.customer) ? `${customerPayment.customer.code} - ${customerPayment.customer.name}` : null}
                    </h5>
                    <span>
                        {currencyFormat(customerPayment.total)}
                    </span>
                </div>
            </div>
            <div className={"content card__content card--material__content"}>
                <div className="table-fix-header mt-1">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="pl-0 border-top-0">{t("text_date")}</th>
                                <th className="border-top-0">{t("text_amount")}</th>
                                <th className="text-right border-top-0">{t("text_remark")}</th>
                                <HasPermission ability={ABILITY_ADVANCE_PAYMENT} access={PERMISSION_DELETE}>
                                    <th className="text-right border-top-0"></th>
                                </HasPermission>
                            </tr>
                        </thead>
                        <tbody>
                            {customerPayment.payments.map(payment => (
                                <tr key={payment.id}>
                                    <td className="text-left pl-0">
                                        {moment(payment.date).format(config.display_date_format)}
                                    </td>
                                    <td>
                                        {currencyFormat(payment.amount)}
                                    </td>
                                    <td className="text-right">
                                        {payment.remark}
                                    </td>
                                    <HasPermission ability={ABILITY_ADVANCE_PAYMENT} access={PERMISSION_DELETE}>
                                        <td className="text-right">
                                            <button
                                                className="btn btn-outline-danger btn-sm"
                                                onClick={e => deletePayment(e, payment.id)}>
                                                <i className="fa fa-trash" />
                                            </button>
                                        </td>
                                    </HasPermission>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SingleCustomerAdvancePayment;