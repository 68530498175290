import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import withLanguage from "../../../../language/LanguageController";
import request from "../../../../library/request";
import * as Sentry from "@sentry/react";
import config from "../../../../../config";
import Spinner from "src/components/core/Spinner";

const ProductDetailTable = ({
    t,
    dairyId,
    dairyCustomerId,
    startDate,
    endDate,
    isFeatureSubscribed,
    onProductTotal = (val) => {},
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const getProducts = useCallback(async () => {
        try {
            setIsLoading(true);
            const filterStr = encodeURI(`view=customer&date=${startDate.format(config.db_date_format)}:${endDate.format(config.db_date_format)}&dairy_customer_id=${dairyCustomerId}`);
            const { status, data } = await request.get('/dairies/' + dairyId + `/customer/products?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                if (data && data.result && data.result[0] && data.result[0] && data.result[0].childs) {
                    setProducts(data.result[0].childs);
                } else {
                    setProducts([]);
                }
            } else {
                setProducts([]);
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    }, [dairyId, dairyCustomerId, startDate, endDate]);

    useEffect(() => {
        if(dairyCustomerId && startDate && endDate && isFeatureSubscribed) {
            getProducts();
        }
        if(!isFeatureSubscribed) {
            setIsLoading(false);
        }
    }, [getProducts, dairyCustomerId, startDate, endDate, isFeatureSubscribed]);

    useEffect(() => {
        if(products.length) {
            onProductTotal(products.reduce((total, product) => total + parseFloat(product.total), 0));
        } else {
            onProductTotal("");
        }
    }, [products, onProductTotal]);

    if(isLoading) {
        return <div className="mt-2"><Spinner /></div>
    }
    return (
        <table className="table mb-0">
            <thead>
                <tr>
                    <th>{t("text_date")}</th>
                    <th>{t("text_product")}</th>
                    <th>{t("text_price")}</th>
                    <th>{t("text_qty")}</th>
                    <th>{t("text_total")}</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, key) => {
                    return (
                        <tr key={key}>
                            <td>{moment(product.date).format('DD MMM, YY')}</td>
                            <td>{product.name}</td>
                            <td>{product.price}</td>
                            <td>{product.quantity}</td>
                            <td>{product.total}</td>
                        </tr>
                    );
                })}
                {!products.length ? (
                    <tr>
                        <td colSpan={5} className="text-center">
                            {t("text_no_data_available")}
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    );
};

ProductDetailTable.defaultProps = {
    isFeatureSubscribed: true
};

export default withLanguage(ProductDetailTable);