import React, { forwardRef } from 'react';
import Select from 'react-select';

const ReactSelectBox = forwardRef((props, ref) => <Select
    theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: "#56b3336b",
            primary50: "#56b3336b",
            primary: '#56b333',
        },
    })}
    styles={{
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 2
        }),
    }}
    ref={ref}
    {...props}
/>);

export default ReactSelectBox;