import React from "react";
import { currencyFormat } from "../../../../library/util";
import PropTypes from "prop-types";

const DairyPayableListView = ({ t, filter, records, onCustomerClick }) => {
    return (
        <div className="t-card shadow-sm">
            <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
                <div className="d-account-h1 float-left mt-1">
                    {t("text_payable")}
                </div>
            </div>
            <div className="content card__content card--material__content">
                <div className="table-fix-header">
                    <table className="table mb-0 table-pad-10">
                        <thead>
                            <tr>
                                <th>{t("text_customer")}</th>
                                <th className="text-center nowrap" style={{ width: "105px" }}>{t("text_payable")}</th>
                                <th className="text-center nowrap">{t("text_paid")}</th>
                                <th className="text-center nowrap">{t("text_to_loan")}</th>
                                <th className="text-center nowrap">{t("text_remaining")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map(row => {
                                if (filter && filter.length) {
                                    filter = filter.toLowerCase();
                                    const name = (row.customer_name || "").toLowerCase();
                                    const code = (row.code || "").toLowerCase();
                                    if (!name.includes(filter) && !code.includes(filter)) {
                                        return null;
                                    }
                                }
                                return (<tr key={row.dairy_customer_id} onClick={e => onCustomerClick(e, row.dairy_customer_id)}>
                                    <td>
                                        <div className="width100p weight-500">
                                            {`${row.code}-${row.customer_name}`}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span>
                                            {currencyFormat(row.payable)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-green">
                                            {currencyFormat(row.paid)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-green">
                                            {currencyFormat(row.transferd_to_loan)}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-red">
                                            {currencyFormat(row.payable_remaining)}
                                        </span>
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

DairyPayableListView.propTypes = {
    t: PropTypes.func,
    filter: PropTypes.string,
    records: PropTypes.array,
    onCustomerClick: PropTypes.func
};

export default DairyPayableListView;