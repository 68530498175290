// @ts-nocheck
import React from "react";
import { connect } from "react-redux";
import {
  fetchDairyTodayTotal,
  fetchDairyDailyTotal,
} from "../../../actions/dairy";
import { fetchCustomers } from "../../../actions/dairyCustomer";
import { fetchProducts } from "../../../actions/product";
import { fetchSettings } from "../../../actions/dairySettings";
import {
  fetchPlans,
  fetchFeatures,
  fetchSubscription,
} from "../../../actions/subscriptions";
import withLanguage from "../../language/LanguageController";
import { checkFeatureInSubscription } from "../../common/FeatureFlag/FeatureFlag";
import analytics from "../../library/firebase/analytics";
import DairyMainNavigation from "./DairyMainNavigation";
import { withRouter } from "react-router";
import { DefaultLayout } from "../common/Layout";
import SliderTab from "./home/SliderTab";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCustomerOpen: false,
      isMilkOpen: false,
      isProductOpen: false,
      isBillOpen: false,
      isLoanOpen: false,
      isReportOpen: false,
      isSettingOpen: false,
    };
  }

  componentDidMount() {
    analytics.setScreenName("DairyPage");
    const { plans = [], features = [] } = this.props;
    (async () => {
      await this.props.fetchSettings(this.props.dairy.id);
      await this.props.fetchDairyTodayTotal(this.props.dairy.id);
      await this.props.fetchDairyDailyTotal(this.props.dairy.id);
      if (
        checkFeatureInSubscription("product_management", false) &&
        this.props.products.length <= 0
      ) {
        await this.props.fetchProducts(this.props.dairy.id);
      }
      if (plans.length <= 0) {
        this.props.fetchPlans();
      }
      if (features.length <= 0) {
        this.props.fetchFeatures();
      }
    })();
  }

  onDeviceChange = (deviceDetails) => {
    this.setState({
      ...deviceDetails,
    });
  };

  render() {
    const { customers } = this.props;
    const { t } = this.props;
    const { isBrowser } = this.state;
  
    return (
      <DefaultLayout
        title={this.props.dairy.name}
        onDeviceChange={this.onDeviceChange}
        bottomGutter={!isBrowser}
      >
        
       <SliderTab
          t={t}
          dairyId={this.props.dairy.id}
          language={this.props.language}
          customers={customers}
        />
        <DairyMainNavigation />
      </DefaultLayout>
    );
  }
}

Home.displayName = "DairyHomePage";

const mapStateToProps = (state) => ({
  customers: selectDairyCustomers(state),
  dairy: state.dairy.dairy,
  language: state.defaultReducer.language,
  products: state.productReducer.products || [],
  plans: state.subscription.plans,
  features: state.subscription.features,
  subscription: state.subscription.subscription,
});

const mapDispatchToProps = {
  fetchProducts,
  fetchSettings,
  fetchCustomers,
  fetchDairyTodayTotal,
  fetchDairyDailyTotal,
  fetchPlans,
  fetchFeatures,
  fetchSubscription,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLanguage(Home)));
