// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDairies } from "src/actions/dairy";
import { fetchDairySubscription } from "src/actions/subscriptions";
import { fetchAppConfiguration } from "../../../../actions";
import { fetchCustomers } from "../../../../actions/dairyCustomer";
import { fetchRateLists } from "../../../../actions/dairyRateList";
import { fetchDairyPreference } from "../../../../actions/dairySettings";
import InitialLoader from '../../../common/InitialLoader';
import DairyLoadError from "../../errorPages/DairyLoadError";
import DairySelection from "src/components/DairySelection";
import { getUserGroupId, getUserId } from "src/reducers/dairyUsers";

const DairyRoutesLayout = ({ children }) => {
    const dispatch = useDispatch();
    const { dairy = {}, isFetchingDairy, fetchDairyError, dairySelected, dairies } = useSelector(state => state.dairy);
    const userId = useSelector(getUserId);
    const userGroupId = useSelector(getUserGroupId);
    const { appConfigFetched, appConfigFecthing } = useSelector(state => state.defaultReducer);
    const { subscriptionFetched } = useSelector(state => state.subscription);
    const { isFetching: isLateListFetching, isFetched: isRateListFetched } = useSelector(state => state.dairyRateList);
    const { isFetching: isCustomersFetching, isFetched: isCustomersFetched} = useSelector(state => state.dairy_customers)

    useEffect(() => {
        if(!dairy?.id) {
            if(userId && Number(userGroupId) === 2) {
                fetchDairies(dispatch, userId);
            }
        }
    }, [dispatch, dairy.id, userId, userGroupId]);

    useEffect(() => {
        if(!appConfigFetched) {
            fetchAppConfiguration(dispatch);
        }
    }, [dispatch, appConfigFetched]);

    useEffect(() => {
        if(!subscriptionFetched && dairy.id) {
            fetchDairySubscription(dispatch, dairy.id);
        }
    }, [dispatch, subscriptionFetched, dairy.id]);

    useEffect(() => {
        if(!isRateListFetched && dairy.id) {
            dispatch(fetchRateLists(dairy.id));
        }
    }, [dispatch, isRateListFetched, dairy.id]);

    useEffect(() => {
        if(!isCustomersFetched && dairy.id) {
            dispatch(fetchCustomers(dairy.id));
        }
    }, [dispatch, isCustomersFetched, dairy.id]);

    useEffect(() => {
        if(dairy.id) {
            dispatch(fetchDairyPreference(dairy.id));
        }
    }, [dairy, dispatch]);

    if(!dairy.id && !isFetchingDairy && fetchDairyError) {
        return <DairyLoadError/>;
    }

    if (
        isFetchingDairy ||
        (!appConfigFetched && appConfigFecthing)
    ) {
        return <InitialLoader />
    }

    if (dairy.id && (
            (!isRateListFetched && isLateListFetching) ||
            (!isCustomersFetched && isCustomersFetching)
        )
    ) {
        return <InitialLoader />
    }

    if(dairies.length > 0 && !dairySelected && !fetchDairyError) {
       return <DairySelection/>
    }

    return children;
}

export default DairyRoutesLayout;