import { Divider, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Fragment, default as React } from "react";
import { PrimaryCheckbox } from "../../../core/Checkbox";


const CustomerListItem = ({ customer, onChecked }) => {
    const labelId = `dairy-customer-report-list-label-${customer?.dairy_customer_id}`;

    return (
        <Fragment>
            <ListItem
                role={undefined}
                dense
                button
                onClick={() => onChecked(customer?.dairy_customer_id, !customer?.is_checked)}
            >
                <ListItemIcon>
                    <PrimaryCheckbox
                        edge="start"
                        checked={customer?.is_checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </ListItemIcon>
                <ListItemText
                    id={labelId}
                    primary={`${customer?.code}-${customer?.name}`}
                    secondary={customer?.mobile}
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </Fragment>
    );
};
export default CustomerListItem;