// @ts-nocheck
import React, {Component, createRef} from 'react';
import moment from "moment";
import { loader, toast } from '../../library/util';
import { errorHandler } from '../../library/response';
import MuiDatepicker from '../../common/MuiDatepicker';
import analytics from '../../library/firebase/analytics';
import config from "../../../config";
import { Typography } from '@material-ui/core';
import withLanguage from '../../language/LanguageController';
import { DefaultLayout } from '../common/Layout';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { RadioPrimary } from "../../core/Radio";
import { postGestationData } from '../../../actions/gestation';
import { connect } from 'react-redux';

class GestationForm extends Component {
  constructor(props) {
    super(props);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.formRef = createRef();
    this.gestationDays = {
      'cow': 283,
      'buffalo': 310
    };
    const breedingDate = moment();
    this.state = {
      customerId: props.user.customer_id,
      animal_type: 'cow',
      breeding_date: breedingDate,
      calving_date: this.getCalvingDate(breedingDate, 'cow'),
      maxDate: moment(),
      is_saving: false
    };
  }

  componentDidMount () {
    analytics.setScreenName("GestationForm");
  }

  inputChangeHandler = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    }, () => {
      if (name === "breeding_date" || name === "animal_type") {
        let calvingDate = '';
        if(this.state.breeding_date){
          calvingDate = this.getCalvingDate(this.state.breeding_date, this.state.animal_type);
        }
        this.setState({
          calving_date: calvingDate
        });
      }
    });
  }

  dateChangeHandler = selectedDate => {
    let calvingDate = this.getCalvingDate(selectedDate, this.state.animal_type);
    this.setState({
      breeding_date: selectedDate,
      calving_date: calvingDate
    });
  }

  getCalvingDate(breedingDate, animalType){
    let gestationDays = this.gestationDays[animalType];
    let date = breedingDate.format(config.date_format);
    return moment(date, config.date_format).add(gestationDays, 'days');
  }

  async formSubmitHandler (e) {
    e.preventDefault();
    try {
      analytics.logEvent("addGestation");
      loader.show(this.props.t("text_saving"));
      this.setState({
        is_saving: true
      });
      const { isEdit, gestation, dispatch } = this.props;
      const formData = new FormData(this.formRef.current);
      formData.append('customer_id', this.state.customerId);
      const message = await postGestationData(dispatch, formData, isEdit ? gestation.gestation_id : null);
      message && toast(message);
      this.formRef.current && this.formRef.current.reset();
      const breedingDate = moment();
      this.setState({
        animal_type: 'cow',
        breeding_date: breedingDate,
        calving_date: this.getCalvingDate(breedingDate, 'cow'),
        image: ""
      });
    } catch (error) {
      errorHandler(error)
    } finally {
      loader.hide();
      this.setState({
        is_saving: false
      });
    }
  }

  onImageSelect = event => {
    if(event.target.files.length) {
      this.setState({
        image: URL.createObjectURL(event.target.files[0])
      });
    }
  }

  render() {
    let animalType = this.props.gestation?.animal_type;
    if(!animalType){
      animalType = this.state.animal_type;
    }
    const { image, is_saving, maxDate, breeding_date, calving_date } = this.state;
    const { t, gestation, header } = this.props;
    return (
      <DefaultLayout
        title={this.props.isEdit ? t("text_edit_animal_pregnancy") : t("text_add_animal_pregnancy")}
        header={header}
        bottomGutter={false}
        toolbar={false}
        back>
        <div className="t-card shadow-sm">
          <Typography variant="h5" component="h2" gutterBottom>
            {this.props.isEdit ? t("text_edit_animal_pregnancy") : t("text_add_animal_pregnancy")}
          </Typography>
          <form onSubmit={this.formSubmitHandler} encType={"multipart/form-data"} ref={this.formRef}>
            <div className="form-group">
              <label className="d-block" >{t("text_animal")}: </label>
              <div className="form-check form-check-inline width120">
                <RadioPrimary
                  id="animal_type_cow"
                  name="animal_type" 
                  value="cow"
                  onChange={this.inputChangeHandler}
                  checked={animalType === "cow"}
                />
                <label htmlFor="animal_type_cow" className="form-check-label">
                  {t("text_cow")}
                </label>
              </div>
              <div className="form-check form-check-inline width120">
                <RadioPrimary
                  id="animal_type_buffalo"
                  name="animal_type" 
                  value="buffalo"
                  onChange={this.inputChangeHandler}
                  checked={animalType === "buffalo"}
                />
                <label htmlFor="animal_type_buffalo" className="form-check-label">
                  {t("text_buffalo")}
                </label>
              </div>
            </div>
            <div className="form-group">
              <label>{t("text_name_tag_number")}</label>
              <input type="text"
                className="form-control"
                aria-describedby="Name"
                name="name"
                required
                onChange={this.inputChangeHandler}
                defaultValue={gestation?.name} />
            </div>
            <div className="form-group">
              <label>{t("text_color")}</label>
              <input type="text"
                className="form-control"
                aria-describedby="Color"
                name="color"
                required
                onChange={this.inputChangeHandler}
                defaultValue={gestation?.color} />
            </div>
            <div className="form-group">
              <label>{t("text_breed")}</label>
              <input type="text"
                className="form-control"
                aria-describedby="Breed"
                name="nasl"
                required
                onChange={this.inputChangeHandler}
                defaultValue={gestation?.nasl} />
            </div>
            <div className="form-group date-group">
              <label>{t("text_breeding_date")}</label>
              <MuiDatepicker
                value={breeding_date}
                maxDate={maxDate}
                onChange={this.dateChangeHandler}
                name="breeding_date"
              />
            </div>
            <div className="form-group date-group">
              <label htmlFor="calvingDate">{t("text_calving_date")}</label>
              <input 
                type="text"
                className="form-control"
                id="calvingDate"
                required
                onChange={this.inputChangeHandler}
                value={calving_date.format(config.display_date_format)}
                defaultValue={gestation?.calving_date}
                readOnly/>
            </div>
            <div className="form-group text-left">
                <label>{t("text_photo")}</label>
                <div className="custom-file-upload">
                  <div className="gest-default-img">
                    <img 
                      src={image}
                      className="img-responsive" alt="" />
                  </div>
                  <input 
                    type="file"
                    name="image"
                    onChange={this.onImageSelect}
                  />
                </div>
            </div>
            <div className="form-group">
              <label>{t("text_description")}</label>
              <textarea className="form-control"
                name="description"
                rows={3}
                onChange={this.inputChangeHandler}
                defaultValue={gestation?.description} />
            </div>             
            <div className="form-group mb-0">
              <PrimaryButton type="submit" disabled={is_saving}>
                {(is_saving) ? t("text_saving") : t("text_save")}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}

GestationForm.defaultProps = {
  header: true
};

const mapStateToProps = state => {
  const { userReducer: { user = {} }, gestationStore } = state;
  return {
    user: user ? user : {},
    gestations: gestationStore?.gestations || [],
    isLoading: gestationStore.is_loading,
  }
};

export default connect(mapStateToProps)(withLanguage(GestationForm));
