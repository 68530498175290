import { Button, Typography } from "@material-ui/core";
import React from "react";
import ModalDialog from "../../../../common/ModalDialog";
import { useLanguage } from "../../../../language/LanguageController";
import { currencyFormat } from "src/components/library/util";

const AddonDetailModal = ({ isOpen, onClose, addon }) => {
    const { t, language } = useLanguage();

    const renderDetail = () => {
        return (<div className="form-row">
            <div className="col">
                <div className="form-row mb-3">
                    <div className="col">
                        <Typography>
                            <span className="font-weight-bold">{t("text_name")}:</span> {addon.name_local?.[language] || addon.name_local?.["en"]}
                        </Typography>
                    </div>
                </div>
                <div className="form-row mb-3">
                    <div className="col">
                        <Typography>
                            <span className="mb-0 font-weight-bold">{t("text_description")}:</span> {addon.description?.[language] || addon.description?.["en"]}
                        </Typography>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <Typography className="mb-2 font-weight-bold">{t("text_addon_pricing")}:</Typography>
                        <table className="table table-sm table-striped mb-0">
                            <tr>
                                <td>1 {t("text_year")}</td>
                                <td>{currencyFormat(addon.annually_price)}</td>
                            </tr>
                            <tr>
                                <td>6 {t("text_months")}</td>
                                <td>{currencyFormat(addon.half_yearly_price)}</td>
                            </tr>
                            <tr>
                                <td>3 {t("text_months")}</td>
                                <td>{currencyFormat(addon.quarterly_price)}</td>
                            </tr>
                            <tr>
                                <td>1 {t("text_month")}</td>
                                <td>{currencyFormat(addon.monthly_price)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>)
    };

    return (
        <ModalDialog
            isOpen={isOpen}
            title={`${t("text_addon_detail")}`}
            content={renderDetail()}
            dividers={false}
            dialogActions={
                <>
                    <Button color="primary" onClick={() => onClose(false)}>
                        {t("text_ok")}
                    </Button>
                </>
            }
        />
    );
}

export default AddonDetailModal;