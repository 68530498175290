import React from 'react';
import { loader, toast, copyObject, cleanString, socialSharing } from "../../../library/util";
import { customerLoanTemplate } from '../../../library/smsTemplates';
import moment from 'moment';
import { errorHandler } from '../../../library/response';
import SimpleValidator from '../../../common/SimpleValidator';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import Eventer from 'src/components/library/Eventer';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';

class DairyLoanController extends React.Component {

    constructor(props) {
        super(props);
        this.getLoanTotal = this.getLoanTotal.bind(this);
        this.getLoanDetails = this.getLoanDetails.bind(this);
        this.recoverLoanHandler = this.recoverLoanHandler.bind(this);
        this.state = {
            balance: 0,
            total_sanctioned: 0,
            total_milk_loan: 0,
            total_recovered: 0,
            sanctioned_loan: [],
            milk_loan: [],
            recovered_loan: [],
            customers: [],
            filtered_customers: [],
            is_recover_modal: false,
            loan_recover_btn: false,
            amount: 0,
            isBrowser: false,
            isFeatureSubscribed: checkFeatureInSubscription("loan", false)
        };
    }

    componentDidMount() {
        analytics.setScreenName("DairyLoanDashboard");
        this.getLoanTotal();
        this.getLoanDetails();
        Eventer.on("loanSanctioned", this.fetchLoan);
    }

    componentWillUnmount() {
        Eventer.off("loanSanctioned", this.fetchLoan);
    }

    fetchLoan = () => {
        this.getLoanTotal();
        this.getLoanDetails();
    }

    async getLoanTotal() {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/accounts/loanTotal`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                this.setState({
                    ...data
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    async getLoanDetails() {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/accounts/customersLoanTotal`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                this.setState({
                    customers: data
                }, () => {
                    this.filterHandler();
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    changePage = (e, dairy_customer_id) => {
        e.persist();
        if(this.state.isMobile) {
            this.props.history.push(`/dairy/loan/customer/${dairy_customer_id}`);
        }
    }

    filterHandler = (e = null) => {
        const stateData = {};
        let { filter, customers } = this.state
        if (e) {
            stateData['filter'] = filter = e.target.value
        }
        customers = copyObject(customers);
        let filteredCustomers = customers;
        if (filter && filter.length) {
            filteredCustomers = customers.filter(customer => {
                filter = filter.toLowerCase();
                const name = (customer.customer_name || "").toLowerCase();
                const code = (customer.customer_code || "").toLowerCase();
                return name.includes(filter) || code.includes(filter);
            })
        }

        this.setState({
            filtered_customers: filteredCustomers
        });
    }

    toggleRecoverLoanModal = (e, customer = {}, index = null) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.state.is_recover_modal) {
            this.loanValidator = new SimpleValidator();
        }
        this.setState({
            is_recover_modal: !this.state.is_recover_modal,
            amount: customer.balance || 0,
            dairy_customer_id: customer.dairy_customer_id || null,
            index: index || null
        });
    }

    async recoverLoanHandler(e) {
        e.preventDefault();
        try {
            if (!this.loanValidator.allValid()) {
                this.loanValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("recoverLoan");
            loader.show(this.props.t("text_loading"));
            this.setState({
                loan_recover_btn: true
            });
            const { dairy_customer_id } = this.state;
            const { dairyId } = this.props;
            const { status, message, error_code } = await request.post('/dairies/' + dairyId + `/accounts/loan/${dairy_customer_id}`, {
                withAuth: true,
                body: new FormData(document.getElementById("loan_recover_form")),
                version: "v2"
            });
            if (status) {
                toast(message);
                this.setState({
                    is_recover_modal: false
                }, () => {
                    this.getLoanTotal();
                    this.getLoanDetails();
                });
                Eventer.emit("loanRecovered");
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                loan_recover_btn: false
            });
        }
    }

    shareLoan = (e, customer) => {
        e.stopPropagation();
        e.preventDefault();
        analytics.logEvent("shareLoan");
        let { customer_name, sanctioned, recovered, balance } = customer;
        const date = moment().format('DD MMM, Y');
        customer_name = cleanString(customer_name);
        let message = customerLoanTemplate(customer_name, date, sanctioned, recovered, balance);
        let subject = `${(customer_name)}'s Loan on ${date}`;
        socialSharing(message, subject);
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }
}

export default DairyLoanController;