import React, { useState } from "react";
import { useLanguage } from "../../../../language/LanguageController";
import RateListPresetModal from "./RateListPresetModal";

const RateListPreset = ({ onPresetApply, rateListPresets }) => {
    const { t } = useLanguage();
    const [presetModal, setPresetModal] = useState(false);
    return (
        <>
            <button
                className="btn btn-success"
                onClick={() => setPresetModal(true)}>
                {t("text_predefined_rate_lists")}
            </button>
            <RateListPresetModal 
                isOpen={presetModal}
                onClose={() => setPresetModal(false)}
                onPresetApply={(event) => {
                    setPresetModal(false);
                    onPresetApply(event);
                }}
                rateListPresets={rateListPresets}
            />
        </>
    )
}

export default RateListPreset;