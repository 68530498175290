// @ts-nocheck
/* eslint-disable no-console */
import React, { Component } from "react";
import withLanguage from "../../language/LanguageController";
import PaymentImg from "../../../assets/img/premium_star2.svg";
import { connect } from "react-redux";
import moment from "moment";
import { isSubscriptionActive } from "../../common/FeatureFlag/FeatureFlag";
import { currencyFormat, replaceAll } from "../../library/util";
import analytics from "../../library/firebase/analytics";
import ModalDialog from "src/components/common/ModalDialog/ModalDialog";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

let popupInterval, popupTimeout;
class SalePremiumModal extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    gotoPage = () => {
        window.localStorage.setItem("salePremiumModal", moment().toISOString());
        this.setState({
            isOpen: false
        }, () => {
            analytics.logEvent("SalePremiumModal - GotoPlansPage");
            this.props.history.push("/subscription/plans");
        });
    }

    checkToShowPopup = () => {
        let salePremiumModalDetails = window.localStorage.getItem("salePremiumModal");
        if(salePremiumModalDetails) {
            const lastShowTime = moment(salePremiumModalDetails);
            const diff = moment().diff(lastShowTime, "minutes");
            return diff >= 1 ? true : false;
        }
        return true;
    }
    
    checkPageToShow = () => {
        const { pathname } = this.props.location
        return !pathname.includes("subscription");
    }
    
    showPopupPolling = () => {
        if(!this.state.isOpen 
            && this.checkToShowPopup()
            && !isSubscriptionActive(this.props.subscription)
            && this.checkPageToShow()
            && this.props.isLogin) {
            analytics.logEvent("SalePremiumModal - Displayed");
            this.setState({
                isOpen: true
            });
        }
    }

    componentDidMount () {
        const { user } = this.props;
        if(user.customer_group_id == 2) {
            if(popupInterval) {
                clearInterval(popupInterval);
            }
            if(popupTimeout) {
                clearTimeout(popupTimeout);
            }
            popupTimeout = setTimeout(() => {
                popupInterval = setInterval(this.showPopupPolling, 1000*60);
                this.showPopupPolling();
            }, 1000*60);
        }
    }

    componentWillUnmount () {
        clearInterval(popupInterval);
        clearTimeout(popupTimeout);
    }

    componentDidUpdate () {
        if(isSubscriptionActive(this.props.subscription)) {
            clearInterval(popupInterval);
        }
    }

    hidePopup = () => {
        analytics.logEvent("SalePremiumModal - Close");
        window.localStorage.setItem("salePremiumModal", moment().toISOString());
        this.setState({
            isOpen: false
        });
    }

    getPriceValue = () => {
        const premiumPlan = this.props.plans.find(plan => plan.name == "Premium");
        return premiumPlan
            ? currencyFormat(premiumPlan.daily_price)
            : currencyFormat(4.1);
    }

    render () {
        const { isOpen } = this.state;
        const { t } = this.props;
        return (
            <ModalDialog
                isOpen={isOpen}
                onClose={this.hidePopup}
                dividers={false}
                title={
                    <div className="text-center">
                        <Typography variant="h4" component="span">{t("text_premium")}</Typography>
                        <div className="mt-3 SalePremiumPopup-img text-center">
                            <img src={PaymentImg} alt="" />
                        </div>
                    </div>
                }
                content={
                    <div className="text-center">
                        <p className="fSize-1-4r">
                            <span dangerouslySetInnerHTML={{
                                __html: t("text_subscribe_premium")
                            }} />
                        </p>
                        <h4 className="mb-4">
                            {replaceAll(t("text_subscribe_premium_price_text"), "{price}", this.getPriceValue())}
                        </h4>
                        <PrimaryButton onClick={this.gotoPage} className="mb-3">
                            {t("text_subscribe")}
                        </PrimaryButton>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    plans: state.subscription.plans,
    subscription: state.subscription.subscription,
    user: state.userReducer.user
});

export default connect(mapStateToProps)(
    withRouter(withLanguage(SalePremiumModal))
);