import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordMask from 'react-password-mask';
import OTPInput from "react-otp-input";
import { toast, loader, replaceAll, resendOtp } from "../../../library/util";
import { ActionSheet } from 'react-onsenui';
import withLanguage from '../../../language/LanguageController';
import { loginSuccess } from "../../../../actions/user";
import { fetchAppConfiguration } from "../../../../actions";
import { fetchSubscription } from "../../../../actions/subscriptions";
import { connect } from 'react-redux';
import SimpleValidator from '../../../common/SimpleValidator';
import { errorHandler } from '../../../library/response';
import { OTP_TIMEOUT } from '../../../../_constants';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { watchOTP, stopWatch } from "../../../library/otpReader";
import { trackUser } from '../../../library/errorTracking';
import { isFeatureEnabled } from '../../../common/FeatureFlag/FeatureFlag';
import { withRouter } from 'react-router';
import { DefaultLayout } from '../../common/Layout';
import startPasswordImg from "src/assets/img/star-password.png";

class LoginSetPassword extends Component {

  constructor(props) {
    super(props);
    this.otpTime = OTP_TIMEOUT;
    this.formId = "otp-form";
    this.state = {
      password: "",
      otp: "",
      customer_id: this.props?.location?.state?.customer_id || 0,
      mobile: this.props?.location?.state?.mobile || "",
      changePassword: this.props?.location?.state?.changePassword || false,
      submitBtn: false,
      time_left: this.otpTime,
      autoReadOtpActionSheet: true,
      shouldAutoFocusOtp: false
    };

    this.setPassword = this.setPassword.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.validator = new SimpleValidator();
  }

  componentDidMount () {
    analytics.setScreenName("LoginSetPassword");
    this.timerId = setInterval(this.countdown, 1000);
    if(isFeatureEnabled("auto_otp_read")) {
      this.readOTPFromSms();
    }
  }

  componentWillUnmount () {
    stopWatch();
  }

  readOTPFromSms = async () => {
    const otp = await watchOTP();
    this.setOTP(otp);
  }

  handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    });
  }

  setOTP = otp => {
    this.setState({
      otp: otp,
      autoReadOtpActionSheet: false
    });
  }

  handleClose = (e) => {
    e.preventDefault();
    if(this.props.navigator.pages.length > 1) {
      this.props.navigator.popPage();
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setPassword();
  }

  async setPassword () {
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      analytics.logEvent("setPassword");
      loader.show(this.props.t("text_verifying"));
      this.setState({
        submitBtn: true
      });
      const requestURL = this.state.changePassword ? "/customer/setPassword" : "/auth/setPassword";
      const { status, data, message } = await request.post(requestURL, {
        withAuth: true,
        body: JSON.stringify({
          "customer_id": this.state.customer_id,
          "password": this.state.password,
          "password_confirmation": this.state.password_confirmation,
          "otp": this.state.otp
        })
      });
      if (status) {
        let customerGroupId = this.props?.user?.customer_group_id;
        if(!this.state.changePassword) {
          toast(this.props.t('msg_login_success'));
          analytics.setUserId(data.customer_id);
          analytics.setUserProperty("userName", data.firstname + " " + data.lastname);
          analytics.setUserProperty("userGroup", data.customer_group_id);
          trackUser(data);
          await this.props.loginSuccess(data);
          customerGroupId = data.customer_group_id;
        } else {
          toast(message);
        }
        this.props.history.replace(customerGroupId == 2 ? "/dairy" : "/");
      } else {
        throw message;
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      loader.hide();
      this.setState({
        submitBtn: false
      });
    }
  }

  async resendOtp(e, otpType) {
    e.preventDefault();
    try {
      analytics.logEvent("setPasswordResendOTP");
      loader.show(this.props.t("text_resending_otp"));
      await resendOtp({
        "customer_id": this.state.customer_id,
        "mobile": this.state.mobile,
        "otp_via": otpType
      });
      this.setState({
        time_left: this.otpTime
      }, () => {
        this.timerId = setInterval(this.countdown, 1000);
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({
          submitBtn: false
      });
    }
  }

  countdown = () => {
    if (this.state.time_left == -1) {
      clearTimeout(this.timerId);
    } else {
      let timeLeft = this.state.time_left-1;
      this.setState({
        time_left: timeLeft
      });
    }
  }

  entryOtpManually = () => {
    this.setState({
      autoReadOtpActionSheet: false,
      shouldAutoFocusOtp: true
    }, () => {
      document.querySelector(".otp_input").focus();
    });
  }

  render() {
    const { submitBtn, autoReadOtpActionSheet, mobile = "", changePassword } = this.state;
    const { t, language } = this.props;
    return (<DefaultLayout title={t('text_set_new_password')} sideNav={false} header={changePassword} bottomGutter={false}>
      <div className="login_mobile height-auto pb-4">
        <div className="clearfix">
          <div className="screen_mobile_number clearfix col-md-12">
            <div className={`m_login_otp_pass_header text-center mb-3 ${!changePassword && "mt-5"}`}>
              {!changePassword && <h2 className="enter_code_heading">
                {t('text_set_new_password')}
              </h2>}
              <img className="img-responsive text-center" 
                src={startPasswordImg} alt="password" />
              <p className="mt-2" dangerouslySetInnerHTML={{__html: replaceAll(t("verify_opt_text"), '{number}', (mobile).substr(-4, 4))}} />
            </div>
            <div className="loginmobileform clearfix col-12 col-sm-6 max-width-500 mx-auto">
              <div className="divSignupForm">
                <form method="POST" id={this.formId} 
                  onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-group position-relative mb-3">
                    <label>{t("text_otp")}</label>
                    <div className="inline_input inline_input_otp">
                      <OTPInput
                        value={this.state.otp}
                        onChange={this.setOTP}
                        isInputNum
                        numInputs={6}
                        inputStyle="otp_input"
                        placeholder="000000"
                        shouldAutoFocus={true}
                      />
                    </div>
                    {this.validator.message("otp", this.state.otp, "required|min:6", {className: "mt-2"})}
                    {this.state.time_left < 0 ? (
                      <div className="mt-2 d-flex justify-content-between">
                        <button className="btn btn-link" onClick={e => this.resendOtp(e, "text")}>
                          {t("text_resend_otp")}
                        </button>
                        <button className="btn btn-link" onClick={e => this.resendOtp(e, "voice")}>
                          {t("text_resend_otp_call")}
                        </button>
                      </div>
                    ) : (
                      (language === "en") ? 
                        (<p className="mt-2 resent_in text-right">{t('text_resend_in')} {this.state.time_left} {t('seconds')}</p>)
                        : (<p className="mt-2 resent_in text-right">{this.state.time_left} {t('seconds')} {t('text_resend_in')}</p>)
                    )}
                  </div>
                  <div className="form-group">
                    <label>{t("text_new_password")}</label>
                    <PasswordMask
                      id="password"
                      name="password"
                      placeholder={t("text_enter_new_password")}
                      inputClassName="form-control input-bottom-border"
                      buttonClassName="no-style"
                      value={this.state.password}
                      onChange={this.handleInput.bind(this)}
                      showButtonContent={<i className="fa fa-eye" />}
                      hideButtonContent={<i className="fa fa-eye-slash" />}
                    />
                    {this.validator.message("password", this.state.password, "required|min:6,string|strong_password", {className: "mt-2"})}
                  </div>
                  <div className="form-group">
                    <label>{t("text_confirm")}</label>
                    <PasswordMask
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder={t("text_enter_password_confirmation")}
                      inputClassName="form-control input-bottom-border"
                      buttonClassName="no-style"
                      value={this.state.password_confirmation}
                      onChange={this.handleInput.bind(this)}
                      showButtonContent={<i className="fa fa-eye" />}
                      hideButtonContent={<i className="fa fa-eye-slash" />}
                    />
                    {this.validator.message("password_confirmation", this.state.password_confirmation, [
                      "required",
                      {
                        "in": this.state.password
                      }
                    ], {className: "mt-2"})}
                    <small className="text-grey-dark mt-4 display-inline-block">{t("text_password_help_min")}</small><br/>
                    <small className="text-grey-dark">{t("text_password_help_case_letter")}</small><br/>
                    <small className="text-grey-dark">{t("text_password_help_special_character")}</small>
                  </div>
                  <div className="form-group">
                    <input 
                      className="form-control mt-0" 
                      type="submit"
                      disabled={submitBtn}
                      value={submitBtn ? t('text_verifying') : t('text_set_password')} />
                  </div>
                </form>
              </div>
            </div>            
          </div>
        </div>
        {isFeatureEnabled("auto_otp_read") ? (
          <ActionSheet isOpen={autoReadOtpActionSheet} animation='default'>
              <div className="bg-white px-2 py-3 text-center">
                <div className="mb-3">
                  <CircularProgress color="primary" size={30} className="text-success" />
                </div>
                <p>
                  {t("auto_verify_otp_body")}
                </p>
                <p className="text-uppercase">
                  <b>{t("text_or")}</b>
                </p>
                <PrimaryButton className="btn" onClick={this.entryOtpManually}>
                  {t("text_enter_manually")}
                </PrimaryButton>
              </div>
          </ActionSheet>
        ) : null}
      </div>
    </DefaultLayout>);
  }
}

const mapStateToProps = state => {
  return {
    user: state?.userReducer?.user
  }
}

const mapDispatchToProps = {
  loginSuccess,
  fetchAppConfiguration,
  fetchSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withLanguage(LoginSetPassword))
);