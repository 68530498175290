// @ts-nocheck
/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { toast, loader } from "../components/library/util";
import { updateDairy } from "./dairy";
import { t } from "../components/language/LanguageController";
import { errorHandler } from "../components/library/response";
import request from "../components/library/request";
import {FETCH_DAIRY_PREFERENCE_SUCCESS} from "../_constants";
/*
 * action types
 */

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";


/*
 * action creators
 */

export const updateSettings = (settings) => ({ 
    type: UPDATE_SETTINGS, 
    settings: settings
});

export function postSettings(dairyId, callback){
    return async dispatch => {
        try {
            loader.show(t("text_saving"));
            const { status, data, message, error_code } = await request.post("/dairies/" + dairyId + "/settings", {
                withAuth: true,
                body: new FormData(document.getElementById("dairySettingForm"))
            });
            if(status === true){
                toast(message);
                await dispatch(updateSettings(data.settings))
                await dispatch(updateDairy(data.dairy));
                await dispatch(fetchDairyPreference(dairyId));
                callback();
            } else if(error_code != 501){
                throw message;
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            loader.hide();
        }
    };
}

export function fetchSettings(dairyId, params = {}){
    return async dispatch => {
        try {
            const { status, data } = await request.get("/dairies/" + dairyId + "/settings", {
                params,
                withAuth: true,
                version: "v2"
            });
            if(status){
                dispatch(updateSettings(data));
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }
}


export const fetchDairyPreferenceSuccess = data => ({
    type: FETCH_DAIRY_PREFERENCE_SUCCESS,
    data: data
});

export  function fetchDairyPreference(dairyId){
    return async dispatch => {
        try {
            const { data } = await request.get(`/dairies/${dairyId}/preference`, {
                withAuth: true
            });
            dispatch(fetchDairyPreferenceSuccess(data));
        } catch (error) {
            errorHandler(error);
        }
    }
}
