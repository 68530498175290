// @ts-nocheck
import React from 'react';
import moment from "moment";
import * as Sentry from "@sentry/react";
import config from '../../../../../config';
import { currencyFormat, loader, ucFirst } from '../../../../library/util';
import { Icon } from 'react-onsenui';
import CustomerMilkDetail from '../KisanCustomerMilkDetail';
import { connect } from 'react-redux';
import Slider from "react-slick";
import InnerNav from '../../InnerNav';
import withLanguage from '../../../../language/LanguageController';
import { fetchDairySubscription } from "../../../../../actions/subscriptions";
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import MilkTotal from "../MilkTotal";
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import MuiDatepicker from '../../../../common/MuiDatepicker';
import TodayMilkMobile from '../../milk/CustomerMilkTotal/TodayMilkMobile';
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { DefaultScrollbar } from 'src/components/pages/common/Scrollbar';
import { withRouter } from 'react-router';

class KisanCustomerMilkTotal extends React.Component {

    constructor(props) {
        super(props);
        this.getTodayMilk = this.getTodayMilk.bind(this);
        this.getMilk = this.getMilk.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getAdvance = this.getAdvance.bind(this);
        this.dayTime = moment().format("HH") >= 15 ? 1 : 0;
        this.state = {
            total: {},
            cow_total: {},
            buffalo_total: {},
            today: [],
            products: [],
            advance: [],
            product_total: {
                amount: "0", 
                quantity: "0"
            },
            isBrowser: false,
            ...this.getDefaultFilter()  
        };
        this.settings = {
            dots: true,
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
    }

    getDefaultFilter() {
        const { startDate, endDate } = getBillingStartEndDate(this.props.dairy.billing_cycle, moment());
        return {
            start_date: startDate,
            end_date: endDate,
            cattle: 'cow',
            shift: "0"
        }
    }

    componentDidMount () {
        analytics.setScreenName("KisanCustomerMilkTotal");
        this.getMilk();
        this.getTodayMilk();
        this.getProducts();
        this.getAdvance();
    }

    async getTodayMilk () {
        try {
            const { status, data } = await request.get('/dairies/' + this.props.dairyId + '/milk/' + this.props.dairyCustomerId + '/today', {
                withAuth: true,
                version: "v2"
            })
            if(status) {
                this.setState({
                    today: data
                }); 
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    async getMilk () {
        try {
            loader.show(this.props.t("text_loading"));
            const { start_date, end_date, cattle, shift } = this.state;
            const { dairyId, dairyCustomerId } = this.props;
            let filterStr = '?milk_type=' + cattle;
                filterStr += '&date=' + start_date.format(config.date_format) + ':' + end_date.format(config.date_format);
                filterStr += '&day_time=' + shift;
            const { status, data } = await request.get('/dairies/' + dairyId + '/milk/' + dairyCustomerId + '/total' + filterStr, {
                withAuth: true,
                version: "v2"
            });
            if(status) {
                const { total, cow_total, buffalo_total } = data;
                this.setState({
                    total: total,
                    cow_total: cow_total,
                    buffalo_total: buffalo_total
                }); 
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            loader.hide();
        }
    }

    async getProducts () {
        try {
            if(!checkFeatureInSubscription("product_management", false)) {
                return false;
            }
            const { start_date, end_date } = this.state;
            const { dairyId, dairyCustomerId } = this.props;
            const filterStr = `view=customer&date=${start_date.format(config.date_format)}:${end_date.format(config.date_format)}&dairy_customer_id=${dairyCustomerId}`;
            const { status, data } = await request.get('/dairies/' + dairyId + `/customer/products?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            let products = [], total = { amount: "0", quantity: "0" };
            if(status) {
                if(data && data.result && data.result[0] && data.result[0] && data.result[0].childs) {
                    products = data.result[0].childs;
                    total = data.total;
                }
            }
            this.setState({
                products: products,
                product_total: total
            });
        } catch(error) {
            Sentry.captureException(error);
        }
    }

    async getAdvance () {
        try {
            if(!checkFeatureInSubscription("advance_payment", false)) {
                return false;
            }
            const { start_date, end_date } = this.state;
            const { dairyId, dairyCustomerId } = this.props;
            const filterStr = `start_date=${start_date.format(config.date_format)}&end_date=${end_date.format(config.date_format)}`;
            const { status, data } = await request.get('/dairies/' + dairyId + `/accounts/advancePayment/${dairyCustomerId}?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            let advance = [];
            if(status) {
                advance = data;
            }
            const advanceTotal = advance.reduce((total, record) => {
                return total + parseFloat(record.amount);
            }, 0);
            
            this.setState({
                advance: advance,
                advanceTotal: advanceTotal
            });
        } catch(error) {
            Sentry.captureException(error);
        }
    }

    dateChangeHandler = (name, selectedDate) => {
        this.setState({
            [name]: selectedDate
        }, () => {
            this.getMilk();
            this.getProducts();
            this.getAdvance();
        });
    }

    spreadTodayMilk = () => {
        let todayMorningMilk = [], todayEveningMilk = [];
        for(let record of this.state.today){
            todayMorningMilk.push(
                <tr id={record.id}>
                    <td>{ucFirst(this.props.t("text_" + record.milk_type)) || "-"}</td>
                    <td>{record.liter_0 || "-"}</td>
                    <td>{record.fat_0 || "-"}</td>
                    <td>{record.clr_0 || "-"}</td>
                    <td>{record.rate_0 || "-"}</td>
                    <td>{record.total_0 || "-"}</td>
                </tr>
            );
            todayEveningMilk.push(
                <tr id={record.id}>
                    <td>{ucFirst(this.props.t("text_" + record.milk_type)) || "-"}</td>
                    <td>{record.liter_1 || "-"}</td>
                    <td>{record.fat_1 || "-"}</td>
                    <td>{record.clr_1 || "-"}</td>
                    <td>{record.rate_1 || "-"}</td>
                    <td>{record.total_1 || "-"}</td>
                </tr>
            );
        }
        return { todayMorningMilk, todayEveningMilk };
    }

    gotoMilkDetail = e => {
        e.persist();
        analytics.logEvent("gotoMilkDetail");
        if(checkFeatureInSubscription("customer_daily_milk_summary", false)) {
            const { dairyCustomerId, dairyId } = this.props;
            const { start_date, end_date } = this.state;
            this.props.history.push({
                pathname: `/dairy/${dairyId}/milkDetail/${dairyCustomerId}`,
                state: {
                    startDate: start_date.toString(),
                    endDate: end_date.toString()
                }
            });
        }
    }

    onDeviceChange = deviceTypes => 
        this.setState({...deviceTypes});

    render() {
        let { todayMorningMilk, todayEveningMilk } = this.spreadTodayMilk();
        let { product_total, advanceTotal, cow_total, buffalo_total, start_date, end_date, isBrowser } = this.state;
        const { t, dairyId, dairyCustomerId, customerId, dairyType, header, bottomGutter, toolbar } = this.props;
        return (
            <DefaultLayout
                back={!toolbar}
                bottomGutter={bottomGutter}
                header={header}
                onDeviceChange={this.onDeviceChange}
                title={this.props.dairyName}
                toolbar={toolbar}>
                    <div className="mb-2">
                        <div className="input-group shadow-sm" role="group" aria-label="Date Selection">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                            </div>
                            <MuiDatepicker
                                value={start_date}
                                maxDate={end_date}
                                onChange={selectedDate => this.dateChangeHandler("start_date", selectedDate)}
                                name="start_date"
                                inputId="start_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                            <div className="input-group-prepend input-group-append">
                                <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                            </div>
                            <MuiDatepicker
                                value={end_date}
                                minDate={start_date}
                                maxDate={moment().endOf("month")}
                                onChange={selectedDate => this.dateChangeHandler("end_date", selectedDate)}
                                name="end_date"
                                inputId="end_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                        </div>
                    </div>
                    <div className="t-card shadow-sm mb-2">
                        <div className="card__title card--material__title mb-0 mt-0">
                            {t("text_todays_milk")}
                        </div>
                        <TodayMilkMobile
                            todayMorningMilk={todayMorningMilk}
                            todayEveningMilk={todayEveningMilk}
                            shift={this.dayTime} />
                    </div>
                    <DefaultScrollbar
                        isEnabled={isBrowser}
                        className="dairy-kisan-customer-milk-total-scrollbar">
                        <Slider className={`dairy_slider ${(!this.state.cow_total || !this.state.buffalo_total) ? 'slide-padding-0' : ''}`} {...this.settings}>
                            <div className="t-card mb-5 shadow-sm">
                                <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                                    <div className="title right card__title card--material__title mt-0 mb-0">
                                        {t("text_milk_total")}
                                    </div>
                                    <div className="position-relative d-lg-none">
                                        <button className="btn"
                                            type="button"
                                            title="View"
                                            aria-label="View"
                                            onClick={this.gotoMilkDetail}>
                                            <Icon icon="md-eye" />
                                        </button>
                                    </div>
                                </div>
                                <MilkTotal total={this.state.total}/>
                            </div>
                            
                            {parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk) > 0 ? (
                                <div className="t-card mb-5 shadow-sm">
                                    <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                                        <div className="title right card__title card--material__title mt-0 mb-0">
                                            {t("text_cow_milk_total")}
                                        </div>
                                        <div className="position-relative d-lg-none">
                                            <button className="btn"
                                                type="button"
                                                title="View"
                                                aria-label="View"
                                                onClick={this.gotoMilkDetail}>
                                                <Icon icon="md-eye" />
                                            </button>
                                        </div>
                                    </div>
                                    <MilkTotal total={this.state.cow_total}/>
                                </div>
                            ) : null}
                            {parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk) > 0 ? (
                                <div className="t-card mb-5 shadow-sm">
                                    <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                                        <div className="title right card__title card--material__title mt-0 mb-0">
                                            {t("text_buffalo_milk_total")}
                                        </div>
                                        <div className="position-relative d-lg-none">
                                            <button className="btn"
                                                type="button"
                                                title="View"
                                                aria-label="View"
                                                onClick={this.gotoMilkDetail}>
                                                <Icon icon="md-eye" />
                                            </button>
                                        </div>
                                    </div>
                                    <MilkTotal total={this.state.buffalo_total}/>
                                </div>
                            ) : null}
                        </Slider>
                        {checkFeatureInSubscription("product_management", false) ? (
                            <div className="t-card mb-5 shadow-sm" style={{marginTop: "-35px"}}>
                                <div className="title right card__title card--material__title mt-0">
                                    {t("text_products")}
                                    {this.state.products.length ? (
                                        <div className="float-right">
                                            {currencyFormat(product_total.amount)}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="content card__content card--material__content">
                                    <table className="table mb-2 size-12 table-pad-10-15">
                                        <thead>
                                            <tr>
                                                <th>{t("text_date")}</th>
                                                <th>{t("text_product")}</th>
                                                <th>{t("text_qty")}</th>
                                                <th>{t("text_price")}</th>
                                                <th>{t("text_total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.products.map(product => {
                                                    return (
                                                        <tr key={product.id}>
                                                            <td>{moment(product.date).format(config.display_date_format)}</td>
                                                            <td>{product.name}</td>
                                                            <td>{product.quantity}</td>
                                                            <td>{product.price}</td>
                                                            <td>{product.total}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            {
                                                !this.state.products.length ? (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">
                                                            {t("text_no_data_available")}
                                                        </td>
                                                    </tr>
                                                ) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                        {checkFeatureInSubscription("advance_payment", false) ? (
                            <div className="t-card shadow-sm" style={{marginTop: "-35px"}}>
                                <div className="title right card__title card--material__title mt-0">
                                    {t("text_advance")}
                                    {this.state.advance.length ? (
                                    <div className="float-right">
                                        {currencyFormat(advanceTotal)}
                                    </div>
                                    ) : null}
                                </div>
                                <div className="content card__content card--material__content">
                                    <table className="table mb-2 size-12 table-pad-10-15">
                                        <thead>
                                            <tr>
                                                <th>{t("text_date")}</th>
                                                <th>{t("text_amount")}</th>
                                                <th className="text-right">{t("text_remark")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.advance.map(row => {
                                                    return (
                                                        <tr key={row.id}>
                                                            <td>{moment(row.date).format(config.display_date_format)}</td>
                                                            <td>{row.amount}</td>
                                                            <td className="text-right">{row.remark}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            {
                                                !this.state.advance.length ? (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">
                                                            {t("text_no_data_available")}
                                                        </td>
                                                    </tr>
                                                ) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </DefaultScrollbar>
                    <InnerNav
                        menu="kisan_customer"
                        page="customerMilk"
                        dairyCustomerId={dairyCustomerId}
                        dairyId={dairyId}
                        customerId={customerId}
                        dairyType={dairyType} />
            </DefaultLayout>
        );
    }
}

KisanCustomerMilkTotal.displayName = "KisanCustomerMilkTotal";

KisanCustomerMilkTotal.defaultProps = {
    header: true,
    bottomGutter: true,
    toolbar: true
};

const mapStateToProps = state => {
    return {
        dairy: state.dairy.dairy,
        dairyName: state.dairy.dairy.name,
        dairyId: state.dairy.dairy.dairy_id,
        dairyCustomerId: state.dairy.dairy.dairy_customer_id,
        dairyType: state.dairy.dairy.type
    }
}

const mapDispatchToProp = {
    fetchDairySubscription
};

export default connect(mapStateToProps, mapDispatchToProp)(
    withRouter(withLanguage(KisanCustomerMilkTotal))
);