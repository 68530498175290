// @ts-nocheck
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleReferralModal } from 'src/actions/reward';
import { useLanguage } from 'src/components/language/LanguageController';
import { currencyFormat } from 'src/components/library/util';
import config from 'src/config';
import InvoiceButtons from "./InvoiceButtons";

const Invoice = ({
    user = {},
    plan,
    subscriptionPlans,
    activation_key_id,
    activation_key,
    is_trial,
    order,
    trial_end_date,
    end_date,
    balance,
    isSubActive,
    trial_days,
    days,
    is_reloading,
    showReferralToDealerCustomer,
    toogleAccountActivationModal,
    refreshSubscription,
    onRenew,
    onUpgrade,
}) => {
    const { t, language } = useLanguage();
    const dispatch = useDispatch();
    const addOns = useSelector(({ subscription }) => subscription?.subscription?.add_ons);

    return (
        <div className="subscription_box_bg rounded p-3 text-white mb-2">
            {plan ? (
                <div className="form-row">
                    {activation_key_id ? (
                        <div className="col-6 text-left">
                            <label className="mb-0">
                                {t("text_activation_key")} 
                                <p className="mb-2">#{activation_key ? activation_key.activation_code : "NA"}</p>
                            </label>
                        </div>
                    ) : (
                        <div className="col-6 text-left">
                            <label className="mb-0">
                                {t("text_order_no")}
                                <p className="mb-2">#{order ? order.order_id : "NA"}</p>
                            </label>
                        </div>
                    )}
                    <div className="col-6 text-right">
                        <label className="mb-0">
                            {t("text_valid_till")}
                            <p className="mb-2">{is_trial == 1 ? moment(trial_end_date).format(config.display_date_format) : moment(end_date).format(config.display_date_format)}</p>
                        </label>
                    </div>
                </div>
            ) : null}

            <div className="plan_and_subs_validity_box bg-white text-dark p-3 shadow-sm position-relative rounded">
                <div className="form-row">
                    <table className="table table-sm table-borderless mb-0">
                        <thead>
                            <tr>
                                <th>{t("text_plan")}</th>
                                <th className="text-right">{t("text_price")} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptionPlans?.map(plan => (
                                <tr key={plan.plan_id}>
                                    <td>
                                        <span className="text-green-light">
                                            {plan.name_local[language] ?? plan.name}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-green-light">
                                            {currencyFormat(plan?.pivot?.price)}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {addOns?.length > 0 && <div className="form-row">
                    <table className="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>{t("text_addon")}</th>
                                <th className="text-right">{t("text_price")} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {addOns?.map(addOn => (
                                <tr key={addOn.add_on_id}>
                                    <td>
                                        <span className="text-green-light">
                                            {addOn.name_local[language] ?? addOn.name}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-green-light">
                                            {currencyFormat(addOn?.pivot?.price)}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                {isSubActive ? (<div className="form-row mb-3">
                    <div className="col text-center">
                        <span>{t("text_balance")}: </span>
                        <span className="text-green-light">{currencyFormat(balance)}</span>
                    </div>
                    <div className="col text-center">
                        <span>{t("text_validity")}: </span>
                        {is_trial == 1 ? (
                            !isSubActive ? (
                                <span className="text-red">
                                    {t("text_trial_membership_expired")}
                                </span>
                            ) : (
                                `${trial_days.days_remaining} ${t("text_days")}`
                            )
                        ) : (
                            !isSubActive ? (
                                <span className="text-red">
                                    {t("text_membership_expired")}
                                </span>
                            ) : (
                                <span className="text-green-light">{days.days_remaining} {t("text_days")}</span>
                            )
                        )}
                    </div>
                </div>) : (
                    <div className="form-row mb-3">
                        <div className={"col text-center"}>
                            {Number(is_trial) === 1 ? (
                                !isSubActive ? (
                                    <span className="text-red">
                                        {t("text_trial_membership_expired")}
                                    </span>
                                ) : (
                                    `${trial_days.days_remaining} ${t("text_days")}`
                                )
                            ) : (
                                !isSubActive ? (
                                    <span className="text-red">
                                        {t("text_membership_expired")}
                                    </span>
                                ) : (
                                    `${days.days_remaining} ${t("text_days")}`
                                )
                            )}
                        </div>
                    </div>
                )}

                <div className="form-row">
                    <div className="col text-center mt-2">
                        <InvoiceButtons
                            plan={plan}
                            days={days}
                            is_trial={is_trial}
                            trial_days={trial_days}
                            is_reloading={is_reloading}
                            onRenew={onRenew}
                            onUpgrade={onUpgrade}
                            referral_code={user.referral_code}
                            toogleAccountActivationModal={toogleAccountActivationModal}
                            refreshSubscription={refreshSubscription}
                        />
                    </div>
                </div>
                {(
                    (user.referral_code && showReferralToDealerCustomer) || (!user.referral_code)
                    && user.referral && user.referral.code
                ) ? (
                    <div className="form-row">
                        <div className="col text-center">
                            <button
                                className="btn btn-link"
                                onClick={() => dispatch(toggleReferralModal(true,true))}
                            >
                                {t("text_refer_extra_subscription")}
                            </button>
                        </div>
                    </div>
                ) : null}          
            </div>
        </div>
    );
}

export default Invoice;