
const parseWeightData = (rawData) => {    
  let sanitizedData = sanitizeString(rawData.replace(/\./g, "")); // Replace dots with an empty string
  let weightNumber = Number(sanitizedData);
  const divisor = Math.pow(10, 1);
  weightNumber = weightNumber / divisor;
  return weightNumber.toString();
}

const sanitizeString = (string) => {
  return string
  .replace("+", "")
  .replace("-", "")
  .replace("kg", "")
  .replace("Kg", "")
  .replace("L", "");
}


export default parseWeightData;