import Model from "./Model";

class Orders extends Model{
    constructor(){
        super("orders");
    }

    static get tableName() {
        return "orders"; // define child's tableName here
    }
}

export default Orders;