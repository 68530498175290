import React, { useEffect } from 'react';
import ModalDialog from '../../../../common/ModalDialog';
import analytics from '../../../../library/firebase/analytics';
import AdvancePaymentDetail from "./AdvancePaymentDetail";

const AdvancePaymentModal = ({
    t,
    isOpen,
    onClose,
    dairyId,
    dairyCustomerId,
    startDate,
    endDate
}) => {

    useEffect(() => {
        analytics.logEvent("AdvancePaymentModal");
    }, []);

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_advance_payment")}
            content={
                <div className="row">
                    <div className="col px-0">
                        <AdvancePaymentDetail
                            dairyId={dairyId}
                            dairyCustomerId={dairyCustomerId}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default AdvancePaymentModal;