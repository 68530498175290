export const FETCH_DAIRY_USERS_REQUEST = "FETCH_DAIRY_USERS_REQUEST";
export const FETCH_DAIRY_USERS_SUCCESS = "FETCH_DAIRY_USERS_SUCCESS";
export const FETCH_DAIRY_USERS_FAILURE = "FETCH_DAIRY_USERS_FAILURE";

export const ADD_DAIRY_USERS_REQUEST = "ADD_DAIRY_USERS_REQUEST";
export const ADD_DAIRY_USERS_SUCCESS = "ADD_DAIRY_USERS_SUCCESS";
export const ADD_DAIRY_USERS_FAILURE = "ADD_DAIRY_USERS_FAILURE";

export const UPDATE_DAIRY_USERS_REQUEST = "UPDATE_DAIRY_USERS_REQUEST";
export const UPDATE_DAIRY_USERS_SUCCESS = "UPDATE_DAIRY_USERS_SUCCESS";
export const UPDATE_DAIRY_USERS_FAILURE = "UPDATE_DAIRY_USERS_FAILURE";

export const DELETE_DAIRY_USERS_SUCCESS = "DELETE_DAIRY_USERS_SUCCESS";
export const RESET_DAIRY_USERS = "RESET_DAIRY_USERS";