// @ts-nocheck
import { useEffect, useState } from "react";
import { useLanguage } from "src/components/language/LanguageController";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import React from "react";
import useAutomaticSetting from "../../../../../hooks/useAutomaticSetting";
import LoaderIcon from '../../../../common/icons/LoaderIcon';
import Eventer from "src/components/library/Eventer";
import useDeviceStatus from "src/hooks/hardwareIntegration/useDeviceStatus/useDeviceStatus";
import * as hardwareApis from "src/auto-collection/hardware-apis";
import parseMilkData from "src/auto-collection/parsers/milk-data-parser/milk-data-parser";

const MachinesStatus = () => {
    const { t } = useLanguage();
    const [milkWeightRawData, setMilkWeightRawData] = useState("");
    const [milkAnalysisRawData, setMilkAnalysisRawData] = useState("");
    const { devicesStatus, fetchDevicesStatus } = useDeviceStatus();    
    const {
        setWeightAnalyserPath,
        setMilkAnalyserPath,
        setWeightAnalyserBaudRate,
        setMilkAnalyserBaudRate,
        setWeightStringDecimalFormat,
        setFatConfig,
        setSnfConfig,
        setClrConfig,
        setTempConfig,
        setWaterConfig,
        reloadSerialDevices,
        handleFormSubmit,        
        weightAnalyserPath,
        weightAnalyserBaudRate,
        milkAnalyserPath,
        milkAnalyserBaudRate,
        clrConfig,
        snfConfig,
        fatConfig,
        tempConfig,
        waterConfig,
        weightStringDecimalFormat,
        connectedPorts,
        isPortLoading
    } = useAutomaticSetting();

    const getSettingByDeviceType = (deviceType) => {
        if(deviceType == "weightAnalyser") {
            return  {
                deviceType,
                path: weightAnalyserPath,
                baudRate: weightAnalyserBaudRate
            }
        } else {
            return  {
                deviceType,
                path: milkAnalyserPath,
                baudRate: milkAnalyserBaudRate
            }
        }
    }

    const handleWeightStringDecimalChange = (event) => {
        setWeightStringDecimalFormat(event.target.value);
    };

    const renderDeviceStatus = (isOpen = false) => isOpen ? 
        <span className="text-success">{t("text_connected")}</span>
        : <span className="text-danger">{t("text_disconnected")}</span>

    const renderDeviceError = (device = {}) => {
        return !device?.isOpen && device?.error?.message ? <p><strong>{t("text_error")}:</strong> {device?.error.message}</p>  : "";
    }

    const connectDevice = async (deviceType) => {
        try {
            console.log("deviceType", deviceType)
            await hardwareApis.reconnectDevice(getSettingByDeviceType(deviceType));
            await hardwareApis.registerReadCallback(deviceType);
        } catch (error) {
            console.log("error", error);
        }
        fetchDevicesStatus();
    }

    const disconnectDevice = async (deviceType) => {
        try {
            await hardwareApis.disconnectDevice(deviceType);
        } catch (error) {
            console.log("error", error);
        }
        fetchDevicesStatus();
    }

    const renderDeviceButton = (device, devicePath) => {
        const isDevicePortPlugged = connectedPorts?.some(({ path }) => path === devicePath);
        // disabled={device?.isOpen || !isDevicePortPlugged} 
        // disabled={!device?.isOpen || !isDevicePortPlugged}
        return <>
            <PrimaryButton className="mr-2" onClick={() => connectDevice(device?.deviceType)}>{t("text_connect")}</PrimaryButton>
            <PrimaryButton  onClick={() => disconnectDevice(device?.deviceType)}>{t("text_disconnect")}</PrimaryButton>
        </>
    }

    const onMilkWeightRawData = ({ detail }) => {
        console.log("onMilkweightRawData", detail);
        setMilkWeightRawData(detail);
    }

    const onMilkAnalysisRawData = ({ detail }) => {
        console.log("onMilkAnalysisRawData", detail);
        setMilkAnalysisRawData(detail);
    }

    useEffect(() => {
        Eventer.on("milkWeightRawData", onMilkWeightRawData);
        Eventer.on("milkAnalysisRawData", onMilkAnalysisRawData);

        return () => {
            Eventer.off("milkWeightRawData", onMilkWeightRawData);
            Eventer.off("milkAnalysisRawData", onMilkAnalysisRawData);
        }
    }, []);

    console.log("milkAnalyserPath", milkAnalyserPath);
    console.log("weightAnalyserPath", weightAnalyserPath);

    return (
        
        <form encType="multipart/form-data" method="post" onSubmit={handleFormSubmit}>
            <div className="row border-bottom pb-2 mb-2">
                <div className="col-6">
                    <h4>{t("text_weight_machine")}</h4>
                    <div className="form-row">
                        <div className="col col-lg-6">
                            <div className="form-group">
                                <label htmlFor="weight_machine_path">
                                    {t("text_select_weight_machine_port")}
                                </label>
                                <select
                                    className="form-control"
                                    name="weight_machine_path"
                                    id="weight_machine_path"
                                    value={weightAnalyserPath}
                                    onChange={(event) => setWeightAnalyserPath(event.target.value)}
                                >
                                    <option value="">{t("text_none")}</option>
                                    {connectedPorts?.filter(port => port.path != milkAnalyserPath).map(port => <option value={port.path}>{port.friendlyName}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col col-lg-6">
                            <div className="form-group">
                                <label htmlFor="weight_machine_baud_rate">
                                    {t("text_weight_machine_baud_rate")}
                                </label>
                                <input type="number"
                                    className="form-control"
                                    name="weight_machine_baud_rate"
                                    id="weight_machine_baud_rate"
                                    value={weightAnalyserBaudRate}
                                    onChange={(event) => setWeightAnalyserBaudRate(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col col-lg-6">
                            <div className="form-group">
                                <label htmlFor="weight_machine_string_decimal_point">
                                    {t("text_weight_string_decimal_place")}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="weight_machine_string_decimal_point"
                                    value={weightStringDecimalFormat}
                                    onChange={handleWeightStringDecimalChange}
                                />
                            </div>
                        </div>
                    </div>
                    <p><strong>{t("text_status")}:</strong> {renderDeviceStatus(devicesStatus?.weightAnalyser?.isOpen)}</p>
                    {renderDeviceError(devicesStatus?.weightAnalyser)}
                    <p>
                        <strong>{t("text_data")}:</strong><br/>
                        {milkWeightRawData}
                    </p>
                    {renderDeviceButton(devicesStatus?.weightAnalyser, weightAnalyserPath)}
                </div>
                <div className="col-6">
                    <h4>{t("text_milk_analyser")}</h4>
                    <div className="form-row">
                        <div className="col col-lg-6">
                            <div className="form-group">
                                <label htmlFor="milk_analyser_path">
                                    {t("text_select_milk_analyser_port")}
                                </label>
                                <select
                                    className="form-control mb-1"
                                    name="milk_analyser_path"
                                    id="milk_analyser_path"
                                    value={milkAnalyserPath}
                                    onChange={(event) => setMilkAnalyserPath(event.target.value)}
                                >
                                    <option value="">{t("text_none")}</option>
                                    {connectedPorts?.filter(port => port.path != weightAnalyserPath).map(port => <option key={port.path} value={port.path}>{port.friendlyName}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col col-lg-6">
                            <div className="form-group ">
                                <label htmlFor="milk_analyser_path">
                                    {t("text_milk_analyser_baud_rate")}
                                </label>
                                <input type="number"
                                    className="form-control mb-1"
                                    name="milk_analyser_baud_rate"
                                    id="milk_analyser_baud_rate"
                                    value={milkAnalyserBaudRate}
                                    onChange={(event) => setMilkAnalyserBaudRate(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="text-center">{t("text_milk_analyzer")}</label>
                        <div className="row">
                            <div className="col">
                                <div className="col-12 mb-2 ">
                                    <span className="form-row">
                                        <div className="col col-lg-6">
                                            <div className="form-group " >
                                                <option id="clr_Config" className="form-control mb-1 text-center">CLR</option>
                                                <option id="temp_Config" className="form-control mb-1 text-center">TEMP</option>
                                                <option id="water_Config" className="form-control mb-1 text-center">WATER</option>
                                            </div>
                                        </div>
                                        <div className="col col-lg-6" >
                                            <div className="form-group" >
                                                <input id="clr_Config_input" value={clrConfig} onChange={(e) => setClrConfig(e.target.value)} className="form-control mb-1" type="number" min="0" />
                                                <input id="temp_Config_input" value={tempConfig} onChange={(e) => setTempConfig(e.target.value)} className="form-control mb-1" type="number" min="0" />
                                                <input id="water_Config_input" value={waterConfig} onChange={(e) => setWaterConfig(e.target.value)} className="form-control mb-1" type="number" min="0" />
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col-12 mb-2 ">
                                    <span className="form-row">
                                        <div className="col col-lg-6">
                                            <div className="form-group " >
                                                <option id="fat" className="form-control mb-1 text-center" >FAT</option>
                                                <option id="snf_Config" className="form-control mb-1 text-center">SNF</option>
                                            </div>
                                        </div>
                                        <div className="col col-lg-6" >
                                            <div className="form-group" >
                                                <input id="fat_Config_input" value={fatConfig} onChange={(e) => setFatConfig(e.target.value)} className="form-control mb-1" type="number" min="0" />
                                                <input id="snf_Config_input" value={snfConfig} onChange={(e) => setSnfConfig(e.target.value)} className="form-control mb-1" type="number" min="0" />
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><strong>{t("text_status")}:</strong> {renderDeviceStatus(devicesStatus?.milkAnalyser?.isOpen)}</p>
                    {renderDeviceError(devicesStatus?.milkAnalyser)}
                    <p>
                        <span><strong>{t("text_data")}:</strong></span><br/>
                        {milkAnalysisRawData}
                    </p>
                    {renderDeviceButton(devicesStatus?.milkAnalyser, milkAnalyserPath)}
                </div>
            </div>
            <div className=''>
                <div className="form-group">
                    <PrimaryButton
                        className='mr-1'
                        startIcon={<LoaderIcon spin={isPortLoading} />}
                        onClick={reloadSerialDevices}
                        disabled={isPortLoading}
                    >
                        {t("text_refresh_devices")}
                    </PrimaryButton>
                    <PrimaryButton type="submit" disabled={isPortLoading}>
                        {t("text_save")}
                    </PrimaryButton>
                </div>
            </div>
        </form>
    )
}

export default MachinesStatus;