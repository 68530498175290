import { useMediaQuery } from "react-responsive";
const MOBILE_VIEW_PORT_MAX = "575px";
const TABLET_VIEW_PORT_MIN = "768px";
const DESKTOP_VIEW_PORT_MIN = "992px";

export const useMobile = () => {
    return useMediaQuery({ query: `(max-width: ${MOBILE_VIEW_PORT_MAX})` });
};

export const useTablet = () => {
    const minWidth = useMediaQuery({ query: `(min-width: ${TABLET_VIEW_PORT_MIN})` });
    return minWidth;
};

export const useBrowser = () => {
    return useMediaQuery({ query: `(min-width: ${DESKTOP_VIEW_PORT_MIN})` });
};

export const isAndroid = () => {
    return typeof cordova !== "undefined";
}

export const useAndroid = () => {
    return isAndroid();
};

export const useDetectDevice = () => {
    return {
        isMobile: useMobile(),
        isTablet: useTablet(),
        isBrowser: useBrowser(),
        isAndroid: useAndroid()
    }
}

export const MobileView = ({ children }) => {
    return useMobile() ? children : null;
}

export const TabletView = ({ children }) => {
    return useTablet() ? children : null;
}

export const BrowserView = ({ children }) => {
    return useBrowser() ? children : null;
}