import request from "../components/library/request";
import * as Sentry from "@sentry/react";
import { 
    SET_LANGUAGE,
    TOGGLE_PREMIUM_POPUP,
    TOGGLE_SUBSCRIPTION_UPSALE_POPUP,
    FETCH_APP_CONFIGURATION_SUCCESS,
    FETCH_APP_CONFIGURATION_REQUEST,
    FETCH_APP_CONFIGURATION_FAILURE,
    TOGGLE_LANGUAGE_POPUP,
    FETCH_CUSTOMER_PREFERENCE
} from "../_constants";
import { TOGGLE_SIDE_BAR, TOGGLE_SIDE_BAR_CLOSE } from "src/_constants/action-types";
import { errorHandler } from "../components/library/response";
import { formatePreferences } from "src/utils/preferences";


export const toggleLanguagePopup = toggleState => ({ 
    type: TOGGLE_LANGUAGE_POPUP, 
    data: toggleState
});

export const togglePremiumPopup = (data = false) => ({ 
    type: TOGGLE_PREMIUM_POPUP, 
    data: data
});

export const toggleSideBar = () => ({ 
    type: TOGGLE_SIDE_BAR
});

export const toggleSideBarClose = () => ({ 
    type: TOGGLE_SIDE_BAR_CLOSE
});

export const fetchAppConfigurationRequest = () => ({
    type: FETCH_APP_CONFIGURATION_REQUEST
});

export const fetchAppConfigurationError = () => ({
    type: FETCH_APP_CONFIGURATION_FAILURE
});

export const fetchAppConfigurationSuccess = data => ({
    type: FETCH_APP_CONFIGURATION_SUCCESS, 
    data: data
});


export const changeLanguage = language => {
    return async dispatch => {
        try {
            dispatch({ 
                type: SET_LANGUAGE, 
                data: language 
            });
            updateCustomerPreference("language", {
                "language" : language
            })
        } catch (err) {
            Sentry.captureException(err);
        }
    }
};

export const updateCustomerPreference = async (preference, value) => {
    try {
        await request.post(`/customer/preference`, {
            withAuth: true,
            body: JSON.stringify({
                "preference": preference,
                "value": value
            })
        });
    } catch(error) {
        errorHandler(error);
    }
}

export const fetchAppConfiguration = async (dispatch) => {
    try {
        dispatch(fetchAppConfigurationRequest());
        const { status, data } = await request.get("/app/configuration", {
            withAuth: true
        });
        if(status) {
            dispatch(fetchAppConfigurationSuccess(data));
        }
    } catch (err) {
        dispatch(fetchAppConfigurationError());
    }
};

export const toggleSubscriptionUpsalePopup = (data = false) => ({ 
    type: TOGGLE_SUBSCRIPTION_UPSALE_POPUP, 
    data: data
});

export const fetchCustomerPreferenceSuccess = data => ({
    type: FETCH_CUSTOMER_PREFERENCE,
    data: data
});


export const fetchCustomerPreference = async (dispatch) => {
    try {
        const { status, data, message } = await request.get(`/customer/preference`);
        if (status) {
            dispatch(fetchCustomerPreferenceSuccess(formatePreferences(data)));
        } else {
            throw message
        }
    } catch (error) {
        errorHandler(error);
    }
}