// @ts-nocheck
let databaseConnection;

export const getDb = async () => {
    console.log("databaseConnection 0", databaseConnection);
    if(databaseConnection) {
        return databaseConnection;
    } else {
        databaseConnection = window?.sqlitePlugin?.openDatabase({
            name: 'liter.db',
            location: 'default',
        });
        console.log("databaseConnection", databaseConnection);
        databaseConnection.transaction((tx) => {
            tx.executeSql("CREATE TABLE IF NOT EXISTS `MilkCollection` (`id` INTEGER PRIMARY KEY AUTOINCREMENT, `dairy_id` INTEGER NOT NULL, `cattle` VARCHAR(50) NOT NULL DEFAULT 'cow', `shift` TINYINT NOT NULL DEFAULT 0, `customer_id` INTEGER, `dairy_customer_id` INTEGER NOT NULL, `date` DATE NOT NULL, `liter` DOUBLE PRECISION(15,2), `fat` FLOAT(15,2), `clr` FLOAT(15,2), `snf` FLOAT(15,2), `rate_type` INTEGER, `rate` FLOAT(15,2), `total` DOUBLE PRECISION(15,2), `date_time` DATETIME, `is_deleted` TINYINT DEFAULT 0, `isSyncedUp` TINYINT DEFAULT 0, `syncedUpTime` DATETIME, `createdAt` DATETIME NOT NULL, `updatedAt` DATETIME NOT NULL)");
            tx.executeSql("CREATE TABLE IF NOT EXISTS `AppSetting` (`key` VARCHAR(255) PRIMARY KEY, `value` VARCHAR(255), `type` VARCHAR(255), `createdAt` DATETIME NOT NULL, `updatedAt` DATETIME NOT NULL)");
        }, (error) => {
            console.error('Transaction ERROR: ' + error.message);
        }, () => {
            console.log('Populated database OK');
        })
        return databaseConnection;
    }
}



// import { createConnection } from "typeorm";
// import MilkCollection from "../entities/MilkCollection";

// let dbConn;

// export const initializeConnection = async () => {
//     dbConn = await createConnection({
//         type: "cordova",
//         database: "test",
//         location: "default",
//         entities: [
//             MilkCollection,
//         ],
//         logging: true,
//         synchronize: true
//     });
// }

// export const getConnection = () => dbConn;
