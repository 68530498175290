import React, { Fragment, useCallback, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from "prop-types";
import { useLanguage } from "../../../../language/LanguageController";
import { PrimaryButton } from "../../../../common/buttons/PrimaryButton";

const RateListPresetModal = ({
    isOpen,
    onClose,
    onPresetApply,
    rateListPresets
}) => {
    const { t } = useLanguage();
    const [selectedListIndex, setSelectedListIndex] = React.useState();
    const [filter, setFilter] = useState("");
    const filterRateList = useCallback((rateListPreset, index) => {
        if(filter) {
            const filterStr = filter.toLowerCase();
            const name = (rateListPreset.name).toLowerCase();
            return name.includes(filterStr) || (index + 1) == filterStr;
        }
        return true;
    }, [filter]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="ratelist-preset-dialog-title"
            scroll="paper">
            <MuiDialogTitle>
                <Typography variant="h6" id="ratelist-preset-dialog-title" component={"span"}>
                    {t("text_predefined_rate_lists")}
                </Typography>
                <IconButton aria-label="close" className="btn-modal-close" onClick={onClose}>
                    <CloseIcon />   
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
                <div>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <input
                                    type="search"
                                    className="form-control rounded"
                                    placeholder={t("text_search_ratelist")}
                                    aria-label={t("text_search_ratelist")}
                                    value={filter}
                                    onChange={({ target }) => setFilter(target.value)}
                                />
                            </ListItemText>
                        </ListItem>
                    </List>
                    <List component="nav" aria-label="Rate List Presets List">
                        {
                            rateListPresets.map((rateListPreset, index) => {
                                if(filterRateList(rateListPreset, index)) {
                                    return (
                                        <Fragment key={rateListPreset.rate_list_id}>
                                            <ListItem
                                                button
                                                selected={selectedListIndex === index}
                                                onClick={() => setSelectedListIndex(index)}
                                            >
                                                <ListItemAvatar>
                                                    <span>{index + 1}</span>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={rateListPreset.name}
                                                    secondary={rateListPreset.description}
                                                />
                                            </ListItem>
                                            {(rateListPresets.length - 1 !== index) && <Divider />}
                                        </Fragment>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                    </List>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    {t("text_cancel")}
                </Button>
                <PrimaryButton
                    variant="contained"
                    onClick={() => onPresetApply(rateListPresets[selectedListIndex])}
                    className="btn btn-success">
                    {t("text_apply")}
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

RateListPresetModal.propTypes = {
    t: PropTypes.func,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onChecked: PropTypes.func,
    allChecked: PropTypes.bool,
    onAllChecked: PropTypes.func,
    customers: PropTypes.array
};

export default RateListPresetModal;