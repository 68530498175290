import React from 'react';
import { useSelector } from 'react-redux';
import AddDairy from './AddDairy';
import Home from './Home';
import PageBottomHelpContact from '../../common/PageBottomHelpContact';
import { getHasDairy } from 'src/reducers/dairy';

const Dairy = () => {
    const hasDairy = useSelector(getHasDairy);
    return (
        <>
            {hasDairy ? <Home /> : <AddDairy/>}
            <PageBottomHelpContact mainPage />
        </>
    );
}

export default Dairy;