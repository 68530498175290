// @ts-nocheck
import React, { useCallback, useEffect, useState} from "react";
import withLanguage, { useLanguage } from "../../language/LanguageController";
import ModalDialog from "../../common/ModalDialog";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import RateListConfirmationCell from "./RateListConfirmationRateCell";

const ImportRateListConfirmation = ({
    isOpen,
    onClose,
    ratesData,
    onConfirm,
    rateBy,
    columns
}) => {
    const { t } = useLanguage();
    const [rates, setRates] = useState(ratesData);
    const handleChange = useCallback((e, fat, CLR_SNF) => {
        const newRates = { ...rates };
        newRates[fat][CLR_SNF] = e.target.value;
        setRates(newRates);
    }, [rates]);
    useEffect(() => {
        setRates(ratesData);
    }, [ratesData]);

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={'lg'}
            title={t("text_confirmation")}
            content={
                <div className="row justify-content-center">
                    <div className="table_container table-responsive bg-light shadow-sm" >
                        <table className="table table-striped table-borderless mb-0" id="pdf-view">
                            <thead>
                                <tr>
                                    <th>FAT/{rateBy}</th>
                                    {columns?.sort().map(column => <th key={column}>{column}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(rates)?.map(fat => {
                                    const fatData = rates[fat];
                                    let fatSnfClr = Object.keys(fatData).sort();
                                    return (
                                        <tr key={fat}>
                                            <td>{fat}</td>
                                            {fatSnfClr.map(CLR_SNF =>
                                                <RateListConfirmationCell key={fat + CLR_SNF} fat={fat} CLR_SNF={CLR_SNF} fatData={fatData} onChange={handleChange} />
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            dialogActions={
                <>
                    <PrimaryButton
                        variant="contained"
                        size="small"
                        onClick={() => onConfirm(rates)}
                    >
                        {t("text_done")}
                    </PrimaryButton>
                    <PrimaryButton
                        variant="contained"
                        size="small"
                        onClick={onClose}
                    >
                        {t("text_cancel")}
                    </PrimaryButton>
                </>
            }
            
/>
    );
};

export default withLanguage(ImportRateListConfirmation);