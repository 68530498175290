import { Switch } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useLanguage } from "../../../../language/LanguageController";
const Bonus = ({ isBonus, bonusAmount }) => {
    const { t } = useLanguage();
    const [isBonusEnabled, setIsBonusEnabled] = useState(isBonus);

    return (
        <>
            <div className="form-row">
                <div className="col-7">
                    <label>{t("text_bonus")}</label>
                </div>
                <div className="col-5 text-right">
                    <Switch
                        color="default"
                        checked={isBonusEnabled == "1"}
                        onChange={e => setIsBonusEnabled(e.target.checked ? 1: 0)}
                        inputProps={{ 'aria-label': 'Switch right to enable and left to disable the Bonus' }}
                    />
                    <input type="hidden" name="is_bonus" value={isBonusEnabled} readOnly/>
                </div>
            </div>
            {isBonusEnabled == 1 && <div className="form-group ">
                <label>{t("text_bonus_amount")}</label>
                <div className="input-group">
                    <input 
                        type="number"
                        className="form-control"
                        name="bonus_amount"
                        step="0.01"
                        defaultValue={bonusAmount || 0}
                    />
                    <span className="input-group-append">
                        <span className="input-group-text">{t("text_paise")}</span>
                    </span>
                </div>
            </div>}
        </>
    )
};

export default Bonus;