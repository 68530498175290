import {
    ADD_PRODUCT,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    FETCH_PRODUCTS_BEGIN,
    RESET_DAIRY_PRODUCTS
} from "../actions/product";
import _ from "lodash";

const initialState = {
    products: [],
    is_loading: true
};

function productReducer(state = initialState, action) {
    switch (action.type) {
    case ADD_PRODUCT:
        return {
            ...state,
            products: [action.data, ...state.products],
        };
    case UPDATE_PRODUCT:
        let products = [...state.products];
        const index = products.findIndex(product => product.id == action.productId);
        products[index] = action.data;
        return {
            ...state,
            products: products
        };
    case DELETE_PRODUCT:
        return {
            ...state,
            products: [...state.products.slice(0, action.index), ...state.products.slice(action.index + 1)]
        };
    case FETCH_PRODUCTS_BEGIN:
        return {
            ...state,
            is_loading: true
        };
    case FETCH_PRODUCTS_SUCCESS: 
        return {
            ...state,
            products: action.data,
            is_loading: false
        };
    case FETCH_PRODUCTS_FAILURE:
        return {
            ...state,
            is_loading: false
        };
    case RESET_DAIRY_PRODUCTS: {
        return {
            ...state,
            products: [],
            is_loading: false
        };
    }
    default:
        return state;
    }
}

export const selectDairyProducts = (state) => _.cloneDeep(state.productReducer.products || []);

export default productReducer;
