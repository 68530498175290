// @ts-nocheck
import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import "../subscription.css";
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";
import Plan from './Plan';
import { fetchPlans, fetchFeatures } from "../../../../actions/subscriptions";
import { connect } from 'react-redux';
import PlanFeaturesTable from "./PlanFeaturesTable";
import withLanguage from '../../../language/LanguageController';
import Swal from 'sweetalert2';
import analytics from '../../../library/firebase/analytics';
import { withRouter } from 'react-router';
import { DefaultLayout } from '../../common/Layout';
import { compose } from 'redux';
import Addons from '../Addons';
import DownScrollArrow from '../../common/DownScrollArrow';

const styles = theme => {
    return {
        root: {
          flexGrow: 1,
        },
        featureList: {
            maxHeight: "calc(100vh - 500px)",
            overflow: "auto",
            padding: "0",
        },
        featureListItem: {
            paddingTop: "0",
            paddingBottom: "0"
        },
        policyText: {
            fontSize: "0.60rem",
            color: "rgb(0 0 0 / 69%)"
        },
        plansContainer: {
            margin: "unset",
            width: "100%"
        },
        bottomSeperator: {
            paddingBottom: "112px"
        }
    }
};

class Plans extends Component {
    spacing = 2;

    constructor (props) {
        super(props);
        this.plansContainer = React.createRef();
        this.addonsRef = React.createRef();
        const { upgrade = false } = this.props.history?.location?.state || {};
        const { plans, subscription = {} } = this.props;
        const filterPlans = plans.filter(plan => plan.show_on_ui == 1);
        let subscribedPlanIndex = -1;
        if(subscription) {
            subscribedPlanIndex = filterPlans.findIndex(plan => plan.plan_id == subscription.plan_id);
        }
        let selectedPlan = filterPlans[subscribedPlanIndex + 1] || {};
        if(props.feature) {
            const featureMatch = props.features.find(feature => feature.feature_key === props.feature);
            if(featureMatch) {
                const featureId = featureMatch.feature_id;
                const featurePlan = filterPlans.find(plan => 
                    plan.plan_features.some(plan_feature => plan_feature.feature_id == featureId)
                );
                selectedPlan = featurePlan;
            }
        }
        this.state = {
            selectedPlan: selectedPlan,
            subscribedPlanId: subscription ? subscription.plan_id : null,
            selectedAddons: []
        }
    }

    componentDidMount () {
        analytics.setScreenName("Plans");
        const { selectedPlan } = this.state;
        const planBox = document.querySelector(`#plan_box_${selectedPlan.plan_id}`);
        if(planBox) {
            const planBoxRect = planBox.getBoundingClientRect();
            this.plansContainer.current.scrollLeft = planBoxRect.x;
        }
        this.props.fetchPlans();
        this.props.fetchFeatures();
    }

    selectPlan = (e, plan) => {
        const { selectedAddons } = this.state;
        this.setState({
            selectedPlan: plan,
            selectedAddons: Number(plan.plan_id) === 4 ? selectedAddons : []
        });
    }

    changePage = () => {
        const { selectedPlan, isBrowser } = this.state;
        if(selectedPlan.plan_id) {
            const { subscribedOrder } = this.props;
            const minDuration = subscribedOrder ? subscribedOrder.duration : null;
            const pathname = isBrowser ? "/subscription/checkout" : "/subscription/billingCycle"
            this.props.history.push({
                pathname: pathname,
                state: {
                    plan: this.state.selectedPlan,
                    minDuration: minDuration,
                    selectedAddons: this.state.selectedAddons
                }
            });
        } else {
            Swal.fire({
                text: this.props.t("error_plan_not_selected"),
                icon: "warning",
                confirmButtonColor: "#4caf50",
            });
        }
    }

    deviceChange = deviceDetails => {
        this.setState({
            ...deviceDetails
        });
    }

    onAddonSelected = (addons) => {
        this.setState({
            selectedAddons: addons
        });
    }

    render() {
        const { t, classes, plans } = this.props;
        const { selectedPlan, subscribedPlanId } = this.state;

        return (
            <DefaultLayout title={t("text_select_plan")} onDeviceChange={this.deviceChange} sideNav={false}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box ml={3} mr={3} mb={2}>
                            <Typography variant="body2" align="center" className={classes.colorWhite}>
                                {t("text_plan_compare_text")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Box ml={1} mr={1} mb={1}>
                            <PlanFeaturesTable />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container
                    spacing={2}
                    justifyContent="center"
                    className={classes.plansContainer + " mb-2"}
                    ref={this.plansContainer}>
                    {
                        plans.filter(
                            plan => plan.show_on_ui == 1 
                                && plan.plan_id != subscribedPlanId
                        ).map(plan => 
                            <Plan
                                key={plan.plan_id}
                                plan={plan}
                                readOnly={false}
                                isSelected={selectedPlan.plan_id == plan.plan_id || false}
                                onSelect={this.selectPlan}/>
                        )
                    }
                </Grid>
                {(Number(selectedPlan.plan_id) === 4) && <>
                    <DownScrollArrow scrollTarget={this.addonsRef.current} />
                    <Addons ref={this.addonsRef} onAddonSelected={this.onAddonSelected}/>
                </>}
                <div className={classes.bottomSeperator}></div>
                <div className="checkout-bottom-button-box">
                    <Grid container>
                        <Grid item xs={12} >
                            <Box textAlign="center">
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    onClick={this.changePage}>
                                    {t("text_continue")}
                                </PrimaryButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} >
                            <Box mt={1} ml={2} mr={2} textAlign="center">
                                <Typography variant="caption" display="block" className={classes.policyText}>
                                    <span dangerouslySetInnerHTML={{__html: t("text_subscription_policy")}}/>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </DefaultLayout>
        );
    }
}

Plans.displayName = "Plans";

const mapStateToProps = state => ({
    plans: state.subscription.plans,
    subscription: state.subscription.subscription,
    features: state.subscription.features
});

const mapDispatchToProps = {
    fetchPlans,
    fetchFeatures
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles, { withTheme: true }),
    withRouter,
    withLanguage
)(Plans);