import React, { Fragment } from 'react';
import {
    Radio,
    ListItem,
    Checkbox,
    Button
} from 'react-onsenui';
import DairyReportController from './DairyReportController';
import { connect } from 'react-redux';
import withLanguage from '../../../language/LanguageController';
import ReportCustomerModal from "./ReportCustomerModal";
import BillDatePicker from '../account/BillDatePicker';
import { DefaultLayout } from '../../common/Layout';
import { BrowserView } from 'src/components/library/detect-device';
import { HasPermission } from '../../../common/AccessPermission';
import { ABILITY_DAIRY_REPORT, PERMISSION_CUSTOMER_REPORT, PERMISSION_DAIRY_REPORT } from "../../../../_constants/permissions";
import hasAccess from '../../../common/AccessPermission/hasAccess';
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class DairyReport extends DairyReportController {

    getFixedComponents = () => {
        return (
            <Fragment>
                <ReportCustomerModal
                    isOpen={this.state.customer_modal}
                    onClose={this.toggleCustomerModal}
                    onChecked={this.onCustomerSelected}
                    onAllChecked={this.onAllChecked}
                    allChecked={this.state.all_checked}
                    customers={this.state.customers}
                    btnStatus={this.state.customer_modal_btn}
                />
            </Fragment>
        );
    }

    render() {
        const {
            is_loading,
            is_generating,
            reportFilename,
            reportHtml,
            isMobile,
        } = this.state;
        const { t, billingCycle, hasAccessCustomerReport, hasAccessDairyReport } = this.props;

        return (
            <DefaultLayout
                back
                bottomGutter={false}
                title={t("text_generate_dairy_reports")}
                toolbar={false}
                onDeviceChange={this.onDeviceChange}
            >
                <div className="form-row">
                    <div className="col-12 col-lg-4">
                        <div className="t-card shadow-sm">
                            <form action="#" id="dairy_report_form" method="post">
                                <div className="title right card__title card--material__title mt-0">
                                    <BillDatePicker
                                        billingCycle={billingCycle}
                                        onChange={this.onDateChange}
                                    />
                                </div>
                                <div className="content card__content card--material__content clearfix">
                                    <div className="row">
                                        <div className="form-group">
                                            <label className="col-md-12">{t("text_report_of")}</label>
                                            <div className="col-md-12">
                                                <HasPermission ability={ABILITY_DAIRY_REPORT} access={PERMISSION_DAIRY_REPORT}>
                                                    <div className="form-check form-check-inline width130">
                                                        <Radio
                                                            modifier="material form-check-input"
                                                            name={"report_type"} 
                                                            inputId={"report_type_dairy"}
                                                            value="dairy"
                                                            defaultChecked />
                                                        <label 
                                                            htmlFor={"report_type_dairy"}
                                                            className="form-check-label">
                                                            {t("text_dairy")} &nbsp;
                                                        </label>
                                                    </div>
                                                </HasPermission>
                                                <HasPermission ability={ABILITY_DAIRY_REPORT} access={PERMISSION_CUSTOMER_REPORT}>
                                                    <div className="form-check form-check-inline">
                                                        <Radio
                                                            modifier="form-check-input"
                                                            name={"report_type"} 
                                                            inputId={"report_type_customer"}
                                                            value="customer"
                                                            onClick={this.toggleCustomerModal}
                                                            />
                                                        <label
                                                            htmlFor={"report_type_customer"}
                                                            className="form-check-label">
                                                            {t("text_customer")} &nbsp;
                                                        </label>
                                                    </div>
                                                </HasPermission>
                                            </div>
                                        </div>
                                    </div>
                                    <ListItem>
                                        <div className="left">
                                            <Checkbox defaultChecked={true} name="report[]" value="bill"/>
                                        </div>
                                        <div className="center">
                                            {t("text_bill")}
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="left">
                                            <Checkbox defaultChecked={true} name="report[]" value="milk_detail"/>
                                        </div>
                                        <div className="center">
                                            {t("text_milk_detail")}
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="left">
                                            <Checkbox defaultChecked={true} name="report[]" value="payment_statement"/>
                                        </div>
                                        <div className="center">
                                            {t("text_payment_statement")}
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="left">
                                            <Checkbox defaultChecked={true} name="report[]" value="loan_statement"/>
                                        </div>
                                        <div className="center">
                                            {t("text_loan_statement")}
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="left">
                                            <Checkbox defaultChecked={true} name="report[]" value="advance_payment"/>
                                        </div>
                                        <div className="center">
                                            {t("text_advance_payment")}
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="left">
                                            <Checkbox defaultChecked={true} name="report[]" value="product_detail"/>
                                        </div>
                                        <div className="center">
                                            {t("text_product_detail")}
                                        </div>
                                    </ListItem>
                                    <div className="form-group mt-3 mb-0 text-center">
                                        <Button
                                            className="mr-2"
                                            onClick={() => this.generateReport(this.state.isMobile && !this.state.isAndroid)}
                                            disabled={is_generating || is_loading}
                                        >
                                            {(isMobile) ? (
                                                is_generating ? t("text_downloading") : t("text_download_report")
                                            ) : (
                                                is_generating ? t("text_loading") : t("text_preview_report")
                                            )}
                                        </Button>
                                        <BrowserView>
                                            <Button
                                                onClick={this.downloadReport}
                                                disabled={is_generating || is_loading}
                                            >
                                                {is_loading ? t("text_downloading") : t("text_download_report")}
                                            </Button>
                                        </BrowserView>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <BrowserView>
                        <div className="col-sm-8">
                            <iframe
                                ref={this.reportPreviewRef}
                                className="border-0 bg-white w-100 vh-100 shadow-sm"
                                title={reportFilename}
                                srcDoc={reportHtml} />
                        </div>
                    </BrowserView>
                </div>
                {this.getFixedComponents()}
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: selectDairyCustomers(state),
        dairyId: state.dairy.dairy.id,
        billingCycle: state.dairy.dairy.billing_cycle,
        hasAccessDairyReport: hasAccess(state?.dairy?.dairy?.is_owner, state?.dairy?.dairy?.permissions)(ABILITY_DAIRY_REPORT, PERMISSION_DAIRY_REPORT),
        hasAccessCustomerReport: hasAccess(state?.dairy?.dairy?.is_owner, state?.dairy?.dairy?.permissions)(ABILITY_DAIRY_REPORT, PERMISSION_CUSTOMER_REPORT)
    }
}

export default connect(mapStateToProps)(withLanguage(DairyReport));