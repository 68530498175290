import React, { useCallback, useMemo, useState } from 'react';
import { useLanguage } from '../../../../language/LanguageController';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles, { useStylesAvatar } from './style';
import { Link } from 'react-router-dom';
import useConfirm from '../../../../confirmDialog/useConfirm';
import { deleteDairyUser } from '../../../../../actions/dairyUsers';
import { useDispatch, useSelector } from 'react-redux';
import { HasPermission } from '../../../../common/AccessPermission';
import { ABILITY_DAIRY_USERS, PERMISSION_DELETE, PERMISSION_UPDATE } from '../../../../../_constants/permissions';
import { errorHandler } from '../../../../library/response';
import { isOwner } from '../../../../services/dairyUsers';
import { getNameCharacterForAvtar } from '../../../../library/util';
import { generateColorHsl } from '../../../../library/color-util';

function User ({ name, mobile, customer_dairy_id, is_owner }) {
    const { t } = useLanguage();
    const [isDeleting, setDeleting] = useState(false);
    const classes = useStyles();
    
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const { id: dairyId } = useSelector(state => state.dairy.dairy);

    const handleDelete = useCallback(async () => {
        try {
            await confirm({
                title: t("text_confirm"),
                description: t("text_delete_confirm"),
                confirmationText: t("text_ok"),
                cancellationText: t("text_cancel"),
            });
            setDeleting(true);
            await deleteDairyUser(dispatch, dairyId, customer_dairy_id);
            setDeleting(false);
        } catch (error) {
            error && errorHandler(error);
        }
    }, [confirm, dispatch, dairyId, customer_dairy_id, t]);

    const userIsOwner = isOwner({ is_owner });
    
    const avatarColor = useMemo(() => generateColorHsl(name), [name]);
    const avatarClasses = useStylesAvatar({ backgroundColor: avatarColor });
    
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar className={avatarClasses.root}>
                    {/* <ImageIcon /> */}
                    {getNameCharacterForAvtar(name)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={
                <span>
                    {name}
                    {userIsOwner && ` (${t("text_owner")})`}
                </span>
            } secondary={mobile} />
            {!userIsOwner && <ListItemSecondaryAction>
                <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_UPDATE}>
                    <IconButton edge="end" aria-label="Edit">
                        <BorderColorIcon className={classes.editIcon}/>
                        <Link to={`/dairy/setting/users/${customer_dairy_id}`} className="stretched-link" />
                    </IconButton>
                </HasPermission>
                <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_DELETE}>
                    <IconButton
                        edge="end"
                        aria-label="Delete"
                        color="secondary"
                        onClick={handleDelete}
                        disabled={isDeleting}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </HasPermission>
            </ListItemSecondaryAction>}
        </ListItem>
    );
}
  
export default User;