import React from "react";
import withLanguage from "../../language/LanguageController";
import BuffaloBlack from "./../../../assets/icons/buffalo.svg";

const BuffaloBlackIcon = ({ t, dispatch, ...restProps  }) => {
    return <img src={BuffaloBlack} alt={t("text_buffalo")} {...restProps} />;
};

BuffaloBlackIcon.defaultProps = {
    width: "25px"
};

export default withLanguage(BuffaloBlackIcon);