import React, { useEffect } from 'react';
import IncrementDecrementSlot from './IncrementDecrementSlot';
import { Icon, Switch } from 'react-onsenui';
import { useState } from 'react';
import { copyObject, generateRandom } from '../../../../library/util';
import { DEFAULT_STD_SNF, DEFAULT_STD_CLR, SNF_LOWER_LIMIT, SNF_UPPER_LIMIT, CLR_LOWER_LIMIT, CLR_UPPER_LIMIT } from '../../../../../_constants/rateList';
import ErrorBoundary from '../../../../common/ErrorBoundary';
import DairyFormula from '../../RateList_new/DairyFormula';
import { RadioPrimary } from '../../../../core/Radio';

const IncrementDecrementSection = ({
    t,
    std_snf,
    std_clr,
    increment_decrement_by,
    increments,
    decrements,
    deduction_on,
    increment_on,
    rateFormula,
    validator,
    incDecDeductionType,
    incDecIncrementType,
    fatFrom,
    factor = 0.2,
    constant = 0.7,
    inc_dec_deduction_start_by = "clr",
    inc_dec_deduction_start_from = "",
    inc_dec_increment_start_by = "clr",
    inc_dec_increment_start_from = ""
}) => {

    const getSlot = () => {
        return {
            id: generateRandom(2),
            on: "calculated_rate",
            by: "compound",
            value: (incDecBy == "clr") ? DEFAULT_STD_CLR : DEFAULT_STD_SNF,
            rate: "",
            type: "fixed"
        }
    }

    const [stdSnf, setStdSnf] = useState(std_snf ? std_snf : DEFAULT_STD_SNF);
    const [stdCLR, setStdCLR] = useState(std_clr ? std_clr : DEFAULT_STD_CLR);
    const [incDecBy, setIncDecBy] = useState(increment_decrement_by ? increment_decrement_by : "clr");
    const [incrementSlots, setIncrementSlot] = useState(increments ? increments : [getSlot()]);
    const [decrementSlots, setDecrementSlot] = useState(decrements ? decrements : [getSlot()]);

    const addIncrementSlot = () => {
        const slots = copyObject(incrementSlots);
        const slot = getSlot();
        if(slots.length > 0) {
            const lastSlot = slots.slice(-1)[0];
            slot.value = + lastSlot.value + 1;
            slot.value = slot.value.toFixed(1);
        }
        slots.push(slot);
        setIncrementSlot(slots);
    }

    const deleteIncrementSlot = (e, index) => {
        const slots = copyObject(incrementSlots);
        setIncrementSlot([ ...slots.slice(0, index), ...slots.slice(parseInt(index) + 1) ]);
    }
    
    const addDecrementSlot = () => {
        const slots = copyObject(decrementSlots);
        const slot = getSlot();
        if(slots.length > 0) {
            const lastSlot = slots.slice(-1)[0];
            slot.value = +lastSlot.value - 1;
            slot.value = slot.value.toFixed(1);
        }
        slots.push(slot);
        setDecrementSlot(slots);
    }

    const deleteDecrementSlot = (e, index) => {
        const slots = copyObject(decrementSlots);
        setDecrementSlot([ ...slots.slice(0, index), ...slots.slice(parseInt(index) + 1) ]);
    }

    const handleSlotChange = (type, slotIndex, newSlot) => {
        const slots = (type === "decrement") ? copyObject(decrementSlots) : copyObject(incrementSlots);
        
        slots[slotIndex][newSlot.name] = newSlot.value;

        if(type === "decrement") {
            setDecrementSlot(slots);
        } else {
            setIncrementSlot(slots);
        }
    }

    const handleSTDChange = (event, type) => {
        const value = event.target.value;
        if(type === "snf") {
            setStdSnf(value);
        } else {
            setStdCLR(value);
        }

        const incSlots = copyObject(incrementSlots);
        const decSlots = copyObject(decrementSlots);
        
        if(incSlots.length > 0) {
            incSlots[0]['value'] = value;
        }
        if(decSlots.length > 0) {
            decSlots[0]['value'] = value;
        }

        setIncrementSlot(incSlots);
        setDecrementSlot(decSlots);
    }

    const handleIncDecByChange = (incDecByType) => {
        const incSlots = copyObject(incrementSlots);
        const decSlots = copyObject(decrementSlots);
        
        if(incSlots.length > 0) {
            incSlots[0]['value'] = incDecByType === "snf" ? stdSnf : stdCLR;
        }
        if(decSlots.length > 0) {
            decSlots[0]['value'] = incDecByType === "snf" ? stdSnf : stdCLR;
        }

        setIncrementSlot(incSlots);
        setDecrementSlot(decSlots);
        setIncDecBy(incDecByType);
        setDeductionStartFrom("");
        setIncrementStartFrom("");
    }

    const [deductionType, setDeductionType] = useState(incDecDeductionType);

    const handleDeductionType = (event) => {
        setDeductionType(event.target.checked ? "by_calculation" : "by_slot");
    }

    const [incrementType, setIncrementType] = useState(incDecIncrementType);

    const handleIncrementType = (event) => {
        setIncrementType(event.target.checked ? "by_calculation" : "by_slot");
    }

    // Deduction Start From Section
    const [deductionStartBy, setDeductionStartBy] = useState(inc_dec_deduction_start_by);
    const [deductionStartFrom, setDeductionStartFrom] = useState(inc_dec_deduction_start_from);
    const [deductionStartFromMax, setDeductionStartFromMax] = useState("");

    const handleDeductionStartBy = (event) => {
        const value = event.target.value;
        setDeductionStartBy(value);
        setDeductionStartFrom("");
    }

    useEffect(() => {
        if(deductionStartBy === incDecBy) {
            if(deductionStartFrom === "") {
                setDeductionStartFrom(deductionStartBy === "snf" ? stdSnf : stdCLR);
            }
            setDeductionStartFromMax(deductionStartBy === "snf" ? stdSnf : stdCLR);
        } else if(deductionStartBy !== incDecBy){
            let calculatedMaxLimit;
            
            if(deductionStartBy === "clr") {
                calculatedMaxLimit = DairyFormula.prototype.calculateCLR(stdSnf, fatFrom, factor, constant);
            } else {
                calculatedMaxLimit = DairyFormula.prototype.calculateSNF(stdCLR, fatFrom, factor, constant);
            }
            calculatedMaxLimit = calculatedMaxLimit.toFixed(2);
            setDeductionStartFromMax(calculatedMaxLimit);
            if(deductionStartFrom === "") {
                setDeductionStartFrom(calculatedMaxLimit);
            }
        }
    }, [deductionStartBy, incDecBy, stdSnf, stdCLR, deductionStartFrom, fatFrom, factor, constant]);

    // Increment Start From Section
    const [incrementStartBy, setIncrementStartBy] = useState(inc_dec_increment_start_by);
    const [incrementStartFrom, setIncrementStartFrom] = useState(inc_dec_increment_start_from);
    const [incrementStartFromMin, setIncrementStartFromMin] = useState("");

    const handleIncrementStartBy = (event) => {
        const value = event.target.value;
        setIncrementStartBy(value);
        setIncrementStartFrom("");
    }

    useEffect(() => {
        if(incrementStartBy === incDecBy) {
            if(incrementStartFrom === "") {
                setIncrementStartFrom(incrementStartBy === "snf" ? stdSnf : stdCLR);
            }
            setIncrementStartFromMin(incrementStartBy === "snf" ? stdSnf : stdCLR);
        } else if(incrementStartBy !== incDecBy){
            let calculatedMinLimit;
            
            if(incrementStartBy === "clr") {
                calculatedMinLimit = DairyFormula.prototype.calculateCLR(stdSnf, fatFrom, factor, constant);
            } else {
                calculatedMinLimit = DairyFormula.prototype.calculateSNF(stdCLR, fatFrom, factor, constant);
            }
            calculatedMinLimit = calculatedMinLimit.toFixed(2);
            setIncrementStartFromMin(calculatedMinLimit);
            if(incrementStartFrom === "") {
                setIncrementStartFrom(calculatedMinLimit);
            }
        }
    }, [incrementStartBy, incDecBy, stdSnf, stdCLR, incrementStartFrom, fatFrom, factor, constant]);

    validator.purgeFields();
    
    return (
        <ErrorBoundary>
            <div className="form-group">
                <div className="form-row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <label>
                                {t("text_deduction_increment_by")}
                            </label>
                            <div className="">
                                <span className="display-inline-block lheight-24 mr-2">CLR</span>
                                    <Switch 
                                        onChange={e => handleIncDecByChange(e.target.checked ? "snf" : "clr")}
                                        checked={incDecBy === "snf"} />
                                <span className="display-inline-block lheight-24 ml-2">SNF</span>
                                <input type="hidden" value={incDecBy} name="increment_decrement_by" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-12">
                        <label>{t(`text_standard_${incDecBy}`)}</label>
                        {incDecBy === "snf" ? (
                            <>
                                <input 
                                    type="number"
                                    step={0.01}
                                    value={stdSnf}
                                    name="std_snf"
                                    className="form-control"
                                    onChange={e => handleSTDChange(e, "snf")} />
                                {validator.message('std_snf', stdSnf, `required|numeric|min:${SNF_LOWER_LIMIT},num|max:${SNF_UPPER_LIMIT},num`)}
                            </>
                        ) : (
                            <>
                                <input 
                                    type="number"
                                    step={1}
                                    value={stdCLR}
                                    name="std_clr"
                                    className="form-control"
                                    onChange={e => handleSTDChange(e, "clr")} />
                                {validator.message('std_clr', stdCLR, `required|numeric|min:${CLR_LOWER_LIMIT},num|max:${CLR_UPPER_LIMIT},num`)}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="form-group mb-0">
                <div className="form-row">
                    <div className="col-md-12">
                        <ul className="nav nav-justified">
                            <li className="nav-item">
                                <a className="nav-link active"
                                    data-toggle="pill"
                                    href="#snf_rate_deduction">
                                    {t("text_deduction")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    data-toggle="pill"
                                    href="#snf_rate_increment">
                                    {t("text_increment")}
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className={"tab-pane container with-border pb-3 pl-0 pr-0 active"} id="snf_rate_deduction">
                                <div className="col-12 mt-3 mb-2">
                                    <div className="form-row">
                                        <div className="col-12 col-md-6">
                                            <label>{t("text_deduction_start_by")}</label>
                                            <div className="form-group mb-0">
                                                <div className="form-check form-check-inline mr-0 ml-n2">
                                                    <RadioPrimary
                                                        name="inc_dec_deduction_start_by"
                                                        id="inc_dec_deduction_start_by_clr"
                                                        value="clr"
                                                        checked={deductionStartBy === "clr"}
                                                        onChange={handleDeductionStartBy}
                                                    />
                                                    <label
                                                        htmlFor="inc_dec_deduction_start_by_clr"
                                                        className="form-check-label"
                                                    >
                                                        {t("text_clr")}
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline mr-0">
                                                    <RadioPrimary
                                                        name="inc_dec_deduction_start_by"
                                                        id="inc_dec_deduction_start_by_snf"
                                                        value="snf"
                                                        checked={deductionStartBy === "snf"}
                                                        onChange={handleDeductionStartBy}
                                                    />
                                                    <label 
                                                        className="form-check-label"
                                                        htmlFor="inc_dec_deduction_start_by_snf"
                                                    >
                                                        {t("text_snf")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label>{t("text_deduction_start_from")}</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="inc_dec_deduction_start_from"
                                                value={deductionStartFrom}
                                                onChange={e => setDeductionStartFrom(e.target.value)}
                                                aria-describedby="inc_dec_deduction_start_from_help"
                                                max={deductionStartFromMax}
                                                min={0}
                                                step={0.1}
                                            />
                                            <small id="inc_dec_deduction_start_from_help" className="form-text text-muted">
                                                {deductionStartFromMax && `${t("text_maximum")}: ${deductionStartFromMax}`}
                                            </small>
                                            {validator.message('deduction_start_from', deductionStartFrom, `numeric|min:0,num|max:${deductionStartFromMax},num`)}
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-16" />
                                <div className="mb-2">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between flex-column flex-md-row">
                                            <label>
                                                {t("text_deduction_type")}
                                            </label>
                                            <div className="">
                                                <span className="display-inline-block lheight-24 mr-2">
                                                    {t("text_by_slot")}
                                                </span>
                                                <Switch
                                                    onChange={handleDeductionType}
                                                    checked={deductionType === "by_calculation"}
                                                />
                                                <span className="display-inline-block lheight-24 ml-2">
                                                    {t("text_by_calculation")}
                                                </span>
                                                <input type="hidden" value={deductionType} name="inc_dec_deduction_type" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{t(`text_${incDecBy}`)}</th>
                                            <th className="pr-0 pl-0">{t(`text_${incDecBy}_rate`)}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        decrementSlots.map((slot, index) => {
                                            return (
                                                <ErrorBoundary key={slot.id}>
                                                    <IncrementDecrementSlot
                                                        t={t}
                                                        index={index}
                                                        type="decrement"
                                                        incDecBy={incDecBy}
                                                        slot={slot}
                                                        stdSnf={stdSnf}
                                                        stdCLR={stdCLR}
                                                        prevSlot={index > 0 ? decrementSlots[index - 1] : null}
                                                        onDelete={deleteDecrementSlot}
                                                        onSlotChange={handleSlotChange}
                                                        validator={validator} />
                                                </ErrorBoundary>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                                <div className="form-row">
                                    <div className="col-12 text-right">
                                        <button
                                            type="button"
                                            className="no-style pl-0"
                                            onClick={addDecrementSlot}>
                                            <Icon icon="plus" /> {t("text_add_more")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={"tab-pane container with-border pb-3 pl-0 pr-0"} id="snf_rate_increment">
                                <div className="col-12 mt-3 mb-2">
                                    <div className="form-row">
                                        <div className="col-12 col-md-6">
                                            <label>{t("text_increment_start_by")}</label>
                                            <div className="form-group mb-0">
                                                <div className="form-check form-check-inline mr-0 ml-n2">
                                                    <RadioPrimary
                                                        name="inc_dec_increment_start_by"
                                                        id="inc_dec_increment_start_by_clr"
                                                        value="clr"
                                                        checked={incrementStartBy === "clr"}
                                                        onChange={handleIncrementStartBy}
                                                    />
                                                    <label
                                                        htmlFor="inc_dec_increment_start_by_clr"
                                                        className="form-check-label"
                                                    >
                                                        {t("text_clr")}
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline mr-0">
                                                    <RadioPrimary
                                                        name="inc_dec_increment_start_by"
                                                        id="inc_dec_increment_start_by_snf"
                                                        value="snf"
                                                        checked={incrementStartBy === "snf"}
                                                        onChange={handleIncrementStartBy}
                                                    />
                                                    <label 
                                                        className="form-check-label"
                                                        htmlFor="inc_dec_increment_start_by_snf"
                                                    >
                                                        {t("text_snf")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label>{t("text_increment_start_from")}</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="inc_dec_increment_start_from"
                                                value={incrementStartFrom}
                                                onChange={e => setIncrementStartFrom(e.target.value)}
                                                aria-describedby="inc_dec_increment_start_from_help"
                                                min={incrementStartFromMin}
                                                step={0.1}
                                            />
                                            <small id="inc_dec_increment_start_from_help" className="form-text text-muted">
                                                {incrementStartFromMin && `${t("text_minimum")}: ${incrementStartFromMin}`}
                                            </small>
                                            {validator.message('increment_start_from', incrementStartFrom, `numeric|min:${incrementStartFromMin},num`)}
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-16" />
                                <div className="mb-2">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between flex-column flex-md-row">
                                            <label>
                                                {t("text_increment_type")}
                                            </label>
                                            <div className="">
                                                <span className="display-inline-block lheight-24 mr-2">
                                                    {t("text_by_slot")}
                                                </span>
                                                <Switch
                                                    onChange={handleIncrementType}
                                                    checked={incrementType === "by_calculation"}
                                                />
                                                <span className="display-inline-block lheight-24 ml-2">
                                                    {t("text_by_calculation")}
                                                </span>
                                                <input type="hidden" value={incrementType} name="inc_dec_increment_type" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{t(`text_${incDecBy}`)}</th>
                                            <th className="pr-0 pl-0">{t(`text_${incDecBy}_rate`)}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            incrementSlots.map((slot, index) => {
                                                return (
                                                    <ErrorBoundary key={slot.id}>
                                                        <IncrementDecrementSlot
                                                            t={t}
                                                            index={index}
                                                            type="increment"
                                                            incDecBy={incDecBy}
                                                            slot={slot}
                                                            stdSnf={stdSnf}
                                                            stdCLR={stdCLR}
                                                            prevSlot={index > 0 ? incrementSlots[index - 1] : null}
                                                            onDelete={deleteIncrementSlot}
                                                            onSlotChange={handleSlotChange}
                                                            validator={validator} />
                                                    </ErrorBoundary>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="form-row">
                                    <div className="col-12 text-right">
                                        <button
                                            type="button"
                                            className="no-style pl-0"
                                            onClick={addIncrementSlot}>
                                            <Icon icon="plus" /> {t("text_add_more")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
}


export default IncrementDecrementSection;