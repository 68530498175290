// @ts-nocheck
import { deleteMilkEntry, fetchAutoCollectionQueue, saveMilkCollection } from "src/offline-storage/dairy/milk-collection";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectDairyCustomers } from "src/reducers/dairyCustomer";
import { cloneDeep } from "lodash";
import { getDairyId } from "src/reducers/dairy";
import moment from "moment";
import { fetchCollectionQueueTotal } from "../collection-queue-total/thunk";
import { removeQueueItem, updateQueueItem } from "./collection-queue";

export const fetchCollectionQueue = createAsyncThunk(
    'collectionQueue/fetchCollectionQueue',
    async ({ dairyId, shift, date }, { getState }) => {
        const queueData = await fetchAutoCollectionQueue({
            date,
            shift,
            dairy_id: dairyId
        });
        const customers = selectDairyCustomers(getState());
        return queueData?.map((item) => {
            const customer = [...customers].find(({ dairy_customer_id }) => Number(dairy_customer_id) === Number(item.dairy_customer_id));
            return {
                customer: cloneDeep(customer),
                analysis: item[`fat`] > 0,
                updateQueue: false,
                cattle: item.cattle,
                calculateRate: item[`fat`] <= 0,
                milk: {
                    liter: item[`liter`],
                    fat: item[`fat`],
                    shift: item[`shift`],
                    clr: item[`clr`],
                    snf: item[`snf`],
                    rate: item[`rate`],
                    total: item[`total`],
                }

            }
        });
    },
);

export const saveCollectionQueueItem = createAsyncThunk(
    'collectionQueue/saveCollectionQueueItem',
    async (dataToSave, { getState, dispatch }) => {
        const state = getState();
        const dairyId = getDairyId(state);

        const date = moment().format("YYYY-MM-DD");
        const shift = dataToSave.shift;
        await saveMilkCollection({
            date,
            dairy_id: dairyId,
            ...dataToSave
        });
        dispatch(updateQueueItem(dataToSave));
        dispatch(fetchCollectionQueueTotal({
            date,
            shift,
            dairyId
        }));
    },
);

export const deleteCollectionQueueItem = createAsyncThunk(
    'collectionQueue/deleteCollectionQueueItem',
    async ({ rowIndex, cattle, dairy_customer_id, shift }, { getState, dispatch }) => {

        const state = getState();
        const dairyId = getDairyId(state);
        const date = moment().format("YYYY-MM-DD");
        await deleteMilkEntry({
            date,
            shift,
            cattle,
            dairy_id: dairyId,
            dairy_customer_id: dairy_customer_id
        });
        dispatch(removeQueueItem({
            rowIndex
        }));
        dispatch(fetchCollectionQueueTotal({
            date,
            shift,
            dairyId
        }));
    },
);
  