import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import _ from 'lodash';
import PropTypes from "prop-types";
import { default as React, useState } from "react";
import SortButton from "src/components/core/SortingButton/SortButton";
import ModalDialog from "../../../common/ModalDialog";
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";
import { PrimaryCheckbox } from "../../../core/Checkbox";
import { useLanguage } from "../../../language/LanguageController";
import { customerFilter } from "../../../library/util";
import CustomerListItem from "./CustomerListItem";

const ReportCustomerModal = ({
    isOpen,
    onClose,
    onChecked,
    allChecked,
    onAllChecked,
    customers
}) => {
    const { t } = useLanguage();
    const [filter, setFilter] = useState("");
    const [sortBy, setSortBy] = useState("name");
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSort = (sortOption) => {
        setSortBy(sortOption.field);
        setSortOrder(sortOption.order);
    };

    const sortedCustomers = _.orderBy(customers, [
        item => parseInt(item[sortBy])
    ], [sortOrder]);


    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_select_customers")}
            content={
                <>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PrimaryCheckbox
                                    edge="start"
                                    checked={allChecked}
                                    id="select-all-customers"
                                    onChange={onAllChecked}
                                    inputProps={{ 'aria-label': t("text_select_all") }}
                                />
                            </ListItemIcon>
                            <ListItemText>
                                <input
                                    id="report-customer-search"
                                    type="search"
                                    className="form-control rounded"
                                    placeholder={t("text_search_customer")}
                                    value={filter}
                                    onChange={({ target }) => setFilter(target.value)}
                                    aria-label={t("text_search_customer")}
                                />
                                <div className="d-block d-sm-none mt-2">
                                <SortButton  onSortChange={handleSort} />
                                </div>
                            </ListItemText>
                            <div className="d-none d-sm-block">
                                <SortButton  onSortChange={handleSort} />
                            </div>
                        </ListItem>
                    </List>
                    <div className="report-modal-body overflow-auto height-680-p">
                        <List>
                            {sortedCustomers.sort((a, b) => b?.is_checked - a?.is_checked).filter(customerFilter(filter)).map((customer, index) => (
                                <CustomerListItem key={customer?.dairy_customer_id} customer={customer} onChecked={onChecked} />
                            ))}
                        </List>
                    </div>
                </>
            }
            dialogActions={
                <PrimaryButton variant="contained" onClick={onClose}>
                    {t("text_done")}
                </PrimaryButton>
            }
        />
    );
};

ReportCustomerModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onChecked: PropTypes.func,
    allChecked: PropTypes.bool,
    onAllChecked: PropTypes.func,
    customers: PropTypes.array
};

export default ReportCustomerModal;
