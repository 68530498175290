
import React from "react";
import { currencyFormat } from "../../../../library/util";
import PropTypes from "prop-types";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";

const DairyReceivableGridView = ({ t, monthDiff, filter, records, onPayClick, onCustomerClick }) => {

    return (
        <div className="table-fix-header">
            {records.map((row, index) => {
                if (filter && filter.length) {
                    filter = filter.toLowerCase();
                    const name = (row.customer_name || "").toLowerCase();
                    const code = (row.code || "").toLowerCase();
                    if (!name.includes(filter) && !code.includes(filter)) {
                        return null;
                    }
                }
                return (
                    <div className="t-card shadow-sm mb-2" key={row.dairy_customer_id}>
                        {index === 0 ? (
                            <div className="title right card__title card--material__title mt-0">
                                <div className="d-account-h1">
                                    {t("text_receivable")}
                                </div>
                            </div>
                        ) : null}
                        <div onClick={e => onCustomerClick(e, row.dairy_customer_id)} className={"content card__content card--material__content"}>
                            <div className="mb-7 clearfix">
                                <span className="capitalize">
                                    {`${row.code}-${row.customer_name}`}
                                </span>
                                {row.receivable_remaining != "0.00" ? (
                                    <div className="float-right">
                                        <PrimaryButton
                                            variant="contained"
                                            size="small"
                                            disabled={!monthDiff}
                                            onClick={e => onPayClick(e, row, index)}
                                        >
                                            {t("text_receive")}
                                        </PrimaryButton>
                                    </div>
                                ) : null}
                            </div>
                            <hr />
                            <div className="display-table">
                                <div className="display-table-cell text-left">
                                    {t("text_receivable")}
                                    <span className="display-block">
                                        {currencyFormat(row.receivable)}
                                    </span>
                                </div>
                                <div className="display-table-cell text-center">
                                    {t("text_received")}
                                    <span className="text-green display-block">
                                        {currencyFormat(row.received)}
                                    </span>
                                </div>
                                <div className="display-table-cell text-right">
                                    {t("text_due")}
                                    <span className="text-red display-block">
                                        {currencyFormat(row.receivable_remaining)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

DairyReceivableGridView.propTypes = {
    t: PropTypes.func,
    monthDiff: PropTypes.bool,
    filter: PropTypes.string,
    records: PropTypes.array,
    onPayClick: PropTypes.func,
    onCustomerClick: PropTypes.func
};

export default DairyReceivableGridView;