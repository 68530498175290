// @ts-nocheck
import React from 'react';
import moment from "moment";
import * as Sentry from "@sentry/react";
import _ from 'lodash';
import { loader, socialSharing, ucFirst } from '../../../../library/util';
import { Icon } from 'react-onsenui';
import { connect } from 'react-redux';
import Slider from "react-slick";
import InnerNav from '../../InnerNav';
import { todayMilkTemplate, milkTotalTemplate } from '../../../../library/smsTemplates';
import withLanguage from '../../../../language/LanguageController';
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import MilkTotal from "../../CustomerMilkTotal/MilkTotal";
import CustomerProduct from "../../CustomerMilkTotal/CustomerProducts";
import CustomerAdvancePayment from "../../CustomerMilkTotal/CustomerAdvancePayment";
import { showInterstitialAd } from '../../../../library/admob';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import { errorHandler } from '../../../../library/response';
import { connectLastPrinter, isConnected } from '../../../../library/bluetoothPrinter';
import BluetoothPrinter from '../../modalPopup/BluetoothPrinter';
import { printCustomerMilkTotal } from "../../../../templates/printerTemplates";
import { generatePDF } from "../../../../library/pdfGenerator";
import Filter from './Filter';
import { getCustomerLabel } from '../../../../library/customer';
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../../_constants';
import config from "../../../../../config";
import TodayMilkMobile from "./TodayMilkMobile";
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { withRouter } from 'react-router';
import Spinner from 'src/components/core/Spinner';
import { HasPermission, withPermission } from '../../../../common/AccessPermission';
import { ABILITY_ADVANCE_PAYMENT, ABILITY_MILK_COLLECTION, ABILITY_PRODUCT_SALE, PERMISSION_DETAIL, PERMISSION_SALE_VIEW, PERMISSION_VIEW } from '../../../../../_constants/permissions';
import { compose } from 'redux';
import Eventer from 'src/components/library/Eventer';
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class CustomerMilkTotal extends React.Component {

    constructor(props) {
        super(props);
        this.getTodayMilk = this.getTodayMilk.bind(this);
        this.getMilk = this.getMilk.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getAdvance = this.getAdvance.bind(this);
        this.printMilkTotal = this.printMilkTotal.bind(this);
        this.downloadMilkTotal = this.downloadMilkTotal.bind(this);
        let customers = this.getCustomersOptions();
        let customer = this.props.customers[0];
        const dairyCustomerId = this.props?.match?.params?.dairyCustomerId;
        if(dairyCustomerId) {
            customer = this.props.customers.find(customer => customer.dairy_customer_id == dairyCustomerId);
        }
        if(customer) {
            customer.value = customer.dairy_customer_id;
            customer.label = getCustomerLabel(customer); //customer.name + " (" + customer.mobile + " - " + ucFirst(customer.type) + ")";
        }
        this.state = {
            total: {},
            cow_total: {},
            buffalo_total: {},
            today: [],
            dairyCustomerId: customer ? customer.dairy_customer_id : null,
            customers: customers,
            selectedCustomer: customer || {},
            products: [],
            advance: [],
            advanceTotal: 0,
            product_total: {
                amount: "0", 
                quantity: "0"
            },
            dropdown_menu: false,
            isBSetting: false,
            code: customer ? customer.code : "",
            shift: moment().format("HH") >= 15 ? 1 : 0,
            isLoadingMilk: true,
            isLoadingProduct: true,
            isLoadingAdvance: true,
            ...this.getDefaultFilter()
        };
        this.settings = {
            dots: true,
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
        this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
        props.forwardedRef && props.forwardedRef(this);
    }

    getDefaultFilter(){
        const { startDate, endDate } = getBillingStartEndDate(this.props.billingCycle, moment());
        return {
            start_date: this.props.startDate || startDate,
            end_date: this.props.endDate || endDate,
            cattle: this.props.cattle || "",
            shift: this.props.shift || "0"
        }
    }

    componentDidMount() {
        analytics.setScreenName("CustomerMilkTotal");
        this.getMilk();
        this.getTodayMilk();
        this.getProducts();
        this.getAdvance();
        showInterstitialAd();
        connectLastPrinter().catch(console.log);
        Eventer.on("milkDeleted", this.getMilk);
    }

    componentWillUnmount() {
        Eventer.off("milkDeleted", this.getMilk);
    }

    getCustomersOptions = () => {
        let customerOptions = this.props.customers.map(customer => {
            let label = getCustomerLabel(customer);
            customer.value = customer.dairy_customer_id;
            customer.label = label;
            return customer;
        });
    
        return customerOptions;
    }

    customerChangeHandler = customer => {
        this.setState({
            dairyCustomerId: customer.value,
            selectedCustomer: customer
        }, () => {
            document.getElementById("customer_code").value = customer.code;
            this.getTodayMilk();
            this.getMilk();
            this.getProducts();
            this.getAdvance();
            this.props.history.replace(`/milk/customer/${customer.value}`);
        });
    }

    handleCodeChange(event) {
        const code = event.target.value;
        const customer = this.props.customers.find(customer => customer.code == code);
        if(customer) {
          this.customerChangeHandler(customer);
        } else {
          this.setState({
            selectedCustomer: {
              label: this.props.t("text_no_customer_for_code"),
              value: null
            },
            dairyCustomerId: null
          });
        }
    }
    
    dateChangeHandler = (name, newDate) => {
        this.setState({
            [name]: newDate
        }, () => {
            this.getMilk();
            this.getProducts();
            this.getAdvance();
        });
    }


    async getTodayMilk () {
        try {
            if(this.props.hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)) {
                if(!this.state.dairyCustomerId) {
                    return false;
                }
                const { status, data } = await request.get('/dairies/' + this.props.dairyId + '/milk/' + this.state.dairyCustomerId + '/today', {
                    withAuth: true,
                    version: "v2"
                });
                if(status === true){
                    this.setState({
                        today: data
                    }); 
                }
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    async getMilk () {
        try {
            if(this.props.hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)) {
                if(!this.state.dairyCustomerId) {
                    return false;
                }
                this.setState({
                    isLoadingMilk: true
                });
                const { cattle, shift, start_date, end_date, dairyCustomerId } = this.state;
                const { dairyId } = this.props;
                let filterStr = `?milk_type=${cattle}`;
                    filterStr += `&date=${start_date.format(config.date_format)}:${end_date.format(config.date_format)}`;
                    filterStr += `&day_time=${shift}`;

                const { status, data } = await request.get(`/dairies/${dairyId}/milk/${dairyCustomerId}/total${filterStr}`, {
                    withAuth: true,
                    version: "v2"
                });
                if (status === true) {
                    const { total, cow_total, buffalo_total } = data;
                    this.setState({
                        total: total,
                        cow_total: cow_total,
                        buffalo_total: buffalo_total
                    }); 
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            this.setState({
                isLoadingMilk: false
            });
        }
    }

    async getProducts () {
        try {
            if(this.props.hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_VIEW)) {
                if(!this.state.dairyCustomerId) {
                    return false;
                }
                if(!checkFeatureInSubscription("product_management", false)) {
                    return false;
                }
                this.setState({
                    isLoadingProduct: true
                });
                const { start_date, end_date, dairyCustomerId } = this.state;
                const { dairyId } = this.props;
                const filterStr = `view=customer&date=${start_date.format(config.date_format)}:${end_date.format(config.date_format)}&dairy_customer_id=${dairyCustomerId}`;
                const { status, data } = await request.get(`/dairies/${dairyId}/customer/products?${filterStr}`, {
                    withAuth: true,
                    version: "v2"
                });
                let products = [], total = { amount: "0", quantity: "0" };
                if(status) {
                    if(data && data.result && data.result[0] && data.result[0] && data.result[0].childs) {
                        products = data.result[0].childs;
                        total = data.total;
                    }
                }
                this.setState({
                    products: products,
                    product_total: total
                });
            }
        } catch(error) {
            Sentry.captureException(error);
        } finally {
            this.setState({
                isLoadingProduct: false
            });
        }
    }

    async getAdvance () {
        try {
            if(this.props.hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW)) {
                if(!this.state.dairyCustomerId) {
                    return false;
                }
                if(!checkFeatureInSubscription("advance_payment", false)) {
                    return false;
                }
                this.setState({
                    isLoadingAdvance: true
                });
                const { start_date, end_date, dairyCustomerId } = this.state;
                const { dairyId } = this.props;
                const filterStr = `start_date=${start_date.format(config.date_format)}&end_date=${end_date.format(config.date_format)}`;
                const { status, data } = await request.get(`/dairies/${dairyId}/accounts/advancePayment/${dairyCustomerId}?${filterStr}`, {
                    withAuth: true,
                    version: "v2"
                });
                let advance = [];
                if(status) {
                    advance = data;
                }
                let advanceTotal = advance.reduce((acc, record) => {
                    return acc + parseFloat(record.amount);
                }, 0);
                this.setState({
                    advance: advance,
                    advanceTotal: advanceTotal
                });
            }
        } catch(error) {
            Sentry.captureException(error);
        } finally {
            this.setState({
                isLoadingAdvance: false
            });
        }
    }
    
    pushPage = (e, Compo, props) => {
        e.preventDefault();
        this.props.navigator.pushPage({
            component: Compo, 
            props: props
        }, {
            animation: 'fade'
        });
    }

    shareTodayMilk = e => {
        analytics.logEvent("shareTodayMilk");
        let message = todayMilkTemplate(this.state.today || []);
        socialSharing(message);
    }

    shareMilkTotal = e => {
        e.stopPropagation();
        e.preventDefault();
        analytics.logEvent("shareMilkTotal");
        const { total: { total_milk, total_amount, avg_milk, avg_amount, avg_rate }, selectedCustomer: { name } } = this.state;
        let message = milkTotalTemplate(name, total_milk, total_amount, avg_milk, avg_amount, avg_rate);
        socialSharing(message);
    }

    async printMilkTotal() {
        analytics.logEvent("printMilkTotal");
        try {
            if(checkFeatureInSubscription("print")) {
                loader.show(this.props.t("text_printing"))
                const connected = await isConnected();
                if(connected) {
                    const { selectedCustomer: { name: customerName, code, type } } = this.state;
                    const { dairyName } = this.props;
                    const { start_date: startDate, end_date: endDate } = this.state;
                    await printCustomerMilkTotal(dairyName, customerName, code, type, { startDate, endDate }, this.state);
                } else {
                    this.setState({
                        isBSetting: true
                    });
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    async downloadMilkTotal () {
        try {
            if(!checkFeatureInSubscription("print")) {
                return false;
            }
            loader.show(this.props.t("text_downloading"));
            const { start_date, end_date, dairyCustomerId } = this.state;
            const { dairyId } = this.props;
            const data = await request.post(`/dairies/${dairyId}/reports/customerMilkTotal`, {
                withAuth: true,
                version: "v2",
                responseType: "text/html; charset=utf-8",
                body: JSON.stringify({
                    "dairy_customer_id": dairyCustomerId,
                    "start_date": start_date.format(config.date_format),
                    "end_date": end_date.format(config.date_format)
                })
            });
            const fileName = `Customers_Milk_Total_Report_${start_date.format("YYYY_MM_DD")}__${end_date.format("YYYY_MM_DD")}.pdf`;
            await generatePDF(data, fileName);
        } catch(error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    togglePrinterModel = () => {
        this.setState({
          isBSetting: !this.state.isBSetting
        });
    }

    renderFixedContent = () => {
        const { isBSetting } = this.state;
        const { t } = this.props;
        if(isBSetting) {
          return (
            <BluetoothPrinter
                t={t} 
                isOpen={isBSetting}
                onClose={this.togglePrinterModel}
            />
          );
        }
    }

    spreadTodayMilk = _ => {
        let todayMorningMilk = [], todayEveningMilk = [];
        for(let record of this.state.today){
            todayMorningMilk.push(
                <tr key={record.id} id={record.id}>
                    <td>{ucFirst(this.props.t("text_" + record.milk_type)) || "-"}</td>
                    <td>{record.liter_0}</td>
                    <td>{record.fat_0 || "-"}</td>
                    <td>{record.clr_0 || "-"}</td>
                    <td>{record.rate_0 || "-"}</td>
                    <td>{record.total_0 || "-"}</td>
                </tr>
            );
            todayEveningMilk.push(
                <tr key={record.id} id={record.id}>
                    <td>{ucFirst(this.props.t("text_" + record.milk_type)) || "-"}</td>
                    <td>{record.liter_1 || "-"}</td>
                    <td>{record.fat_1 || "-"}</td>
                    <td>{record.clr_1 || "-"}</td>
                    <td>{record.rate_1 || "-"}</td>
                    <td>{record.total_1 || "-"}</td>
                </tr>
            );
        }
        return { todayMorningMilk, todayEveningMilk };
    }

    gotoMilkDetail = e => {
        e.persist();
        analytics.logEvent("gotoMilkDetail");
        if(checkFeatureInSubscription("customer_daily_milk_summary")) {
            const { 
                selectedCustomer: { 
                    dairy_customer_id
                }, 
                start_date,
                end_date
            } = this.state;
            this.props.history.push({
                pathname: `/customers/milk/summary/${dairy_customer_id}`,
                state: {
                    startDate: start_date.toString(),
                    endDate: end_date.toString()
                }
            });
        }
    }

    onDeviceChange = deviceDetail => {
        this.setState({
            ...deviceDetail
        });
    }

    render() {
        let { todayMorningMilk, todayEveningMilk } = this.spreadTodayMilk();
        let { 
            customers,
            selectedCustomer,
            product_total,
            cow_total,
            buffalo_total,
            advanceTotal,
            start_date,
            end_date,
            code,
            shift,
            isAndroid,
            isLoadingMilk,
            isLoadingProduct,
            isLoadingAdvance
        } = this.state;
        const { t, header, filter } = this.props;
        return (
            <DefaultLayout title={this.props.dairyName} toolbar={false} back header={header} bottomGutter={header} onDeviceChange={this.onDeviceChange}>
                {filter ? <Filter
                    header={header}
                    startDate={start_date}
                    endDate={end_date}
                    customers={customers}
                    customer={selectedCustomer}
                    customerChangeHandler={this.customerChangeHandler}
                    dateChangeHandler={this.dateChangeHandler}
                    handleCodeChange={this.handleCodeChange}
                    code={code}/> : null}
                <div className="t-card mx-0 shadow-sm mb-2" modifier="material customer_today_milk">
                    <div className="card-header p-0 bg-white d-flex justify-content-between border-0">
                        <div className="card__title card--material__title mb-0 mt-0">
                            {t("text_todays_milk")}
                        </div>
                        {isAndroid && <button className="btn"
                            type="button"
                            title="Share"
                            onClick={this.shareTodayMilk}>
                            <Icon icon="md-share" />
                        </button>}
                    </div>
                    <TodayMilkMobile
                        todayMorningMilk={todayMorningMilk}
                        todayEveningMilk={todayEveningMilk}
                        shift={shift} />
                </div>
                <Slider className={`dairy_slider ${!(parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk)) ? 'slide-padding-0' : ''}`} {...this.settings}>
                    <div className="t-card mb-2 mx-0 shadow-sm">
                        <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                            <div className="title right card__title card--material__title mt-0 mb-0">
                                {t("text_milk_total")}
                            </div>
                            <div className="position-relative">
                                <button className="btn d-lg-none"
                                    type="button"
                                    title="View"
                                    aria-label="View"
                                    onClick={this.gotoMilkDetail}
                                    disabled={isLoadingMilk}
                                >
                                    <Icon icon="md-eye" />
                                </button>
                                <button className="btn"
                                    type="button"
                                    title="Print"
                                    aria-label="Print"
                                    onClick={this.printMilkTotal}
                                    disabled={isLoadingMilk}
                                >
                                    <Icon icon="md-print" />
                                </button>
                                <button className="btn"
                                    type="button"
                                    title="Download"
                                    aria-label="Download"
                                    onClick={this.downloadMilkTotal}
                                    disabled={isLoadingMilk}>
                                    <Icon icon="md-download"/>
                                </button>
                                {isAndroid && <button className="btn"
                                    type="button"
                                    title="Share"
                                    aria-label="Share"
                                    onClick={this.shareMilkTotal}
                                    disabled={isLoadingMilk}
                                >
                                    <Icon icon="md-share" />
                                </button>}
                            </div>
                        </div>
                        {!isLoadingMilk ? <MilkTotal total={this.state.total}/> : <Spinner/>}
                    </div>
                    {parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk) > 0 ? (
                        <div className="t-card mb-2 mx-0 shadow-sm">
                            <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                                <div className="title right card__title card--material__title mt-0 mb-0">
                                    {t("text_cow_milk_total")}
                                </div>
                            </div>
                            <MilkTotal total={this.state.cow_total}/>
                        </div>
                        ) : null}
                    {parseFloat(cow_total.total_milk) > 0 && parseFloat(buffalo_total.total_milk) > 0 ? (
                        <div className="t-card mb-2 mx-0 shadow-sm">
                            <div className="card-header p-0 bg-white d-flex justify-content-between border-0 pl-2">
                                <div className="title right card__title card--material__title mt-0 mb-0">
                                    {t("text_buffalo_milk_total")}
                                </div>
                            </div>
                            <MilkTotal total={this.state.buffalo_total}/>
                        </div>
                    ) : null}
                </Slider>
                <HasPermission ability={ABILITY_PRODUCT_SALE} access={PERMISSION_SALE_VIEW}>
                    {checkFeatureInSubscription("product_management", false) && <CustomerProduct
                        products={this.state.products}
                        productTotal={product_total}
                        selectedCustomer={this.state.selectedCustomer}
                        isLoading={isLoadingProduct}
                    />}
                </HasPermission>
                <HasPermission ability={ABILITY_ADVANCE_PAYMENT} access={PERMISSION_VIEW}>
                    {checkFeatureInSubscription("advance_payment", false) && <CustomerAdvancePayment
                        advance={this.state.advance}
                        advanceTotal={advanceTotal}
                        selectedCustomer={this.state.selectedCustomer}
                        isLoading={isLoadingAdvance}
                    />}
                </HasPermission>
                <InnerNav
                    menu="customer"
                    page="customerMilk"
                    navigator={this.props.navigator}
                    dairyCustomerId={selectedCustomer.value} />
                {this.renderFixedContent()}
            </DefaultLayout>
        );
    }
}

CustomerMilkTotal.defaultProps = {
    header: true,
    filter: true
};

CustomerMilkTotal.displayName = "CustomerMilkTotal";

const mapStateToProps = state => {
    return {
        customers: selectDairyCustomers(state),
        dairyName: state.dairy.dairy.name,
        dairyId: state.dairy.dairy.id,
        billingCycle: state.dairy.dairy.billing_cycle
    }
}

const CustomerMilkTotalComponent = compose(
    withRouter,
    withLanguage,
    withPermission,
    connect(mapStateToProps)
)(CustomerMilkTotal);

export default React.forwardRef((props, ref) =>
    <CustomerMilkTotalComponent {...props} forwardedRef={(compoRef) => {
        if(ref) {
            ref.current = compoRef
        }
    }} />)

