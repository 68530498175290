/* eslint-disable react/prop-types */

import React from "react";
import { InputText } from "../../../../core/input";
import { useLanguage } from "../../../../language/LanguageController";

const AddMilkSNFCLR = ({
    clr,
    rateListTypeId,
    handleInput,
    rate_by,
    snf
}) => {
  const { t } = useLanguage();

    if(rate_by === "snf")  {
        return <div className="col">
        <InputText
          label={t("text_clr")}
          type="number" 
          className="form-control input-bottom-border"
          name="clr"
          id="input_clr"
          value={clr || ""}
          min="18"
          max="40"
          step="0.1"
          readOnly
          tabIndex="-1"
          autoComplete="new-password" />
      </div>; 
    } else if( rateListTypeId !== 5)  {
        return <div className="col">
        <InputText 
          label={t("text_snf")}
          type="number"
          className="form-control input-bottom-border"
          name="snf"
          id="input_snf"
          value={snf || ""}
          readOnly
          required
          tabIndex="-1"
          autoComplete="new-password"
          onChange={handleInput}/>
      </div>;
    }

    return null;
};

export default AddMilkSNFCLR;