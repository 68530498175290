// @ts-nocheck
import React, { Component, createRef } from 'react';
import { loader } from "../../library/util";
import { Page } from 'react-onsenui';
import withLanguage from '../../language/LanguageController';
import { errorHandler } from '../../library/response';
import SimpleValidator from '../../common/SimpleValidator';
import request from '../../library/request';
import analytics from '../../library/firebase/analytics';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import verificationImg from "../../../assets/img/verification.png"

class MobileNumber extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.mobileRef = createRef();
    this.formId = "login-form";
    this.state = {
      is_buttons_disabled: false,
      btn_otp: false,
      btn_password: false,
      mobile: ""
    };

    this.validator = new SimpleValidator();
    this.loginTypePathMap = {
      otp: "/auth/loginByOtp",
      password: "/auth/loginByMobile"
    }
    this.afterLoginActionMap = {
      register: {
        path: "/registration"
      },
      set_password: {
        path: "/verifyPassword"
      },
      verify_otp: {
        path: "/verifyOtp"
      },
      password: {
        path: "/verifyPassword"
      }
    }
  }

  componentDidMount () {
    analytics.setScreenName("MobileNumber");
    this.mobileRef.current.focus();
  }

  handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    });
  }

  async handleSubmit (event, loginType) {
    event.preventDefault();
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      analytics.logEvent("login", {
        mobile: this.state.mobile,
        loginType
      });
      loader.show(this.props.t("text_verifying_mobile_number"));
      this.setState({
        is_buttons_disabled: true,
        [`btn_${loginType}`]: true
      });
      let { status, data, message } = await request.post(this.loginTypePathMap[loginType], {
        body: JSON.stringify({
          mobile: this.state.mobile
        })
      });
      if (status) {
        const pathName = this.afterLoginActionMap[data.action].path;
        this.props.history.push({
          pathname: pathName,
          state: { 
            mobile: this.state.mobile,
            customer_id: data.customer_id
          }
        });
      } else {
        throw message;
      }
    } catch(error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({
        is_buttons_disabled: false,
        btn_otp: false,
        btn_password: false,
      });
    }
  }

  render() {
    const { is_buttons_disabled, btn_otp, btn_password } = this.state;
    const { t } = this.props;
    return (<Page className="page">
      <div className="login_mobile">
        <div className="clearfix">
          <div className="screen_mobile_number clearfix col-md-12">
            <div className="m_login_header text-center">
              <img className="img-responsive text-center" src={verificationImg} alt="1" />
              <p>{t('login_text')}</p>
            </div>
            <div className="loginmobileform clearfix col-12 mx-auto col-lg-6 col-md-6 mt-0 mb-4 max-width-500">
              <div className="divSignupForm">
                <form method="POST" id={this.formId} onSubmit={(event) => this.handleSubmit(event, "password")}>
                  <div className="form-group mb-4">
                    <div className="inline_input col-md-12">
                      <div className="inline country_code">
                        <input
                          className="form-control input-bottom-border bg-white"
                          type="text"
                          defaultValue="+91"
                          readOnly
                          name="country_code" />
                      </div>
                      <div className="inline numberfield">
                        <input
                          className="form-control input-bottom-border"
                          type="tel"
                          placeholder={t('text_enter_mobile')}
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.handleInput}
                          ref={this.mobileRef} />
                      </div>
                    </div>
                    {this.validator.message('r_mobil_number', this.state.mobile, 'required|numeric|size:10,string', {
                      className: "pl-15 pr-15 mt-2"
                    })}
                  </div>
                  <div className="form-group form-row mx-0">
                    <div className="col-md-8 mx-auto">
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                        disabled={is_buttons_disabled}
                      >
                        {btn_password ? t('text_verifying') : t('btn_login_with_password')}
                      </button>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    {t("text_or")}
                  </div>
                  <div className="row form-group">
                    <div className="col-md-8 mx-auto">
                      <button
                        className="btn btn-outline-success btn-block"
                        type="button"
                        onClick={(event) => this.handleSubmit(event, "otp")}
                        disabled={is_buttons_disabled}
                      >
                        {btn_otp ? t('text_verifying') : t('btn_login_with_otp')}
                      </button>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    {t("text_first_time_user")} <Link to="/registration">{t("text_register")}</Link>
                  </div>
                </form>
              </div>
            </div>
            <p className="text-center mb-2">
              <small>
                <a href="https://liter.live/privacypolicy.html" target="_blank" rel="noreferrer">
                  {t('term_text')}
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </Page>);
  }
}

export default withRouter(withLanguage(MobileNumber));
