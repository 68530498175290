// @ts-nocheck
import React, { Component } from "react";
import { Ripple } from 'react-onsenui';
import { loader, toast, confirmPop } from "../../../../library/util";
import { connect } from "react-redux";
import moment from "moment";
import withLanguage from "../../../../language/LanguageController";
import { errorHandler } from "../../../../library/response";
import BillDatePicker from "../BillDatePicker";
import { getBillingStartEndDate } from "../../../../library/dateUtils";
import analytics from "../../../../library/firebase/analytics";
import request from "../../../../library/request";
import { DefaultLayout } from "src/components/pages/common/Layout";
import SingleCustomerAdvancePayment from "./SingleCustomerAdvancePayment";
import Eventer from "src/components/library/Eventer";
import { DefaultScrollbar } from "src/components/pages/common/Scrollbar";
import { withRouter } from "react-router";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import SubscriptionLock from "../../../../common/FeatureFlag/SubscriptionLock";
import { HasPermission } from "../../../../common/AccessPermission";
import { ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD } from "../../../../../_constants/permissions";

class AdvancePayment extends Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.deletePayment = this.deletePayment.bind(this);
        this.state = {
            customerPayments: [],
            isFeatureSubscribed: checkFeatureInSubscription("advance_payment", false),
            ...getBillingStartEndDate(props.billingCycle, moment())
        };
    }

    componentDidMount() {
        analytics.setScreenName("AdvancePayment");
        this.getData();
        Eventer.on("advancePaymentAdded", this.getData);
    }

    componentWillUnmount() {
        Eventer.off("advancePaymentAdded", this.getData);
    }

    groupPaymentByCustomer = (payments) => {
        let customerPayments = {};
        for(let payment of payments) {
            if(customerPayments[payment.dairy_customer_id]) {
                customerPayments[payment.dairy_customer_id].payments.push(payment);
                customerPayments[payment.dairy_customer_id].total += parseFloat(payment.amount);
            } else {
                customerPayments[payment.dairy_customer_id] = {
                    customer: payment.dairy_customer,
                    payments: [payment],
                    total: parseFloat(payment.amount)
                };
            }
        }
        return Object.values(customerPayments);
    }
    
    async getData() {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            let { startDate, endDate } = this.state;
            startDate = startDate.format('DD-MM-YYYY');
            endDate = endDate.format('DD-MM-YYYY');
            let filterStr = encodeURI(`start_date=${startDate}&end_date=${endDate}`);
            const { status, data } = await request.get('/dairies/' + this.props.dairyId + `/accounts/advancePayment?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                this.setState({
                    customerPayments: this.groupPaymentByCustomer(data)
                });
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getData();
        });
    }

    async deletePayment(e, id) {
        e.preventDefault();
        try {
            const confirm = await confirmPop(this.props.t("text_delete_confirm"));
            if (confirm == 1) {
                analytics.logEvent("deleteAvancePayment");
                loader.show(this.props.t("text_deleting"));
                const { status, message } = await request.delete('/dairies/' + this.props.dairyId + `/accounts/advancePayment/${id}`, {
                    withAuth: true,
                    version: "v2"
                });
                if (status) {
                    toast(message);
                    this.getData();
                } else {
                    throw message
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    changePage = e => {
       this.props.history.push("/dairy/advancePayment/add")
    }

    filterHandler = e => {
        e.preventDefault();
        this.setState({
            filter: e.target.value
        });
    }

    filterCustomerPayments = (filter) => {
        return customerPayment => {
            if (filter && filter.length) {
                filter = filter.toLowerCase();

                let name = "", code = "";
                if (customerPayment.customer && customerPayment.customer.name) {
                    name = (customerPayment.customer.name).toLowerCase();
                }

                if (customerPayment.customer && customerPayment.customer.code) {
                    code = (customerPayment.customer.code).toLowerCase();
                }

                if (!name.includes(filter) && !code.includes(filter)) {
                    return false;
                }
            }
            return true;
        }
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }

    render() {
        let { filter, customerPayments, isMobile, isFeatureSubscribed } = this.state;
        const { t, billingCycle, header } = this.props;
        return (
            <DefaultLayout
                bottomGutter={false}
                header={header}
                onDeviceChange={this.onDeviceChange}
                title={t("text_advance_payment")}
                toolbar={false}
                back>
                <form className="mb-2">
                    <div className="bg-light rounded shadow-sm">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-warning bg-light border-0">
                                    <i className="fa fa-search"></i>
                                </span>
                            </div>
                            <label className="sr-only" htmlFor="search-bar">{t("text_search")}</label>
                            <input type="text"
                                placeholder={t("text_search")}
                                id="search-bar"
                                className="form-control border-0 bg-light rounded-right"
                                onChange={this.filterHandler} />
                        </div>
                    </div>
                </form>
                <div className="mb-2 bg-white p-1 px-2 clearfix shadow-sm rounded d-flex">
                    <div className="mr-2">
                        <BillDatePicker
                            billingCycle={billingCycle}
                            onChange={this.onDateChange}
                            isFeatureSubscribed={isFeatureSubscribed}
                        />
                    </div>
                    <HasPermission ability={ABILITY_ADVANCE_PAYMENT} access={PERMISSION_ADD}>
                        <div className="d-sm-none">
                            <button
                                className="btn btn-success py-1 text-nowrap"
                                onClick={this.changePage}>
                                <Ripple />
                                {t("text_pay_advance")}
                            </button>
                        </div>
                    </HasPermission>
                </div>
                <DefaultScrollbar
                    isEnabled={!isMobile}
                    className="advance-payment-payments-list-scrollbar">
                    {customerPayments
                        .filter(customerPayment => this.filterCustomerPayments(filter)(customerPayment))
                        .map(customerPayment => 
                            <SingleCustomerAdvancePayment key={customerPayment.customer.dairy_customer_id}
                                customerPayment={customerPayment}
                                deletePayment={this.deletePayment} />)}
                    {!customerPayments.length ? (
                        <div className="t-card mb-2">
                            <div className={"content card__content card--material__content"}>
                                <div className="text-center">
                                    <h5>
                                        {t("text_no_data_available")}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </DefaultScrollbar>
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

AdvancePayment.displayName = "AdvancePayment";

AdvancePayment.defaultProps = {
    header: true
};

const mapStateToProps = state => {
    return {
        dairyId: state.dairy.dairy.id,
        billingCycle: state.dairy.dairy.billing_cycle
    };
};

export default connect(mapStateToProps)(
    withRouter(withLanguage(AdvancePayment))
);