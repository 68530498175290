import React, { Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import CollectionSlider from "./home/CollectionSlider";
import GraphSlider from "./home/GraphSlider";
import DistributionSlider from "./home/DistributionSlider";
import DairyTopProducts from "./home/DairyTopProducts";
import { checkFeatureInSubscription } from "../../common/FeatureFlag/FeatureFlag";
import { getShiftType } from "../../library/util";

const HomePageSection = ({
  t,
  dairyId,
  customers,
  language,
  today_total,
  daily_total = {},
}) => {
  return (
    <>
      <div className="form-row mb-sm-3 border">
        <div className="col-12 col-sm-6 mb-2 mb-sm-0">
          <div className="t-card m-sm-0 h-100 shadow-sm">
            <div className="content card__content card--material__content clearfix h-100">
              <h3 className="my-0 d-flex justify-content-between align-items-center h-100 h4 ">
                <span>{moment().format("ddd DD MMM, YYYY ")}</span>
                <span>{t(`text_${getShiftType()}`)}</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-2 mb-sm-0">
          <div className="t-card m-sm-0 shadow-sm">
            <div className="content card__content card--material__content clearfix">
              <div className="form-row">
                <div className="col text-center">
                  <div className="hp-cst-count">
                    {
                      customers.filter(
                        (customer) =>
                          customer.status == 1 && customer.type === "seller"
                      ).length
                    }
                  </div>
                  <div>{t("text_active")}</div>
                  <div className="size-11">{t("text_seller")}</div>
                </div>
                <div className="col text-center">
                  <div className="hp-cst-count">
                    {
                      customers.filter(
                        (customer) =>
                          customer.status == 1 && customer.type === "buyer"
                      ).length
                    }
                  </div>
                  <div>{t("text_active")}</div>
                  <div className="size-11">{t("text_buyer")}</div>
                </div>
                <div className="col text-center">
                  <div className="hp-cst-count">
                    {
                      customers.filter((customer) => customer.status == 1)
                        .length
                    }
                  </div>
                  <div>{t("text_active")}</div>
                  <div className="size-11">{t("text_customers")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-12">
          <CollectionSlider t={t} today_total={today_total.collection} />
        </div>
      </div>
      <div className="form-row">
        <div className="col-12">
          <DistributionSlider t={t} today_total={today_total.distribution} />
        </div>
      </div>
      {checkFeatureInSubscription("product_management", false) ? (
        <div className="form-row mt-n2 mt-sm-0">
          <div className="col-12">
            <DairyTopProducts t={t} dairyId={dairyId} />
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    today_total: state.dairy.today_total,
    daily_total: state.dairy.daily_total,
  };
};

export default connect(mapStateToProps)(HomePageSection);
