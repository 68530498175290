// @ts-nocheck
// @ts-ignore
/* eslint-disable no-console */
import store from "../../../store";

const checkoutMobile = ({ orderId, amount, name, mobile, email, address, orderDetail }, successCallback, failedCallback) => {
    const state = store.getState();
    const razorPayConfig = state.defaultReducer.razorpay;
    const rzpOptions = {
        "key": razorPayConfig.api_key, // Enter the Key ID generated from the Dashboard
        "amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Liter",
        "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "prefill": {
            "name": name,
            "email": email,
            "contact": mobile,
        },
        "notes": {
            "address": address,
            "orderDetail": orderDetail
        }
    };
    const _successCallback = (response) => {
        console.log("razorpay _successCallback",  JSON.stringify(response));
        successCallback(response);
    };
    const _failedCallback = (response) => {
        console.log("razorpay _successCallback",  JSON.stringify(response));
    };
    // eslint-disable-next-line no-undef
    RazorpayCheckout.open(rzpOptions, _successCallback, _failedCallback);
};


const checkoutBrowser = ({ orderId, amount, name, mobile, email, address, orderDetail }, successCallback, failedCallback) => {
    const state = store.getState();
    const razorPayConfig = state.defaultReducer.razorpay;
    const options = {
        "key": razorPayConfig.api_key, // Enter the Key ID generated from the Dashboard
        "amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Liter",
        "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){
            successCallback(response);
        },
        "prefill": {
            "name": name,
            "email": email,
            "contact": mobile
        },
        "notes": {
            "address": address,
            "orderDetail": orderDetail
        }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response){
        console.log("response", response);
        failedCallback(response);
    });
    rzp1.open();
};

export default {
    checkout: (typeof cordova === "undefined") ? checkoutBrowser : checkoutMobile
};