import { useLanguage } from "../../../../language/LanguageController";
import { useSelector } from 'react-redux';
import { PrimarySwitch } from 'src/components/core/Switch';
import { FormHelperText } from '@material-ui/core';
import { useState } from "react";

const RateListCommission = ({ enabled, type, value}) => {
    const { t } = useLanguage();
    const [commissionEnabled, setCommissionEnabled] = useState(Boolean(enabled));

    const handleChange = () => {
        setCommissionEnabled(!commissionEnabled);
    }

    return (<div className="my-2 mb-3">
        <div className="form-row">
            <div className="col-12">
                <div className='form-group'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <label className='mb-0'>{t("text_commission_setting")}</label>
                            <FormHelperText>{t("text_message_commision_enabled_help")}</FormHelperText>
                        </div>
                        <div>
                            <PrimarySwitch
                                checked={commissionEnabled}
                                onChange={handleChange}
                                name="rate_commission[enabled]"
                                value={commissionEnabled ? 1 : 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {commissionEnabled && <div className="col col-lg-4">
                <div className="form-group mb-0">
                    <label htmlFor="commission_value">{t("text_commission_value")}</label>
                    <div className="input-group">
                        <input 
                            type="number"
                            className="form-control"
                            data-name="commission_value"
                            id="commission_value"
                            name="rate_commission[value]"
                            defaultValue={value}
                        />
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                    <FormHelperText>{t("text_commision_calculation_help")}</FormHelperText>
                </div>
            </div>}
        </div>
    </div>);
}

RateListCommission.defaultProps = {
    enabled: 0,
    type: "fixed",
    value: ""
}

export default RateListCommission;