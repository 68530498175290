import { copyObject } from "../library/util";
import React, { Component } from "react";

class InputText extends Component{
    render() {
        const { label, errorMessage, ...rest } = this.props;
        const errorClass = "";
        const restProps = copyObject(rest);
        delete restProps.errorMessage;
        return (
            <div className={"form-group " + errorClass}>
                <label htmlFor={rest.id} className="text-nowrap">{label}</label>
                <input {...rest} />
                {errorMessage}
            </div>
        );
    }
}

export default InputText;