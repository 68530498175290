import React, { Component } from 'react';
import { Switch, Checkbox } from 'react-onsenui';
import SimpleValidator from '../../../common/SimpleValidator';
import { ucFirst } from '../../../library/util';
import withLanguage from "../../../language/LanguageController";
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import { updateCustomer} from '../../../../actions/dairyCustomer';
import { togglePremiumPopup } from "../../../../actions";
import { fetchRateLists } from "../../../../actions/dairyRateList";
import { connect } from 'react-redux';
import analytics from '../../../library/firebase/analytics';
import { getCustomerCodeError } from '../../../library/customer';
import { withRouter } from 'react-router';
import Select from "react-select";
import { getSelectedRateList, mapRateListsToSelect } from '../../../helpers/rateListHelper';
import { DefaultLayout } from '../../common/Layout';
import SubscriptionLock from '../../../common/FeatureFlag/SubscriptionLock';
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerHelp: false,
      buyerHelp: false,
      ...this.getInitialState()
    }
    this.validator = new SimpleValidator();
  }

  getInitialState () {
    const dairyCustomerId = this.props.match.params.dairy_customer_id;
    const customer = this.props.customers.find(customer => customer.dairy_customer_id == dairyCustomerId);
    const customerIndex = this.props.customers.indexOf(customer);
    let milkAnimals = customer.milk_animals;
    let cowRate = (customer.rate) ? customer.rate.cow : false;
    let buffaloRate = (customer.rate) ? customer.rate.buffalo : false;

    if(!checkFeatureInSubscription("multiple_cattle", false)) {
      cowRate = false;
      buffaloRate = false;
    }

    return {
      personal_rate: (customer.rate && !customer.rate_list_id) ? true : false,
      rate: customer.rate,
      milk_animal_buffalo: milkAnimals.indexOf('buffalo') > -1 ? true : false,
      milk_animal_cow: milkAnimals.indexOf('cow') > -1 ? true : false,
      name: customer.name,
      code: customer.code,
      mobile: (customer.c_mobile) ? customer.c_mobile : customer.mobile,
      cow_rate: cowRate ? cowRate : '',
      buffalo_rate: buffaloRate ? buffaloRate : '',
      personal_rate_buffalo: buffaloRate ? true : false,
      personal_rate_cow: cowRate ? true : false,
      customer_index: customerIndex,
      rate_list_id: customer.rate_list_id,
      dairyCustomerId,
      customer
    }
  }

  componentDidMount () {
    analytics.setScreenName("EditCustomer");
    if(this.props.rateLists.length <= 0) {
      this.props.fetchRateLists(this.props.dairyId);
    }
  }

  handleInput = (e) => {
    let target = e.target,
        value = target.type === 'checkbox' ? target.checked : target.value,
        name = target.name,
        dName = target.getAttribute("data-name");
        name = dName ? dName : name;
    if(name === "milk_animals[]"){
      name = (target.value == "cow") ? "milk_animal_cow" : "milk_animal_buffalo";
    }
    if(["milk_animal_buffalo","milk_animal_cow"].indexOf(name) > -1) {
      if(!checkFeatureInSubscription("multiple_cattle", false) && value) {
          if((name === "milk_animal_buffalo" && this.state.milk_animal_cow === true) 
              || (name === "milk_animal_cow" && this.state.milk_animal_buffalo === true)) {
              target.checked = false;
              this.props.togglePremiumPopup({
                feature: "multiple_cattle"
              });
              return false;
          }
      }
    }
    this.setState({
        [name]: value
    });
  }

  personalRateChangeHandler = e => {
    analytics.logEvent("personalRateChangeHandler");
    const name = e.target.getAttribute('data-name');
    let checked = e.target.checked;
    if(!checkFeatureInSubscription("personal_rate")) {
      checked = false
    }
    this.setState({
      [name]: checked
    });
  }

  showHelp = (e, type) => {
    analytics.logEvent("showHelp");
    let stateData = {
        sellerHelp: false,
        buyerHelp: false
    };
    stateData[type + "Help"] = !this.state[type + "Help"];
    this.setState(stateData);
  }

  updateCustomer = event => {
    event.preventDefault();
    analytics.logEvent("updateCustomer");
    // validate form before submitting to server 
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate(); // rerender to show messages for the first time
      return false;
    }

    this.props.updateCustomer(this.props.dairyId, this.state.dairyCustomerId, this.state.customer_index);
  }

  handleRateListChange = (rateList) => {
    this.setState({
      rate_list_id: rateList ? rateList.value : "",
      personal_rate: !rateList?.value && this.state.rate ? true : false,
    });
  }
  
  render() {
    this.validator.purgeFields();
    const { t, rateLists } = this.props;
    const { personal_rate_buffalo, personal_rate_cow, customer, rate_list_id } = this.state;
    let milk_animals = customer.milk_animals.split(',');
    const filteredRateLists = rateLists.filter((rateLists, index) => index > 1);
    const rateListsForSelect = mapRateListsToSelect(filteredRateLists);
    const selectedRateList = getSelectedRateList(rateLists, rate_list_id);
    return (
      <DefaultLayout title={t("text_edit_customer")} back>
        <div className="col-12 col-sm-6 px-0 mx-auto">
          <div className="t-card shadow-sm">
            <form id="editCustomerForm" 
              onSubmit={this.updateCustomer} noValidate>       
              <div className="row">
                <div className="form-group">
                    <label className="col-md-12">{t("text_customer_is")} ?</label>
                    <div className="col-md-12">
                        <div className="form-check form-check-inline width130">
                          {ucFirst(t(`text_${customer.type}`))}
                        </div>
                    </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t("text_name")}</label>
                <input type="text"
                  className="form-control"
                  name="name"
                  defaultValue={customer.name}
                  onChange={this.handleInput}/>
                {this.validator.message('customer_name', this.state.name, 'required|alpha_num_space')}
              </div>
              <div className="form-group">
                <label htmlFor="customer_code">{t("text_code")}</label>
                <input type="number"
                  className="form-control"
                  name="code"
                  id="customer_code"
                  defaultValue={customer.code}
                  onChange={this.handleInput}/>
                {getCustomerCodeError(this.validator, this.state.code)}
              </div>
              <div className="form-group">
                <label>{t("mobile_number")}</label>
                <input type="number"
                  className="form-control"
                  name="mobile"
                  defaultValue = {(customer.c_mobile) ? customer.c_mobile : customer.mobile}
                  onChange={this.handleInput}/>
                {this.validator.message('mobile_number', this.state.mobile, 'numeric|size:10,string')}
              </div>
              {rateListsForSelect.length > 0 && <div className="form-group">
                <label>{t("text_rate_list")}</label>
                <Select
                  placeholder={t("text_select_rate_list") + "..."}
                  options={rateListsForSelect}
                  isSearchable={false}
                  isClearable
                  name="rate_list_id"
                  value={selectedRateList}
                  onChange={this.handleRateListChange}
                />
              </div>}
              <div className="form-group">
                <label className="">
                  {t("text_animals")}:
                </label>
                <div className="form-row position-relative">
                  {!checkFeatureInSubscription("multiple_cattle", false) && <SubscriptionLock/>}
                  <div className="col-md-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-check form-check-inline">
                              <Checkbox
                                modifier="material form-check-input"
                                name={"milk_animals[]"}
                                inputId={"milk_animal_cow"}
                                value="cow"
                                data-name="milk_animal_cow"
                                defaultChecked={milk_animals.indexOf("cow") > -1 ? true : false}
                                onChange={this.handleInput} />
                              <label 
                                htmlFor="milk_animal_cow"
                                className="form-check-label">{t("text_cow")}</label>
                            </div>
                        </div>
                        {this.state.milk_animal_cow && <div className="col-6">
                          <div className="personal_rate">
                            {t("text_personal_rate")} &nbsp;
                            <Switch
                              material="material"
                              name="personal_rate_cow"
                              value="1"
                              data-name={"personal_rate_cow"}
                              checked={personal_rate_cow}
                              onChange={this.personalRateChangeHandler}
                            />
                          </div>
                        </div>}
                    </div>
                    {this.state.milk_animal_cow && this.state.personal_rate_cow ? (
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group mt-1 mb-0">
                                  <input 
                                      className="form-control add_customer_input" 
                                      type="number"
                                      name={"rate[cow]"}
                                      data-name="cow_rate"
                                      step={"0.01"}
                                      placeholder={t("text_enter_cow_personal_rate")}
                                      defaultValue={this.state.cow_rate}
                                      onChange={this.handleInput} />
                                  {this.validator.message('cow_rate', this.state.cow_rate, 'required|min:1,num')}
                              </div>
                          </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt-2">
                      <div className="row">
                        <div className="col-6">
                            <div className="form-check form-check-inline">
                              <Checkbox
                                modifier="material form-check-input"
                                name={"milk_animals[]"} 
                                inputId={"milk_animal_buffalo"}
                                value="buffalo"
                                data-name="milk_animal_buffalo"
                                defaultChecked={milk_animals.indexOf("buffalo") > -1 ? true : false}
                                onChange={this.handleInput} />
                              <label 
                                htmlFor="milk_animal_buffalo"
                                className="form-check-label">{t("text_buffalo")}</label>
                            </div>
                        </div>
                        {this.state.milk_animal_buffalo && <div className="col-6">
                          <div className="personal_rate">
                            {t("text_personal_rate")} &nbsp;
                            <Switch
                              material="material"
                              name="personal_rate_buffalo"
                              value="1"
                              data-name={"personal_rate_buffalo"}
                              checked={personal_rate_buffalo}
                              onChange={this.personalRateChangeHandler}
                            />
                          </div>
                        </div>}
                      </div>
                      {this.state.milk_animal_buffalo && this.state.personal_rate_buffalo ? (
                          <div className="row">
                              <div className="col-12">
                                  <div className="form-group mt-1 mb-0">
                                      <input 
                                          className="form-control add_customer_input" 
                                          type="number"
                                          name={"rate[buffalo]"}
                                          data-name="buffalo_rate"
                                          step={"0.01"}
                                          placeholder={t("text_enter_buffalo_personal_rate")}
                                          defaultValue={this.state.buffalo_rate}
                                          onChange={this.handleInput} />
                                      {this.validator.message('buffalo_rate', this.state.buffalo_rate, 'required|min:1,num')}
                                  </div>
                              </div>
                          </div>
                      ) : null}
                  </div>
                  <div className="col-md-12">
                    {this.validator.message('milk_animal', (this.state.milk_animal_buffalo || this.state.milk_animal_cow) ? 'true' : '', 'required')}
                  </div>
                </div>
              </div>
              <div className="modal-footer pb-0 px-4">
                <button
                  type="submit"
                  className="btn btn-success btn-block">
                  {t("text_update")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    dairyId: state.dairy.dairy.id,
    customers: selectDairyCustomers(state),
    rateLists: state.dairyRateList.rateLists
  }
};

const mapDispatchToProps = {
  togglePremiumPopup,
  updateCustomer,
  fetchRateLists
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withLanguage(EditCustomer))
);