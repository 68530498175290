export const styles = theme => {
    return {
        root: {
            flexGrow: 1,
        },
        durationContainer: {
            paddingBottom: "94px"
        },
        colorWhite: {
            color: "#fff"
        },
        featureList: {
            maxHeight: "calc(100vh - 460px)",
            overflow: "auto",
            padding: "0",
        },
        featureListItem: {
            paddingTop: "0",
            paddingBottom: "0"
        },
        policyText: {
            fontSize: "0.60rem",
            color: "rgb(0 0 0 / 69%)"
        },
        planName: {
            fontWeight: "600!important"
        }
    };
};