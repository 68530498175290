// @ts-nocheck
import { checkIsAdFree } from "../common/FeatureFlag/FeatureFlag";
import config from "../../config";

export const showBannerAd = async () => {
    if(!config.ads) {
        return false;
    }
    try {
        if(!checkIsAdFree()) {
            console.log("show banner add");
            await window.admob.createBannerView({
                adSize: window.admob.AD_SIZE.SMART_BANNER
            });
        }
    } catch (err) {
        console.log('Error creating banner:', err);
    }
};

export const removeBannerAd = async () => {
    if(!config.ads) {
        return false;
    }
    try {
        await window.admob.destroyBannerView();
    } catch (err) {
        console.log('Error destroying banner:', err);
    }
};

export const showInterstitialAd = async () => {
    if(!config.ads) {
        return false;
    }
    try {
        if(!checkIsAdFree()) {
            await window.admob.requestInterstitialAd();
        }
    } catch (err) {
        console.log('Error requesting interstitial ad:', err);
    }
};

export const showRewardedAd = async (checkSubscription) => {
    if(!config.ads) {
        return false;
    }
    try {
        if(!checkSubscription || !checkIsAdFree()) {
            await window.admob.requestRewardedAd();
        }
    } catch (err) {
        console.log('Error showing/hiding interstitial ad:', err);
    }
};