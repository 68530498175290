import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { ProgressCircular } from "react-onsenui";
import withLanguage from "../language/LanguageController";
import PropTypes from "prop-types";

const InitialLoader = ({ t }) => {
    return (
        <Grid container className="initial_loader_container">
            <Box className="text-center">
                <div>
                    <ProgressCircular
                        className="page_loader"
                        indeterminate />
                </div>
                <div className="mt-2">
                    {t("text_loading")}
                </div>
            </Box>
        </Grid>
    );
};

InitialLoader.propTypes = {
    t: PropTypes.func.isRequired
};

export default withLanguage(InitialLoader);
