import React from "react";
import withLanguage from '../../../../language/LanguageController';
import ProductForm from "../ProductForm";
import Product from "../Product";
import { DefaultLayout } from '../../../common/Layout';
import { TabletView, useMobile } from "../../../../library/detect-device";
import { usePermission } from "../../../../common/AccessPermission";
import { ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD } from "../../../../../_constants/permissions";

const ProductSales = ({ t }) => {
    const isMobile = useMobile();
    const { hasAccess } = usePermission();
    const saleAddAccess = hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD);
    return (
        <DefaultLayout
            title={t("text_customers_products")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                {saleAddAccess && <TabletView>
                    <div className="col-12 col-md-5 col-lg-4">
                        <ProductForm
                            header={false}
                            bottomGutter={false} />
                    </div>
                </TabletView>}
                <div className={saleAddAccess ? "col-12 col-md-7 col-lg-8" : "col-12"}>
                    <Product
                        header={false}
                        bottomGutter={isMobile} />
                </div>
            </div>
        </DefaultLayout>
    )
};

ProductSales.displayName = "ProductSales";

export default withLanguage(ProductSales);