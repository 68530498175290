import moment from "moment";
import { executeQuery } from "../database/queryExecutor";

export const saveSettingMobile = async (data) => {
    const { key, value, type = "string" } = data;
    try {
        console.log("saveSetting key: ", key);
        const query = "SELECT `key`, `value`, `type`, `createdAt`, `updatedAt` FROM `AppSetting` WHERE `AppSetting`.`key` = ?";
        const { rows } = await executeQuery(query, [key]);
        console.log("saveSetting rows: ", rows);
        if(rows.length > 0) {
            const encodedValue = encodeValue(value, type);
            let query = `UPDATE AppSetting SET value = ? WHERE \`key\` = ?`;
            console.log("saveSetting query: ", query);
            console.log("saveSetting encodedValue: ", encodedValue);

            const insertResult = await executeQuery(query, [encodedValue, key]);
            console.log("saveSetting updateResult: ", insertResult);

        } else {
            const query = `INSERT INTO AppSetting (
                key,
                value,
                type,
                createdAt,
                updatedAt
            ) VALUES (?,?,?,?,?);`;
            const insertData = [
                key,
                encodeValue(value, type),
                type,
                moment(new Date()).toISOString(),
                moment(new Date()).toISOString()
            ];
            console.log("saveSetting insertData", insertData);
            const insertResult = await executeQuery(query, insertData);
            console.log("saveSetting insertResult: ", insertResult);
        }
    } catch(error) {
        console.log("saveSetting Error: ", error);
    }
}

export const getSettingMobile = async (key) => {
    try {
        const query = `SELECT * FROM AppSetting  WHERE \`key\` = ?;`;
        console.log("getSettingMobile query: ", query);
        const { rows } = await executeQuery(query, [key]);
        console.log("getSettingMobile rows: ", rows);
        if(rows.length) {
            const data = rows.item(0);
            console.log("data", data);
            return decodeValue(data.value, data.type);
        }
    } catch (error) {
        console.log("getSettingMobile Error: ", error);
    }

    return {};
}

const valueEncoders = {
    string: (value) => value,
    json: (value) => JSON.stringify(value),
  };
  
const valueDecoders = {
    string: (value) => value,
    json: (value) => (value ? JSON.parse(value) : value),
};
  
const encodeValue = (value, type) =>
    valueEncoders[type](value);
const decodeValue = (value, type) => valueDecoders[type](value);