import React from "react";
import withLanguage from '../../../language/LanguageController';
import DairyLoanDashboard from "./DairyLoanDashboard";
import CustomerLoanHistory from "./CustomerLoanHistory";
import SanctionLoan from "./SanctionLoan";
import { DefaultLayout } from "../../common/Layout";
import { usePermission } from "../../../common/AccessPermission";
import { ABILITY_LOAN, PERMISSION_ADD  } from "../../../../_constants/permissions";

const LoanManagement = ({ t }) => {
    const { hasAccess } = usePermission();
    const loadAddAccess = hasAccess(ABILITY_LOAN, PERMISSION_ADD);
    return (
        <DefaultLayout
            title={t("text_loan_managment")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                {loadAddAccess && <div className="col-md-4">
                    <SanctionLoan
                        header={false}
                        bottomGutter={false}
                        innerNav={false}/>
                </div>}
                <div className={loadAddAccess ? "col-md-4" : "col-md-6"}>
                    <DairyLoanDashboard
                        header={false}
                        bottomGutter={false}
                        innerNav={false} />
                </div>
                <div className={loadAddAccess ? "col-md-4" : "col-md-6"}>
                    <CustomerLoanHistory
                        header={false}
                        bottomGutter={false}
                        innerNav={false} />
                </div>
            </div>
        </DefaultLayout>
    )
};

LoanManagement.displayName = "LoanManagement";

export default withLanguage(LoanManagement);