import React from 'react';
import moment from "moment";
import { loader } from "../../../../library/util";
import { errorHandler } from '../../../../library/response';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';

class CustomerAccountController extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            milk_payment_btn: false,
            milk_payment_modal: false,
            loan_payment_btn: false,
            loan_payment_modal: false,
            loan_sanction_modal: false,
            loan_sanction_btn: false,
            product_modal: false,
            advance_payment_modal: false,
            milk_total_modal: false,
            filter_modal: false,
            records: [],
            total: {
                "milk": "0.00",
                "amount": "0.00",
                "purchase": "0.00",
                "payable": "0.00"
            },
            loan_detail: {},
            customer_type: "seller",
            ...getBillingStartEndDate(props.billingCycle, moment())
        };
    }

    componentDidMount() {
        analytics.setScreenName("CustomerCustomerAccount");
        this.getData();
    }

    async getData () {
        try {
            loader.show(this.props.t("text_loading"));
            let { startDate, endDate } = this.state;
            let { dairyId, dairyCustomerId } = this.props;
            let filterStr = encodeURI(`start_date=${startDate.format('DD-MM-YYYY')}&end_date=${endDate.format('DD-MM-YYYY')}`);
            const { status, data, message, error_code } = await request.get('/dairies/' + dairyId + `/bills/${dairyCustomerId}?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                let payableAmount = parseFloat(data.payable_amount || 0),
                    paidAmount = parseFloat(data.paid_amount || 0),
                    remainingAmount = parseFloat(data.remaining || 0),
                    amountToPay = 0,
                    amountToReceive = 0;

                if(remainingAmount < 0 && payableAmount > 0 && payableAmount !== paidAmount) {
                    amountToPay = payableAmount - paidAmount;
                } else if(remainingAmount > 0) {
                    amountToPay = remainingAmount;
                }
                
                if(remainingAmount < 0) {
                    amountToReceive = remainingAmount;
                }
                
                this.setState({
                    ...data,
                    amount_to_pay: amountToPay,
                    amount_to_receive: amountToReceive
                });
            } else if(error_code != 501) {
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getData();
        });
    }

    toggleProductModal = () => {
        if(this.state.isMobile) {
            this.setState({
                product_modal: !this.state.product_modal
            });
        }
    }

    toggleAdvancePaymentModal = () => {
        if(this.state.isMobile) {
            this.setState({
                advance_payment_modal: !this.state.advance_payment_modal
            });
        }
    }

    toggleMilkTotalModal = () => {
        if(this.state.isMobile) {
            this.setState({
                milk_total_modal: !this.state.milk_total_modal
            });
        }
    }

    onDeviceChange = deviceDetail => {
        this.setState({ ...deviceDetail });
    }
}

export default CustomerAccountController;
