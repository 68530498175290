// Sidebar Menu
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const TOGGLE_SIDE_BAR_CLOSE = "TOGGLE_SIDE_BAR_CLOSE";

// Dairy ratelist action
export const DAIRY_RATE_LIST_ADD = "DAIRY_RATE_LIST_ADD";
export const DAIRY_RATE_LIST_ADD_SUCCESS = "DAIRY_RATE_LIST_ADD_SUCCESS";
export const DAIRY_RATE_LIST_ADD_ERROR = "DAIRY_RATE_LIST_ADD_ERROR";

export const DAIRY_RATE_LIST_UPDATE = "DAIRY_RATE_LIST_UPDATE";
export const DAIRY_RATE_LIST_UPDATE_SUCCESS = "DAIRY_RATE_LIST_UPDATE_SUCCESS";
export const DAIRY_RATE_LIST_UPDATE_ERROR = "DAIRY_RATE_LIST_UPDATE_ERROR";

export const DAIRY_RATE_LIST_DELETE = "DAIRY_RATE_LIST_DELETE";
export const DAIRY_RATE_LIST_DELETE_SUCCESS = "DAIRY_RATE_LIST_DELETE_SUCCESS";
export const DAIRY_RATE_LIST_DELETE_ERROR = "DAIRY_RATE_LIST_DELETE_ERROR";

export const DAIRY_RATE_LISTS_FETCH_REQUEST ="DAIRY_RATE_LISTS_FETCH_REQUEST";
export const DAIRY_RATE_LISTS_FETCH_ERROR ="DAIRY_RATE_LISTS_FETCH_ERROR";
export const DAIRY_RATE_LISTS_FETCH_SUCCESS ="DAIRY_RATE_LISTS_FETCH_SUCCESS";

export const DAIRY_RATE_LISTS_RESET = "DAIRY_RATE_LISTS_RESET";
export const DAIRY_RATE_LIST_ADDED_RESET = "DAIRY_RATE_LIST_ADDED_RESET";
export const DAIRY_RATE_LIST_DELETE_RESET = "DAIRY_RATE_LIST_DELETE_RESET";

// Offers Action Types
export const FETCH_OFFERS_REQUEST = "FETCH_OFFERS_REQUEST";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_FAILURE = "FETCH_OFFERS_FAILURE";

// Gestation Action Types
export const FETCH_GESTATIONS_REQUEST = "FETCH_GESTATIONS_REQUEST";
export const FETCH_GESTATIONS_SUCCESS = "FETCH_GESTATIONS_SUCCESS";
export const FETCH_GESTATIONS_FAILURE = "FETCH_GESTATIONS_FAILURE";
export const POST_GESTATIONS_REQUEST = "POST_GESTATIONS_REQUEST";
export const ADD_GESTATIONS_SUCCESS = "ADD_GESTATIONS_SUCCESS";
export const UPDATE_GESTATIONS_SUCCESS = "UPDATE_GESTATIONS_SUCCESS";
export const POST_GESTATIONS_FAILURE = "POST_GESTATIONS_FAILURE";
export const DELETE_GESTATIONS_REQUEST = "DELETE_GESTATIONS_REQUEST";
export const DELETE_GESTATIONS_SUCCESS = "DELETE_GESTATIONS_SUCCESS";
export const DELETE_GESTATIONS_FAILURE = "DELETE_GESTATIONS_FAILURE";
export const CONFIRM_GESTATIONS_REQUEST = "CONFIRM_GESTATIONS_REQUEST";
export const CONFIRM_GESTATIONS_SUCCESS = "CONFIRM_GESTATIONS_SUCCESS";
export const CONFIRM_GESTATIONS_FAILURE = "CONFIRM_GESTATIONS_FAILURE";