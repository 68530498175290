/**
 * Open a native contact picker and return promise that will resolve to a contact object
 * @returns Promise
 */
export const contactPicker = () => {
    return new Promise((resolve, reject) => {
        if(typeof navigator.contacts !== "undefined") {
            navigator.contacts.pickContact(
                resolve,
                error => {
                    if(error == 6 || error == 1100) {
                        resolve(null);
                    } else {
                        reject(new Error(error));
                    }
                }
            );
        } else {
            reject(new Error("Contact picker not defined!"));
        }
    });
};