import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withLanguage from "../../../../language/LanguageController";
import { Switch } from "react-onsenui";


const FatSNFRatioRateList = ({
    t,
    fatRate,
    setFatRate,
    validator,
    snfRate,
    setSNFRate,
    fatRateRatio,
    setFatRateRatio,
    isFatSnfRatioEnabled,
    setIsFatSnfRatioEnabled
}) => {
    
    return (
        <>
            <div className="form-row">
                <div className="col-6">
                    <div className={"form-group"}>
                        <label>{t("text_fat_rate")}</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                name="fat_rate[base_rate]"
                                value={fatRate}
                                onChange={e => {
                                    validator.purgeFields();
                                    setFatRate(e.target.value);
                                }}/>
                            <span className="input-group-append">
                                <span className="input-group-text">/ {t("text_kg")}</span>
                            </span>
                        </div>
                        {validator.message("fat_rate", fatRate, "required|numeric|min:1,num")}
                    </div>
                </div>
                <div className="col-6">
                    <div className={"form-group"}>
                        <label>{t("text_snf_rate")}</label>
                        <div className="input-group">
                            <input
                                type="number"
                                min="1"
                                className="form-control"
                                name="fat_rate[snf_base_rate]"
                                value={snfRate}
                                onChange={e => setSNFRate(e.target.value)}
                                onBlur={() => validator.showMessageFor("email")}/>
                            <span className="input-group-append">
                                <span className="input-group-text">/ {t("text_kg")}</span>
                            </span>
                        </div>
                        {validator.message("snf_rate", snfRate, "required|numeric|min:1,num")}
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-9">
                    <label>{isFatSnfRatioEnabled ? t("text_disable_fat_snf_ratio") : t("text_enable_fat_snf_ratio")}</label>
                </div>
                <div className="col-3 text-right">
                    <Switch
                        modifier="material"
                        checked={isFatSnfRatioEnabled}
                        onChange={e => {
                            validator.purgeFields();
                            setIsFatSnfRatioEnabled(e.target.checked);
                        }}/>
                    <input type="hidden" name="fat_rate[is_fat_snf_ratio]" value={isFatSnfRatioEnabled ? 1 : 0} readOnly/>
                </div>
            </div>
            {isFatSnfRatioEnabled ? (
                <div className="form-row">
                    <div className="col-6">
                        <div className={"form-group"}>
                            <label className="text-sm">{t("text_fat_rate_ratio")}</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="fat_rate[base_rate_ratio]"
                                    value={fatRateRatio}
                                    onChange={e => setFatRateRatio(e.target.value)}/>
                                <span className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </span>
                            </div>
                            {validator.message("fat_rate_ratio", fatRateRatio, "required|numeric|min:1,num|max:100,num")}
                        </div>
                    </div>                                    
                    <div className="col-6">
                        <div className={"form-group"}>
                            <label className="text-sm">{t("text_snf_rate_ratio")}</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    name="fat_rate[snf_base_rate_ratio]"
                                    readOnly
                                    value={100 - fatRateRatio}/>
                                <span className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </span>
                            </div>
                            {validator.message("snf_rate_ratio", (fatRateRatio ? 100 - fatRateRatio : ""), "required|numeric|min:1,num|max:100,num")}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

FatSNFRatioRateList.propTypes = {
    t: PropTypes.func.isRequired,
    fatRate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    setFatRate: PropTypes.func.isRequired,
    validator: PropTypes.object,
    snfRate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    setSNFRate: PropTypes.func.isRequired,
    fatRateRatio: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    setFatRateRatio: PropTypes.func,
    isFatSnfRatioEnabled: PropTypes.bool.isRequired,
    setIsFatSnfRatioEnabled: PropTypes.func.isRequired
};

export default withLanguage(FatSNFRatioRateList);