/* eslint-disable no-console */
/* eslint-disable no-undef */
import * as XLSX from "xlsx";

export const saveBlob2File = async (basePath, dir, fileName, blobData) => {
    try {
        // Resolve local file system before creating/getting download directory
        const baseDir = await getLocalFileSystem(basePath);
        // Create or Get download directory for save file
        const dirEntry = await getCreateDir(baseDir, dir);
        // Create empty file before writing data
        const fileEntry = await createFile(dirEntry, fileName);
        // write data to file
        await writeFile(fileEntry, blobData);
        return fileEntry;
    } catch (error) {
        throw error;
    }
};

const getLocalFileSystem = baseBath => {
    return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(baseBath, baseDir => {
            resolve(baseDir);
        }, error => {
            reject({
                type: "GET_LOCAL_FS",
                error: error
            });
            console.log("error", error);
        });
    });
}

const getCreateDir = (baseDir, dir) => {
    return new Promise((resolve, reject) => {
        baseDir.getDirectory(dir, { create: true }, (dirEntry) => {
            resolve(dirEntry);
        }, error => {
            reject({
                type: "GET_CREATE_DIR",
                error: error
            })
            console.log("error", error);
        });
    });
}

const createFile = (dirEntry, fileName) => {
    return new Promise((resolve, reject) => {
        dirEntry.getFile(fileName,  { 
                create: true, 
                exclusive: false 
            },  fileEntry => {
                resolve(fileEntry)
            }, error => {
                reject({
                    type: "GET_CREATE_FILE",
                    error: error
                })
                console.log("error", error);
            }
        );
    });
}

const writeFile = (fileEntry, fileData) => {
    return new Promise((resolve, reject) => {
        // Create a FileWriter object for our FileEntry
        fileEntry.createWriter(fileWriter => {
            fileWriter.onwriteend = () => {
                console.log('Successful file write...');
                resolve(true);
            };
            fileWriter.onerror = error => {
                reject({
                    type: "WRITE_FILE",
                    error: error
                });
                console.log('Failed file write: ', error);
            };

            fileWriter.write(fileData);
        });
    });
}

export const openFile = (fileEntry, mimeType) => {
    return new Promise((resolve, reject) => {
        let fileToOpen = fileEntry.toURL();
        console.log("fileToOpen", fileToOpen);
        cordova.plugins.fileOpener2.open(fileToOpen, mimeType, {
            error: error => {
                console.error(error);
                reject({
                    type: "OPEN_FILE",
                    error: error
                });
            },
            success: () => {
                resolve(true);
            }
        });
    });
}

export const readExcelFileToJSON = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? 'binary' : 'array',
                bookVBA: true,
            });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const header = sheetData[0];
            const data = [...sheetData.slice(1)];
            resolve({ header, data });
        };
        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    });
}