import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

export const usePageHead = (title) => {
    const defaultTitle = "Liter - Milk Collection and Distribution Software Powered By LoopSys Tech."
    const location = useLocation();
    const pageTitle = location.pathname === "/" ? defaultTitle : `${title} - Liter`;
    return (
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>
    );
}