import { useLanguage } from "src/components/language/LanguageController";

const ShiftSelector = ({ shift, onShiftChange }) => {
    const { t } = useLanguage();
    return (
        <div className="btn-group btn-group-sm" role="group" aria-label={t("text_shift_selection")}>
            <button
                className={`btn ${Number(shift) === 0 ? "bg-light-blue text-white active" : "btn-light"}`}
                type="button"
                onClick={() => onShiftChange(0)}>
                <i className="fa fa-sun"></i>
                &nbsp;{t("text_morning")}
            </button>
            <button
                className={`btn ${Number(shift) === 1 ? "bg-light-blue text-white active" : "btn-light"}`}
                type="button"
                onClick={() => onShiftChange(1)}>
                <i className="fa fa-moon"></i>
                &nbsp;{t("text_evening")}
            </button>
        </div>
    );
}

export default ShiftSelector;