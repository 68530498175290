// @ts-nocheck
/* eslint-disable react/prop-types */

import React, { useCallback } from "react";
import { Box, Grid, Paper, Typography, Button } from "@material-ui/core";
import withLanguage from "../../../language/LanguageController";
import { useHistory } from "react-router";

const CheckoutAddress = ({ t, classes, user }) => {
    const history = useHistory();
    const { address } = user;
    const editAddress = useCallback(() => {
        history.push("/account/address/edit");
    }, [history]);
    
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} >
                <Box className="mb-2">
                    <Paper className={classes.addressPaper}>
                        <Typography display="block" gutterBottom>{user.firstname} {user.lastname}</Typography>
                        <Typography noWrap variant="body2" display="block">
                            {address.address_1}
                        </Typography>
                        <Typography variant="body2" display="block" gutterBottom>
                            {address.city}, {address.state} - {address.postcode}
                        </Typography>
                        <Typography variant="body2" display="block" gutterBottom>
                            {user.telephone}
                        </Typography>
                        <Button
                            className={classes.addressButton}
                            variant="contained"
                            disableElevation
                            fullWidth
                            onClick={editAddress}>
                            {t("text_change_address")}
                        </Button>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
};


export default withLanguage(CheckoutAddress);