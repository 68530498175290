// @ts-nocheck
import ActionSheetElement from 'onsenui/esm/elements/ons-action-sheet';
import { ActionSheetAnimator, IOSActionSheetAnimator, MDActionSheetAnimator } from 'onsenui/esm/elements/ons-action-sheet/animator';
import AnimatorFactory from 'onsenui/esm/ons/internal/animator-factory';
import platform from 'onsenui/esm/ons/platform';
import util from 'onsenui/esm/ons/util';
import internal from 'onsenui/esm/ons/internal/index';
import { ActionSheet } from "react-onsenui";

class ExtendedAnimatorFactory extends AnimatorFactory {
    /**
   * @param {Object} options
   * @param {String} [options.animation] The animation name
   * @param {Object} [options.animationOptions] The animation options
   * @param {Object} defaultAnimator The default animator instance
   * @return {Object} An animator instance
   */
  newAnimator(options = {}, defaultAnimator) {

    let animator = null;

    if (options.animation instanceof this._baseClass) {
      return options.animation;
    }

    let Animator = null;

    if (typeof options.animation === 'string') {
      Animator = this._animators[options.animation];
    }

    if (!Animator && defaultAnimator) {
      animator = defaultAnimator;
    } else {
      Animator = Animator || this._animators[this._animation];

      const animationOpts = util.extend(
        {},
        this._animationOptions,
        options.animationOptions || {},
        internal.config.animationsDisabled ? {duration: 0, delay: 0} : {}
      );

      animator = (isConstructor(Animator)) ? new Animator(animationOpts) : Animator();

      if (typeof animator === 'function') {
        animator = new animator(animationOpts); // eslint-disable-line new-cap
      }
    }

    if (!(animator instanceof this._baseClass)) {
      util.throw(`"animator" is not an instance of ${this._baseClassName}`);
    }

    return animator;
  }
}

ActionSheetElement.prototype._updateAnimatorFactory = () => {
    const _animatorDict = {
        'default': () => platform.isAndroid() ? MDActionSheetAnimator : IOSActionSheetAnimator,
        'none': ActionSheetAnimator
    };
    return new ExtendedAnimatorFactory({
        animators: _animatorDict,
        baseClass: ActionSheetAnimator,
        baseClassName: 'ActionSheetAnimator',
        defaultAnimation: "default"
    });
}

const handler = {
    construct() {
      return handler
    }
}
  
const isConstructor = x => {
    try {
      return !!(new(new Proxy(x, handler))())
    } catch (e) {
      return false
    }
}

export default ActionSheet;