import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useMobile } from "src/components/library/detect-device";
import PropTypes from "prop-types";

const DefaultScrollbar = ({ isEnabled, ...props }) => {
    const isMobile = useMobile();
    const renderTrackVertical = ({ style, ...restProps }) => {
        const thumbStyle = {
            zIndex: 9
        };
        return (
            <div
                style={{ 
                    position: "absolute",
                    right: "0px",
                    bottom: "2px",
                    top: "2px",
                    borderRadius: "3px",
                    ...style,
                    ...thumbStyle
                }}
                {...restProps}/>
        );
    }

    if(isMobile || !isEnabled) {
        return props.children;
    }
    return (
        <Scrollbars
            renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
            renderTrackVertical={renderTrackVertical}
            {...props}>
            {props.children}
        </Scrollbars>
    );
};

DefaultScrollbar.displayName = "DefaultScrollbar";

DefaultScrollbar.defaultProps = {
    isEnabled: true
};

DefaultScrollbar.propTypes = {
    isEnabled: PropTypes.bool
}

export default DefaultScrollbar;