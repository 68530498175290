import React from "react";

const RateListConfirmationRateCell = ({ fat, fatData, CLR_SNF, onChange}) => {
    const CLR_SNF_data = fatData[CLR_SNF];
    return (
        <td>
            <input
                type="number"
                name={`rate[${fat}][${CLR_SNF}]`}
                value={CLR_SNF_data}
                onChange={(e) => onChange(e, fat, CLR_SNF)}
                className="w-65"
            />
        </td>
    );
};

export default RateListConfirmationRateCell;