// @ts-nocheck
import React, { useEffect } from "react";
import withLanguage from "../language/LanguageController";
import { Icon, Modal, Button } from "react-onsenui";
import { useState } from "react";
import { checkUpdate } from "../library/inAppUpdate";
import analytics from "../library/firebase/analytics";

const AppUpdateModal = ({t}) => {

    const [updateInfo, setUpdateInfo] = useState({
        update: false,
        force_update: false
    });

    useEffect(() => {
        if(typeof window.cordova !== "undefined") {
            (async() => {
                const updateData = await checkUpdate();
                if(updateData.update) {
                    analytics.logEvent("AppUpdateModalShown");
                }
                setUpdateInfo(updateData);
            })();
        }
    }, []);
    
    const openPlayStore = () => {
        analytics.logEvent("updateApp");
        window.cordova.plugins.market.open('in.liter.live', {
            success: function() {
                console.log("success");
            },
            error: function() {
                console.log("error");
            }
        });
    }

    const hidePopup = () => {
        analytics.logEvent("AppUpdateModalClose");
        setUpdateInfo({
            update: false,
            force_update: false
        });
    }

    return (
        <Modal isOpen={updateInfo.update} animation="fade">
            <div className="modal_content">
                <div className="modal_header">
                    <div className="modal_title text-center">
                        {t("text_title_update_modal")}
                    </div>
                    {!updateInfo.force_update ? (
                        <button type="button" className="modal_close" onClick={hidePopup}>
                            <Icon icon="md-close"/>
                        </button>
                    ) : null}
                </div>
                <div className="modal_body text-center">
                    {t("text_body_update_modal")}
                </div>
                <div className="modal_footer">
                    <Button
                        modifier="material-flat"
                        className="float-left"
                        onClick={openPlayStore}>
                            {t("text_upgrade")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default withLanguage(AppUpdateModal);