import React, {Component} from 'react';
import { Toolbar, BackButton, ToolbarButton, Icon } from 'react-onsenui';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withLanguage from '../../language/LanguageController';
import { socialSharing } from '../../library/util';
import analytics from '../../library/firebase/analytics';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toggleSideBar } from "../../../actions/";
import { Button } from '@material-ui/core';
import HomeIcon from "@material-ui/icons/Home"
import { Link } from 'react-router-dom';
import { isAndroid } from 'src/components/library/detect-device';

class InnerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      right_menu: null
    };
  }

  shareApp = () => {
    analytics.logEvent("shareApp");
    const message = `${this.props.t("liter_title")}\n\n${this.props.t("text_download_now")}:\nhttps://play.google.com/store/apps/details?id=in.liter.live`;
    socialSharing(message, "", "share_app");
  }

  toggleRightMenu = event => {
    event.preventDefault();
    analytics.logEvent("toggleRightMenu");
    return this.setState({
      right_menu: !this.state.right_menu ? event.currentTarget : null
    });
  };

  changePage = (event, NextPage) => {
    event.preventDefault();
    this.setState({
      right_menu: null
    }, () => {
      analytics.logEvent("changePage", {
        page: NextPage.name
      });
      this.props.navigator.resetPage({
        component: NextPage, 
        props:  {
          key: NextPage.name
        }
      }, {
        animation: "fade"
      });
    });
  }

  render() {   
    const { right_menu: rightMenu } = this.state; 
    const { t } = this.props;
    return (
      <div className="row">
        <div className={`inner_header shadow-sm clearfix ${this.props.className ? this.props.className : ''}`}>
          <Toolbar modifier="tk-toolbar">
            <div className='left'>
              {this.props.back ? <BackButton onClick={this.props.onBack}>Back</BackButton> : null}
              {(this.props.toolbar) ? (
                <>
                  <ToolbarButton onClick={this.props.toggleSideBar} className="d-lg-none">
                      <Icon icon='ion-navicon, material:md-menu'></Icon>
                  </ToolbarButton>
                  <Button size="large" className="text-white d-none d-lg-block mt-3 ml-3" component={Link} to="/">
                    <HomeIcon fontSize="large" />
                  </Button>
                </>
              ) : null}
            </div>
            <div className="center d-flex align-items-center overflow-auto">
              {(this.props.title) ? (
                  <h3 className="text-white h4 mb-0">
                    {this.props.title}
                  </h3>
              ) : null}
            </div>
            <div className='right'>
              {this.props.rightBar}
              <button className="app-share" aria-controls="simple-menu" aria-haspopup="true" onClick={this.toggleRightMenu}>
                <Icon icon='ion-more-vert, material:md-more-vert'></Icon>
              </button>
              <Menu
                id="header-right-menu"
                anchorEl={rightMenu}
                keepMounted
                open={Boolean(rightMenu)}
                onClose={this.toggleRightMenu}
              >
                {isAndroid() && (
                <MenuItem onClick={this.shareApp}>
                  <Icon icon='ion-share, material:md-share' className="fa fa-share"/>
                  &nbsp;&nbsp;{t("text_share")}
                </MenuItem>
                )}
                <MenuItem component={Link} to="/contact">
                  <Icon icon='ion-user-headset, material:md-user-headset' className="fa fa-headset"/>
                  &nbsp;&nbsp;{t("text_contact_us")}
                </MenuItem>
                <MenuItem component={Link} to="/help">
                  <Icon icon='ion-user-headset, material:md-user-headset' className="fa fa-life-ring"/>
                  &nbsp;&nbsp;{t("text_help")}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
          {this.props.children}
        </div>
      </div>
    );
  }
}

InnerHeader.defaultProps = {
  toolbar: true
};

const mapDispatchToProps = {
  toggleSideBar
}

export default connect(null, mapDispatchToProps)(withRouter(withLanguage(InnerHeader)));
