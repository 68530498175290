// @ts-nocheck
import moment from "moment";
import { executeQuery } from "../database/queryExecutor";

export const saveMilk = async (data) => {
    const {
        dairy_id,
        date,
        dairy_customer_id,
        cattle,
        shift,
        liter,
        fat,
        clr,
        snf,
        rate_type = 0,
        rate,
        total,
    } = data;
    const where = [ dairy_id, cattle, dairy_customer_id, date, shift ];

    try {
        const query = "SELECT * FROM MilkCollection WHERE dairy_id = ? AND cattle = ? AND dairy_customer_id = ? AND date = ? AND shift = ?";
        const { rows } = await executeQuery(query, where);
        console.log("saveMilk rows: ", rows);
        if(rows.length > 0) {
            const savedRecord = rows.item(0);
            console.log("savedRecord", savedRecord);
            const updateData = [
                liter,
                fat ?? "",
                clr,
                snf ?? "",
                rate_type,
                rate,
                total,
                0,
                0
            ];
            let query = `UPDATE MilkCollection SET 
                liter = ?,
                fat = ?,
                clr = ?,
                snf = ?,
                rate_type = ?,
                rate = ?,
                total = ?,
                isSyncedUp = ?,
                is_deleted = ?
            `;
            if (Number(savedRecord[`is_deleted`]) === 1) {
                updateData.push(moment(new Date()).toISOString());
                query += `, date_time = ?`;
            }

            query += ' WHERE id = ?';

            
            console.log("saveMilk updateData", updateData);
            console.log("saveMilk where", savedRecord.id);

            const insertResult = await executeQuery(query, [...updateData, savedRecord.id]);
            console.log("saveMilk updateResult: ", insertResult);

        } else {
            const query = `INSERT INTO MilkCollection (
                dairy_id,
                cattle,
                dairy_customer_id,
                date,
                shift,
                liter,
                fat,
                clr,
                snf,
                rate_type,
                rate,
                total,
                date_time,
                createdAt,
                updatedAt
            ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`;
            const insertData = [
                dairy_id,
                cattle,
                dairy_customer_id,
                date,
                shift,
                liter,
                fat ?? "",
                clr,
                snf ?? "",
                rate_type,
                rate,
                total,
                moment(new Date()).toISOString(),
                moment(new Date()).toISOString(),
                moment(new Date()).toISOString()
            ];
            console.log("saveMilk insertData", insertData);
            const insertResult = await executeQuery(query, insertData);
            console.log("saveMilk insertResult: ", insertResult);
        }

    } catch(error) {
        console.log("saveMilk Error: ", error);
    }
}

export const fetchQueueData = async ({ where = {}, sorting = ["date_time", "DESC"] }) => {
    try {
        const whereKeys = Object.keys(where);
        const whereValues = Object.values(where);
        console.log("fetchQueueData whereKeys: ", whereKeys);
        console.log("fetchQueueData whereValues: ", whereValues);
        const query = `SELECT * FROM MilkCollection WHERE ${generateWhereKeys(whereKeys)}`;
        console.log("fetchQueueData query: ", query);
        const result = await executeQuery(query, whereValues);
        console.log("fetchQueueData result: ", result);
        const records = [];
        for(let i = 0; i < result.rows.length; i++) {
            records.push(result.rows.item(i));
        }
        return records;
    } catch(error) {
        console.log("fetchQueueData Error: ", error);
    }

}

export const getCollectionTotal = async ({ where }) => {
    try {
        const whereKeys = Object.keys(where);
        const whereValues = Object.values(where);
        console.log("getCollectionTotal whereKeys: ", whereKeys);
        console.log("getCollectionTotal whereValues: ", whereValues);
        const query = `SELECT 
            ROUND(sum(IFNULL(liter, 0)), 2) AS total_milk,
            ROUND(sum(IFNULL(total, 0)), 2) AS total_amount,
            ROUND(SUM(IFNULL(total, 0)) / SUM(IFNULL(liter, 0)), 2) AS avg_rate,
            ROUND(SUM(IFNULL(liter, 0) * IFNULL(fat, 0)) / SUM(IFNULL(liter, 0)), 2) AS avg_fat,
            ROUND(SUM(IFNULL(liter, 0) * IFNULL(clr, 0)) / SUM(IFNULL(liter, 0)), 2) AS avg_clr,
            ROUND(SUM(IFNULL(liter, 0) * IFNULL(snf, 0)) / SUM(IFNULL(liter, 0)), 2) AS avg_snf
            FROM MilkCollection
            WHERE ${generateWhereKeys(whereKeys)} AND is_deleted = 0;
        `;
        console.log("getCollectionTotal query: ", query);
        const result = await executeQuery(query, whereValues);
        console.log("getCollectionTotal result: ", result);
        const data = result.rows.item(0);
        const count = await getTotalEntrie({ where });
        console.log("getCollectionTotal data: ", data);
        console.log("getCollectionTotal count: ", count);
        return {
            data,
            count
        };
    } catch(error) {
        console.log("getCollectionTotal Error: ", error);
    }
}

export const getTotalEntrie = async ({ where }) => {
    try {
        const whereKeys = Object.keys(where);
        const whereValues = Object.values(where);
        console.log("getTotalEntrie whereKeys: ", whereKeys);
        console.log("getTotalEntrie whereValues: ", whereValues);
        const query = `SELECT count(*) AS \`count\` FROM MilkCollection WHERE ${generateWhereKeys(whereKeys)} AND is_deleted = 0;`;
        console.log("getTotalEntrie query: ", query);
        const result = await executeQuery(query, whereValues);
        console.log("getTotalEntrie result: ", result);
        const count = result.rows.item(0)?.count ?? 0;
        console.log("getTotalEntrie count: ", count);
        return count;
    } catch(error) {
        console.log("getTotalEntrie Error: ", error);
    }
}

export const deleteMilkRecord = async ({ where }) => {    
    try {
        const whereKeys = Object.keys(where);
        const whereValues = Object.values(where);
        console.log("deleteMilkRecord whereKeys: ", whereKeys);
        console.log("deleteMilkRecord whereValues: ", whereValues);
        const query = `UPDATE MilkCollection SET is_deleted=?, isSyncedUp=?, updatedAt=? WHERE ${generateWhereKeys(whereKeys)}`;
        console.log("deleteMilkRecord query: ", query);
        const params = [
            1,
            0,
            moment(new Date()).toISOString(),
            ...whereValues
        ]
        const result = await executeQuery(query, params);
        console.log("deleteMilkRecord result: ", result);
    } catch(error) {
        console.log("deleteMilkRecord Error: ", error);
    }
}

const generateWhereKeys = (whereKeys) => {
    return whereKeys.length > 0 ? `${whereKeys.join(" = ? AND ")} = ?` : "";
}