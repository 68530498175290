import React from 'react';
import moment from "moment";
import * as Sentry from "@sentry/react";
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import config from "../../../config";
import Eventer from 'src/components/library/Eventer';

class DiaryController extends React.Component {

    constructor(props) {
        super(props);
        this.getTodayMilk = this.getTodayMilk.bind(this);
        this.getMilkTotal = this.getMilkTotal.bind(this);
        this.state = {
            add_milk_modal: false,
            filter_modal: false,
            records: [],
            total: {},
            today: [],
            ...this.getDefaultFilter()
        };
    }

    componentDidMount () {
        analytics.setScreenName("Diary");
        this.getTodayMilk();
        this.getMilkTotal();
        Eventer.on("milkAdded", this.fetchMilk);
        Eventer.on("milkDeleted", this.fetchMilk);
        this.props.onRef && this.props.onRef(this);
    }

    componentWillUnmount () {
        Eventer.off("milkAdded", this.fetchMilk);
        Eventer.off("milkDeleted", this.fetchMilk);
    }

    fetchMilk = () => {
        this.getTodayMilk();
        this.getMilkTotal();
    }

    getDefaultFilter(){
        return {
            customer_id: this.props.customerId,
            start_date: moment().startOf("month"),
            end_date: moment().endOf('month')
        }
    }

    async getTodayMilk () {
        try {
            const { status, data } = await request.get('/diary/' + this.props.customerId + '/today', {
                withAuth: true
            });
            if(status === true){
                this.setState({
                    today: data
                }); 
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    async getMilkTotal () {
        try {
            const { start_date, end_date } = this.state;
            const { customerId } = this.props;
            const filterStr = '?date=' + start_date.format(config.date_format) + ':' + end_date.format(config.date_format);
            const { status, data } = await request.get('/diary/' + customerId + '/total' + filterStr, {
                withAuth: true
            });
            if(status === true) {
                this.setState({
                    total: data
                }); 
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    pushPage = (e, NextPage, props) => {
        e.preventDefault();
        this.props.navigator.pushPage({
            component: NextPage, 
            props: props
        }, {
            animation: 'fade'
        });
    }

    dateChangeHandler = (name, selectedDate) => {
        this.setState({
            [name]: selectedDate
        }, () => this.getMilkTotal());
    }
}

export default DiaryController;
