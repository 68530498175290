// @ts-nocheck
import React from "react";
import MilkDetailTotalController from "./MilkDetailTotalController";
import withLanguage from "../../language/LanguageController";
import Slider from "react-slick";
import MuiDatepicker from "../../common/MuiDatepicker";
import moment from "moment";
import { connect } from "react-redux";
import { DefaultLayout } from "../common/Layout";
import { DefaultScrollbar } from "../common/Scrollbar";

class MilkDetailTotal extends MilkDetailTotalController {

    render() {
        const { records, total, start_date, end_date, isBrowser } = this.state;
        const { t, header, filter } = this.props;
        return (
            <DefaultLayout
                back
                bottomGutter={false}
                header={header}
                onDeviceChange={this.onDeviceChange}
                title={t("text_milk_detail")}
                toolbar={false}>
                    {filter ? <div className="mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                            </div>
                            <MuiDatepicker
                                value={start_date}
                                maxDate={end_date}
                                onChange={selectedDate => this.dateChangeHandler("start_date", selectedDate)}
                                name="start_date"
                                inputId="start_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                            <div className="input-group-prepend input-group-append">
                                <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                            </div>
                            <MuiDatepicker
                                value={end_date}
                                minDate={start_date}
                                maxDate={moment().endOf("month")}
                                onChange={selectedDate => this.dateChangeHandler("end_date", selectedDate)}
                                name="end_date"
                                inputId="end_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                        </div>
                    </div> : null}
                    <DefaultScrollbar
                        isEnabled={isBrowser}
                        className="my-milk-diary-milk-detail-total-scrollbar">
                        <div className="t-card shadow-sm mb-2">
                            <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
                                <div className="d-account-h1 float-left mt-0 mb-0 capitalize">
                                    {t("text_total")}
                                </div>
                            </div>
                            <div className={"content card__content card--material__content kisan-milk-detail-total"}>
                                <Slider {...this.settings}>
                                    <div key={1} className="table_container">
                                        <table className="table table-striped table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor: "#18a742"}}>{t("text_milk")}</th>
                                                    <th>{t("text_cow")}</th>
                                                    <th>{t("text_buffalo")}</th>
                                                    <th>{t("text_total")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{t("text_morning")}</td>
                                                    <td className="nowrap">{total.cow_morning.milk} {t("text_l")}</td>
                                                    <td className="nowrap">{total.buffalo_morning.milk} {t("text_l")}</td>
                                                    <td className="nowrap">{total.morning_total.milk} {t("text_l")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("text_evening")}</td>
                                                    <td className="nowrap">{total.cow_evening.milk} {t("text_l")}</td>
                                                    <td className="nowrap">{total.buffalo_evening.milk} {t("text_l")}</td>
                                                    <td className="nowrap">{total.evening_total.milk} {t("text_l")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("text_total")}</td>
                                                    <td className="nowrap">{total.cow_total.milk} {t("text_l")}</td>
                                                    <td className="nowrap">{total.buffalo_total.milk} {t("text_l")}</td>
                                                    <td className="nowrap">{total.total_milk} {t("text_l")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div key={2} className="table_container">
                                        <table className="table table-striped table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor: "#18a742"}}>{t("text_amount")}</th>
                                                    <th className="nowrap">{t("text_cow")}</th>
                                                    <th className="nowrap">{t("text_buffalo")}</th>
                                                    <th className="nowrap">{t("text_total")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{t("text_morning")}</td>
                                                    <td className="nowrap">{total.cow_morning.amount}/-</td>
                                                    <td className="nowrap">{total.buffalo_morning.amount}/-</td>
                                                    <td className="nowrap">{total.morning_total.amount}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("text_evening")}</td>
                                                    <td className="nowrap">{total.cow_evening.amount}/-</td>
                                                    <td className="nowrap">{total.buffalo_evening.amount}/-</td>
                                                    <td className="nowrap">{total.evening_total.amount}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("text_total")}</td>
                                                    <td className="nowrap">{total.cow_total.amount}/-</td>
                                                    <td className="nowrap">{total.buffalo_total.amount}/-</td>
                                                    <td className="nowrap">{total.total_amount}/-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div key={3} className="table_container">
                                        <table className="table table-striped table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{backgroundColor: "#18a742"}}>{t("text_rate")}</th>
                                                    <th className="nowrap">{t("text_cow")}</th>
                                                    <th className="nowrap">{t("text_buffalo")}</th>
                                                    <th className="nowrap">{t("text_total")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{t("text_morning")}</td>
                                                    <td className="nowrap">{total.cow_morning.rate}/{t("text_l")}</td>
                                                    <td className="nowrap">{total.buffalo_morning.rate}/{t("text_l")}</td>
                                                    <td className="nowrap">{total.morning_total.rate}/{t("text_l")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("text_evening")}</td>
                                                    <td className="nowrap">{total.cow_evening.rate}/{t("text_l")}</td>
                                                    <td className="nowrap">{total.buffalo_evening.rate}/{t("text_l")}</td>
                                                    <td className="nowrap">{total.evening_total.rate}/{t("text_l")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("text_total")}</td>
                                                    <td className="nowrap">{total.cow_total.rate}/{t("text_l")}</td>
                                                    <td className="nowrap">{total.buffalo_total.rate}/{t("text_l")}</td>
                                                    <td className="nowrap">{total.total_rate}/{t("text_l")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        {records.map(record => {
                            const { detail } = record;
                            return (
                                <div className="t-card shadow-sm mb-2 kisan-milk-detail-total" key={record.date}>
                                    <div className="card__title card--material__title mb-0 mt-0">
                                        <div className="d-account-h1 mt-0 mb-1 capitalize">
                                        {record.date}
                                        </div>
                                    </div>
                                    <Slider {...this.settings}>
                                        <div key={1} className="table_container">
                                            <table className="table table-striped table-borderless mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{backgroundColor: "#18a742"}}>{t("text_milk")}</th>
                                                        <th>{t("text_cow")}</th>
                                                        <th>{t("text_buffalo")}</th>
                                                        <th>{t("text_total")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t("text_morning")}</td>
                                                        <td className="nowrap">{detail.cow ? detail.cow.liter_0 : 0} {t("text_l")}</td>
                                                        <td className="nowrap">{detail.buffalo ? detail.buffalo.liter_0 : 0} {t("text_l")}</td>
                                                        <td className="nowrap">{detail.total ? detail.total.liter_0 : 0} {t("text_l")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("text_evening")}</td>
                                                        <td className="nowrap">{detail.cow && detail.cow.liter_1} {t("text_l")}</td>
                                                        <td className="nowrap">{detail.buffalo ? detail.buffalo.liter_1 : 0} {t("text_l")}</td>
                                                        <td className="nowrap">{detail.total ? detail.total.liter_1 : 0} {t("text_l")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("text_total")}</td>
                                                        <td className="nowrap">{detail.cow ? detail.cow.liter : 0} {t("text_l")}</td>
                                                        <td className="nowrap">{detail.buffalo ? detail.buffalo.liter : 0} {t("text_l")}</td>
                                                        <td className="nowrap">{detail.total.liter} {t("text_l")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div key={2} className="table_container">
                                            <table className="table table-striped table-borderless mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{backgroundColor: "#18a742"}}>{t("text_amount")}</th>
                                                        <th>{t("text_cow")}</th>
                                                        <th>{t("text_buffalo")}</th>
                                                        <th>{t("text_total")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t("text_morning")}</td>
                                                        <td className="nowrap">{detail.cow ? detail.cow.total_0 : 0} /-</td>
                                                        <td className="nowrap">{detail.buffalo ? detail.buffalo.total_0 : 0} /-</td>
                                                        <td className="nowrap">{detail.total ? detail.total.total_0 : 0} /-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("text_evening")}</td>
                                                        <td className="nowrap">{detail.cow && detail.cow.total_1} /-</td>
                                                        <td className="nowrap">{detail.buffalo ? detail.buffalo.total_1 : 0} /-</td>
                                                        <td className="nowrap">{detail.total ? detail.total.total_1 : 0} /-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("text_total")}</td>
                                                        <td className="nowrap">{detail.cow ? detail.cow.total : 0} /-</td>
                                                        <td className="nowrap">{detail.buffalo ? detail.buffalo.total : 0} /-</td>
                                                        <td className="nowrap">{detail.total.amount} /-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Slider>
                                </div>
                            );
                        })}
                        {!records.length ? (
                            <div className="t-card mb-2">
                                <div className={"content card__content card--material__content"}>
                                    <h5 className="text-center">
                                        {t("text_no_data_available")}
                                    </h5>
                                </div>
                            </div>
                        ) : null}
                    </DefaultScrollbar>
            </DefaultLayout>
        );
    }
}

MilkDetailTotal.displayName = "MilkDetailTotal";

MilkDetailTotal.defaultProps = {
    header: true,
    filter: true
};

const mapStateToProps = state => ({
    customerId: state.userReducer.user.customer_id
});

const MilkDetailTotalComponent = connect(mapStateToProps)(withLanguage(MilkDetailTotal));

export default MilkDetailTotalComponent;