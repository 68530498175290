import React from "react";
import withLanguage from '../../../../language/LanguageController';
import DairyAccountPage from "../DairyAccount";
import DairyBuyersBill from "../DairyBuyersBill";
import DairySellersBill from "../DairySellersBill";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { usePermission } from "../../../../common/AccessPermission";
import { ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL } from "../../../../../_constants/permissions";

const ManageBill = ({ t }) => {
    const { hasAccess } = usePermission();
    const customerBillViewAccess = hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL);
    return (
        <DefaultLayout
            title={t("text_dairy_bill")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                <div className={customerBillViewAccess ? "col-md-3" : "col-4 mx-auto"}>
                    <DairyAccountPage
                        header={false}
                        bottomGutter={false} />
                </div>
                {customerBillViewAccess && <div className="col-md-9">
                    <div className="form-row">
                        <div className="col-md-6">
                            <DairySellersBill header={false} />
                        </div>
                        <div className="col-md-6">
                            <DairyBuyersBill header={false} />
                        </div>
                    </div>
                </div>}
            </div>
        </DefaultLayout>
    )
};

ManageBill.displayName = "ManageBill";

export default withLanguage(ManageBill);