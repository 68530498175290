import React from "react";
import moment from "moment";
import CustomerPaymentHistoryController from "./CustomerPaymentHistoryController";
import { connect } from "react-redux";
import withLanguage from "../../../../language/LanguageController";
import BillDatePicker from "../BillDatePicker";
import { currencyFormat } from "../../../../library/util";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { Typography } from "@material-ui/core";

class CustomerPaymentHistory extends CustomerPaymentHistoryController {

    render() {
        const { records } = this.state;
        const { t, header } = this.props;

        return (
            <DefaultLayout
                back
                bottomGutter={false}
                header={header}
                title={t("text_your_payment_statement")}
                toolbar={false}>
                <div className="t-card shadow-sm">
                    <div className="title right card__title card--material__title mt-0 mb-2">
                        <Typography variant="h5" component="h2" className="d-none d-lg-block mb-2">
                            {t("text_your_payment_statement")}
                        </Typography>
                        <BillDatePicker
                            billingCycle={"monthly"}
                            onChange={this.onDateChange}
                        />
                    </div>
                    <div className="content card__content card--material__content">
                        <div className="table-fix-header">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="pl-0">{t("text_date")}</th>
                                        <th className="min-width105">{t("text_paid_amount")}</th>
                                        <th>{t("text_remark")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map(record => {
                                        return (
                                            <tr key={record.id}>
                                                <td className="pl-0 nowrap">
                                                    <div className="weight-500 size-15">
                                                        {moment(record.date).format("MMM DD")}
                                                    </div>
                                                </td>
                                                <td className="text-nowrap">
                                                    <span className={"size-15 " + (record.mode == "paid" ? "text-green" : "text-red")}>
                                                        {currencyFormat(record.amount)}
                                                    </span>
                                                </td>
                                                <td className="text-nowrap">
                                                    <span className="size-15">
                                                        {record.remark}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {records.length <= 0 
                                        ? (
                                            <tr>
                                                <td colSpan={4} className="text-center" style={{background: "#eee"}}>
                                                    <div className="">{t("text_no_data_available")}</div>
                                                </td>
                                            </tr>
                                        ) 
                                        : null }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

CustomerPaymentHistory.displayName = "CustomerPaymentHistory";

CustomerPaymentHistory.defaultProps = {
    header: true
};

const mapStateToProps = state => ({
    dairyId: state.dairy.dairy.dairy_id,
    dairyCustomerId: state.dairy.dairy.dairy_customer_id,
    dairyType: state.dairy.dairy.type,
    billingCycle: state.dairy.dairy.billing_cycle
});
  
export default connect(mapStateToProps)(withLanguage(CustomerPaymentHistory));