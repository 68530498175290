import { t } from "../components/language/LanguageController";

export const formatCustomersWithCattle = (customers, customer) => {
    const cattles = customer.milk_animals.split(",");
    cattles.forEach((cattle) => {
        customers.push({
            ...customer,
            cattles: cattles,
            label: customer.label + " - " + t(`text_${cattle}`),
            value: customer.dairy_customer_id + "-" + cattle,
            cattle
        });
    });

    return customers;
}

export const filterSelectedCustomers = (collectionQueue) => customer => {
    return !collectionQueue.find(queueItem => 
        customer.cattle === queueItem.cattle && Number(customer.dairy_customer_id) === Number(queueItem.customer?.dairy_customer_id)
    );
}