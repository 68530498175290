// @ts-nocheck
/* eslint-disable no-console */
import { toast, loader } from "../components/library/util";
import {
    DAIRY_SELECTED_TOGGLE,
    FETCH_DAIRY_REQUEST,
    FETCH_DAIRY_FAILURE,
    FETCH_DAIRY_PAYMENT_SUCCESS,
    FETCH_DAIRY_PAYMENT_FAILED,
    FETCH_DAIRY_SUCCESS,
    FETCH_DAIRIES_SUCCESS,
    UPDATE_DAIRY,
    FETCH_DAIRY_TODAY_TOTAL_SUCCESS,
    FETCH_KISAN_DAIRY_REQUEST,
    FETCH_KISAN_DAIRY_SUCCESS,
    FETCH_KISAN_DAIRY_FAILURE,
    SELECT_DAIRY,
    FETCH_DAIRY_DAILY_TOTAL_SUCCESS
} from "../_constants";
import { t } from "../components/language/LanguageController";
import { errorHandler } from "../components/library/response";
import request from "../components/library/request";
import * as Sentry from "@sentry/react";

export const fetchDairiesSuccess = data => ({
    type: FETCH_DAIRIES_SUCCESS,
    data: data
});

export const fetchDairyTodayTotalSuccess = data => ({
    type: FETCH_DAIRY_TODAY_TOTAL_SUCCESS,
    data: data
});
export const fetchDairyDailyTotalSuccess = data => ({
    type: FETCH_DAIRY_DAILY_TOTAL_SUCCESS,
    data: data
});

export const fetchDairyPaymentSuccess = data => ({
    type: FETCH_DAIRY_PAYMENT_SUCCESS,
    data: data
});

export const fetchDairyPaymentFailed = _ => ({
    type: FETCH_DAIRY_PAYMENT_FAILED,
    data: {}
})

export const updateDairy = data => ({
    type: UPDATE_DAIRY,
    data: data
});

export const dairySelectedToggle = (toggleState) => ({
    type: DAIRY_SELECTED_TOGGLE,
    data: toggleState
});

export const addDairy = async (dispatch, customerId) => {
    try {
        loader.show(t("text_saving"));
        let formData = new FormData(document.getElementById("AddDairyForm"));
        formData.append("customer_id", customerId);
        formData.append("dairy_status", 1);
        formData.append("type", 1);

        const { status, data, message } = await request.post('/dairies', {
            withAuth: true,
            body: formData
        });

        if (status === true) {
            dispatch(fetchDairySuccess(data));
            toast(message);
        } else {
            throw message;
        }
    } catch (error) {
        errorHandler(error)
    } finally {
        loader.hide();
    }
};

export const fetchDairyRequest = () => ({
    type: FETCH_DAIRY_REQUEST
});

export const fetchDairySuccess = data => ({
    type: FETCH_DAIRY_SUCCESS,
    data: data
});

export const fetchDairyError = (error) => ({
    type: FETCH_DAIRY_FAILURE,
    payload: error
});

export const selectDairy = (payload) => ({
    type: SELECT_DAIRY,
    payload
});

export const fetchDairies = async (dispatch) => {
    try {
        dispatch(fetchDairyRequest());
        const { status, data } = await request.get("/dairies", {
            withAuth: true,
            version: "v2"
        });
        if (status === true) {
            dispatch(fetchDairiesSuccess(data));
        }
    } catch (error) {
        dispatch(fetchDairyError(error));
    }
};

export const fetchKisanDairyRequest = () => ({
    type: FETCH_KISAN_DAIRY_REQUEST
});

export const fetchKisanDairySuccess = data => ({
    type: FETCH_KISAN_DAIRY_SUCCESS,
    data: data
});

export const fetchKisanDairyError = () => ({
    type: FETCH_KISAN_DAIRY_FAILURE
});

export const fetchKisanDairyByDairyId = async (dispatch, customerId, dairyId) => {
    try {
        dispatch(fetchKisanDairyRequest())
        const { status, data } = await request.get(`/customers/${customerId}/dairies/${dairyId}`, {
            withAuth: true,
            version: "v2"
        });
        if (status === true) {
            dispatch(fetchKisanDairySuccess(data))
        }
    } catch (error) {
        dispatch(fetchKisanDairyError());
    }
};

export const fetchDairyTodayTotal = dairyId => {
    return async dispatch => {
        try {
            const { status, data } = await request.get("/dairies/" + dairyId + "/todayTotal", {
                withAuth: true,
                version: "v2"
            });
            if (status === true) {
                dispatch(fetchDairyTodayTotalSuccess(data));
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }
};
export const fetchDairyDailyTotal = dairyId => {
    return async dispatch => {
        try {
            const { status, data } = await request.get("/dairies/" + dairyId + "/dailyTotal", {
                withAuth: true,
                version: "v2"
            });
            if (status === true) {
                dispatch(fetchDairyDailyTotalSuccess(data));
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }
};

export const fetchDairyPayment = (customerId) => {
    return async dispatch => {
        try {
            const { status, data } = await request.get("/dairies/" + customerId + "/payment", {
                withAuth: true
            });
            if(status === true){
                dispatch(fetchDairyPaymentSuccess(data));
            } else {
                dispatch(fetchDairyPaymentFailed(data));
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };
}

export const renewSubscription = (e, customerId) => {
    return async dispatch => {
        try {
            const { status, data, message } = await request.post("/dairies/" + customerId + "/payment");
            if(status === true){
                dispatch(fetchDairyPaymentSuccess(data));
            } else {
                dispatch(fetchDairyPaymentFailed(data));
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        }
    };
}