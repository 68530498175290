export const getLanguages = () => {
    return [
        {
            name: "Hindi",
            display_name: "हिंदी",
            code: "hi",
            enabled: true
        }, {
            name: "English",
            display_name: "English",
            code: "en",
            enabled: true
        }, {
            name: "Punjabi",
            display_name: "ਪੰਜਾਬੀ",
            code: "pa",
            enabled: true
        }, {
            name: "Gujarati",
            display_name: "ગુજરાતી",
            code: "gu",
            enabled: true
        }, {
            name: "Marathi",
            display_name: "मराठी",
            code: "mr",
            enabled: true
        }, {
            name: "Bengali",
            display_name: "বাংলা",
            code: "bn",
            enabled: true
        }, {
            name: "Oriya",
            display_name: "ଓଡିଆ",
            code: "or",
            enabled: true
        }, {
            name: "Kannada",
            display_name: "ಕನ್ನಡ",
            code: "kn",
            enabled: true
        }, {
            name: "Telugu",
            display_name: "తెలుగు",
            code: "te",
            enabled: true
        }
    ];
};

// , {
//     name: "Malayalam",
//     display_name: "മലയാളം",
//     code: "ml",
//     enabled: false
// }, {
//     name: "Tamil",
//     display_name: "தமிழ்",
//     code: "ta",
//     enabled: false
// }