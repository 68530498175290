import { AUTOMATIC_COLLECTION_SETTINGS } from '../../_constants';
import { getSetting, saveSetting } from '../../offline-storage/app/app-setting';
import { toast } from 'src/components/library/util';
import { useLanguage } from '../../components/language/LanguageController';
import * as hardwareApis from 'src/auto-collection/hardware-apis';
const { useState, useCallback, useEffect } = require("react");

const useAutomaticSetting = () => {
    const { t } = useLanguage();
    const [weightAnalyserPath, setWeightAnalyserPath] = useState("");
    const [milkAnalyserPath, setMilkAnalyserPath] = useState("");
    const [weightAnalyserBaudRate, setWeightAnalyserBaudRate] = useState(9600);
    const [milkAnalyserBaudRate, setMilkAnalyserBaudRate] = useState(9600);
    const [connectedPorts, setConnectedPorts] = useState([]);
    const [isPortLoading, setIsPortLoading] = useState(false);
    const [weightStringDecimalFormat, setWeightStringDecimalFormat] = useState('1');
    const [fatConfig, setFatConfig] = useState('0');
    const [clrConfig, setClrConfig] = useState('2');
    const [snfConfig, setSnfConfig] = useState('1');
    const [tempConfig, setTempConfig] = useState('6');
    const [waterConfig, setWaterConfig] = useState('7');

    const handleFormSubmit = useCallback(async (event) => {
        event?.preventDefault();
        try {
            await saveSetting({
                key: AUTOMATIC_COLLECTION_SETTINGS.MACHINE_PORTS,
                value: {
                    weightAnalyserPath,
                    milkAnalyserPath,
                    weightAnalyserBaudRate,
                    milkAnalyserBaudRate,
                    fatConfig,
                    snfConfig,
                    clrConfig,
                    tempConfig,
                    waterConfig,
                    weightStringDecimalFormat
                },
                type: "json"
            });
            hardwareApis.updateMachineConfiguration()
            toast(t("text_setting_saved"));
        } catch (error) {
            console.log("error", error);
        }
    }, [weightAnalyserPath, milkAnalyserPath, weightAnalyserBaudRate, milkAnalyserBaudRate, fatConfig, snfConfig, clrConfig, tempConfig, waterConfig, weightStringDecimalFormat, t]);

    const fetchSettings = useCallback(async () => {
        const {
            weightAnalyserPath = "",
            milkAnalyserPath = "",
            weightAnalyserBaudRate = 9600,
            milkAnalyserBaudRate = 9600,
            fatConfig = "0",
            snfConfig = "1",
            clrConfig = "2",
            tempConfig = "6",
            waterConfig = "7",
            weightStringDecimalFormat = "1"

        } = await getSetting(AUTOMATIC_COLLECTION_SETTINGS.MACHINE_PORTS);

        setWeightAnalyserPath(weightAnalyserPath);
        setMilkAnalyserPath(milkAnalyserPath);
        setWeightAnalyserBaudRate(weightAnalyserBaudRate);
        setMilkAnalyserBaudRate(milkAnalyserBaudRate);
        setFatConfig(fatConfig);
        setClrConfig(clrConfig);
        setSnfConfig(snfConfig);
        setTempConfig(tempConfig);
        setWaterConfig(waterConfig);
        
    }, []);

    const reloadSerialDevices = async () => {
        setIsPortLoading(true);
        try {
            const ports = await hardwareApis.getSerialDevices();
            console.log("ports", ports);
            setConnectedPorts(ports);
        } catch(error) {
            console.error("Error in fetching devices", error);
        } finally {
            setIsPortLoading(false);
        }
    }

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    useEffect(() => {
        reloadSerialDevices();
    }, []);

    return {
        setWeightAnalyserPath,
        setMilkAnalyserPath,
        setWeightAnalyserBaudRate,
        setMilkAnalyserBaudRate,
        setWeightStringDecimalFormat,
        setFatConfig,
        setSnfConfig,
        setClrConfig,
        setTempConfig,
        setWaterConfig,
        reloadSerialDevices,
        handleFormSubmit,
        weightAnalyserPath,
        weightAnalyserBaudRate,
        milkAnalyserPath,
        milkAnalyserBaudRate,
        clrConfig,
        snfConfig,
        fatConfig,
        tempConfig,
        waterConfig,
        weightStringDecimalFormat,
        connectedPorts,
        isPortLoading
    };
}

export default useAutomaticSetting;