import { DefaultLayout } from "../common/Layout";
import ErrorIcon from '@material-ui/icons/Error';
import { useLanguage } from "../../language/LanguageController";

export default function DairyLoadError() {
    const { t } = useLanguage();
    return (
        <DefaultLayout sideNav={false}>
            <div className="col-4 mx-auto text-center mt-5">
                <div>
                    <ErrorIcon fontSize="large"/>
                </div>
                <div className="mt-2">
                    {t("text_server_error")}
                </div>
            </div>
        </DefaultLayout>
    );
};