// @ts-nocheck
import moment from "moment";
import { checkFeatureInSubscription } from "../common/FeatureFlag/FeatureFlag";
import { getCustomerRateList, getRateListSettings } from "../helpers/rateListHelper";

const SHIFT_MORNING = "morning";
const SHIFT_EVENING = "evening";

const SHIFT_NUMBER = {
    [SHIFT_MORNING]: 0,
    [SHIFT_EVENING]: 1
};

const getDairyTypeSettings = (dairyType, settings) => settings['dairy_rate_' + dairyType];

export const getShift = () => moment().format("HH") >= 15 ? SHIFT_EVENING : SHIFT_MORNING;

export const getShiftNumber = () => SHIFT_NUMBER[getShift()];

export const getMilkCollectionEntrySettings = (rateLists, {
    rateListId,
    customerType,
    cattle
}) => {
    const rateList = getCustomerRateList(rateLists, rateListId, customerType);
    const {
        rateListTypeId,
        rateListSetting,
    } = getRateListSettings(rateList, cattle);
    const { defaultClr, defaultSnf } = getDefaultCLRSNF(rateListSetting);

    return {
        rate_by: getMilkRateBy(rateListTypeId, rateListSetting),
        isCLRSNFEnabled: checkIsCLRSNFEnabled(rateListTypeId, rateListSetting),
        isFatEnabled: isFatEnabled(rateListTypeId, rateListSetting),
        milkTypes: getRateListMilkTypes(rateListSetting),
        defaultClr,
        defaultSnf,
        rateListTypeId,
        rateListSetting,
    }
}

export const checkIsCLRSNFEnabled = (rateListTypeId, rateListSetting) => {
    if((rateListTypeId === 7 || rateListTypeId === 8) && ["snf", "clr"].includes(rateListSetting.rate_by)) {
        return true;
    }
    return false;
};

export const getMilkDefaultRate = (dairyType, cattle, settings) => {
    const dairyTypeSetting = getDairyTypeSettings(dairyType, settings);
    if(dairyType == 5) {
        return dairyTypeSetting['dairy_rate_5']?.milk_rate[cattle]?.rate;
    }
    return "";
}

export const getMilkRateBy = (rateListTypeId, rateListSetting) => {
    let rateBy = "clr";
    if(rateListTypeId == 7 || rateListTypeId == 8) {
        rateBy = rateListSetting.rate_by;
    }
    return rateBy;
}

export const getDefaultCLRSNF = ({ rate_settings = {
    default_clr_value: "",
    default_snf_value: ""
}}) => {
    let defaultClr = null, defaultSnf = null;
    if(checkFeatureInSubscription("default_clr_snf", false)) {
      defaultClr = rate_settings.default_clr_value || "";
      defaultSnf = rate_settings.default_snf_value || "";
    }

    return {
        defaultClr,
        defaultSnf
    }
}

export const isFatEnabled = (rateListTypeId, rateListSetting) => {
    return rateListTypeId == 5 
        ? rateListSetting.is_fat_enabled == 1
        : true
}

export const getRateListMilkTypes = (rateListSetting) => {
    let milkTypes = rateListSetting?.milk_rate || [];
    if(!Array.isArray(milkTypes)) {
        milkTypes = [milkTypes];
    }

    return milkTypes.map(milkType => milkType?.rate || 0);
}