// @ts-nocheck
import React, { useEffect } from "react";
import { useLanguage } from "../../language/LanguageController";
import { useState } from "react";
import analytics from "../../library/firebase/analytics";
import ModalDialog from "../../common/ModalDialog/ModalDialog";
import { Button, Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import Eventer from "../../library/Eventer";
import { niceBytes } from "../../library/util";
import moment from "moment";
import { ANALYTIC_EVENTS, ANALYTIC_PROPERTIES } from "src/_constants";

const useStyles = makeStyles({
    accordion: {
      border: 'none',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    accordionSummary: {
        padding: '0'
    },
    accordionDetails: {
        whiteSpace: "pre-line"
    }
});

const DesktopUpdateModal = () => {
    const { t } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [updateInfo, setUpdateInfo] = useState({
        size: 143,
        releaseDate: "2024-06-25T13:20:08.054Z",
        notes: "Second release"
    });
    const onClose = () => {
        setIsOpen(false);
        analytics.logEvent(ANALYTIC_EVENTS.UPDATE_MODAL_CLOSED, {
            [ANALYTIC_PROPERTIES.CURRENT_VERSION]: updateInfo.currentVersion,
            [ANALYTIC_PROPERTIES.NEW_VERSION]: updateInfo.version
        });
    }
    const classes = useStyles();

    const handleDownload = () => {
        analytics.logEvent(ANALYTIC_EVENTS.UPDATE_DOWNLOAD_CLICKED, {
            [ANALYTIC_PROPERTIES.CURRENT_VERSION]: updateInfo.currentVersion,
            [ANALYTIC_PROPERTIES.NEW_VERSION]: updateInfo.version
        });
        Eventer.emit("downloadUpdate", {
            downloadPath: updateInfo.downloadPath
        });
    }

    const onUpdateAvailable = ({ detail }) => {
        analytics.logEvent(ANALYTIC_EVENTS.UPDATE_AVAILABLE, {
            [ANALYTIC_PROPERTIES.CURRENT_VERSION]: detail.currentVersion,
            [ANALYTIC_PROPERTIES.NEW_VERSION]: detail.version
        });
        setIsOpen(true);
        setUpdateInfo(detail);
    }

    useEffect(() => {
        Eventer.on("updateAvailable", onUpdateAvailable);
        return () => Eventer.off("updateAvailable", onUpdateAvailable);
    }, []);

    return (
        <ModalDialog
            isOpen={isOpen}
            title={t("text_update_available")}
            onClose={onClose}
            content={
                <div>
                    <Typography component="p" className="mb-1" variant="subtitle1">
                        {t("text_body_update_modal")}
                    </Typography>
                    <Typography component="p" className="mb-3" variant="subtitle1">
                        {t("text_size")}: {niceBytes(updateInfo.size)}
                    </Typography>
                    <Accordion square className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                            <div className="d-flex flex-column">
                                <Typography component="h2" variant="subtitle1">
                                    <strong>{t("text_whats_new")}</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {t("text_released_on")} {moment(updateInfo.releaseDate).format("MMM Do, YYYY")}
                                </Typography>                            
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                {updateInfo.notes}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
            dialogActions={
                <PrimaryButton onClick={handleDownload}>
                    {t("text_download_now")}
                </PrimaryButton>
            }
            keepMounted
            disableEscapeKeyDown
        />
    );
};

export default DesktopUpdateModal;