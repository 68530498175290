import React, { useState } from "react";
import { Switch } from "react-onsenui";
import withLanguage from "../../../language/LanguageController";
import { SNF_LOWER_LIMIT, SNF_UPPER_LIMIT } from "../../../../_constants/rateList";

const DefaultSNF = ({ t, rate_settings = {}, validator}) => {
    const [snfType, setClrType] = useState(rate_settings.default_snf_type || "");
    const [defaultSNFValue, setDefaultSNFValue] = useState(rate_settings.default_snf_value || "");
    return (
        <div className="form-group position-relative">
            <div className="form-row">
                <div className="col-6">
                    <label>{t("text_default_snf")}</label>
                </div>
                <div className="col-6 text-right">
                    {t("text_custom")}&nbsp;
                    <Switch
                        modifier="material"
                        onChange={e => setClrType(e.target.checked  ? "fixed" : "custom")}
                        checked={snfType === "fixed"}/>
                    &nbsp;{t("text_fixed")}
                    <input
                        type="hidden"
                        name="rate_settings[default_snf_type]"
                        value={snfType}/>
                </div>
            </div>
            <input
                type="number"
                className="form-control"
                name="rate_settings[default_snf_value]"
                step="0.1"
                value={defaultSNFValue}
                onChange={e => setDefaultSNFValue(e.target.value)}
            />
            {validator.message("default_snf", defaultSNFValue, `numeric|min:${SNF_LOWER_LIMIT},num|max:${SNF_UPPER_LIMIT},num${snfType === "fixed" ? "|required" : ""}`)}
        </div>
    );
};

export default withLanguage(DefaultSNF);
