import { FETCH_ADDON_SUCCESS } from "../_constants/addons";

const initialState = {
    addons: [],
    is_fetching: false,
};

const addonsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_ADDON_SUCCESS: {
            return {
                ...state,
                addons: action.payload
            }
        }
        default:
            return state
    }
}

export default addonsReducer;