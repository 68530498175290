import React, { useEffect } from 'react';
import analytics from '../../../../library/firebase/analytics';
import { DefaultLayout } from '../../../common/Layout';
import { useLanguage } from '../../../../language/LanguageController';
import UserForm from '../userForm';

function AddUserPage () {
    const { t } = useLanguage();

    useEffect(() => {
        analytics.setScreenName("AddUserPage");
    }, []);
    
    return (
        <DefaultLayout
            back
            bottomGutter={false}
            title={t("text_dairy_users_add_user")}
            toolbar={false}
        >
            <UserForm />
        </DefaultLayout>
    );
}
  
export default AddUserPage;