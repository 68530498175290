// @ts-nocheck
import React, { useRef } from "react";
import { Ripple } from "react-onsenui";
import MilkDetailTotal from "./milk/MilkDetailTotal";
import DairyMilkDetail from "./milk/DairyMilkDetail";
import CustomerMilkTotal from "./milk/CustomerMilkTotal";
import AddMilk from "./milk/AddMilk";
import ProductForm from "./customer/ProductForm";
import Product from "./product/Product";
import CustomerProduct from "./customer/Product";
import DairyAccount from "./account/DairyAccount";
import CustomerAccount from "./account/CustomerAccount";
import KisanCustomerAccount from "./account/customer/CustomerAccount";
import MilkPaymentDetail from "./milk/MilkPaymentDetail";
import SanctionLoan from "./loan/SanctionLoan";
import DairyLoanDashboard from "./loan/DairyLoanDashboard";
import CustomerLoanHistory from "./loan/CustomerLoanHistory";
import KisanCustomerLoanHistory from "./loan/customer/CustomerLoanHistory";
import CustomerPaymentHistoryPage from "./account/CustomerPaymentHistory";
import KisanCustomerPaymentHistoryPage from "./account/customer/CustomerPaymentHistory";
import AdvancePaymentPage from "./account/advancePayment/AdvancePayment";
import PersonalMilkDiary from "../diary/Diary";
import withLanguage from "../../language/LanguageController";
import { checkFeatureInSubscription } from "../../common/FeatureFlag/FeatureFlag";
import { replacePage } from "../../library/navigation";
import analytics from "../../library/firebase/analytics";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useHistory } from "react-router";
import { useBrowser, useMobile } from "src/components/library/detect-device";
import { useSelector } from "react-redux";
import { usePermission } from "../../common/AccessPermission";
import { ABILITY_ADVANCE_PAYMENT, ABILITY_DAIRY_BILL, ABILITY_LOAN, ABILITY_MILK_COLLECTION, ABILITY_PRODUCT_MANAGEMENT, ABILITY_PRODUCT_SALE, PERMISSION_ADD, PERMISSION_DETAIL, PERMISSION_SALE_ADD, PERMISSION_SALE_VIEW, PERMISSION_VIEW, PERMISSION_VIEW_CUSTOMER_BILL, PERMISSION_VIEW_DAIRY_BILL } from "../../../_constants/permissions";

const SCROLL_WIDTH = 80;

const milkMenus = [
    {
        key: "addMilk",
        name: "text_buy_sell",
        path: "/milk/collection"
    },
    {
        key: "milkTotal",
        name: "text_total_buy_sell",
        feature: "daily_milk_total",
        path: "/milk/totalCollection"
    },
    {
        key: "milkSummary",
        name: "text_buy_sell_summary",
        feature: "dairy_milk_summary",
        path: "/milk/summary",
        deviceType: "Mobile"
    },
    {
        key: "customerMilk",
        name: "text_customer_milk",
        path: "/milk/customer"
    }
];

const productMenus = [
    {
        key: "addSales",
        name: "text_add_product_sell",
        feature: "product_management",
        path: "/dairy/product/addSales",
        deviceType: "Mobile"
    },
    {
        key: "productSummary",
        page: CustomerProduct,
        name: "text_product_summary",
        feature: "product_management",
        path: "/dairy/product/salesSummary"
    },
    {
        key: "manageProduct",
        name: "text_manage_products",
        feature: "product_management",
        path: "/dairy/product"
    }
];

const billMenus = [
    {
        key: "dairyAccount",
        name: "text_dairy_bill",
        feature: "dairy_billing",
        path: "/dairy/bill"
    },
    {
        key: "customerAccount",
        name: "text_customers_bill",
        feature: "customer_billing",
        path: "/dairy/bill/customer"
    },
    {
        key: "advancePayment",
        name: "text_pay_in_advance",
        feature: "advance_payment",
        path: "/dairy/advancePayment"
    },
    {
        key: "milkPaymentDetail",
        name: "text_payment_summary",
        path: "/dairy/paymentSummary"
    }
];

const loanMenus = [
    {
        key: "sanctionLoan",
        name: "text_sanction_loan",
        feature: "loan",
        path: "/dairy/loan/sanction"
    },
    {
        key: "loanDashboard",
        name: "text_loan_dashboard",
        feature: "loan",
        path: "/dairy/loan"
    },
    {
        key: "customerLoanHistory",
        name: "text_customers_loan",
        feature: "loan",
        path: "/dairy/loan/customer"
    }
];

const customerMenus = [
    {
        key: "bill",
        path: "/dairy/bill/customer",
        name: "text_bill",
        feature: "customer_billing"
    },
    {
        key: "customerLoan",
        path: "/dairy/loan",
        name: "text_loan",
        feature: "loan"
    },
    {
        key: "customerAccount",
        page: CustomerPaymentHistoryPage,
        path: "/dairy/account/paymentHistory",
        name: "text_payment_history",
        feature: "customer_billing"
    }
];

const InnerNav = (props) => {

    const { t, dairyType } = props;

    const deviceTypes = {
        isMobile: useMobile(),
        isBrowser: useBrowser()
    };

    const { hasAccess } = usePermission();

    const history = useHistory();

    const getkisanCustomerMenu = () => {
        const kisanCustomerMenus = [];
        const isCustomerBillingInPlan = checkFeatureInSubscription("customer_billing", false);
        if(deviceTypes.isBrowser) {
            kisanCustomerMenus.push({
                key: "milk_detail",
                path: `/dairy/${props.dairyId}`,
                name: "text_milk_detail",
                hasAccess: true
            });
        }
        if(isCustomerBillingInPlan && !deviceTypes.isBrowser) {
            kisanCustomerMenus.push({
                key: "bill",
                path: `/dairy/${props.dairyId}/bill`,
                name: "text_bill",
                feature: "customer_billing",
                hasAccess: true
            });
        }
        if(checkFeatureInSubscription("loan", false) && !deviceTypes.isBrowser) {
            kisanCustomerMenus.push({
                key: "customerLoan",
                path: `/dairy/${props.dairyId}/loan`,
                name: "text_loan",
                feature: "loan",
                hasAccess: true
            });
        }
        if(isCustomerBillingInPlan) {
            kisanCustomerMenus.push({
                key: "customerAccount",
                page: KisanCustomerPaymentHistoryPage,
                path: `/dairy/${props.dairyId}/payment`,
                name: deviceTypes.isBrowser ? `${t("text_loan")} & ${t("text_payment_detail")}` : t("text_payment_history"),
                feature: "customer_billing",
                hasAccess: true
            });
        }
        kisanCustomerMenus.push({
            key: "personalDiary",
            path: `/diary`,
            name: "text_personal_milk_diary",
            hasAccess: true
        });
        
        return kisanCustomerMenus;
    };

    const menus = {
        milk: [
            {
                key: "addMilk",
                name: "text_milk_collection",
                path: "/milk/collection",
                hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD)
            },
            {
                key: "milkTotal",
                name: "text_total_collection",
                feature: "daily_milk_total",
                path: "/milk/totalCollection",
                hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
            },
            {
                key: "milkSummary",
                name: "text_collection_summary",
                feature: "dairy_milk_summary",
                path: "/milk/summary",
                deviceType: "Mobile",
                hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
            },
            {
                key: "customerMilk",
                name: "text_customer_milk",
                path: "/milk/customer",
                hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
            }
        ],
        product: [
            {
                key: "addSales",
                name: "text_add_sales",
                feature: "product_management",
                path: "/dairy/product/addSales",
                deviceType: "Mobile",
                hasAccess: hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD)
            },
            {
                key: "productSummary",
                page: CustomerProduct,
                name: "text_product_summary",
                feature: "product_management",
                path: "/dairy/product/salesSummary",
                hasAccess: hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_VIEW)
            },
            {
                key: "manageProduct",
                name: "text_manage_products",
                feature: "product_management",
                path: "/dairy/product",
                hasAccess: hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_VIEW)
            }
        ],
        bill: [
            {
                key: "dairyAccount",
                name: "text_dairy_bill",
                feature: "dairy_billing",
                path: "/dairy/bill",
                hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL)
            },
            {
                key: "customerAccount",
                name: "text_customers_bill",
                feature: "customer_billing",
                path: "/dairy/bill/customer",
                hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
            },
            {
                key: "advancePayment",
                name: "text_pay_in_advance",
                feature: "advance_payment",
                path: "/dairy/advancePayment",
                hasAccess: hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD) || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW)
            },
            {
                key: "milkPaymentDetail",
                name: "text_payment_summary",
                path: "/dairy/paymentSummary",
                hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
            }
        ],
        loan: [
            {
                key: "sanctionLoan",
                name: "text_sanction_loan",
                feature: "loan",
                path: "/dairy/loan/sanction",
                hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_ADD)
            },
            {
                key: "loanDashboard",
                name: "text_loan_dashboard",
                feature: "loan",
                path: "/dairy/loan",
                hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_VIEW)
            },
            {
                key: "customerLoanHistory",
                name: "text_customers_loan",
                feature: "loan",
                path: "/dairy/loan/customer",
                hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_VIEW)
            }
        ],
        customer: [
            {
                key: "bill",
                path: "/dairy/bill/customer",
                name: "text_bill",
                feature: "customer_billing",
                hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL)
            },
            {
                key: "customerLoan",
                path: "/dairy/loan",
                name: "text_loan",
                feature: "loan",
                hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_VIEW)
            },
            {
                key: "customerAccount",
                page: CustomerPaymentHistoryPage,
                path: "/dairy/account/paymentHistory",
                name: "text_payment_history",
                feature: "customer_billing",
                hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
            }
        ],
        kisan_customer: getkisanCustomerMenu()
    };

    const changePage = (event, { path = null, feature = null }) => {
        event.preventDefault();
        if(feature) {
            if(!checkFeatureInSubscription(feature)) {
                return false;
            }
        }
        if(path) {
            analytics.logEvent("changePage", { path });
            history.push(path);
        }
    };

    const navContainer = useRef();
    const menusToRender = menus[props.menu];
    
    const { user: { customer_group_id } } = useSelector(state => state.userReducer);
    if(deviceTypes.isBrowser && customer_group_id == 2) {
        return null;
    }
    
    return (
        <BottomNavigation
            value={props.page}
            showLabels
            ref={navContainer}
            id="inner-bottom-navigation"
            className="border-top fixed-bottom w-100 justify-content-start justify-content-md-center overflow-auto inner-bottom-navigation shadow-top align-items-center">
            {
                menusToRender.map(menu => {
                    if((menu.deviceType && !deviceTypes[`is${menu.deviceType}`]) || !menu.hasAccess) {
                        return null;
                    }
                    if(dairyType === "buyer" 
                        && props.menu === "kisan_customer"
                        && menu.key === "customerLoan") {
                        return null;
                    }
                    return <BottomNavigationAction
                        className="text-dark border-right font-weight-bold h-100"
                        key={menu.key}
                        label={t(menu.name)}
                        value={menu.key}
                        onClick={e => props.page != menu.key ? changePage(e, menu) : {}}/>;
                })
            }
        </BottomNavigation>
    );
};


export default withLanguage(InnerNav);