import React, {Component} from 'react';
import GestationForm from './GestationForm';
import { Fab, Icon, SpeedDial } from 'react-onsenui';
import withLanguage from '../../language/LanguageController';
import { connect } from 'react-redux';
import analytics from '../../library/firebase/analytics';
import SingleGestation from "./SingleGestation";
import { DefaultLayout } from '../common/Layout';
import { Link } from 'react-router-dom';
import { fetchGestations } from '../../../actions/gestation';
import Spinner from '../../core/Spinner';

class Gestation extends Component {
  constructor(props) {
    super(props);
    this.index = null;
    this.state = {
      search: '',
      alertDialogShown: false,
      customer_id: props.user.customer_id,
      error: null,
      gestation: {},
      is_edit: false,
      isModalOpen : false,
    }
  }
  
  getDefaultFilter(){
    return {
      animal_type: 'all',
      search: ''
    }
  }

  componentDidMount() {
    analytics.setScreenName("Gestation");
    if(this.state.customer_id) {
      fetchGestations(this.props.dispatch, this.state.customer_id);
    }
  }

  filterGestation = e => {
    this.setState({
      search: e.target.value
    })
  }

  getFilteredGestations = () => {
    let { search = "" } = this.state;
    const { gestations } = this.props;

    if(!gestations) {
      return [];
    }

    search = search.trim();
    if(!search){
      return gestations;
    }
    search = search.toLowerCase();

    return gestations.filter((gestation) => {
      let deccription = gestation.description || "";
      let animal_type = gestation.animal_type || "";
      let name = gestation.name || "";
      let color = gestation.color || "";
      let nasl = gestation.nasl || "";
      
      if(animal_type.toLowerCase().includes(search) 
        || name.toLowerCase().includes(search)
        || color.toLowerCase().includes(search)
        || nasl.toLowerCase().includes(search)
        || deccription.toLowerCase().includes(search)){
          return true;
      }
      return false;
    });
  }

  deviceChange = deviceDetail => {
    this.setState({
      ...deviceDetail
    });
  }

  render() {
    let gestations = this.getFilteredGestations();
    const { t, header, isLoading, bottomGutter } = this.props;
    const { isBrowser } = this.state;
    return (
      <DefaultLayout
        title={t("gestation_text")}
        header={header}
        onDeviceChange={this.deviceChange}
        bottomGutter={bottomGutter}
      >
        <form className="mb-2">
          <div className="bg-light rounded shadow-sm">
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  type="submit" 
                  className="btn btn-link text-warning">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <input type="text"
                placeholder={t("text_search")}
                aria-describedby="button-addon2"
                className="form-control border-0 bg-light"
                onChange={this.filterGestation}/>
            </div>
          </div>
        </form>
        {gestations.map((data, index) => (
          <SingleGestation
            key={data.gestation_id}
            index={index}
            gestation={data}
            onDelete={this.deleteGestation}
            onConfirm={this.confirmGestation}/>
        ))}
        {isLoading && <Spinner />}
        {!isBrowser && <SpeedDial position='bottom right'>
          <Fab style={{backgroundColor: '#11b232' }}>
            <Link to="/gestation/add" className="text-white">
              <span className="sr-only">{t("text_add_animal_pregnancy")}</span>
              <Icon icon='md-plus' />
            </Link>
          </Fab>
        </SpeedDial>}
      </DefaultLayout>
    );
  }
}

Gestation.displayName = "Gestation";

Gestation.defaultProps = {
  header: true,
  bottomGutter: true
};

const mapStateToProps = state => {
  const { userReducer: { user = {} }, gestationStore } = state;
  return {
    user: user ? user : {},
    gestations: gestationStore?.gestations || [],
    isLoading: gestationStore.is_loading,
  }
};

export default connect(mapStateToProps)(withLanguage(Gestation));