import React from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";

export const swalPopup = (WrappedComponent, options) => {

    const wrapper = document.createElement("div");
    ReactDOM.render(<WrappedComponent />, wrapper);

    return Swal.fire({
        html: wrapper,
        allowEscapeKey: true,
        ...options
    });
};
