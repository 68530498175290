import React from "react";
import { useLanguage } from "../../language/LanguageController";
import { DefaultLayout } from "../common/Layout";
import Gestation from "./Gestation";
import GestationForm from "./GestationForm";

const GestationManagement = () => {

    const { t } = useLanguage();

    return (
        <DefaultLayout title={t("gestation_text")} bottomGutter={false}>
            <div className="form-row">
                <div className="col-5">
                    <GestationForm
                        header={false}
                    />
                </div>
                <div className="col-7">
                    <Gestation
                        header={false}
                        bottomGutter={false}
                    />
                </div>
            </div>
        </DefaultLayout>
    );
};

GestationManagement.displayName = "GestationManagement";

export default GestationManagement;