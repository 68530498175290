import React from "react";
import { useLanguage } from "../../../language/LanguageController"
import dairyTypes from '../../../../config/dairy_type.json';
import Select from "react-select";
import { mapRateListTypesToSelect } from "../../../helpers/rateListHelper";

const RateListTypeSelector = ({ rateListType, onChange }) => {
    const { t } = useLanguage();
    const rateListTypes = mapRateListTypesToSelect(dairyTypes);
    return (
        <div className="form-group">
            <label>
                {t("text_rate_list_type")}
            </label>
            <Select
                noOptionsMessage={() => {
                    return t("text_no_options");
                }}
                isSearchable={false}
                placeholder={t("text_select_rate_list") + "..."}
                options={rateListTypes}
                value={rateListType}
                name="rate_list_type_id"
                onChange={onChange}/>
        </div>
    )
};

export default RateListTypeSelector;