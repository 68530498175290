/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck
import { getLang, t } from "../language/LanguageController";
import { parseResponse } from "./response";
import { getAppBuild, getAppVersion, getDeviceId, getToken, toast } from "./util";
import http from "./http";

const defaultOption = { 
    withAuth: false
};

/**
 * A get method to make get request call using browser in-built fetch method
 * 
 * @param {*} apiEndPoint /api/to/call
 * @param {*} options {
 * withAuth: true|false default is false,
 * body: request body(json string or formData object)
 * }
 * @returns 
 */
export const get = async (apiEndPoint, options = defaultOption) => {
    return await doRequest(apiEndPoint, "GET", options);
}

/**
 * A post method to make post request call using browser in-built fetch method
 * 
 * @param {*} apiEndPoint /api/to/call
 * @param {*} options {
 * withAuth: true|false default is false,
 * body: request body(json string or formData object)
 * }
 * @returns 
 */
export const post = async (apiEndPoint, options = {}) => {
    return await doRequest(apiEndPoint, "POST", options);
}

/**
 * A put method to make put request call using browser in-built fetch method
 * 
 * @param {*} apiEndPoint /api/to/call
 * @param {*} options {
 * withAuth: true|false default is false,
 * body: request body(json string or formData object)
 * }
 * @returns 
 */
 const put = async (apiEndPoint, options = {}) => {
    return await doRequest(apiEndPoint, "PUT", options);
}

/**
 * A delete method to make delete request call using browser in-built fetch method
 * 
 * @param {*} apiEndPoint /api/to/call
 * @param {*} options {
 * withAuth: true|false default is false,
 * body: request body(json string or formData object)
 * }
 * @returns 
 */
const deleteRq = async (apiEndPoint, options = {}) => {
    return await doRequest(apiEndPoint, "DELETE", options);
}

/**
 * A patch method to make patch request call using browser in-built fetch method
 * 
 * @param {*} apiEndPoint /api/to/call
 * @param {*} options {
 * withAuth: true|false default is false,
 * body: request body(json string or formData object)
 * }
 * @returns 
 */
const patch = async (apiEndPoint, options = {}) => {
    return await doRequest(apiEndPoint, "PATCH", options);
}

const doRequest = async (
    apiEndPoint,
    method,
    {
        withAuth = false,
        body = null,
        responseType = "json",
        version = "v1",
        params = null
    } = {}
) => {
    if(!checkConnectivity()) {
        return {
            status: false,
            message: t("error_no_internet_connection")
        };
    }
    
    const requestOptions = getRequestOptions({
        method: method,
        withAuth: true,
        body: body
    });
    
    const queryString = params ? getQueryString(params) : "";

    requestOptions.url = `${version + apiEndPoint + queryString}`;

    const response = await http(requestOptions);
    const parsedResponse = await parseResponse(response, responseType);
    return parsedResponse;
}

const getRequestOptions = ({
    method = "post",
    withAuth = true,
    headers = {},
    body = null
}) => {
    const requestHeaders = {
        "Accept": "application/json",
        "Language": getLang(),
        "X-DEVICE-ID": getDeviceId(),
        "X-APP-VERSION": getAppVersion(),
        "X-APP-BUILD": getAppBuild(),
        ...headers
    };

    if(withAuth) {
        requestHeaders["Authorization"] = "Bearer " + getToken();
    }

    if(!(body instanceof FormData)) {
        requestHeaders["Content-Type"] = "application/json";
    }
    
    const requestOptions = {
        method: method,
        headers: requestHeaders,
        data: body
    };

    return requestOptions;
}

const checkConnectivity = () => {
    const onLine = navigator.onLine;
    if(!onLine && !window?.literAPI?.desktop) {
        toast(t('error_no_internet_connection'));
        return false;
    }
    return true;
};

const getQueryString = (params = {}) => {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return queryString ? `?${queryString}` : "";
}

export default {
    get,
    post,
    put,
    patch,
    delete: deleteRq
};