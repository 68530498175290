import React from "react";
import PropTypes from "prop-types";
import CustomersSelect from "../../../common/CustomersSelect";
import withLanguage from "../../../../language/LanguageController";
import MuiDatepicker from "../../../../common/MuiDatepicker";
import moment from "moment";
import CowWhiteIcon from "../../../../common/icons/CowWhiteIcon";
import CowBlackIcon from "../../../../common/icons/CowBlackIcon";
import BuffaloWhiteIcon from "../../../../common/icons/BuffaloWhiteIcon";
import BuffaloBlackIcon from "../../../../common/icons/BuffaloBlackIcon";

const Filter = ({ 
    t,
    startDate,
    endDate,
    cattle,
    shift,
    customers,
    customer,
    customerChangeHandler,
    dateChangeHandler,
    cattleChangeHandler,
    shiftChangeHandler
}) => {
    return (
        <div className="form-row">
            <div className="col-12">
                <div className="d-flex mb-2">
                    <div className="btn-group btn-group-sm mr-1" role="group" aria-label="Cattle Selection">
                        <button
                            className={`btn ${cattle === "cow" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => cattleChangeHandler("cow")}>
                            {cattle === "cow" ? <CowWhiteIcon /> : <CowBlackIcon />} 
                            &nbsp;{t("text_cow")}
                        </button>
                        <button
                            className={`btn ${cattle === "buffalo" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => cattleChangeHandler("buffalo")}>
                            {cattle === "buffalo" ? <BuffaloWhiteIcon /> : <BuffaloBlackIcon />}
                            &nbsp;{t("text_buffalo")}
                        </button>
                    </div>
                    <div className="flex-fill">
                        <CustomersSelect
                            customers={customers}
                            onChange={customerChangeHandler}
                            value={customer}/>
                    </div>
                </div>
                <div className="d-flex mb-2 justify-content-between">
                    <div className="btn-group btn-group-sm mr-1" role="group" aria-label="Shift Selection">
                        <button
                            className={`btn text-nowrap ${shift == "0" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => shiftChangeHandler("0")}>
                            <i className="fa fa-sun"></i>
                            &nbsp;{t("text_morning")}
                        </button>
                        <button
                            className={`btn text-nowrap ${shift == "1" ? "bg-light-blue text-white active" : "btn-light"}`}
                            type="button"
                            onClick={() => shiftChangeHandler("1")}>
                            <i className="fa fa-moon"></i>
                            &nbsp;{t("text_evening")}
                        </button>
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                        </div>
                        <MuiDatepicker
                            value={startDate}
                            maxDate={endDate}
                            onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                            name="start_date"
                            inputId="start_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                        <div className="input-group-prepend input-group-append">
                            <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                        </div>
                        <MuiDatepicker
                            value={endDate}
                            minDate={startDate}
                            maxDate={moment().endOf("month")}
                            onChange={selectedDate => dateChangeHandler("end_date", selectedDate)}
                            name="end_date"
                            inputId="end_date"
                            inputClass="bg-white"
                            arrowButtons={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Filter.propTypes = {
    t: PropTypes.func.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    cattle: PropTypes.string.isRequired,
    shift: PropTypes.string.isRequired,
    customers: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    customerChangeHandler: PropTypes.func.isRequired,
    dateChangeHandler: PropTypes.func,
    cattleChangeHandler: PropTypes.func,
    shiftChangeHandler: PropTypes.func
};

export default withLanguage(Filter);