// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { Divider, InputAdornment, List, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';
import User from "./User";
import { PrimaryTextField } from 'src/components/core/TextField';
import { useSelector } from 'react-redux';
import NoData from 'src/components/pages/common/NoData';
import { useLanguage } from '../../../../language/LanguageController';

function UsersList () {
    const classes = useStyles();
    const { t } = useLanguage()
    const { users } = useSelector(state => state.dairyUsers);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if(!search) {
            setFilteredUsers(users);
        } else {
            setFilteredUsers(users.filter(
                user => user.name.toLowerCase().includes(search.toLowerCase()) || user.mobile.toLowerCase().includes(search.toLowerCase())
            ));
        }
    }, [users, search]);
    
    return (
        <>
            <div className="mb-2">
                <TextField
                    id="input-with-icon-adornment"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <SearchIcon edge="end" />
                        </InputAdornment>,
                    }}
                    fullWidth
                    variant="outlined"
                    placeholder={t("text_search_users")}
                    onChange={e => setSearch(e.target.value)}
                    size="small"
                />
            </div>
            <List className={classes.root}>
                {filteredUsers.map((user, index) =>
                    <Fragment key={user.customer_id}>
                        <User {...user} />
                        {(users.length !== index + 1) && <Divider variant="inset" component="li" />}
                    </Fragment>
                )}
                {!users.length && <NoData />}
            </List>
        </>
    );
}
  
export default UsersList;