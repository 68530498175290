// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react"
import { Typography, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from "@material-ui/core"
import { PrimaryCheckbox } from "src/components/core/Checkbox";
import { currencyFormat } from "src/components/library/util";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { useLanguage } from "src/components/language/LanguageController";
import AddonDetailModal from "../AddonDetailModal/AddonDetailModal";

export default function AddonItem({
    add_on_id,
    name,
    daily_price,
    handleToggle,
    checked,
    addon,
    showSelect,
    buyNow,
    onBuyNow
}) {
    const { t, language } = useLanguage();
    const [isDetailModalOpen, setDetailModalState] = useState(false);

    const handleBuyNow = useCallback(() => {
        onBuyNow(add_on_id);
    }, [add_on_id, onBuyNow]);

    const getAddOnName = useCallback(() => {
        return addon?.name_local?.[language] || addon?.name_local?.["en"];
    }, [addon?.name_local, language]);

    return (
        <>
            <ListItem button className="bg-white shadow-sm rounded" role={undefined} dense={showSelect} onClick={handleToggle(add_on_id)}>
                {showSelect && <ListItemIcon className="min-width30i">
                    <PrimaryCheckbox
                        edge="start"
                        checked={checked.indexOf(add_on_id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${add_on_id}` }}
                    />
                </ListItemIcon>}
                <ListItemText
                    id={`checkbox-list-label-${add_on_id}`}
                    primary={getAddOnName()}
                />
                <ListItemSecondaryAction>
                    <Typography component="span">{currencyFormat(daily_price)}/{t("text_day")}</Typography>
                    <IconButton edge={!buyNow && "end"} onClick={() => setDetailModalState(true)}>
                        <VisibilityIcon />
                    </IconButton>
                    {buyNow && <PrimaryButton edge="end" onClick={handleBuyNow}>{t("text_buy_now")}</PrimaryButton>}
                </ListItemSecondaryAction>
            </ListItem>
            <AddonDetailModal
                isOpen={isDetailModalOpen}
                addon={addon}
                onClose={setDetailModalState}
            />
        </>
    )
}