import { createSelector } from "@reduxjs/toolkit";
import { selectOfflineStore } from "../selectors";

export const selectCollectionQueueTotalState = createSelector(
    selectOfflineStore,
    (state) => state.collectionQueueTotal
);

export const selectCollectionQueueTotal = createSelector(
    selectCollectionQueueTotalState,
    (state) => state.data.data
);

export const selectCollectionQueueCount = createSelector(
    selectCollectionQueueTotalState,
    (state) => state.data.count
)