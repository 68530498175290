import withLanguage from '../../../../language/LanguageController';
import AddMilk from "../AddMilk";
import React from "react";
import DairyMilkDetail from "../DairyMilkDetail";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { HasPermission, usePermission } from '../../../../common/AccessPermission';
import { ABILITY_MILK_COLLECTION, PERMISSION_DETAIL } from '../../../../../_constants/permissions';

const MilkCollection = ({ t }) => {
    const { hasAccess } = usePermission();
    return (
        <DefaultLayout
            title={t("text_milk_collection")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                <div className={hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL) ? "col-md-6 col-lg-4" : "col-5 mx-auto"}>
                    <AddMilk header={false}/>
                </div>
                <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DETAIL}>
                    <div className="col-md-6 col-lg-8">
                        <DairyMilkDetail header={false} />
                    </div>
                </HasPermission>
            </div>
        </DefaultLayout>
    )
};

MilkCollection.displayName = "MilkCollection";

export default withLanguage(MilkCollection);