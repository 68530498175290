import React, { useState } from "react";
import withLanguage from '../../../../language/LanguageController';
import ProductForm from "../ProductForm";
import Product from "../Product";
import ProductDetail from "../ProductDetail";
import { DefaultLayout } from "../../../common/Layout";
import { TabletView, useMobile } from "../../../../library/detect-device";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import SubscriptionLock from "../../../../common/FeatureFlag/SubscriptionLock";
import { usePermission } from "../../../../common/AccessPermission";
import { ABILITY_PRODUCT_MANAGEMENT, PERMISSION_ADD, PERMISSION_UPDATE } from "../../../../../_constants/permissions";

const ManageProduct = ({ t }) => {
    const [product, selectProduct] = useState({ id: ""});
    const isMobile = useMobile();
    const { hasAccess } = usePermission();
    const productAddAccess = hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_ADD);
    return (
        <DefaultLayout
            title={t("text_manage_products")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                {productAddAccess && <TabletView>
                    <div className="col-md-4">
                        <ProductForm
                            header={false}
                            bottomGutter={false}/>
                    </div>
                </TabletView>}
                <div className={productAddAccess ? "col-md-3" : "col-md-5"}>
                    <Product
                        header={false}
                        bottomGutter={isMobile}
                        onProductSelect={selectProduct}
                        selectedProductId={product.id}/>
                </div>
                <TabletView>
                    <div className={productAddAccess ? "col-12 col-md-5" : "col-12 col-md-7"}>
                        <ProductDetail
                            key={product.id}
                            header={false}
                            bottomGutter={false}
                            product={product}/>
                    </div>
                </TabletView>
            </div>
            {!checkFeatureInSubscription("product_management", false) && <SubscriptionLock/>}
        </DefaultLayout>
    )
};

ManageProduct.displayName = "ManageProduct";

export default withLanguage(ManageProduct);