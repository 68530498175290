// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../language/LanguageController';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, InputAdornment, RadioGroup, Typography } from '@material-ui/core';
import { PrimaryTextField } from 'src/components/core/TextField';
import { PrimaryCheckbox } from 'src/components/core/Checkbox';
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import request from 'src/components/library/request';
import { errorHandler } from 'src/components/library/response';
import Spinner from 'src/components/core/Spinner';
import { copyObject } from '../../../../library/util';
import { addDairyUser, updateDairyUser } from '../../../../../actions/dairyUsers';
import { useDispatch, useSelector } from 'react-redux';
import featuresPermissionList, { rolePermissionMap } from "./featuresPermissionList";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './style';
import { combinePermissions, getDependentPermissions } from '../../../../library/dairyUsers/userPermission';
import { isOwner } from '../../../../services/dairyUsers';
import { RadioPrimary } from '../../../../core/Radio';
import { Redirect, useHistory } from 'react-router';

function UserForm ({ isEdit, editUser }) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useLanguage();
    const formRef = useRef();
    const mobileRef = useRef();
    const [dependentPermissions, setDependentPermissions] = useState({});
    const [user, setUser] = useState({
        mobile: "",
        name: "",
        dairyUserId: null,
        permissions: {}
    });
    const [isSearching, setSearching] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const dispatch = useDispatch();
    const { id: dairyId } = useSelector(state => state.dairy.dairy);

    const searchUser = useCallback(async () => {
        try {
            if(!user.mobile) {
                mobileRef.current.focus();
                return;
            }
            setSearching(true);
            const { status, data } = await request.get("/customers/search", {
                params: {
                    mobile: user.mobile
                }
            });
            if(status && data.length > 0) {
                setUser({
                    ...user,
                    mobile: data[0].telephone,
                    name: data[0].name,
                    customer_d: data[0].customer_id,
                });
            }
        } catch(error) {
            errorHandler(error);
        } finally {
            setSearching(false);
        }
    }, [user, setUser]);

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckboxChange = useCallback((isChecked, featurePermissionKey, permission) => {
        const userToUpdate = copyObject(user);
        if(isChecked) {
            if(!userToUpdate.permissions[featurePermissionKey]) {
                userToUpdate.permissions[featurePermissionKey] = [];
            }
            if(!userToUpdate.permissions[featurePermissionKey].includes(permission)) {
                userToUpdate.permissions[featurePermissionKey].push(permission);
            }
        } else {
            const index = userToUpdate.permissions[featurePermissionKey].indexOf(permission);
            if(index > -1) {
                userToUpdate.permissions[featurePermissionKey].splice(index, 1);
            }
        }
        const dependentPermissions = getDependentPermissions(featuresPermissionList, userToUpdate.permissions);
        userToUpdate.permissions = combinePermissions(userToUpdate.permissions, dependentPermissions);
        setUser(userToUpdate);
        setDependentPermissions(dependentPermissions);
    }, [user]);

    const handleSubmit = useCallback(async event => {
        event.preventDefault();
        const formData =  new FormData(formRef.current);
        setSaving(true);
        let success = false;
        if(isEdit) {
            success = await updateDairyUser(dispatch, dairyId, formData, user.dairyUserId);
        } else {
            success = await addDairyUser(dispatch, dairyId, formData);
            formRef.current.reset();
        }
        if(success) {
            history.replace("/dairy/setting/users");
        }
        setSaving(false);
    }, [isEdit, dispatch, dairyId, user.dairyUserId, history]);

    const onRoleSelect = useCallback(roleName => {
        const userToUpdate = copyObject(user);
        userToUpdate.permissions = rolePermissionMap[roleName];
        setUser(userToUpdate);
    }, [user]);

    useEffect(() => {
        if(editUser) {
            setUser({
                mobile: editUser?.mobile,
                name: editUser?.name,
                dairyUserId: editUser?.customer_dairy_id,
                permissions: editUser?.permissions
            });
        }
    }, [editUser]);

    const isInDependentPermission  = useCallback((permissionKey, permission) => {
        return dependentPermissions?.[permissionKey]?.includes(permission) || false;
    }, [dependentPermissions]);

    if(editUser && isOwner(editUser)) {
        return <Redirect to="/dairy/setting/users"/>
    }
    
    return (
        <div>
            <form method="post" onSubmit={handleSubmit} ref={formRef}>
                <div className="t-card mb-2 shadow-sm">
                    <div className="form-group">
                        <div className="d-flex">
                            <PrimaryTextField
                                label={t("text_user_mobile")}
                                fullWidth
                                InputProps={{
                                    endAdornment: !isEdit ? (<InputAdornment position="end" variant="filled">
                                        <IconButton edge="end">
                                            <i className="fa fa-address-book"/>
                                        </IconButton>
                                    </InputAdornment>) : null,
                                }}
                                name="mobile"
                                variant="outlined"
                                className={`${!isEdit && "mr-2"}`}
                                value={user.mobile}
                                onChange={handleChange}
                                disabled={isEdit}
                                inputRef={mobileRef}
                                placeholder={t("text_user_mobile_placeholder")}
                                size="small"
                            />
                            {!isEdit && <PrimaryButton onClick={searchUser} disabled={isSearching}>
                                {!isSearching && t("text_search")}
                                {isSearching && <Spinner />}
                            </PrimaryButton>}
                        </div>
                    </div>
                    <div className="form-group mb-0">
                        <PrimaryTextField
                            label={t("text_user_name")}
                            fullWidth
                            name="name"
                            variant="outlined"
                            value={user.name}
                            onChange={handleChange}
                            disabled={isEdit}
                            placeholder={t("text_user_name_placeholder")}
                            size="small"
                        />
                    </div>
                </div>
                <div className="t-card mb-2 shadow-sm">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            {t("text_role")} <FormHelperText component={"span"}>({t("dairy_user_role_help")})</FormHelperText>
                        </FormLabel>
                        <RadioGroup row name="role">
                            <FormControlLabel
                                control={<RadioPrimary onChange={() => onRoleSelect("admin")} value="admin" />}
                                label={t("text_role_admin")}
                            />
                            <FormControlLabel
                                control={<RadioPrimary onChange={() => onRoleSelect("dairy_manager")} value="dairy_manager" />}
                                label={t("text_role_dairy_manager")}
                            />
                            <FormControlLabel
                                control={<RadioPrimary onChange={() => onRoleSelect("collection_user")} value="collection_user" />}
                                label={t("text_role_milk_collection_user")}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="mb-5r">
                    {featuresPermissionList.map(({feature_permission_key,feature_permission_label,feature_permissions}) => {
                        return (
                            <Accordion key={feature_permission_key} defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={`panel-header-${feature_permission_key}`}
                                >
                                    <Typography className={classes.heading}>{t(feature_permission_label)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        {feature_permissions.map(({ permission, label, default_selected }) => {
                                            const inDependentPermission = isInDependentPermission(feature_permission_key, permission);
                                            const CheckBoxComponent = (inDependentPermission || default_selected) ? Checkbox : PrimaryCheckbox;
                                            const isSelected = user?.permissions?.[feature_permission_key]?.includes(permission) ? true : false;
                                            return (
                                                <FormControlLabel
                                                    key={permission}
                                                    control={
                                                        <CheckBoxComponent
                                                            name={`permissions[${feature_permission_key}][]`}
                                                            value={permission}
                                                            checked={default_selected || isSelected}
                                                            onChange={event => !inDependentPermission && handleCheckboxChange(event.target.checked, feature_permission_key, permission)}
                                                            disableRipple={default_selected || inDependentPermission}
                                                            color="default"
                                                        />
                                                    }
                                                    label={t(label)}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
                <div className="fixed-bottom p-3 px-4 bg-white shadow-top ml-lg-135">
                    <div className="form-row mx-0">
                        <div className="col-12 col-md-8 col-lg-6 mx-auto">
                            <input
                                className="btn btn-success btn-block"
                                type="submit"
                                value={t("text_save")}
                                disabled={isSaving}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

UserForm.defaultProps = {
    isEdit: false,
    editUser: null
};
  
export default UserForm;