import { List, ListItem } from '@material-ui/core';
import analytics from '../../../library/firebase/analytics';
import React, { useEffect, useState } from  'react';
import { Switch, ProgressCircular } from 'react-onsenui';
import { scanDevices, isBTEnabled, enableBluetooth, printersList, connectDevice } from '../../../library/bluetoothPrinter';
import ModalDialog from '../../../common/ModalDialog';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';

const BluetoothPrinter = ({t, isOpen, onClose}) => {

    const [isScanning, setScanning] = useState(false);
    const [isEnabled, setEnabled] = useState(false);
    const [isEnabling, setEnabling] = useState(false);
    const [devices, setDevices] = useState([]);
    const [connectingTo, setConnecting] = useState(false);
    const [connectedTo, setConnected] = useState(false);
    const [connectionError, setConnectionError] = useState(false);

    const scanBTDevices = async () => {
        try {
            setScanning(true);
            let foundDevices = await scanDevices();
            if(foundDevices.length) {
                setDevices([...devices, ...foundDevices]);
            }
            setScanning(false);
        } catch (error) {
            setScanning(false);
        }
    };

    const enableBT = async () => {
        try {
            setEnabling(true);
            let result = await enableBluetooth();
            setEnabling(false);
            setEnabled(result);
            const printers = await printersList();
            setDevices(printers);
        } catch (error) {
            setEnabling(false);
        }
    };

    const connectToDevice = async (e, device) => {
        if(!connectingTo) {
            try {
                setConnected(false);
                setConnectionError(false);
                setConnecting(device.address);
                await connectDevice(device.address);
                setConnecting(false);
                setConnected(device.address);
            } catch (error) {
                setConnecting(false);
                setConnectionError(device.address);
            }
        }
    }

    const getDeviceStatus = (deviceAddress) => {
        if(connectingTo === deviceAddress) {
            return (<div className="size-13 text-grey-dark">
                {t("text_connecting") + '...'}
            </div>);
        } else if(connectedTo === deviceAddress) {
            return (<div className="size-13 text-grey-dark">
                {t("text_connected")}
            </div>);
        } else if(connectionError === deviceAddress) {
            return (<div className="size-13 text-grey-dark">
                {t("text_connection_error")}
            </div>);
        }

        return null;
    }

    useEffect(() => {
        analytics.logEvent("BluetoothPrinterModal");
        (async () => {           
            try {
                let btEnabled = await isBTEnabled();
                setEnabled(btEnabled);
                const printers = await printersList();
                console.log("printers", printers);
                setDevices(printers);
            } catch (error) {
                console.log("error", error);
            }
        })();
    }, []);

    let dialogActions = false;
    if(isEnabled) {
        dialogActions = (<>
            {isScanning && <ProgressCircular indeterminate />}
            {!isScanning && <PrimaryButton
                variant="contained"
                onClick={scanBTDevices}
            >
                {t("text_scan_printer")}
            </PrimaryButton>}
        </>);
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_printer_setting")}
            content={
                <>
                    <div className="row mb-2">
                        <div className="col-6 lheight-30 pl-4">
                            {t("text_enabled")}
                        </div>
                        <div className="col-6 text-right">
                            <Switch
                                onChange={enableBT}
                                checked={isEnabled}
                                disabled={isEnabling}
                                color="primary"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {isEnabling ? (
                                <div className="text-center">
                                    {t("text_enabling_bluetooth")}
                                </div>
                            ) : null}
                            {isEnabled && devices.length ?
                                <List className="printer-list">
                                    {devices.map(device => {
                                        return (<ListItem
                                            key={device.address}
                                            className="printer-list-item text-ellipsis">
                                            <div className="left" onClick={e => connectToDevice(e, device)}>
                                                <div><b>{device.name}</b></div>
                                                <div>{device.address}</div>
                                                {getDeviceStatus(device.address)}
                                            </div>
                                        </ListItem>);
                                    })}
                                </List>
                            : null}
                            {!devices.length && isEnabled && !isEnabling  ? (
                                <div className="text-center mb-3">
                                    {t("text_no_paired_device_found")}
                                </div>
                            ) : null}
                            {!isEnabled && !isEnabling ? (
                                <div className="text-center mb-3">
                                    {t("text_on_bluetooth_to_connect")}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </>
            }
            dialogActions={dialogActions}
        />
    );
};

export default BluetoothPrinter;