import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
function Loader() {
  return (
    <div>
            <CircularProgress className='text-success' />
            <p> Loading...</p>
    </div>
  )
}

export default Loader