import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { green } from '@material-ui/core/colors';

const PrimarySwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default PrimarySwitch
