import {
    ADD_DC_PRODUCT,
    FETCH_DC_PRODUCTS_BEGIN,
    FETCH_DC_PRODUCTS_SUCCESS,
    FETCH_DC_PRODUCTS_FAILURE,
    UPDATE_DC_PRODUCT,
    DELETE_DC_PRODUCT,
    RESET_DC_PRODUCTS
} from "../actions/dairyCustomerProduct";

const initialState = {
    is_loading: true,
    products: [],
    total: {}
};

function dairyCustomerProduct(state = initialState, action) {
    switch (action.type) {
    case ADD_DC_PRODUCT:
        return {
            ...state,
            products: [action.data, ...state.products],
        };
    case UPDATE_DC_PRODUCT:
        let products = [...state.products];
        products[action.index] = action.data;
        return {
            ...state,
            products: products
        };
    case DELETE_DC_PRODUCT:
        return {
            ...state,
            products: [...state.products.slice(0, action.index), ...state.products.slice(action.index + 1)]
        };
    case FETCH_DC_PRODUCTS_BEGIN: 
        return {
            ...state,
            is_loading: true
        };
    case FETCH_DC_PRODUCTS_SUCCESS: 
        return {
            ...state,
            is_loading: false,
            products: (action.data.result) ? action.data.result : [],
            total: (action.data.total) ? action.data.total : {}
        };
    case FETCH_DC_PRODUCTS_FAILURE:
        return {
            ...state,
            is_loading: false,
            products: [],
            total: {}
        };
    case RESET_DC_PRODUCTS: {
        return {
            ...state,
            products: [],
            total: {}
        }
    }
    default:
        return state;
    }
}

export default dairyCustomerProduct;
