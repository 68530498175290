// @ts-nocheck
const { getDb } = require("./connection");

export const executeQuery = async (query, params = []) => {
    const db = await getDb();
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    console.log("executeQuery db", db)
    db.transaction(
        (tx) => tx.executeSql(
            query,
            [...params],
            (tx, res) => resolve(res),
            (tx, error) => reject(error)
        ), 
        (error) => reject(error),
        () => console.log('transaction ok')
    );

    return promise;
}