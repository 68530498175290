import React, { useEffect, useState } from "react";
import { Switch } from "react-onsenui";
import ErrorBoundary from "src/components/common/ErrorBoundary";
import { useLanguage } from "src/components/language/LanguageController";
import Eventer from "src/components/library/Eventer";
import { generateRandom } from "src/components/library/util";

export default function MilkRateByAnimal ({
    rateListId,
    milkRate,
    fatEnabled
}) {
    const { t } = useLanguage();
    const [isFatEnabled, setIsFatEnabled] = useState(fatEnabled == 1 ? true : false);
    const [rateSlots, setRateSlot] = useState([{slot_id: generateRandom(3), rate: 0}]);

    const addSlot = () => {
        const slots = [...rateSlots];
        slots.push({
            rate: 0,
            slot_id: generateRandom(3)
        });
        setRateSlot(slots);
    }

    const deleteSlot = slotIndex => {
        const slots = [...rateSlots.slice(0, slotIndex), ...rateSlots.slice(slotIndex + 1)];
        setRateSlot(slots);
    }

    useEffect(() => {
        if(milkRate) {
            const slots = Array.isArray(milkRate) ? milkRate : [milkRate.cow];
            setRateSlot(slots);
        }
    }, [milkRate]);

    useEffect(() => {
        Eventer.on(`applyRateListPreset${rateListId}`, onRateListPresetApply);
        return () => {
            Eventer.off(`applyRateListPreset${rateListId}`, onRateListPresetApply);
        }
    }, [rateListId]);

    const onRateListPresetApply = (event) => {
        const { detail } = event;
        if(detail.rateListTypeId == 5) {
            setRateSlot(detail.milk_rate);
        }
    }

    return (
        <ErrorBoundary>
            <div className="clearfix">
                <div className="form-row form-group mb-0">
                    <div className="col-7">
                        <label>{t("text_enable_fat")}</label>
                    </div>
                    <div className="col-5 text-right">
                        <Switch
                            modifier="material" 
                            checked={isFatEnabled}
                            onChange={e => setIsFatEnabled(e.target.checked)}
                            />
                        <input type="hidden" name="is_fat_enabled" value={isFatEnabled == true ? 1 : 0}/>
                    </div>
                </div>
                <div className="form-row form-group">
                    <div className="col-12">
                        <div className="form-row">
                            <div className="col-6">
                                <label className="control-label block" id="">{t("text_rate")}</label>
                            </div>
                            <div className="col-6">
                                <label className="control-label block">{t("text_remark")}</label>
                            </div>
                        </div>
                        {rateSlots.map((rateSlot, index) => {
                            return (
                                <div className="form-row mb-2" key={rateSlot?.slot_id}>
                                    <div className="col-6">
                                        <div className="input-group">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text text-sm">
                                                    {t("text_type")} {index + 1} {t("text_rate")}
                                                </span>
                                            </span>
                                            <input 
                                                type="number"
                                                name={`milk_rate[${index}][rate]`}
                                                step="any"
                                                className="form-control"
                                                min="0"
                                                defaultValue={rateSlot ? rateSlot["rate"] : 0}/>
                                            <input
                                                type="hidden"
                                                name={`milk_rate[${index}][slot_id]`}
                                                defaultValue={rateSlot?.slot_id} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-row">
                                            <div className={`${index > 0 ? "col-8 col-md-10 col-lg-11" : "col-12"}`}>
                                                <input 
                                                    type="text"
                                                    name={`milk_rate[${index}][remark]`}
                                                    className="form-control"
                                                    defaultValue={rateSlot ? rateSlot["remark"] : ""}
                                                />
                                            </div>
                                            {index > 0 && <div className="col-4 col-md-2 col-lg-1 text-right">
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    onClick={() => deleteSlot(index)}>
                                                    <span className="sr-only">{t("text_delete")}</span>
                                                    <i className="fa fa-trash" />
                                                </button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="form-row">
                            <div className="col-12 text-right">
                                <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={addSlot}>
                                    <span className="sr-only">{t("text_add_slot")}</span>
                                    <i className="fa fa-plus" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
}