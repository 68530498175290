import { createSlice } from '@reduxjs/toolkit'
import { fetchCollectionQueueTotal } from "./thunk";

const initialState = {
  data: {
    count: 0,
    data: {}
  },
  isLoading: false,
  error: null
}

export const collectionQueueTotalSlice = createSlice({
  name: 'collectionQueueQueue',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCollectionQueueTotal.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCollectionQueueTotal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = {
        count: action.payload?.count || 0,
        data: action.payload?.data || 0
      };
      state.error = null;
    });

    builder.addCase(fetchCollectionQueueTotal.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message || null;
    });
  }
})


export default collectionQueueTotalSlice.reducer;