import {
    ADD_GESTATIONS_SUCCESS,
    CONFIRM_GESTATIONS_SUCCESS,
    DELETE_GESTATIONS_SUCCESS,
    FETCH_GESTATIONS_FAILURE,
    FETCH_GESTATIONS_REQUEST,
    FETCH_GESTATIONS_SUCCESS,
} from "../_constants/action-types";

const initialState = {
    gestations: [],
    is_loading: true,
    is_error: false,
};

function gestationStore(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_GESTATIONS_REQUEST: {
            return {
                ...state,
                is_loading: true
            }
        }
        case FETCH_GESTATIONS_SUCCESS: {
            return {
                ...state,
                gestations: payload,
                is_loading: false,
                is_error: false,
            };
        }
        case FETCH_GESTATIONS_FAILURE: {
            return {
                ...state,
                is_loading: false,
                is_error: true,
            };
        }
        case ADD_GESTATIONS_SUCCESS: {
            return {
                gestations: [payload, ...state.gestations],
            };
        }
        case DELETE_GESTATIONS_SUCCESS: {
            const index = getIndex(state.gestations, payload);
            return {
                gestations: [
                    ...state.gestations.slice(0, index),
                    ...state.gestations.slice(index + 1)
                ],
            };
        }
        case CONFIRM_GESTATIONS_SUCCESS: {
            const index = getIndex(state.gestations, payload.gestationId);
            const gestations = [...state.gestations];
            gestations[index] = payload.data;
            return {
                gestations: gestations,
            };
        }
        default:
            return state;
    }
}

const getIndex = (gestations, gestationId) => {
    return gestations.findIndex(gestation => gestation.gestation_id == gestationId);
}

export default gestationStore;
