import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: "center"
  },
  rootBackground: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#6e6e6e52",
    zIndex: 9999,
    alignItems: "center"
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#11b232',
    animationDuration: '3s',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const Spinner = ({ size, withBackground }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${withBackground && classes.rootBackground}`}>
        <div className="position-relative" style={{maxHeight:`${size}px`}}>
            {!withBackground && <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={size}
                thickness={4}
                value={100}
            />}
            <CircularProgress
                variant="indeterminate"
                className={classes.top}
                classes={{
                  circle: classes.circle,
                }}
                size={size}
                thickness={4}
            />
        </div>
    </div>
  );
}

Spinner.defaultProps = {
  size: 25,
  withBackground: false
};


export default Spinner;