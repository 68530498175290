const parserFormat = {"fat": "0", "snf": "1", "clr": "2", "temp": "6", "water": "7"};

const parseMilkData = (rawData) => {    
    console.log("parseMilkData rawData: ", rawData);
    const sanitizedData = sanitizeString(rawData);
    const formatedData = formatMilkData(sanitizedData.split(" "));
    console.log("parseMilkData formatedData: ", formatedData);
    return formatedData;
}

const formatMilkData = (milkData) => {
    const milkDataKeyMap = getMilkDataKeyMap();

    return Object.keys(milkDataKeyMap).reduce((parsedData, dataKeyPosition) => {
      const dataKey = milkDataKeyMap[dataKeyPosition];
      parsedData[dataKey] = milkData[dataKeyPosition];
      return parsedData;
    }, {});
};

const sanitizeString = (string) => {
    return string.replace("#", "");
}

const getMilkDataKeyMap = () => {
    return JSON.parse(
      `{"${parserFormat.fat}": "fat", "${parserFormat.snf}": "snf", "${parserFormat.clr}": "clr", "${parserFormat.temp}": "temp", "${parserFormat.water}": "water"}`,
    );
}


export default parseMilkData;