import React from "react";
import Slider from "react-slick";
import { SLICK_SLIDER_BREAKPOINT } from "../../../../_constants";
import { currencyFormat } from "../../../library/util";

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: SLICK_SLIDER_BREAKPOINT,
};

const CollectionSlider = ({ t, today_total }) => {
  return (
    <Slider className="dairy_loan_type_slide pb-lg-0" {...sliderSettings}>
      <div className="pr-sm-1">
        <div className="t-card mb-4 mb-lg-3 shadow-sm">
          <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
            <div className="d-account-h1 pull-left mb-2">
              {t("text_total_collection")}
            </div>
          </div>
          <div className="content card__content card--material__content clearfix">
            <div className="form-row mb-2">
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.total_milk, false)}
                </div>
                <div>{t("text_liter")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.total_fat}
                </div>
                <div>{t("text_avg_fat")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.rate)}/{t("text_l")}
                </div>
                <div>{t("text_rate")}</div>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col text-center d-inline-flex flex-column">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.total_amount)}
                </div>
                <div>{t("text_amount")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.total_clr}
                </div>
                <div>{t("text_avg_clr")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.total_snf}
                </div>
                <div>{t("text_avg_snf")}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 text-center">
                <b>{today_total.cow_total_entries}</b> {t("text_cow")} +{" "}
                <b>{today_total.buffalo_total_entries}</b> {t("text_buffalo")} ={" "}
                <b>{today_total.total_entries}</b> {t("text_samples")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-sm-1">
        <div className="t-card mb-4 mb-lg-3 shadow-sm">
          <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
            <div className="d-account-h1 pull-left mb-2">
              {t("text_morning_collection")}
            </div>
          </div>
          <div className="content card__content card--material__content clearfix">
            <div className="form-row mb-2">
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.morning_milk, false)}
                </div>
                <div>{t("text_liter")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.morning_avg_fat}
                </div>
                <div>{t("text_avg_fat")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.morning_rate)}/{t("text_l")}
                </div>
                <div>{t("text_rate")}</div>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col text-center d-inline-flex flex-column">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.morning_amount)}
                </div>
                <div>{t("text_amount")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.morning_avg_clr}
                </div>
                <div>{t("text_avg_clr")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.morning_avg_snf}
                </div>
                <div>{t("text_avg_snf")}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 text-center">
                <b>{today_total.morning_entries_cow}</b> {t("text_cow")} +{" "}
                <b>{today_total.morning_entries_buffalo}</b> {t("text_buffalo")}{" "}
                = <b>{today_total.morning_entries}</b> {t("text_samples")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-sm-1">
        <div className="t-card mb-4 mb-lg-3 shadow-sm">
          <div className="title right card__title card--material__title mt-0 mb-0 clearfix">
            <div className="d-account-h1 pull-left mb-2">
              {t("text_evening_collection")}
            </div>
          </div>
          <div className="content card__content card--material__content clearfix">
            <div className="form-row mb-2">
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.evening_milk, false)}
                </div>
                <div>{t("text_liter")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.evening_avg_fat}
                </div>
                <div>{t("text_avg_fat")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.evening_rate)}/{t("text_l")}
                </div>
                <div>{t("text_rate")}</div>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col text-center d-inline-flex flex-column">
                <div className="size-20 weight-600">
                  {currencyFormat(today_total.evening_amount)}
                </div>
                <div>{t("text_amount")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.evening_avg_clr}
                </div>
                <div>{t("text_avg_clr")}</div>
              </div>
              <div className="col text-center">
                <div className="size-20 weight-600">
                  {today_total.evening_avg_snf}
                </div>
                <div>{t("text_avg_snf")}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 text-center">
                <b>{today_total.evening_entries_cow}</b> {t("text_cow")} +{" "}
                <b>{today_total.evening_entries_buffalo}</b> {t("text_buffalo")}{" "}
                <b>{today_total.evening_entries}</b> {t("text_samples")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default CollectionSlider;
