// @ts-nocheck
import React, { useCallback } from 'react';
import { DefaultLayout } from '../common/Layout';
import { useLanguage } from "../../language/LanguageController";
import { useMobile, useBrowser, useAndroid } from 'src/components/library/detect-device';
import "../../../assets/css/_Reward.scss";
import Typography from "@material-ui/core/Typography";
import RewardBalance from './RewardBalance';
import RewardHistory from './RewardHistory';
import { DefaultScrollbar } from '../common/Scrollbar';
import { useSelector } from 'react-redux';
import { PLAY_STOR_LINK } from 'src/_constants';
import { PrimaryButton } from 'src/components/common/buttons/PrimaryButton';
import ShareIcon from '@material-ui/icons/Share';
import { Card } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import analytics from "../../library/firebase/analytics";
import { socialSharing } from 'src/components/library/util';

const Reward = () => {

    const { t } = useLanguage();
    const isMobile = useMobile();
    const isAndroid = useAndroid();
    const isBrowser = useBrowser();
    const { user } = useSelector(state => state.userReducer);
    let playstoreLink = PLAY_STOR_LINK;
    let shareText = t("text_refer_desscription").replace("<Strong>{referral_code}</Strong>", t("text_code_2") + " " + user?.referral?.code);
    let shareTitle = t("text_refer_us_to_your_friends");
    const whatsAppShareLink  = `https://wa.me/?text=${shareText + ' ' + playstoreLink}`;

    const onWhatsapp = () => {
        analytics.logEvent("ReferUsToYourFreinds - ShareOnWhatsapp");
    }

    const shareData = useCallback(() => {
        analytics.logEvent("ReferUsToYourFreinds - Share");
        if(isAndroid) {
            socialSharing(shareText, shareTitle);
        } else {
            let shareData = {
                title: shareTitle,
                text: shareText,
                url: playstoreLink
            };
            navigator.share(shareData);
        }
    }, [isAndroid, playstoreLink, shareText, shareTitle]);

    return (
        <DefaultLayout
            title={t("text_reward")}
            sideNav={false}
            bottomGutter={false}
        >
            <div className={`main-section-2 ${isMobile?'mb-5 mb-3':''}`}>
                <div className="form-row mb-2 ">
                    <div className="col-12 col-md-6">
                        <DefaultScrollbar className="shadow-sm subscription-orders-list-scrollbar" isEnabled={isBrowser}>
                            <RewardBalance />
                        </DefaultScrollbar>
                    </div>
                    {!isMobile && <div className="col-12 col-md-6 pl-3 mb-5 mb-4">
                        <Typography component="h2" variant={"h5"} className="mb-2 font-weight-bold">
                            {t("text_reward_history")}
                        </Typography>
                        <div className="col px-0">
                            <DefaultScrollbar className="shadow-sm subscription-orders-list-scrollbar" isEnabled={isBrowser}>
                                <RewardHistory />
                            </DefaultScrollbar>
                        </div>
                    </div>}
                </div>
            </div>
            <Card className="fixed-bottom text-center" square elevation={4}>
                <div className='m-1'>
                    <PrimaryButton
                        size={isMobile ? "small" : "large"}
                        className={`${isBrowser ? "p-2 m-1" : 'm-2'}`}
                        variant="outlined"
                        component={'a'}
                        href={whatsAppShareLink}
                        target="_blank"
                        onClick={onWhatsapp}
                        rel="noreferrer"
                    >
                        <WhatsAppIcon />
                        &nbsp;&nbsp;
                        {t("text_share")}
                    </PrimaryButton>

                    <PrimaryButton
                        variant="contained"
                        color="primary"
                        type="button"
                        size={isMobile ? "small" : "large"}
                        className={isBrowser ? "p-2 m-1" : 'm-2'}
                        onClick={shareData}
                    >
                        <ShareIcon />
                        &nbsp;&nbsp;
                        {t("text_refer_your_friends")}
                    </PrimaryButton>
                </div>
            </Card>
        </DefaultLayout >
    )
}

export default Reward
