// @ts-nocheck
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const styledCellStyle = ({ stickyCell }) => {
    let stickyStyle = {};
    if(stickyCell) {
        stickyStyle = {
            left: 0,
            position: "sticky",
            backgroundColor: "inherit"
        }
    }
    return {
        fontSize: 12,
        whiteSpace: "nowrap",
        padding: 6,
        ...stickyStyle
    }
};

export const StyledTableCell = withStyles(() => ({
    body: ({ stickyCell }) => styledCellStyle({ stickyCell }),
    head: ({ stickyCell }) => styledCellStyle({ stickyCell })
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "rgb(237 239 255)",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#fff",
        },
    },
}))(TableRow);

export const useStyles = makeStyles({
    container: {
        maxHeight: "calc(100vh - 405px)",
    },
    tableHead: {
        backgroundColor: "#7f88f6",
        color: "#ffffff",
    },
    fixedColumn: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: "sticky"
    }
});