import moment from "moment";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MuiDatepicker from "src/components/common/MuiDatepicker";
import { useLanguage } from "src/components/language/LanguageController";
import { DefaultLayout } from "src/components/pages/common/Layout";
import { togglePremiumPopup } from "../../../../../actions";
import { checkFeatureInSubscription } from "../../../../common/FeatureFlag/FeatureFlag";
import MilkDetailTotal from "../MilkDetailTotal"
import MilkPaymentDetail from "../MilkPaymentDetail";

const MilkTotalCollection = () => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const [isFeatureSubscribed, setIsFeatureSubscribed] = useState(false);
    const [milkDetailTotalRef, setMilkDetailTotalRef] = useState(createRef());
    const [milkPaymentDetailRef, setMilkPaymentDetailRef] = useState(createRef());
    const [customerType, setCustomerType] = useState("seller");
    const [selectedDate, setSelectedDate] = useState({
        start_date: moment(),
        end_date: moment(), 
    });
    const dateChangeHandler = useCallback((name, newDate) => {
        const dairyBillingFeatureSubscribed = checkFeatureInSubscription("dairy_billing", false);
        
        if(isFeatureSubscribed) {
            milkDetailTotalRef.current?.dateChangeHandler(name, newDate);
        }
        if(dairyBillingFeatureSubscribed) {
            milkPaymentDetailRef.current?.dateChangeHandler(name, newDate);
        }
        if(isFeatureSubscribed || dairyBillingFeatureSubscribed){
            setSelectedDate({
                ...selectedDate,
                [name]: newDate
            });
        } else {
            dispatch(togglePremiumPopup());
        }
    }, [dispatch, isFeatureSubscribed, selectedDate, milkDetailTotalRef, milkPaymentDetailRef]);

    const customerTypeChangeHandler = useCallback(customerType => {
        const dairyBillingFeatureSubscribed = checkFeatureInSubscription("dairy_billing", false);

        if(isFeatureSubscribed) {
            milkDetailTotalRef.current.customerTypeChangeHandler(customerType);
        }
        if(dairyBillingFeatureSubscribed) {
            milkPaymentDetailRef.current.customerTypeChangeHandler(customerType);
        }
        if(isFeatureSubscribed || dairyBillingFeatureSubscribed){
            setCustomerType(customerType);
        } else {
            dispatch(togglePremiumPopup());
        }
    }, [dispatch, milkDetailTotalRef, milkPaymentDetailRef, isFeatureSubscribed]);

    useEffect(() => {
        setIsFeatureSubscribed(checkFeatureInSubscription("daily_milk_total", false));
    }, []);

    return (
        <DefaultLayout
            title={t("text_total_collection")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                <div className="col-12">
                    <div className="d-flex mb-2 justify-content-between">
                        <div className="btn-group mr-1 btn-group-sm" role="group" aria-label="Customer Type">
                            <button
                                className={`btn ${customerType === "seller" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => customerTypeChangeHandler("seller")}>
                                {t("text_seller")}
                            </button>
                            <button
                                className={`btn ${customerType === "buyer" ? "bg-light-blue text-white active" : "btn-light"}`}
                                type="button"
                                onClick={() => customerTypeChangeHandler("buyer")}>
                                {t("text_buyer")}
                            </button>
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm">{t("text_from")}</span>
                            </div>
                            <MuiDatepicker
                                value={selectedDate.start_date}
                                maxDate={selectedDate.end_date}
                                onChange={date => dateChangeHandler("start_date", date)}
                                name="start_date"
                                inputId="start_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm">{t("text_to")}</span>
                            </div>
                            <MuiDatepicker
                                value={selectedDate.end_date}
                                minDate={selectedDate.start_date}
                                maxDate={moment().endOf("month")}
                                onChange={date => dateChangeHandler("end_date", date)}
                                name="end_date"
                                inputId="end_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-6">
                    <MilkDetailTotal
                        onRef={(instance) => {
                            setMilkDetailTotalRef({
                                current: instance
                            });
                        }}
                        bottomGutter={false}
                        header={false}
                        customerTypeFilter={false}
                        dateFilter={false}
                        startDate={selectedDate.start_date}
                        endDate={selectedDate.end_date}
                        customerType={customerType} />
                </div>
                <div className="col-md-6">
                    <MilkPaymentDetail
                        onRef={(instance) => {
                            setMilkPaymentDetailRef({
                                current: instance
                            });
                        }}
                        bottomGutter={false}
                        header={false}
                        filter={false}
                        startDate={selectedDate.start_date}
                        endDate={selectedDate.end_date}
                        customerType={customerType}
                        innerNav={false} />
                </div>
            </div>
        </DefaultLayout>
    );
};

export default MilkTotalCollection;