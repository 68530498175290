// @ts-nocheck
import { saveMilk, fetchQueueData, deleteMilkRecord, getCollectionTotal } from "../../mobile/offline-store/milk-collection/milk-collection";
const { milkCollection } = window?.literAPI?.offlineDataStore || { milkCollection: () => {} };

export const saveMilkCollection = async (milkData) => {
    if(typeof cordova === "undefined") {
        return await milkCollection("saveMilk", milkData);
    } else {
        return await saveMilk(milkData);
    }
};

export const fetchAutoCollectionQueue = async (where) => {
    if(typeof cordova === "undefined") {
        return await milkCollection("fetchQueueData", { where });
    } else {
        return await fetchQueueData({ where });
    }
}

export const deleteMilkEntry = async (where) => {
    if(typeof cordova === "undefined") {
        return await milkCollection("deleteMilkEntry", { where })
    } else {
        return await deleteMilkRecord({ where });
    }
}

export const fetchCollectionTotal = async (where) => {
    if(typeof cordova === "undefined") {
        return await milkCollection("getCollectionTotal", { where });
    } else {
        return await getCollectionTotal({ where });
    }
}