import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff4e5',
      color: '#663c00',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #663c00',
    },
}))(Tooltip);

export default HtmlTooltip;