// @ts-nocheck
import { Button, Menu, MenuItem, Tab } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRateList, updateRateList } from "src/actions/dairyRateList";
import { useLanguage } from "src/components/language/LanguageController";
import { errorHandler } from "src/components/library/response";
import { confirmPop, notification } from "src/components/library/util";
import { ABILITY_DAIRY_RATE_LIST, PERMISSION_DELETE, PERMISSION_UPDATE } from "../../../../../_constants/permissions";
import { usePermission } from "../../../../common/AccessPermission";
import { getRateListName } from "../../../../helpers/rateListHelper";
import { HasPermission } from "../../../../common/AccessPermission";
 

const RateListTab = ({ rate_list_id, name, selected, active, onTabChange, index, ...restProps }) => {
    const { t } = useLanguage();
    const rateListNameRef = useRef();
    const dispatch = useDispatch();
    const [isRename, setIsRename] = useState(false);
    const [rateListName, setRateListName] = useState(name);
    const { hasAccess } = usePermission();
    const hasUpdateAccess = hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_UPDATE);
    const hasDeleteAccess = hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_DELETE);
    const { 
        dairyRateList: {
            rateLists
        },
        dairy: {
            dairy
        }
    } = useSelector(state => state);

    const handleRenameSubmit = (event) => {
        event.preventDefault();
        setIsRename(false);
        if(rateListName !== name) {
            updateRateList(dispatch, dairy.id, rate_list_id, JSON.stringify({
                name: rateListName,
                rate_list_type_id: 1,
                rate_list_id: rate_list_id
            }));
        }
    };

    const handleRename = event => {
        event.preventDefault();
        setAnchorEl(null);
        setIsRename(true);
    };

    const handleKeyDown = event => {
        if(event.keyCode === 27) {
            setIsRename(false);
            setRateListName(name);
        }
    }

    const handleDelete = async () => {
        try {
            setAnchorEl(null);
            if(rateLists.length > 1) {
                const isConfirm = await confirmPop(t("text_delete_confirm"));
                if(isConfirm == 1) {
                    deleteRateList(dispatch, dairy.id, rate_list_id);
                }
            } else {
                notification("text_warning", t("text_delete_last_rate_list_warning"));
            }
        } catch (error) {
            errorHandler(error);
        }
    }

    useEffect(() => {
        if(isRename) {
            rateListNameRef.current.focus();
        }
    }, [isRename]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Tab
            className="rate-list-tab"
            label={
            <>
            <span
                className="nav-link px-2 py-1 text-nowrap mw-100 overflow-hidden"
                onDoubleClick={(event) => index > 1 && handleRename(event)}
            >
                    {isRename ? <form onSubmit={handleRenameSubmit}>
                        <label className="sr-only">{t("text_rate_list_name")}</label>
                        <input
                            type="text"
                            name="name"
                            value={getRateListName(rateListName)}
                            ref={rateListNameRef}
                            onChange={event => setRateListName(event.target.value)}
                            onBlur={handleRenameSubmit}
                            onKeyDown={handleKeyDown} />
                    </form> : getRateListName(rateListName)
                    }{" "}
            </span>
            {(index > 1 && (hasUpdateAccess || hasDeleteAccess)) && <>
                <Button className="dropdown-toggle min-w-auto" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <span className="sr-only">Open Menu</span>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted={false}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {hasUpdateAccess && <MenuItem onClick={handleRename}>
                        <i className="fa fa-edit text-green mr-2"/>
                        {t("text_rename")}
                    </MenuItem>}
                    {hasDeleteAccess && <MenuItem onClick={handleDelete}>
                        <i className="fa fa-trash text-danger mr-2"/>
                        {t("text_delete")}
                    </MenuItem>}
                </Menu>
            </>}
            </>
        }
        {...restProps}/>
    )
};

export default RateListTab;