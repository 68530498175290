import * as Sentry from "@sentry/react";
import request from '../components/library/request';
import { FETCH_OFFERS_FAILURE, FETCH_OFFERS_REQUEST, FETCH_OFFERS_SUCCESS } from "../_constants/action-types";

// action creators
export const fetchOffersRequest = () => ({
    type: FETCH_OFFERS_REQUEST
});

export const fetchOffersSuccess = payload => ({
    type: FETCH_OFFERS_SUCCESS,
    payload
});

export const fetchOffersFailure = () => ({
    type: FETCH_OFFERS_FAILURE
});

export const fetchOffers = async (dispatch) => {
    try {
        dispatch(fetchOffersRequest());
        const { status, data = [] } = await request.get("/subscription/offers", {
            withAuth: true
        });
        if(status) {
            dispatch(fetchOffersSuccess(data));
        }
    } catch (error) {
        Sentry.captureException(error);
        dispatch(fetchOffersFailure());
    }
}
