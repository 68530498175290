// @ts-nocheck
import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from '@material-ui/core';
import { Page } from 'react-onsenui';
import "./subscription.css";
import { PrimaryButton } from "../../common/buttons/PrimaryButton";
import BillingCycleBox from './BillingCycleBox';
import Checkout from './Checkout';
import { styles } from "./BillingCycle/style";
import InnerHeaderSmall from '../common/InnerHeaderSmall';
import withLanguage, { getLang } from '../../language/LanguageController';
import { getDurations } from "./paymentService";
import analytics from '../../library/firebase/analytics';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DefaultLayout } from '../common/Layout';
import Eventer from '../../library/Eventer';

class BillingCycle extends Component {
    spacing = 2;

    constructor (props) {
        super(props);
        const { plan, selectedDuration: propSelectedDuration, minDuration } = this.props.history.location.state;
        const durations = getDurations(plan);
        let selectedDuration = durations[0];
        if(minDuration) {
            selectedDuration = durations.find(duration => duration.value == minDuration);
        }
        if(selectedDuration) {
            selectedDuration = {
                value: propSelectedDuration
            };
        }
        this.state = {
            minDuration: minDuration,
            selectedDuration: selectedDuration.value || 12,
            billingDurations: durations
        };
        this.durations = durations;
    }

    componentDidMount () {
        analytics.setScreenName("BillingCycle");
        Eventer.on("offerApplied", this.offerApplied);
    }

    componentWillUnmount () {
        Eventer.off("offerApplied", this.offerApplied);
    }

    offerApplied = ({ detail }) => {
        if(detail.duration != this.state.selectedDuration) {
            this.setState({
                selectedDuration: detail.duration
            });
        }
    }

    selectDuration = (e, duration) => {
        this.setState({
            selectedDuration: duration
        }, () => {
            Eventer.emit("planDurationChanged", {
                duration: duration
            });
        });
    }

    changePage = () => {
        this.props.history.push({
            pathname: "/subscription/checkout",
            state: {
                plan: this.props.history.location.state.plan,
                duration: this.state.selectedDuration,
                minDuration: this.state.minDuration,
                durations: this.durations,
                selectedAddons: this.props?.history?.location?.state?.selectedAddons ?? [],
                renew: this.props?.history?.location?.state?.renew
            }
        })
    }

    render() {
        const { t, classes, user, header } = this.props;
        const { selectedDuration, billingDurations, minDuration } = this.state;
        const { plan } = this.props.history.location.state;
        const lang = getLang();
        return (
            <DefaultLayout title={t("text_select_duration")} header={header}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="mx-3 mb-2">
                            <Typography variant="body2" align="center">
                                {t("text_duration_compare")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Box ml={3} mr={3} mb={2}>
                            <Typography variant="h5" component="h2" align="center" className={classes.planName}>
                                {t("text_plan_selected")}: {plan?.name_local?.[lang] || plan?.name}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" className={classes.durationContainer}>
                    <Grid item xs={12} >
                        <Box ml={3} mr={3}>
                            {
                                billingDurations.map(duration => 
                                    <BillingCycleBox
                                        key={duration.id}
                                        duration={duration}
                                        readOnly={minDuration <= duration.value ? false : true}
                                        isSelected={selectedDuration == duration.value || false}
                                        monthlyTotal={plan.monthly_price * 12}
                                        onSelect={this.selectDuration}/>
                                )
                            }
                        </Box>
                    </Grid>
                </Grid>
                {header && <div className="checkout-bottom-button-box">
                    {!user.referral_code ? (
                        <Grid container>
                            <Grid item xs={12} >
                                <Box textAlign="center">
                                    <PrimaryButton variant="contained" color="primary" onClick={this.changePage}>
                                        {t("text_continue")}
                                    </PrimaryButton>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container>
                        <Grid item xs={12} >
                            <Box mt={1} ml={2} mr={2} textAlign="center">
                                <Typography variant="caption" display="block" className={classes.policyText}>
                                    {t("text_subscription_duration_policy")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </div>}
            </DefaultLayout>
        );
    }
}

BillingCycle.defaultProps = {
    header: true,
    renew: false
};

const mapStateToProp = state => ({
    user: state.userReducer.user
});

const BillingCycleComp = withStyles(styles, { withTheme: true })(BillingCycle);

export default connect(mapStateToProp, null)(withRouter(withLanguage(BillingCycleComp)));