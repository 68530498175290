// @ts-nocheck
import MomentUtils from "@date-io/moment";
import { IconButton, createTheme, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FIFTEEN_DAYS, MONTHLY, ONE_DAY, TEN_DAYS, WEEKLY_MONDAY, WEEKLY_SUNDAY } from "src/_constants";
import { togglePremiumPopup } from "../../../../actions";
import { getBillingStartEndDate } from "../../../library/dateUtils";

const materialTheme = (billingCycle) => {
  return createTheme ({
    overrides: {
      MuiFormControl: {
        root: {
          maxWidth: billingCycle === MONTHLY? "70px" : "145px"
        } 
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#6e78f7",
          backgroundImage: "linear-gradient(61deg, #0ba52a 8%, #6e78f7 91%)"
        }
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: "#fff"
        }
      },
      MuiInput: {
        underline: {
          "&::after": {
            borderBottom: "2px solid #6e78f7"
          }
        }
      }
    },
  });
}

const renderInput = ({
  label,
  value,
  onClick,
  name,
  id,
  inputClass = "",
  arrowButtons,
  incrementDate,
  decrementDate,
}) => {
  const elements = [];
  if(arrowButtons) {
      elements.push(
          <div className="input-group-prepend" key="left-arrow">
              <button
                  className="btn btn-outline-secondary border-secondary-light"
                  type="button"
                  onClick={decrementDate}>
                  <i className="fa fa-chevron-left"></i>
              </button>
          </div>
      );
  }
  elements.push(
      <input
        key="input-box"
        type="text"
        readOnly
        className={`form-control MuiInput--datepicker text-center ${inputClass}`}
        id={id}
        value={value}
        onClick={onClick}
        name={name}
      />
  );
  if(arrowButtons) {
      elements.push(
          <div className="input-group-append" key="right-arrow">
              <button
                  className="btn btn-outline-secondary border-secondary-light"
                  type="button"
                  onClick={incrementDate}>
                  <i className="fa fa-chevron-right"></i>
              </button>
          </div>
      );
  }
  return (
      <>
        {label ? <label htmlFor={id}>{label}</label> : null}
        {arrowButtons 
            ? <div className="input-group">{elements}</div>
            : elements}
      </>
  );
};

class BillDatePicker extends PureComponent {
  
  constructor(props) {
    super(props);
    const extraPorps = {}
    if(props.billingCycle !== "monthly") {
      extraPorps.renderDay = this.renderWrappedWeekDay
    };

    this.state = {
      selectedDate: this.props.value || moment(),
      ...getBillingStartEndDate(props.billingCycle, this.props.value || moment()),
      calendarView: props.billingCycle === MONTHLY ? "month" : "date",
      extraPorps
    };
  }

  componentDidUpdate() {
    if(this.props.value && !this.state.startDate.isSame(this.props.value)) {
      this.setState({
        selectedDate: this.props.value,
        ...getBillingStartEndDate(this.props.billingCycle, this.props.value || moment()),
      });
    }
  }

  handleChange = date => {
    if(!this.props.isFeatureSubscribed) {
      this.props.dispatch(togglePremiumPopup());
      return false;
    }
    this.setState({ 
      selectedDate: date,
      ...getBillingStartEndDate(this.props.billingCycle, date.clone())
    }, () => {
      this.props.onChange({
        date: this.state.selectedDate,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      })
    });
  };

  formatSelectLabel = (date, invalidLabel) => {
    const { selectedDate, startDate, endDate } = this.state;
    const { billingCycle } = this.props;
    
    if (date && date.isValid()) {
      if (billingCycle === MONTHLY) {
        return `${selectedDate.format("MMM - YYYY")}`;
      } else if (billingCycle === ONE_DAY) {
        return `${selectedDate.format("Do, MMM YY")}`;
      } else if (billingCycle === WEEKLY_SUNDAY || billingCycle === WEEKLY_MONDAY)
      {
       if (startDate.format("MMM") === endDate.format("MMM")) {
        return `${startDate.format("Do ")} - ${endDate.format("Do, MMM YY")}`;
      } else {
        return `${startDate.format("Do, MMM ")} - ${endDate.format("Do, MMM YY")}`;
      }}
      else
      {
        return `${startDate.format("Do ")} - ${endDate.format("Do, MMM YY")}`;
      }
    }
    
    return invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props;
    const { startDate, endDate } = getBillingStartEndDate(this.props.billingCycle, selectedDate.clone());
    const dayIsBetween = date.isBetween(startDate, endDate);
    const isFirstDay = date.isSame(startDate);
    const isLastDay = date.isSame(endDate);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {date.format("D")} </span>
        </IconButton>
      </div>
    );
  };

  billingCycleMapping = {
    [MONTHLY]: {
      value: 1,
      unit: "M",
    },
    [TEN_DAYS]: {
      value: 10,
      unit: "d",
    },
    [ONE_DAY]: {
      value: 1,
      unit: "d",
    },
    [FIFTEEN_DAYS]: {
      value: 15,
      unit: "d",
    },
    [WEEKLY_MONDAY]: {
      value: 7,
      unit: "d",
    },
    [WEEKLY_SUNDAY]: {
      value: 7,
      unit: "d",
    },
  };

  addBillingCycleDays = (currentDate, billingCycle) => {
    
    const billingCycleValue = this.billingCycleMapping[billingCycle];
    return currentDate.add(billingCycleValue.value, billingCycleValue.unit);
  };

  subtractBillingCycleDays = (currentDate, billingCycle) => {
    const billingCycleValue = this.billingCycleMapping[billingCycle];
    return currentDate.subtract(billingCycleValue.value, billingCycleValue.unit); }

  incrementDate = () => {
    const currentDate = moment(this.state.selectedDate);
    const billingStartEndDate = getBillingStartEndDate(this.props.billingCycle, moment());
    const maxDate = this.props.maxDate || billingStartEndDate.endDate;
    const newDate = this.addBillingCycleDays(currentDate, this.props.billingCycle);

    if (newDate.isSameOrBefore(maxDate, 'day')) {
      this.handleChange(newDate);
    }
  }

  decrementDate = () => {
    const currentDate = moment(this.state.selectedDate);
    const newDate = this.subtractBillingCycleDays(currentDate, this.props.billingCycle);

    if (
      (this.props.minDate && newDate.isAfter(this.props.minDate, 'day')) ||
      !this.props.minDate
    ) {
      this.handleChange(newDate);
    }
  }

  render() {
    const { startDate, calendarView, extraPorps } = this.state;
    const { billingCycle } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={materialTheme(billingCycle)}>
          <span className={`bill-datepicker-${billingCycle} d-inline-block w-100`}>
            <DatePicker
              value={startDate}
              maxDate={moment()}
              onChange={this.handleChange}
              labelFunc={this.formatSelectLabel}
              TextFieldComponent={inputProps => renderInput({
                ...inputProps,
                arrowButtons: true,
                incrementDate: this.incrementDate,
                decrementDate: this.decrementDate,
              })}
              views={[calendarView]}
              openTo={calendarView}
              {...extraPorps}
            />
          </span>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = createStyles(theme => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: "#6e78f7",
    color: theme.palette.common.white,
  },
  firstHighlight: {
    background: "#6e78f7",
    color: theme.palette.common.white,
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    background: "#6e78f7",
    color: theme.palette.common.white,
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

BillDatePicker.defaultProps = {
  isFeatureSubscribed: true
};

export default connect()(withStyles(styles)(BillDatePicker));
