import React, { useEffect, useState } from "react";
import analytics from "../../../library/firebase/analytics";
import PropTypes from "prop-types";
import ModalDialog from "../../../common/ModalDialog";
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";

const CustomerMilkReceiveModal = ({
    t,
    validator,
    dueAmount,
    isOpen,
    onClose,
    onSubmit,
    submitBtn
}) => {

    const [amount, setAmount] = useState(dueAmount ? Math.abs(dueAmount) : 0);

    useEffect(() => {
        analytics.logEvent("CustomerMilkReceiveModal");
    });

    const amountHasError = validator.message("amount", amount, `required|min:1,num|max:${dueAmount ? Math.abs(dueAmount) : 0},num`);

    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t("text_receive_milk_bill")}
            form={{
                id: "milk_bill_receive_form",
                onSubmit
            }}
            content={
                <>
                    <div className={`form-group`}>
                        <label htmlFor="milk_bill_receive_amount_input">
                            {t("text_amount")}
                        </label>
                        <input
                            name="amount"
                            id="milk_bill_receive_amount_input"
                            type="number"
                            className={`form-control ${amountHasError && "is-invalid"}`}
                            step="0.01"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                        />
                        {amountHasError}
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="milk_bill_receive_remark_input">
                            {t("text_remark")}
                        </label>
                        <textarea
                            name="remark"
                            className="form-control"
                            id="milk_bill_receive_remark_input"
                        />
                    </div>
                </>
            }
            dialogActions={
                <PrimaryButton
                    variant="contained"
                    disabled={submitBtn}
                    type="submit"
                >
                    {t("text_receive")}
                </PrimaryButton>
            }
        />
    );
};

CustomerMilkReceiveModal.propTypes = {
    t: PropTypes.func,
    validator: PropTypes.object,
    dueAmount: PropTypes.number,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitBtn: PropTypes.bool
};

export default CustomerMilkReceiveModal;