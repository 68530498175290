import { checkFeatureInSubscription } from "../../../common/FeatureFlag/FeatureFlag";
import { currencyFormat } from "../../../library/util";
import React from "react";
import { Icon } from "react-onsenui";
import withLanguage from "../../../language/LanguageController";

const MilkTotal = ({ t, total }) => {
    return (
        <table className="table table-stripped mb-0 no-f-border">
            <tbody>
                <tr>
                    <td>{t("text_total_milk")}:</td>
                    <td className="text-center">{currencyFormat(total.total_milk, false)}</td>
                </tr>
                <tr>
                    <td>{t("text_total_amount")}:</td>
                    <td className="text-center">{currencyFormat(total.total_amount)}</td>
                </tr>
                <tr>
                    <td>{t("text_avg_milk")}:</td>
                    <td className="text-center">
                        {checkFeatureInSubscription("avg_milk", false) 
                            ? currencyFormat(total.avg_milk, false) : <Icon icon="md-lock" size={20} />}
                    </td>
                </tr>
                <tr>
                    <td>{t("text_avg_amount")}:</td>
                    <td className="text-center">
                        {checkFeatureInSubscription("avg_amount", false) 
                            ? currencyFormat(total.avg_amount) : <Icon icon="md-lock" size={20} />}
                    </td>
                </tr>
                <tr>
                    <td>{t("text_avg_rate")}:</td>
                    <td className="text-center">
                        {checkFeatureInSubscription("avg_rate", false) 
                            ? currencyFormat(total.avg_rate) : <Icon icon="md-lock" size={20} />}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default withLanguage(MilkTotal);