// @ts-nocheck
import React, { forwardRef, useEffect, useState } from "react";
import { Box, Typography, List } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux";
import AddonItem from "./AddonItem";
import { fetchAddons } from "../../../../actions/addons";
import { useLanguage } from "src/components/language/LanguageController";

const Addons = ({ showSelect, onAddonSelected, selectedAddons, buyNow, onBuyNow, forwardedRef }) => {
    const { t } = useLanguage();
    const { addons } = useSelector(state => state.addonsReducer);
    const [checked, setChecked] = useState(selectedAddons);
    const dispatch = useDispatch();
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };

    useEffect(() => {
        onAddonSelected(checked);
    }, [onAddonSelected, checked]);

    useEffect(() => {
        fetchAddons(dispatch);
    }, [dispatch])

    return (
        <div ref={forwardedRef}>
            <Box>
                <Typography component="h2" variant="body1" align="center" className="font-weight-bold">{t("text_customize_plan_with_addons")}</Typography>
            </Box>
            <List>
                {addons.map(addon => <AddonItem
                    key={addon.add_on_id}
                    handleToggle={handleToggle}
                    checked={checked}
                    showSelect={showSelect}
                    buyNow={buyNow}
                    onBuyNow={onBuyNow}
                    addon={addon}
                    {...addon} />
                )}
                
            </List>
        </div>
    );
};

Addons.defaultProps = {
    selectedAddons: [],
    onAddonSelected: () => {},
    onBuyNow: () => {},
    showSelect: true,
    buyNow: false
}

export default forwardRef((props, ref) => <Addons {...props} forwardedRef={ref} />);