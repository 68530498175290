import Model from "./Model";

class DairySetting extends Model {
    constructor(){
        super("dairy_setting");
    }

    static get tableName() {
        return "dairy_setting"; // define child's tableName here
    }
}

export default DairySetting;