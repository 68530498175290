import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 135;

export default makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      overflowY: "auto",
      overflowX: "hidden",
      top: "64px",
      height: "calc(100% - 64px)"
    },
    drawerContainer: {
      overflow: 'unset',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    menuItem: {
      flexDirection: "column",
      "&.Mui-selected": {
          backgroundColor: "#738edf",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#738edf",
          }
      },
      "& .menu-item-icon-image-active": {
          display: "none"
      },
      "&:hover": {
          backgroundColor: "#738edf",
          color: "#fff",
          "& .menu-item-icon-image": {
              display: "none"
          },
          "& .menu-item-icon-image-active": {
              display: "block"
          }
      },
      "&:hover .desktop-side-menu-submenu": {
          display: "block"
      }
    },
    menuItemIcon: {
      minWidth: "unset"
    },
    subMenu: {
      minWidth: "175px",
      marginLeft: "120px"
    },
    subMenuMenuList: {
      paddingLeft: "15px",
      paddingRight: "15px"
    },
    subMenuMenuItem: {
      height: "45px",
      "&:hover": {
          backgroundColor: "#EFEFEF",
          borderRadius: "8px",
          color: "#040404",
          fontWeight: "bold"
      }
    }
}));