// @ts-nocheck
import { AppBar, Tabs, Tooltip } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRateList, dairyAddListAddedReset, dairyAddListDeleteReset } from "src/actions/dairyRateList";
import { ABILITY_DAIRY_RATE_LIST, PERMISSION_ADD } from "../../../../../_constants/permissions";
import { togglePremiumPopup } from "../../../../../actions";
import { HasPermission } from "../../../../common/AccessPermission";
import { getFeatureLimit } from "../../../../common/FeatureFlag/FeatureFlag";
import { useLanguage } from "../../../../language/LanguageController";
import RateListTab from "./RateListTab";

const RateListTabs = ({ onTabChange }) => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const { 
        dairyRateList: {
            rateLists,
            isListAdded,
            isListDeleted
        },
        dairy: {
            dairy
        }
    } = useSelector(state => state);

    const addNewRateList = () => {
        const feature = "no_of_rate_lists";
        const rateListsLimit = getFeatureLimit(feature);
        if(rateListsLimit === "unlimited") {
            const newRateListName = `Rate List ${rateLists.length + 1}`;
            dispatch(addRateList(dairy.id, {
                name: newRateListName,
                rate_list_type_id: 7
            }))
        } else {
            dispatch(togglePremiumPopup({
                feature
            }));
        }
    };

    const handleTabChange = useCallback((index) => {
        setActiveTab(index);
        onTabChange(index);
    }, [setActiveTab, onTabChange]);

    useEffect(() => {
        if (isListAdded || isListDeleted){
            setActiveTab(rateLists.length - 1);
            onTabChange(rateLists.length - 1);
            dispatch(dairyAddListAddedReset());
            dispatch(dairyAddListDeleteReset());
        }
    }, [isListAdded,isListDeleted, rateLists.length, onTabChange, dispatch]);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        onTabChange(newValue);
    };

    return (
        <AppBar
            position="static"
            color="default"
            className="flex-row rate-list-tabs">
            <Tabs
                value={activeTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                aria-label="scrollable auto tabs example"
            >
                {rateLists.map((rateList, index) => 
                    <RateListTab
                        key={rateList.rate_list_id}
                        index={index}
                        {...rateList}
                    />
                )}
            </Tabs>
            <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_ADD}>
                <Tooltip placement="bottom" title={t("text_add_new_rate_list")}>
                    <button className="btn" onClick={addNewRateList}>
                            <i className="fa fa-plus" />
                            <span className="sr-only">
                                {t("text_add_new_rate_list")}
                            </span>
                    </button>
                </Tooltip>
            </HasPermission>
        </AppBar>
    )
};

export default RateListTabs;
