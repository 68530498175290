// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductDetail from './ProductDetail';
import { Icon, SpeedDial } from 'react-onsenui';
import { toast, confirmPop, loader, copyObject } from '../../../library/util';
import User from '../../../models/User';
import { deleteProduct, fetchProducts } from '../../../../actions/product';
import InnerNav from '../InnerNav';
import withLanguage from '../../../language/LanguageController';
import { errorHandler } from '../../../library/response';
import { FabWithFeatureFLag } from "../../../core/withFeatureFlag";
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import SingleProduct from './SingleProduct';
import { DefaultLayout } from '../../common/Layout';
import { DefaultScrollbar } from "../../common/Scrollbar";
import { withRouter } from 'react-router';
import Eventer from '../../../library/Eventer';
import { HasPermission } from '../../../common/AccessPermission';
import { ABILITY_PRODUCT_MANAGEMENT, PERMISSION_ADD } from "../../../../_constants/permissions";

class Product extends Component {
  constructor(props) {
    super(props);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.user = new User();
    this.index = null;
    this.state = {
      search: '',
      alertDialogShown: false,
      customer_id: this.user.get('customer_id'),
      error: null,
      isLoaded: false,
      product: {},
      is_edit: false,
      show_detail: false
    }
  }

  componentDidMount() {
    analytics.setScreenName("Product");
    this.props.fetchProducts(this.props.dairyId);
    Eventer.on("productAdded", this.onProductAdded);
    if( this.props.products.length > 0) {
      this.props.onProductSelect(this.props.products[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.products.length !== this.props.products.length && this.props.products.length > 0) {
      this.props.onProductSelect(this.props.products[0]);
    }
  }

  componentWillUnmount() {
    Eventer.off("productAdded", this.onProductAdded);
  }
  
  onProductAdded = () => {
    this.props.fetchProducts(this.props.dairyId);
  }

  addProduct = (e) => {
    analytics.logEvent("addProduct");
    this.props.history.push("/dairy/product/add");
  }

  async deleteProduct(e, index) {
    e.stopPropagation();
    try {
      analytics.logEvent("deleteProduct");
      const buttonIndex = await confirmPop(this.props.t("text_delete_confirm"));
      if (buttonIndex === 1) {
        loader.show(this.props.t("text_deleting"));
        let product = this.props.products[index];
        const { status, message } = await request.delete("/dairies/" + this.props.dairyId + "/products/" + product.id, {
          withAuth: true
        });
        if (status === true) {
          toast(message);
          this.props.deleteProduct(index);
        } else {
          throw message;
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  filterChangeHandler = e => {
    this.setState({
      search: e.target.value
    })
  }

  viewProductHandler = (e, product) => {
    analytics.logEvent("viewProductHandler");
    if(typeof this.props.onProductSelect === "function" && !this.state.isMobile) {
      this.props.onProductSelect(product);
    } else {
      this.props.history.push(`/dairy/product/detail/${product.id}`);
    }
  }

  onBack = () => {
    this.setState({
      is_edit: false,
      product: {}
    });
  }

  filterProducts = () => {
    let search = this.state.search;
    let products = copyObject(this.props.products);
    if (search && search.trim()) {
      search = search.trim().toLocaleLowerCase();
      products = products.filter((product) => {
        let quantity = (product.quantity) ? product.quantity : 0;
        let price = (product.price) ? product.price : 0;
        if (product.name.toLocaleLowerCase().includes(search)
          || product.code.toLocaleLowerCase().includes(search)
          || (price).toString().includes(search)
          || (quantity).toString().includes(search)) {
          return true;
        }
        return false;
      });
    }
    return products;
  }

  deviceChange = deviceDetail => {
    this.setState({
      ...deviceDetail
    });
  }

  render() {
    let products = this.filterProducts();
    const { t, header, selectedProductId, bottomGutter } = this.props;
    const { isMobile } = this.state;
    return (
      <DefaultLayout
        title={t("text_products")}
        toolbar={false}
        header={header}
        bottomGutter={bottomGutter}
        onDeviceChange={this.deviceChange}
        back>
        <form className="mb-2">
          <div className="bg-light rounded shadow-sm">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text text-warning bg-light border-0">
                  <i className="fa fa-search"></i>
                </span>
              </div>
              <label className="sr-only" htmlFor="search-bar">{t("text_search")}</label>
              <input
                type="text"
                id="search-bar"
                placeholder={t("text_search")}
                className="form-control border-0 bg-light rounded-right"
                onChange={this.filterChangeHandler} />
            </div>
          </div>
        </form>
        <DefaultScrollbar className="dairy-product-list-scrollbar">
          {products.map((product, index) => 
            <SingleProduct 
              key={product.id}
              index={index}
              product={product}
              viewProductHandler={this.viewProductHandler}
              deleteProduct={this.deleteProduct}
              active={!isMobile ? selectedProductId == product.id : false}
            />
          )}
        </DefaultScrollbar>
        <InnerNav
          menu="product"
          page="manageProduct"
          navigator={this.props.navigator}
        />
        <HasPermission ability={ABILITY_PRODUCT_MANAGEMENT} access={PERMISSION_ADD}>
          <SpeedDial position='bottom right' direction='right' className="bottom-60i right-3 d-md-none">
            <FabWithFeatureFLag
              feature="product_management"
              style={{ backgroundColor: '#11b232', color: '#fff' }}
              onClick={this.addProduct}>
              <Icon icon='md-plus' />
            </FabWithFeatureFLag>
          </SpeedDial>
        </HasPermission>
      </DefaultLayout>
    );
  }
}

Product.displayName = "Product";

Product.defaultProps = {
  products: [],
  header: true,
  bottomGutter: true
};

const mapStateToProps = state => {
  return {
    products: state.productReducer.products,
    dairyId: state.dairy.dairy.id
  }
};

const mapDispatchToProps = {
  fetchProducts,
  deleteProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withLanguage(Product))
);