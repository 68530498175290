import {
    FETCH_OFFERS_FAILURE,
    FETCH_OFFERS_REQUEST,
    FETCH_OFFERS_SUCCESS,
} from "../_constants/action-types";

const initialState = {
    offers: [],
    is_loading: true,
    is_error: false,
};

function offerStore(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_OFFERS_REQUEST: {
            return {
                ...state,
                is_loading: true
            }
        }
        case FETCH_OFFERS_SUCCESS: {
            return {
                ...state,
                offers: payload,
                is_loading: false,
                is_error: false,
            };
        }
        case FETCH_OFFERS_FAILURE: {
            return {
                ...state,
                is_loading: false,
                is_error: true,
            };
        }
        default:
            return state;
    }
}

export default offerStore;
