import Mustache from "mustache";
import { getLang } from "src/components/language/LanguageController";

export const parseHtml = (html, options = {}) => {
    const language = getLang();
    const view = Object.keys(options).reduce((view, key) => {
        view[key] = options?.[key]?.[language] || options?.[key]?.["en"];
        return view;
    }, {});
    return Mustache.render(html, view);
}