// @ts-nocheck
import withLanguage from '../../../language/LanguageController';
import React from "react";
import { useSelector } from "react-redux";
import KisanCustomerPaymentHistoryPage from "../account/customer/CustomerPaymentHistory";
import KisanCustomerLoanHistory from "../loan/customer/CustomerLoanHistory";
import { DefaultLayout } from "../../common/Layout";

const KisanPaymentLoanPage = () => {
    const dairyName = useSelector(state => state.dairy.dairy.name);
    return (
        <DefaultLayout title={dairyName}>
            <div className="form-row">
                <div className="col-12 col-sm-6">
                    <KisanCustomerLoanHistory header={false} />
                </div>
                <div className="col-12 col-sm-6">
                    <KisanCustomerPaymentHistoryPage header={false} />
                </div>
            </div>
        </DefaultLayout>
    )
};

KisanPaymentLoanPage.displayName = "KisanPaymentLoanPage";

export default withLanguage(KisanPaymentLoanPage);