// @ts-nocheck
import React, { useRef, useState } from "react";
import withLanguage from '../../language/LanguageController';
import Diary from "./Diary";
import AddMilk from "./AddMilk";
import MilkDetail from "./MilkDetail";
import MilkDetailTotal from "./MilkDetailTotal";
import MuiDatepicker from "src/components/common/MuiDatepicker";
import moment from "moment";
import { DefaultLayout } from "../common/Layout";

const MyMilkDiary = ({ t }) => {
    const milkDetailRef = useRef();
    const diaryRef = useRef();
    const milkDetailTotalRef = useRef();

    const [startDate, setStartDate] = useState(moment().startOf("month"));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const setDate = {
        start_date: setStartDate,
        end_date: setEndDate
    };

    const dateChangeHandler = (name, selectedDate) => {
        setDate[name](selectedDate);
        milkDetailRef.current.dateChangeHandler(name, selectedDate);
        diaryRef.current.dateChangeHandler(name, selectedDate);
        milkDetailTotalRef.current.dateChangeHandler(name, selectedDate);
    };

    return (
        <DefaultLayout
            title={t("text_my_milk_diary")}
            toolbar={false}
            bottomGutter={false}
            back>
            <div className="form-row">
                <div className="col-12 col-sm-3">
                    <AddMilk header={false} />
                </div>
                <div className="col-12 col-sm-9">
                    <div className="form-row mb-2">
                        <div className="col-12">
                            <div className="input-group" role="group" aria-label="Date Selection">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-sm px-2">{t("text_from")}</span>
                            </div>
                            <MuiDatepicker
                                value={startDate}
                                maxDate={endDate}
                                onChange={selectedDate => dateChangeHandler("start_date", selectedDate)}
                                name="start_date"
                                inputId="start_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                            <div className="input-group-prepend input-group-append">
                                <span className="input-group-text text-sm px-2">{t("text_to")}</span>
                            </div>
                            <MuiDatepicker
                                value={endDate}
                                minDate={startDate}
                                maxDate={moment().endOf("month")}
                                onChange={selectedDate => dateChangeHandler("end_date", selectedDate)}
                                name="end_date"
                                inputId="end_date"
                                inputClass="bg-white"
                                arrowButtons={false}
                            />
                        </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 col-sm-4">
                            <MilkDetail
                                header={false}
                                filter={false}                                
                                onRef={(instance) => {
                                    milkDetailRef.current = instance
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <Diary
                                header={false}
                                filter={false}
                                onRef={(instance) => {
                                    diaryRef.current = instance
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <MilkDetailTotal
                                header={false}
                                filter={false}
                                onRef={(instance) => {
                                    milkDetailTotalRef.current = instance
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
};

MyMilkDiary.displayName = "MyMilkDiary";

export default withLanguage(MyMilkDiary);