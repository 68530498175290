/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { currencyFormat } from "../../library/util";
import { useStyles } from "./BillingCycle/BillingCycleBoxStyle";
import withLanguage from "../../language/LanguageController";

const BillingCycleBox = ({ t, language, duration, readOnly, isSelected, monthlyTotal, onSelect}) => {

    const classes = useStyles();
    const purchaseSave = monthlyTotal - (duration.price/duration.value * 12);
    const purchaseSavePercent = purchaseSave/monthlyTotal * 100;
    return (
        <Grid item mr={5} ml={5} onClick={e => !readOnly ? onSelect(e, duration.value) : ""}>
            <Paper className={`${classes.duration} ${isSelected ? classes.durationSelected : ""}`}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={9}>
                        <Box pt={1} pb={1} pl={3} pr={1} >
                            <Box mb={1}>
                                <Typography variant="subtitle1" gutterBottom ={false} display="block">
                                    {duration.name[language] || duration.name["en"]} @&nbsp;
                                    <span className={classes.pricePerMonth}>₹ {(duration.price/duration.value)}</span>
                                    <span className={classes.pricePerMonthText}> / {t("text_month")}</span>
                                </Typography>
                                <Typography variant="body1" display="block">
                                        {t("text_price")}: {currencyFormat(duration.price)}
                                </Typography>
                                {duration.value > 1 ? (
                                    <Typography variant="body1" className={classes.saveOnPurchase} gutterBottom display="block">
                                    {t("text_savings")}: {currencyFormat(purchaseSave)} ({purchaseSavePercent.toFixed(1)}%)
                                    </Typography>
                                ) : null}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container
                            justifyContent="center"
                            direction="column"
                            alignContent="center"
                            className={classes.durationRadioContainer}>
                            <Grid item>
                                <Radio
                                    checked={isSelected}
                                    icon={<RadioButtonUncheckedIcon fontSize="large"  className={`${classes.durationRadio} ${isSelected ? classes.durationRadioSelected : ""}`}/>}
                                    checkedIcon={<CheckCircleIcon fontSize="large" className={classes.durationRadioSelected}/>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default withLanguage(BillingCycleBox);