// @ts-nocheck
import React, { useEffect } from 'react'
import { useLanguage } from "../../language/LanguageController";
import Typography from "@material-ui/core/Typography";
import { useMobile } from 'src/components/library/detect-device';
import { Box, Card, Grid } from "@material-ui/core";
import coinImage from "../../../assets/img/reward/coins.png";
import rupcoinImage from "../../../assets/img/reward/rupee.png";
import iconOne from "../../../assets/img/reward/icon-1.png";
import iconTwo from "../../../assets/img/reward/icon-2.png";
import iconThree from "../../../assets/img/reward/icon-3.png";
import iconFour from "../../../assets/img/reward/star.png";
import iconArrow from "../../../assets/img/reward/arrow.png";
import "../../../assets/css/_Reward.scss";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { fetchCustomerReward } from '../../../actions/reward';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import RewardFAQ from './RewardFAQ'

const RewardBalance = () => {

    const { t } = useLanguage();
    const isMobile = useMobile();
    const { reward } = useSelector(state => state.reward);
    const { user } = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchCustomerReward(dispatch);
    }, []);

    return (
        <div className="form-row mb-2">
            <div className="col-12 col-md-12 subscription_box_bg">
                <div className="form-row mb-2 ">
                    <div className="col">
                        <Grid container >
                            <Grid item xs={12}>
                                <Box className="p-2">
                                    <Grid container>
                                        <Grid item className='pt-4' xs={9}>
                                            <Box >
                                                <Typography component="span" variant="subtitle2" className="text-white">
                                                    {t("text_refer_your_friends")}
                                                </Typography>
                                                <Typography component="h2" variant="h6" className="mb-2 text-white font-weight-bold">
                                                    {t("text_earn_points_each").replace("{earn_points}", reward.earn_reward_points ? reward.earn_reward_points : '')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} className="text-right">
                                            <img src={coinImage} className="img-responsive mb-3 coinImageStyle" alt="blank" />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-12 totalRewardBoxStyle" >
                <Grid item xs={12}>
                    <Box className='mr-3 ml-3'>
                        <Card className="p-2 ">
                            <Box className='d-flex justify-content-between'>
                                <Box className='d-flex justify-content-start '>
                                    <Box>
                                        <img src={rupcoinImage} alt="" className="img-responsive pl-2 iconStyle" />
                                    </Box>
                                    <Box>
                                        <Typography component="span" variant="subtitle2" className='ml-3 font-weight-bold totalRewardStyle'>
                                            {t("text_total_reward_points")}
                                        </Typography><br />
                                        <Typography component="span" variant="h6" className='ml-3 font-weight-bold'>
                                            {reward.points}
                                        </Typography>
                                    </Box>
                                </Box>
                                {isMobile && <Box className='align-self-center'>
                                    <Box className='rightArrowStyle '> <Link to={'reward-history'}><ChevronRightIcon /></Link> </Box>
                                </Box>}
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            </div>

            <div className="col-12 col-md-12" >
                <Card className="p-4 mt-3 bg-red cardStyle" >
                    <Box className='d-flex justify-content-start'>
                        <Box>
                            <Box className='iconStyle2'>
                                <img src={iconTwo} className="img-responsive " alt="blank" />
                            </Box>
                        </Box>
                        <Typography className="ml-2 font-weight-bold rewardTextStyle mt-1" component="span" variant="subtitle2">
                            {t("text_invite_your_friend_to_install_the_app_with_link")}
                        </Typography>
                    </Box>
                    <Box className='mt-1 mb-1'>
                        <img src={iconArrow} className="img-responsive  iconArrowStyle" alt="blank" />
                    </Box>
                    <Box className='d-flex justify-content-start'>
                        <Box>
                            <Box className='iconStyle2'>
                                <img src={iconOne} className="img-responsive " alt="blank" />
                            </Box>
                        </Box>
                        <Typography className="ml-2 font-weight-bold rewardTextStyle mt-1" component="span" variant="subtitle2">
                            {t("text_when_your_friend_create_account")}
                        </Typography>
                    </Box>
                    <Box className='mt-1 mb-1 pl-3'>
                        <img src={iconArrow} className="img-responsive iconMirrorArrowStyle " alt="blank" />
                    </Box>
                    <Box className='d-flex justify-content-start'>
                        <Box>
                            <Box className='iconStyle2'>
                                <img src={iconThree} className="img-responsive " alt="blank" />
                            </Box>
                        </Box>
                        <Typography className="ml-2 font-weight-bold rewardTextStyle mt-1" component="span" variant="subtitle2">
                            {t("text_you_and_your_friend_both_of_you_will_points").replace("{earn_points}", reward.earn_reward_points ? reward.earn_reward_points : '')}
                        </Typography>
                    </Box>
                    {user.customer_group_id==2 ?
                    (<>
                    <Box className='mt-1 mb-1'>
                        <img src={iconArrow} className="img-responsive  iconArrowStyle" alt="blank" />
                    </Box>
                    <Box className='d-flex justify-content-start'>
                        <Box>
                            <Box className='iconStyle2'>
                                <img src={iconFour} className="img-responsive " alt="blank" />
                            </Box>
                        </Box>
                        <Typography className="ml-2 font-weight-bold rewardTextStyle mt-1" component="span" variant="subtitle2">
                            {t("text_when_your_friend_subscribes_you_both_will_get_reward_points_equal_to_the_amount_paid")}
                        </Typography>
                    </Box>
                    </>)
                    :""}
                </Card>
                {reward && <RewardFAQ reward={reward}/>}
            </div>
        </div>
    )
}

export default RewardBalance
