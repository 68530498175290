import React from "react";
import { Icon } from "react-onsenui";
import { Typography } from "@material-ui/core";
import withLanguage from "../../language/LanguageController";
import { Link } from "react-router-dom";

const UserAddress = ({ t, address = {} }) => {
    return (
        <div className="t-card shadow-sm">
            <div className="card-toolbar-dropdown dropdown gesation-cicular-dropdown dropdown_zindex">
                <Link
                    className="btn"
                    title={t("text_edit_adress")}
                    to="/account/address/edit">
                    <Icon style={{"color": "#5cb848","fontSize": "20px"}} icon="md-edit" />
                    <span className="sr-only">{t("text_edit_adress")}</span>
                </Link>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5>{t("text_address")}</h5>
                    <Typography variant="body2" display="block">
                        {address?.address_1}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                        {address?.city}, {address?.state} - {address?.postcode}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default withLanguage(UserAddress)