import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { IconButton } from "@material-ui/core";

const style = {
    color: "#fff",
    fontWeight: 600,
    backgroundColor: red[700],
    "&:hover": {
        backgroundColor: red[500],
    },
}
export const RedButton = withStyles(theme => ({
    root: style,
}))(Button);

export const RedIconButton = withStyles(theme => ({
    root: style,
}))(IconButton);