import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';

const SecondaryFab = withStyles(theme => ({
  root: {
      color: theme.palette.common.white,
      backgroundColor: "#4156bd",
      '&:hover': {
        backgroundColor: "#3549ad",
      },
  },
}))((props) => <Fab color="default" {...props} />);

export default SecondaryFab;