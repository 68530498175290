// @ts-nocheck
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import { ActionSheet } from 'react-onsenui';
import OTPInput from "react-otp-input";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import startPasswordImg from "src/assets/img/star-password.png";
import { OTP_TIMEOUT } from '../../../../_constants';
import { fetchAppConfiguration } from "../../../../actions";
import { fetchSubscription } from "../../../../actions/subscriptions";
import { loginSuccess } from "../../../../actions/user";
import { isFeatureEnabled } from '../../../common/FeatureFlag/FeatureFlag';
import SimpleValidator from '../../../common/SimpleValidator';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import withLanguage from '../../../language/LanguageController';
import { trackUser } from '../../../library/errorTracking';
import analytics from '../../../library/firebase/analytics';
import { stopWatch, watchOTP } from "../../../library/otpReader";
import request from '../../../library/request';
import { errorHandler } from '../../../library/response';
import { loader, replaceAll, resendOtp, toast } from "../../../library/util";

class VerifyOtp extends Component {

  constructor(props) {
    super(props);
    this.otpTime = OTP_TIMEOUT;
    this.formId = "otp-form";
    this.state = {
      toastShown: false,
      alertShown: false,
      otp: '',
      submitBtn: false,
      time_left: this.otpTime,
      autoReadOtpActionSheet: true,
      shouldAutoFocusOtp: false,
      mobile: props?.location?.state?.mobile || "",
      customerId: props?.location?.state?.customer_id
    };
    this.verifyOtp = this.verifyOtp.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.validator = new SimpleValidator();
  }

  componentDidMount () {
    analytics.setScreenName("VerifyOtp");
    this.timerId = setInterval(this.countdown, 1000);
    if(isFeatureEnabled("auto_otp_read")) {
      this.readOTPFromSms();
    }
  }

  componentWillUnmount () {
    stopWatch();
  }

  readOTPFromSms = async () => {
    const otp = await watchOTP();
    this.setOTP(otp, true);
  }

  handleInput = e => {
    let target = e.target;    
    let name = target.name;
    let value = target.value;    
    this.setState({
      [name]: value
    });
  }

  setOTP = (otp, autoSubmit = true) => {
    this.setState({
      otp: otp,
      autoReadOtpActionSheet: false
    }, () => {
      if(autoSubmit) {
        this.verifyOtp();
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();    
    this.verifyOtp();
  }

  async verifyOtp () {
    try {
      // validate form before submitting to server 
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // rerender to show messages for the first time
        return false;
      }
      analytics.logEvent("verifyOtp");
      loader.show(this.props.t("text_verifying"));
      this.setState({
        submitBtn: true
      });      
      const { status, data, message } = await request.post('/auth/verifyOtp', {
        withAuth: true,
        body: JSON.stringify({
          "customer_id": this.props.history.location.state.customer_id,
          "otp": this.state.otp
        })
      });

      if (status) {
        analytics.setUserId(data.customer_id);
        analytics.setUserProperty("userName", data.firstname + " " + data.lastname);
        analytics.setUserProperty("userGroup", data.customer_group_id);
        trackUser(data);
        await this.props.loginSuccess(data);
        toast(this.props.t('msg_login_success'));
        if(data.customer_group_id == 2) {
          const pathToGo = this.props.referral_type === "dealer" ? "/accountActivation" : "/dairy"
          this.props.history.replace(pathToGo);
        } else {
          this.props.history.replace("/");
        }
      } else {
        throw message;
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({
        submitBtn: false
      });
    }
  }

  async resendOtp(e, otpType) {
    e.preventDefault();
    try {
      analytics.logEvent("verifyOTPResendOTP");
      loader.show(this.props.t("text_resending_otp"));

      await resendOtp({
        "customer_id": this.state.customerId,
        "mobile": this.state.mobile,
        "otp_via": otpType
      });

      this.setState({
        time_left: this.otpTime
      }, () => {
        this.timerId = setInterval(this.countdown, 1000);
      });      
    } catch (err) {
      errorHandler(err);
    } finally {
      loader.hide();
      this.setState({
          submitBtn: false
      });
    }
  }

  countdown = () => {
    if (this.state.time_left <= 0) {
      clearTimeout(this.timerId);
    } else {
      let timeLeft = this.state.time_left-1;
      this.setState({
        time_left: timeLeft
      });
    }
  }

  entryOtpManually = () => {
    this.setState({
      autoReadOtpActionSheet: false,
      shouldAutoFocusOtp: true
    }, () => {
      document.querySelector(".otp_input").focus();
    });
  }

  render() {
    const { submitBtn, autoReadOtpActionSheet, mobile } = this.state;
    const { t, language } = this.props;
    return (
    <div>
      <div className="login_mobile">
        <div className="clearfix">
          <div className="screen_mobile_number clearfix col-md-12">
            <div className="m_login_header text-center pt-5 mt-5">
              <h2 className="enter_code_heading">
                {t('text_enter_code')}
              </h2>
              <img className="img-responsive text-center" 
                src={startPasswordImg} alt="1" />
              <p dangerouslySetInnerHTML={{__html: replaceAll(t("verify_opt_text"), '{number}', (mobile ? mobile : this.props.mobile).substr(-4, 4))}} />
            </div>
            <div className="loginmobileform clearfix col-12 col-sm-6 mx-auto max-width-500">
              <div className="divSignupForm">
                <form method="POST" id={this.formId} 
                  onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <div className="inline_input inline_input_otp col-md-12">
                      <div className="">
                        <OTPInput
                          value={this.state.otp}
                          onChange={this.setOTP}
                          isInputNum
                          numInputs={6}
                          inputStyle="otp_input"
                          placeholder="000000"
                          shouldAutoFocus={true}
                        />
                        {this.validator.message("otp", this.state.otp, "required|min:6", {className: "mt-3 text-center"})}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-md-12">
                      <input 
                        className="form-control" 
                        type="submit"
                        value={submitBtn ? t('text_verifying') : t('btn_verify')} />
                    </div>
                  </div>
                </form>
              </div>
              <div className="otp_not_received">
                {this.state.time_left <= 0 ? (
                  <p>
                    <button className="btn btn-link" onClick={e => this.resendOtp(e, "text")}>
                      {t("text_resend_otp")}
                    </button>
                    <button className="btn btn-link" onClick={e => this.resendOtp(e, "voice")}>
                      {t("text_resend_otp_call")}
                    </button>
                  </p>
                ) : (
                  (language === "en") ? 
                    (<p className="resent_in">{t('text_resend_in')} {this.state.time_left} {t('seconds')}</p>)
                    : (<p className="resent_in">{this.state.time_left} {t('seconds')} {t('text_resend_in')}</p>)
                )}
              </div>
            </div>
          </div>
        </div>
        {isFeatureEnabled("auto_otp_read") ? (
          <ActionSheet isOpen={autoReadOtpActionSheet} animation='default'>
              <div className="bg-white px-2 py-3 text-center">
                <div className="mb-3">
                  <CircularProgress color="primary" size={30} className="text-success" />
                </div>
                <p>
                  {t("auto_verify_otp_body")}
                </p>
                <p className="text-uppercase">
                  <b>{t("text_or")}</b>
                </p>
                <PrimaryButton className="btn" onClick={this.entryOtpManually}>
                  {t("text_enter_manually")}
                </PrimaryButton>
              </div>
          </ActionSheet>
        ) : null}
      </div>
    </div>);
  }
}

VerifyOtp.defaultProps = {
  mobile: "9907085461",
  referral_type: ""
};

const mapDispatchToProps = {
  loginSuccess,
  fetchAppConfiguration,
  fetchSubscription
};

export default connect(null, mapDispatchToProps)(withRouter(withLanguage(VerifyOtp)));