
// eslint-disable-next-line no-undef
export const listPermissions = () => cordova?.plugins?.permissions;

export const hasPermission = (permissionToCheck) => {
    return new Promise((resolve, reject) => {
        listPermissions().checkPermission(permissionToCheck, function( status ){
            if ( status.hasPermission ) {
                console.log("Yes :D ");
                resolve(true);
            } else {
                console.log("No :D ");
                resolve(false);
            }
          });
    })
}

export const requestPermission = (permissionToRequest) => {
    return new Promise((resolve, reject) => {
        listPermissions().requestPermission(permissionToRequest, status => {
            resolve(status.hasPermission);
        }, error => {
            reject(error);
        });
    })
}
