import moment from "moment";
import { FIFTEEN_DAYS, MONTHLY, ONE_DAY, TEN_DAYS, WEEKLY_MONDAY, WEEKLY_SUNDAY } from "src/_constants";

export const getTenDaysStartEndDate = selectedDate => {
    const dateFormat = "YYYY-MM-DD";
    const selectedDay = selectedDate.format("DD");
    let startDate, endDate;
    const selectedDateCloned = selectedDate.clone();
    if (selectedDay.startsWith("0") || selectedDay === "10") {
        startDate = selectedDateCloned.format("YYYY-MM-01");
        endDate = selectedDateCloned.format("YYYY-MM-10");
    } else if (selectedDay.startsWith("1") || selectedDay === "20") {
        startDate = selectedDateCloned.format("YYYY-MM-11");
        endDate = selectedDateCloned.format("YYYY-MM-20");
    } else if (selectedDay.startsWith("2") || selectedDay.startsWith("3")) {
        startDate = selectedDateCloned.format("YYYY-MM-21");
        endDate = selectedDateCloned.endOf("month").format("YYYY-MM-DD");
    }
    return {
        startDate: moment(startDate, dateFormat),
        endDate: moment(endDate, dateFormat)
    };
};

export const getBillingStartEndDate = (billingCycle, selectedDate) => {
    const dateFormat = "YYYY-MM-DD";
    const selectedDay = selectedDate.format("DD");
    let startDate, endDate;
    const selectedDateCloned = selectedDate.clone();

    if (billingCycle === MONTHLY) {
        startDate = selectedDateCloned.startOf("month").format(dateFormat);
        endDate = selectedDateCloned.endOf("month").format(dateFormat);
    } else if (billingCycle === FIFTEEN_DAYS) {
        if (selectedDay <= 15) {
            startDate = selectedDateCloned.startOf("month").format(dateFormat);
            endDate = selectedDateCloned.date(15).format(dateFormat);
        } else {
            startDate = selectedDateCloned.date(16).format(dateFormat);
            endDate = selectedDateCloned.endOf("month").format(dateFormat);
        }
    } else if (billingCycle === TEN_DAYS) {
        if (selectedDay <= 10) {
            startDate = selectedDateCloned.startOf("month").format(dateFormat);
            endDate = selectedDateCloned.date(10).format(dateFormat);
        } else if (selectedDay <= 20) {
            startDate = selectedDateCloned.date(11).format(dateFormat);
            endDate = selectedDateCloned.date(20).format(dateFormat);
        } else {
            startDate = selectedDateCloned.date(21).format(dateFormat);
            endDate = selectedDateCloned.endOf("month").format(dateFormat);
        }
    } else if (billingCycle === WEEKLY_SUNDAY) {
        startDate = selectedDateCloned.startOf('week').format(dateFormat);
        endDate = selectedDateCloned.endOf('week').format(dateFormat);
    } else if (billingCycle === WEEKLY_MONDAY) {
        startDate = selectedDateCloned.startOf('isoWeek').format(dateFormat);
        endDate = selectedDateCloned.endOf('isoWeek').format(dateFormat);
    } else {
        startDate = selectedDateCloned.startOf("day").format(dateFormat);
        endDate = selectedDateCloned.endOf("day").format(dateFormat);
    }
    return {
        startDate: moment(startDate, dateFormat),
        endDate: moment(endDate, dateFormat)
    };
};

export const get10DaysCycleDiff = (startDate) => {
    const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
    const { startDate: currentStartDate, endDate: currentEndDate } = getBillingStartEndDate(TEN_DAYS, moment());
    if (!startDateMoment.isBetween(currentStartDate, currentEndDate, undefined, "[]")) {
        const selectedDay = currentStartDate.format("DD");
        let lastStartDate, lastEndDate;
        if (selectedDay.startsWith("0") || selectedDay === "10") {
            const lastMonth = moment().subtract(1, "months");
            lastStartDate = lastMonth.format("YYYY-MM-21");
            lastEndDate = lastMonth.endOf("month").format("YYYY-MM-DD");
        } else if (selectedDay.startsWith("1") || selectedDay === "20") {
            lastStartDate = moment().format("YYYY-MM-01");
            lastEndDate = moment().format("YYYY-MM-10");
        } else if (selectedDay.startsWith("2") || selectedDay.startsWith("3")) {
            lastStartDate = moment().format("YYYY-MM-11");
            lastEndDate = moment().format("YYYY-MM-20");
        }
        if (lastStartDate && lastEndDate) {
            return startDateMoment.isBetween(lastStartDate, lastEndDate, undefined, "[]");
        }
    }

    return false;
};

export const get15DaysCycleDiff = (startDate) => {
    const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
    const { startDate: currentStartDate, endDate: currentEndDate } = getBillingStartEndDate(FIFTEEN_DAYS, moment());
    
    if (!startDateMoment.isBetween(currentStartDate, currentEndDate, undefined, "[]")) {
        const selectedDay = currentStartDate.format("DD");
        let lastStartDate, lastEndDate;
        
        if (selectedDay <= 15) {
            const lastMonth = moment().subtract(1, "months");
            lastStartDate = lastMonth.date(16).format("YYYY-MM-DD");
            lastEndDate = lastMonth.endOf("month").format("YYYY-MM-DD");
        } else {
            lastStartDate = moment().startOf("month").format("YYYY-MM-DD");
            lastEndDate = moment().date(15).format("YYYY-MM-DD");
        }

        if (lastStartDate && lastEndDate) {
            return startDateMoment.isBetween(lastStartDate, lastEndDate, undefined, "[]");
        }
    }
    return false;
};

export const get7DaysCycleDiffSunday = (startDate) => {
    const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
    const { startDate: currentStartDate, endDate: currentEndDate } = getBillingStartEndDate(WEEKLY_SUNDAY, moment());
    if (!startDateMoment.isBetween(currentStartDate, currentEndDate, undefined, "[]")) {
        const lastWeek = moment().subtract(1, "weeks");
        const lastStartDate = lastWeek.startOf('week').format("YYYY-MM-DD");
        const lastEndDate = lastWeek.endOf('week').format("YYYY-MM-DD");
        return startDateMoment.isBetween(lastStartDate, lastEndDate, undefined, "[]");
    }
    return false;
};

export const get7DaysCycleDiffMonday = (startDate) => {
    const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
    const { startDate: currentStartDate, endDate: currentEndDate } = getBillingStartEndDate(WEEKLY_MONDAY, moment());
    if (!startDateMoment.isBetween(currentStartDate, currentEndDate, undefined, "[]")) {
        const lastWeek = moment().subtract(1, "weeks");
        const lastStartDate = lastWeek.startOf('isoWeek').format("YYYY-MM-DD");
        const lastEndDate = lastWeek.endOf('isoWeek').format("YYYY-MM-DD");
        return startDateMoment.isBetween(lastStartDate, lastEndDate, undefined, "[]");
    }
    return false;
};

export const get1DayCycleDiff = (startDate) => {
    const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
    const { startDate: currentStartDate, endDate: currentEndDate } = getBillingStartEndDate(ONE_DAY, moment());
    if (!startDateMoment.isBetween(currentStartDate, currentEndDate, undefined, "[]")) {
        const lastDay = moment().subtract(1, "days");
        const lastStartDate = lastDay.startOf('day').format("YYYY-MM-DD");
        const lastEndDate = lastDay.endOf('day').format("YYYY-MM-DD");
        return startDateMoment.isBetween(lastStartDate, lastEndDate, undefined, "[]");
    }
    return false;
};
