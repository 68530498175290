import React from "react";
import Select from "react-select";
import { getCustomersForSelect } from "../../library/customer";
import PropTypes from "prop-types";
import withLanguage, { t } from "../../language/LanguageController";

const CustomersSelect = ({
    t,
    customers,
    onChange,
    name,
    id = "",
    allOption,
    parseCustomers,
    className,
    placeholder,
    ...restProps
}) => {
    const options = parseCustomers ? getCustomersForSelect(customers) : customers;
    if(allOption) {
        options.unshift({
            label: t("text_all_customers"),
            value: ""
        });
    }
    return (
        <Select
            noOptionsMessage={() => {
                return t("text_no_options");
            }}
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            name={name}
            inputId={id}
            className={`customer-select zIndex-1000i ${className ? className : ""}`}
            {...restProps}/>
    );
};

CustomersSelect.propTypes = {
    customers: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.object,
    inHeader: PropTypes.bool,
    allOption: PropTypes.bool,
    parseCustomers: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string
};

CustomersSelect.defaultProps = {
    onChange: () => {},
    inHeader: true,
    allOption: false,
    name: "dairy_customer_id",
    parseCustomers: true,
    placeholder: t("text_select_customer")
};

export default withLanguage(CustomersSelect);