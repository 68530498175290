import React, { useEffect, useState } from 'react';
import analytics from '../../../../library/firebase/analytics';
import { DefaultLayout } from '../../../common/Layout';
import { useLanguage } from '../../../../language/LanguageController';
import UserForm from '../userForm';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDairyUsers } from '../../../../../actions/dairyUsers';

function EditUserPage () {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState(null);
    const { dairyUserId } = useParams();
    const { id: dairyId } = useSelector(state => state.dairy.dairy);
    const { users } = useSelector(state => state.dairyUsers);
    useEffect(() => {
        analytics.setScreenName("EditUserPage");
    }, []);

    useEffect(() => {
        if(users.length === 0) {
            fetchDairyUsers(dispatch, dairyId);
        }
    }, [dispatch, dairyId, users]);

    useEffect(() => {
        if(dairyUserId) {
            const foundUser = users.find(user => user.customer_dairy_id == dairyUserId);
            setEditUser(foundUser);
        } else {
            setEditUser(null);
        }
    }, [dairyUserId, users]);
    
    return (
        <DefaultLayout
            back
            bottomGutter={false}
            title={t("text_dairy_users_edit_user")}
            toolbar={false}
        >
            <UserForm editUser={editUser} isEdit={true}/>
        </DefaultLayout>
    );
}
  
export default EditUserPage;