// @ts-nocheck
import React, { Fragment } from "react";
import config from "../../../../config";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import withLanguage from "../../../language/LanguageController";
import PropTypes from "prop-types";
import { ucFirst } from "../../../library/util";
import { swalPopup } from "../../../common/SwalPopup";
import OrderDetailsPopup from "../OrderDetailsPopup";
import moment from "moment";
import analytics from "../../../library/firebase/analytics";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));
const OrdersList = ({ t, language, orders }) => {
    const classes = useStyles();

    if (!orders.length) return null;

    const showDetails = (order) => {
        analytics.logEvent("showOrderDetail");
        let { order_id, integration_payment_response, addons, plans } = order;
        if(typeof integration_payment_response === "string") {
            integration_payment_response = JSON.parse(integration_payment_response);
        }
        const Content = () => <OrderDetailsPopup
          orderId={order_id}
          order={order}
          paymentDetail={integration_payment_response || {}}
          t={t}
          language={language}
          addOns={addons}
          plans={plans}
        />

        swalPopup(Content, {
            title: t("text_payment_detail"),
            icon: 'info',
            confirmButtonText: t("text_close"),
            allowOutsideClick: false,
            customClass: {
              icon: "swal-popup-icon-small"
            },
        });
    };

    return (
      <List className={classes.root}>
        {orders.map((order, index) => (
          <Fragment key={order.order_id}>
            <ListItem dense button onClick={e => order.order_status == "paid" ? showDetails(order) : null}>
              <ListItemAvatar>
                <Fragment>
                  <Typography variant="h5" component="h6" display="block" align="center">
                    {moment(order.date_added).format("DD")}
                  </Typography>
                  <Typography variant="subtitle1" display="block" align="center">
                    {moment(order.date_added).format("MMM")}
                  </Typography>
                  <Typography variant="body2" display="block" align="center">
                    {moment(order.date_added).format("YYYY")}
                  </Typography>
                </Fragment>
              </ListItemAvatar>
              <ListItemText
                primary={order?.plan?.name_local ? order?.plan?.name_local?.[language] : order?.plan?.name}
                secondary={
                  <Fragment>
                    <Typography component="span" display="block" variant="body2">
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="textPrimary"
                      >
                        {t("text_duration")}: 
                      </Typography>{" "}
                      {order.duration} {order.duration > 1 ? (
                        order.integration_type == "referral" ? t("text_days") : t("text_months")
                      ) : order.integration_type == "referral" ? t("text_day") : t("text_month")}
                    </Typography>
                    <Typography component="span" display="block" variant="body2">
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="textPrimary"
                      >
                        {t("text_date")}:
                      </Typography>{" "}
                      {moment(order.date_added).format(config.display_datetime_format)}
                    </Typography>
                    <Typography component="span" display="block" variant="body2">
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="textPrimary"
                      >
                        {t("text_status")}:
                      </Typography>{" "}
                      {ucFirst(t(`text_status_${order.order_status}`))}
                    </Typography>
                  </Fragment>
                }
              />
            </ListItem>
            {+index + 1 < orders.length ? (
              <Divider variant="inset" component="li" />
            ) : null}
          </Fragment>
        ))}
      </List>
    );
};

OrdersList.displayName = "OrdersList";

OrdersList.propTypes = {
    orders: PropTypes.array.isRequired,
};

const mapStateToProp = (state) => ({
    orders: state.subscription.orders,
});

export default connect(mapStateToProp)(withLanguage(OrdersList));
