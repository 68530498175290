// @ts-nocheck
import React, { useCallback, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useLanguage } from "../../../language/LanguageController";
import { Card, CardActionArea, CardContent, makeStyles } from "@material-ui/core";
import GiftBoxImg from "src/assets/img/subscription/offers/gift_box.png";
import ModalDialog from "src/components/common/ModalDialog";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";
import { parseHtml } from "src/htmlTemplating";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    offerCardStyle: props => ({
        backgroundColor: props?.background_color + "!important",
        borderColor: props?.border_color + "!important",
        color: props?.text_color + "!important",
        height: "100%"
    }),
    offerFooterStyle: props => ({
        backgroundColor: props?.footer_bg_color + "!important",
        position: "absolute",
        bottom: 0,
        width: "100%"
    }),
});

const SingleOffer = ({ title, description, coupon, config }) => {
    const { t, language } = useLanguage();

    const [isModalOpen, toggleModal] = useState(false);
    const classes = useStyles(config?.card_style || {});
    const plans = useSelector(state => state.subscription.plans);
    const history = useHistory();

    const applyOffer = useCallback(() => {
        const couponPlan = coupon?.coupon_plan?.[0] || {};
        const plan = plans.find(plan => plan.plan_id == couponPlan.plan_id);
        console.log("plan", plan);
        history.push({
            pathname: "/subscription/checkout",
            state: {
                plan: plan,
                defaultCoupon: coupon.code,
                defaultCouponDuration: couponPlan?.plan_duration
            }
        });
    }, [coupon, history, plans])

    return (
        <div className="col-6 col-lg mb-2 offer-card">
            <Card className={classes.offerCardStyle}>
                <CardActionArea onClick={() => toggleModal(true)} className="z-index-9 h-100 align-items-start d-inline-flex">
                    <CardContent>
                        <div className="text-center mb-2 mt-1">
                            <img src={GiftBoxImg} alt="" />
                        </div>
                        <p className="text-center lheight-1i font-family-default font-size-1_2">
                            {title?.small?.[language] || title?.small?.["en"]}
                        </p>
                    </CardContent>
                    <div className={`offer-footer ${classes.offerFooterStyle}`}>
                        <Typography
                            component="p"
                            variant="h6"
                            className="text-center font-family-default"
                        >
                            {description?.footer?.[language] || description?.footer?.["en"]}
                        </Typography>
                    </div>
                </CardActionArea>
                <span className="offer-bg-circle-1"/>
                <span className="offer-bg-circle-2"/>
                <span className="offer-bg-circle-3"/>
            </Card>
            <ModalDialog
                isOpen={isModalOpen}
                onClose={() => toggleModal(false)}
                title={<Typography component={"p"} variant="h4" className="text-center">
                    {description?.popup?.title?.[language] || description?.popup?.title?.["en"]}
                </Typography>}
                content={(<div>
                    <div
                        dangerouslySetInnerHTML={{__html: parseHtml(description?.popup?.content_html, description?.popup?.content_html_data) }}/>
                    <div className="text-center mb-2">
                        <PrimaryButton
                            size="large"
                            className="px-4 py-2"
                            onClick={applyOffer}
                        >
                            {t("text_apply")}
                        </PrimaryButton>
                    </div>
                </div>)}
                dividers={false}
            />
        </div>
    );
};

export default SingleOffer;