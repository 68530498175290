// @ts-nocheck
import { Component } from 'react';
import moment from "moment";
import { loader } from "../../../library/util";
import { errorHandler } from '../../../library/response';
import { getBillingStartEndDate } from '../../../library/dateUtils';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import Eventer from '../../../library/Eventer';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import { ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL } from '../../../../_constants/permissions';

class DairyAccountController extends Component {

    constructor(props) {
        super(props);
        this.getBillDetail = this.getBillDetail.bind(this);
        this.state = {
            product_pay_modal: false,
            milk_payment_modal: false,
            milk_payment_adjust_modal: false,
            filter_modal: false,
            milk_payment_adjust_btn: false,
            sellers_bill: {},
            buyers_bill: {},
            sellers_bills: [],
            net_receivable: "0",
            total: {
                "milk": "0.00",
                "amount": "0.00",
                "purchase": "0.00",
                "payable": "0.00"
            },
            selectedDate: {
                year: this.cYear,
                month: this.cMonth
            },
            milk_payable: {},
            product_receivable: {},
            milk_receivable: {},
            product_payable: {},
            loan_detail: {},
            isFeatureSubscribed: checkFeatureInSubscription("dairy_billing", false),
            ...getBillingStartEndDate(props.billingCycle, moment())
        };
    }

    componentDidMount () {
        analytics.setScreenName("DairyAccount");
        this.getBillDetail();
        Eventer.on("customerBillPaid", this.getBillDetail);
        Eventer.on("customerBillReceived", this.getBillDetail);
    }

    componentWillUnmount () {
        Eventer.off("customerBillPaid", this.getBillDetail);
        Eventer.off("customerBillReceived", this.getBillDetail);
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            Eventer.emit("dairyBillDateChanged", {
                startDate,
                endDate
            });
            this.getBillDetail();
        });
    }

    async getBillDetail () {
        if(!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            let { startDate, endDate } = this.state;
                startDate = startDate.format('DD-MM-YYYY');
                endDate = endDate.format('DD-MM-YYYY');

            let filterStr = encodeURI(`start_date=${startDate}&end_date=${endDate}`);
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/accounts?${filterStr}`, {
                withAuth: true,
                version: "v3"
            });
            if (status) {
                this.setState({
                    ...data
                });
            } else if(error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error)
        } finally {
            loader.hide();
        }
    }

    pushPage = (e, Compo, props) => {
        e.preventDefault();
        this
            .props
            .navigator
            .pushPage({
                component: Compo,
                props: props
            }, { animation: 'fade' });
    }

    gotoSellersBill = () => {
        const { startDate, isBrowser } = this.state;
        const { hasAccess } = this.props;
        if(!isBrowser && hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)) {
            this.props.history.push({
                pathname: "/dairy/bill/sellersBill",
                state: {
                    startDate: startDate.toString()
                }
            });
        }
    }

    gotoBuyersBill = () => {
        const { startDate, isBrowser } = this.state;
        const { hasAccess } = this.props;
        if(!isBrowser && hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)) {
            this.props.history.push({
                pathname: "/dairy/bill/buyersBill",
                state: {
                    startDate: startDate.toString()
                }
            });
        }
        
    }

    onDeviceChange = (deviceTypes) => {
        this.setState({...deviceTypes});
    }
}

export default DairyAccountController;
