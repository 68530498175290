import React from 'react';
import DefaultCLR from '../DefaultCLR';
import DefaultSNF from '../DefaultSNF';

const DefaultCLRSNF = ({ defaultRateBy, rateSettings, validator }) => {
    if(defaultRateBy === "clr") {
        return (
            <DefaultCLR 
                rate_settings={rateSettings}
                validator={validator}/>
        );
    } else if(defaultRateBy === "snf") {
        return (
            <DefaultSNF
                rate_settings={rateSettings}
                validator={validator}/>
        );
    } else {
        return null;
    }
};

export default DefaultCLRSNF;