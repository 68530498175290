// @ts-nocheck
import React, { useRef, useState } from "react";
import { Icon } from "react-onsenui";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { dataURItoBlob, getProfilePic } from "../../library/util";
import { updateProfilePic, updateProfilePicture } from "../../../actions/user";
import analytics from "../../library/firebase/analytics";
import { Fragment } from "react";
import ImageCropper from "../../common/ImageCropper";
import { errorHandler } from "../../library/response";
import { blobToDataURL } from "../../library/canvasUtils";
import { useAndroid } from "src/components/library/detect-device";

const ProfilePicture = ({  user, ...restProps }) => {
    const [cameraImage, setCameraImage] = useState(null); //"https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
    const [profilePic, setProfilePic] = useState(null);
    const avatarInputRef = useRef();
    const isAndroid = useAndroid();
    const openCamera = () => {
        analytics.logEvent("changeProfilePicture");
        try {
            if(isAndroid) {
                captureImageFromAndroid();
            } else {
                captureImageFromBrowser();
            }
        } catch(error) {
            Sentry.captureException(error);
        }
    };

    const captureImageFromAndroid = () => {
        // eslint-disable-next-line no-undef
        let srcType = Camera.PictureSourceType.CAMERA;
        const options = {
            targetHeight: 500,
            targetWidth: 500,
            quality: 90,
            // eslint-disable-next-line no-undef
            destinationType: Camera.DestinationType.DATA_URL,
            sourceType: srcType,
            // eslint-disable-next-line no-undef
            encodingType: Camera.EncodingType.JPEG,
            // eslint-disable-next-line no-undef
            mediaType: Camera.MediaType.PICTURE,
            allowEdit: false,
            correctOrientation: false  //Corrects Android orientation quirks
        };

        navigator.camera.getPicture((imageUri) => {
            imageUri = "data:image/jpeg;base64," + imageUri;
            setCameraImage(imageUri);
        }, error => {
            if(error !== "No Image Selected" || error !== 20) {
                Sentry.captureException(error);
            }
        }, options);
    };

    const captureImageFromBrowser = () => {
        avatarInputRef.current.click();
    }

    const onImageSelectFromBrowser = async (event) => {
        const selectedFile = avatarInputRef.current.files?.[0];
        if(selectedFile) {
            const imgBase64 = await selectedFile.convertToBase64();
            setCameraImage(imgBase64.result);
        }
    }

    const updateProfilePic = (croppedImage) => {
        try {
            setCameraImage(null);
            analytics.logEvent("updateProfilePic");
            blobToDataURL(croppedImage, (imageUri) => {
                setProfilePic(imageUri);
                const blobImage = dataURItoBlob(imageUri);
                const formData = new FormData();
                formData.append("image", blobImage);
                restProps.updateProfilePicture(formData, user.customer_id);
            });
        } catch (error) {
            errorHandler(error);
        }
    };

    const cancelCropping = () => {
        setCameraImage(null);
    };
    
    return (
        <Fragment>
            <div className="user-avtar text-center">
                <div className="user-avtar-box border rounded-circle border-secondary" onClick={openCamera}>
                    <img className="img-responsive"
                        src={profilePic || getProfilePic(user)} alt="" />
                    <div className="edit-profile-pic">
                        <button type="button" aria-label="Select profile picture">
                            <Icon icon="md-camera"/>
                        </button>
                        <input
                            type="file"
                            ref={avatarInputRef}
                            className="sr-only"
                            aria-label="Select profile picture"
                            accept="image/*"
                            capture="user"
                            onChange={onImageSelectFromBrowser}
                        />
                    </div>
                </div>
            </div>
            {cameraImage ? 
                <ImageCropper
                    cropShape="round"
                    imgSrc={cameraImage} 
                    onCroppDone={updateProfilePic}
                    onCancel={cancelCropping} /> : null}
        </Fragment>
    );
};

ProfilePicture.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const { userReducer: { user = {} } } = state;
    return {
        user: user ? user : {}
    };
};

const mapDispatchToProps = ({
    updateProfilePic,
    updateProfilePicture
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);